<div id="tenant" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" (click)="goBack()">
                    <mat-icon>{{'arrow_back' | translate}}</mat-icon>
                </button>


                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="pageType ==='edit'">
                        <!-- {{tenunt.name}} -->
                    </div>
                    <div class="h2" *ngIf="pageType ==='new'">
                        {{'PREREQUISITE_ENTRY.prerequisiteDetails' | translate}}
                    </div>
                    <div class="subtitle secondary-text">
                        <span>{{'PREREQUISITE_ENTRY.prerequisiteDetails' | translate}}</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->
            <!-- [disabled]="tenantForm.invalid" -->
            <div class="button-container">
                <mat-spinner *ngIf="isLoading" strokeWidth="2" [diameter]="25"></mat-spinner>
                <button mat-raised-button class="save-tenant-button" [disabled]="!isValidForm() || isLoading"
                    *ngIf="pageType ==='new'" (click)="addPrerequisite()">
                    <span>{{'PREREQUISITE_ENTRY.add' | translate}}</span>
                </button>

                <button mat-raised-button class="save-tenant-button"
                    [disabled]="!isValidForm() || isLoading" *ngIf="pageType ==='edit'"
                    (click)="savePrerequisite()">
                    <span>{{'PREREQUISITE_ENTRY.save' | translate}}</span>
                </button>
            </div>

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="PrerequisiteForm" [formGroup]="PrerequisiteForm" class="tenant w-100-p" fxLayout="column" fxFlex>
                    <div class="tab-content" fusePerfectScrollbar>

                       
                      
                        <div class="p-24">
                            
                                <name-language-editer #nameLanguageEditor [data]="Prerequisite.name"></name-language-editer>
                            
                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>{{'PREREQUISITE_ENTRY.description' | translate}}</mat-label>
                                    <input matInput name="description" formControlName="description" required>
                                </mat-form-field>
                            
                                <!-- URL Text Box -->
                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>URL</mat-label>
                                    <input matInput formControlName="url" placeholder="Enter URL">
                                </mat-form-field>
                            
                                <!-- InApp Checkbox -->
                                <div class="checkbox-layout">
                                    <mat-checkbox formControlName="inAppURL">In App</mat-checkbox>                                
                                </div>
                            
                         
                        </div>
                    </div>
                </form>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>