import { FuseUtils } from "@fuse/utils";

export class Permission {
    constructor(permission?)
    {
        permission = permission || {};
        this.id = permission.id || 0;//FuseUtils.generateGUID();
        this.name = permission.name || '';
        
    }
    id : string;    
    name: string;
}