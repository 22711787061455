import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { GroupService } from 'app/model/GroupService';
import { Location } from 'app/model/Location';
import { apiUrls } from 'app/shared/constants/apiUrls';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LocationService implements Resolve<any>{

    locations: Location[] = new Array<Location>();
    location: Location = new Location();
    onLocationChanged: BehaviorSubject<any>;
    Id:string
    groupServices : GroupService[] = new Array<GroupService>();
    groupService : GroupService  =   new GroupService() ;
    onGroupServiceChanged: BehaviorSubject<any>;
    currentPage: number = 1;
    pageSize : number = 10;

    /**
      * Constructor
      *
      * @param {HttpClient} _httpClient
      */
    constructor(
        private _httpClient: HttpClient
    ) {
        // Set the defaults
        this.onLocationChanged = new BehaviorSubject({});
    }

    /**
      * Resolver
      *
      * @param {ActivatedRouteSnapshot} route
      * @param {RouterStateSnapshot} state
      * @returns {Observable<any> | Promise<any> | any}
      */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getLocationsWithoutPagenation()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }


    getAllLocations(): Observable<any> {
        return this._httpClient.get(environment.serviceUrl3 + apiUrls.location)
    }

    getLocations(pageIndex ,pageSize): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.location + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize)
                .subscribe((response: any) => {
                    this.locations = response;
                    this.onLocationChanged.next(this.locations);
                    resolve(response);
                }, reject);
        });
    }

    getLocationsWithoutPagenation(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.location )
                .subscribe((response: any) => {
                    this.locations = response;
                    this.onLocationChanged.next(this.locations);
                    resolve(response);
                }, reject);
        });
    }

    getLocationsSearch(pageIndex ,pageSize ,search): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.location + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize + '&search=' + search)
                .subscribe((response: any) => {
                    this.locations = response;
                    this.onLocationChanged.next(this.locations);
                    resolve(response);
                }, reject);
        });
    }


    // get location by tenantid without pagenation
    getLocationsByTenantId(tenantId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.location +  apiUrls.tenant + tenantId  )
                .subscribe((response: any) => {
                    this.locations = response;
                    this.onLocationChanged.next(this.locations);
                    resolve(response);
                }, reject);
        });
    }

    getLocationsByTenant(tenantId: string , pageIndex ,pageSize): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.location +  apiUrls.tenant + tenantId + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize )
                .subscribe((response: any) => {
                    this.locations = response;
                    this.onLocationChanged.next(this.locations);
                    resolve(response);
                }, reject);
        });
    }

    getLocationsByTenantSearch(tenantId: string , pageIndex ,pageSize , search): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.location +  apiUrls.tenant + tenantId + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize + '&search=' + search)
                .subscribe((response: any) => {
                    this.locations = response;
                    this.onLocationChanged.next(this.locations);
                    resolve(response);
                }, reject);
        });
    }
    getMediaGroups(tenantId: string): Promise<any> { 
        return new Promise((resolve, reject) => {
          this._httpClient.get(`${environment.serviceUrl4}${apiUrls.mediaItem}/${apiUrls.mediaGroup}?tenantId=${tenantId}`)
            .subscribe({
              next: (response) => resolve(response),
              error: (error) => reject(error)
            });
        });
      }
      getNewsGroups(tenantId: string): Promise<any> {
        return new Promise((resolve, reject) => {
          this._httpClient.get(`${environment.serviceUrl4}${apiUrls.mediaGroup}?tenantId=${tenantId}`)
            .subscribe({
              next: (response) => resolve(response),
              error: (error) => reject(error)
            });
        });
      }
    getLocationById(id ,isDefaultLanguage = false): Promise<any> {
        return new Promise((resolve, reject) => {
            let headers: HttpHeaders = new HttpHeaders();
            if(isDefaultLanguage){
                headers = headers.append("without-accept-language" , "true");
            }
            this._httpClient.get(environment.serviceUrl3 + apiUrls.location + id , {headers : headers})
                .subscribe((response: any) => {
                    this.location = response;
                    this.onLocationChanged.next(this.location);
                    resolve(response);
                }, reject);
        });
    }

    // Delete location
    deleteLocation(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete(environment.serviceUrl3 + apiUrls.location + id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    // Update Location 
    saveLocation(location): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(environment.serviceUrl3 + apiUrls.location , location)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    saveLocationService(service): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(environment.serviceUrl3 + apiUrls.location + apiUrls.locationService, service)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    addLocationService(service): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.serviceUrl3 + apiUrls.location + apiUrls.service, service)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    // save location
    addLocation(location): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.serviceUrl3 + apiUrls.location, location)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getServicesByLocationId(locationId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.location + '/' + locationId + '/services')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addLocationWorkingDay(workingDays): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.serviceUrl3 + apiUrls.workingDay , workingDays)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    savesoltTime(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.serviceUrl3 + apiUrls.location + apiUrls.dailyEvents, data)
            .subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }
  //Methode to add service group
  addGroupService(serviceGroup): Promise<any>{
    return new Promise((resolve, reject) => {
        this._httpClient.post(environment.serviceUrl3 + apiUrls.groupService, serviceGroup)
            .subscribe((response: any) => {
                resolve(response);
            }, reject);
    });
   }
        
   //Methode to update service group
  saveGroupService(serviceGroup): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put(environment.serviceUrl3 + apiUrls.groupService , serviceGroup)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

     //Methode to get service group by id
     getGroupServiceByLocationId(LocationId , pageIndex ,pageSize): Promise<any>{
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.groupService + '/Location/' + LocationId + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize )
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    } 

     //Search Methode to get service group by id
     searchGroupServiceByLocationId(LocationId , pageIndex ,pageSize ,search): Promise<any>{
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.groupService + '/Location/' + LocationId + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize + '&search=' + search)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    } 

    //Methode to get service group by id Pagination
    getGroupServiceByLocationIdPagination(LocationId ): Promise<any>{
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.groupService + '/Location/' + LocationId  )
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    } 
  
       //Methode to get all service group 
    getGroupServiceById(id): Promise<any>{
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.groupService + '/' + id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }


    getsoltTime(Id,Type): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl + apiUrls.location +Type +  '/' + Id + '/'+apiUrls.dailyEvent)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
}


deletedailyEvent(id): Promise<any>
{
    return new Promise((resolve, reject) => {
        this._httpClient.delete(environment.serviceUrl3 + apiUrls.location+apiUrls.dailyEvent + '?'+"Id=" +id )
        .subscribe((response: any) => {
            resolve(response);
        }, reject);
    });}


        // Update slot 
        Updateslot (data): Promise<any> {
            return new Promise((resolve, reject) => {
                this._httpClient.put(environment.serviceUrl3 + apiUrls.location+apiUrls.dailyEvent , data)
                    .subscribe((response: any) => {
                        resolve(response);
                    }, reject);
            });
        }
     //Methode to delete service group 
    deleteGroupService(id): Promise<any>{
      return new Promise((resolve, reject) => {
        this._httpClient.delete(environment.serviceUrl3 + apiUrls.groupService +"/"+ id )
            .subscribe((response: any) => {
                resolve(response);
            }, reject);
    });
}

   }



        