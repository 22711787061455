import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule } from '@fuse/components';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CallApiService } from './call-api.service';
import { CallApiComponent } from './call-api.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTabsModule,MatTabGroup} from '@angular/material/tabs';




const routes = [
    {
        path     : 'callApi',
        component: CallApiComponent,
        resolve  : {
            callApi: CallApiService
        }
    }
];

@NgModule({
    declarations: [
        CallApiComponent,
        
    ],
    imports     : [
        RouterModule.forChild(routes),

    FuseSharedModule,
    MatToolbarModule,
    MatTooltipModule,
    TranslateModule,
    FuseConfirmDialogModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatButtonModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule
    ],
    providers   : [
        CallApiService
    ]
})
export class CallApiModule
{
}
