import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Workflow } from 'app/model/Workflow';
import { apiUrls } from 'app/shared/constants/apiUrls';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class WorkFlowService {


  workflows : Workflow[] = new Array<Workflow>();
  workflow : Workflow  =   new Workflow() ;

  onWorkflowChanged: BehaviorSubject<any>;

   /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient )
  {
    this.onWorkflowChanged = new BehaviorSubject({});
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
  {
      return new Promise<void>((resolve, reject) => {

          Promise.all([
              this.getWorkflow()
          ]).then(
              () => {
                  resolve();
              },
              reject
          );
      });
  }


getAllWorkflows() :Observable<any>{
 return this._httpClient.get(environment.serviceUrl3 + apiUrls.workflow )
}

getWorkflow(): Promise<any>
{
    return new Promise((resolve, reject) => {
        this._httpClient.get(environment.serviceUrl3 + apiUrls.workflow)
            .subscribe((response: any) => {
                this.workflows = response;
                this.onWorkflowChanged.next(this.workflows);
                resolve(response);
            }, reject);
    });
}

getWorkflowById(id,isDefaultLanguage = false): Promise<any>
{
    return new Promise((resolve, reject) => {
      let headers: HttpHeaders = new HttpHeaders();
      if(isDefaultLanguage){
          headers = headers.append("without-accept-language" , "true");
      }
        this._httpClient.get(environment.serviceUrl3 + apiUrls.workflow + '/' + id, {headers : headers} )
            .subscribe((response: any) => {
                this.workflows = response;
                this.onWorkflowChanged.next(this.workflows);
                resolve(response);
            }, reject);
    });
}


// Delete workflow
deleteWorkflow(id): Promise<any>
{
   return new Promise((resolve, reject) => {
       this._httpClient.delete(environment.serviceUrl3 + apiUrls.workflow +"/"+ id )
           .subscribe((response: any) => {
               resolve(response);
           }, reject);
   });
}

saveWorkflow(workflow): Promise<any> {
  return new Promise((resolve, reject) => {
    this._httpClient.put(environment.serviceUrl3 + apiUrls.workflow , workflow)
      .subscribe((response: any) => {
        resolve(response);
      }, reject);
  });
}

addWorkflow(workflow): Promise<any>
{
    return new Promise((resolve, reject) => {
        this._httpClient.post(environment.serviceUrl3 + apiUrls.workflow, workflow)
            .subscribe((response: any) => {
                resolve(response);
            }, reject);
    });
}


}
