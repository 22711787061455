import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { List } from 'lodash';
import { v4 as uuid } from 'uuid';


@Component({
    selector: 'name-language-editer',
    templateUrl: './name-language-editer.component.html',
})
export class NameLanguageEditer  implements OnChanges {
@ViewChild("manageEntityForm") manageEntityForm: FormGroup;
@Input("data") data = "";
deserializedData=[];
languages = [
    { name: "English", code: "en" },
    { name: "العربية", code: "ar" }
];

constructor(
    private activeRouter: Router,  
    private activeRoute: ActivatedRoute,
    private translate:TranslateService
    ) {
       
}
    generateGuid(){
        return uuid();
    }
  
    removeRow(row) {
        this.deserializedData.splice(this.deserializedData.indexOf(row), 1);
        this.deserializedData = [...this.deserializedData];
    }

    ngOnChanges(changes: SimpleChanges): void {   
        if(this.data == ""){
            return;
        }
        try{
            this.deserializedData =  JSON.parse(this.data);                    
        } 
        catch{

            this.deserializedData.push({name : this.data , languageCode:"ar" , guid : uuid()});        
        }
    }

    addRow(){
        this.deserializedData.push({name : "" , languageCode:"" , guid : uuid()})
    }
    
}