import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { KeypadEntryComponent } from '../keypad-entry/keypad-entry.component';
import { KeypadService } from '../keypad.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
declare var window: any;

@Component({
  selector: 'app-no-show-dialog',
  templateUrl: './no-show-dialog.component.html',
  styleUrls: ['./no-show-dialog.component.scss']
})
export class NoShowDialogComponent implements OnInit {
  Tickets: any;
  customerPhoneNumber : any;
  ticketNumber: any;

  CounterNumberConfigration: number;
  TicketId: any;
  TicketNumber: any = this.translate.instant('KEYPAD.noTickets');
  _start: boolean = true;


  clock: any;
  minutes: any = '00';
  seconds: any = '00';
  milliseconds: any = '00';

  //  start: boolean;
  showTimerControls: boolean;
  waitingTime: any;
  reset: NodeJS.Timeout;

  constructor(private dialogRef: MatDialogRef<KeypadEntryComponent> ,
     private keypadService: KeypadService,
     private _matSnackBar: MatSnackBar,
     private translate: TranslateService) { }

  ngOnInit(): void {

    this.refresh();
  }
  close() {
    document.getElementsByClassName("animate__animated")[0].classList.remove("animate__slideInRight");
    document.getElementsByClassName("animate__animated")[0].classList.add("animate__slideOutRight");
    setTimeout(() => {this.dialogRef.close();}, 1000);
  }

  recall(ticketId:string) {

    this.keypadService.Recall(ticketId).then(res => {
      console.log(res);
      this.startTimer();
      this.customerPhoneNumber = res.phone;
      this.ticketNumber  = res.ticketNumber
      this._matSnackBar.open(this.translate.instant('KEYPAD.recallSuccess'), this.translate.instant('ok'), {
        verticalPosition: 'top',
        duration: 2000
      });
    })
  }
  delete(ticketId:any){
    var d = ticketId.toString();
    var HoldUsersDB= window.openDatabase("NotCompleatedTickits", '1.0', "My WebSQL Database", 2 * 1024 * 1024);
    HoldUsersDB.transaction((tx) => {
      tx.executeSql('DELETE FROM NoShowDBUsers WHERE TicketId = ?', [ticketId]);
    });
    this.refresh();
    this.close();
  }

  refresh(){
    // var NoShowDB= window.openDatabase("NotCompleatedTickits", '1.0', "My WebSQL Database", 2 * 1024 * 1024);
    // NoShowDB.transaction((tx) => {
    //   tx.executeSql('SELECT * FROM NoShowDBUsers',
    //   [], (tx, results) => {
    //       if (results.rows.length > 0) {
    //         this.Tickets = results.rows;
    
    //       }
    //   })
    // });
    this.keypadService.getAllNotShowing()
    .then((res) => {  
      if(res){
        this.Tickets=res
      }
      }).catch(err => {
      this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
          verticalPosition: 'top',
          duration: 2000
      });
  });


  }

  clearTimer() {
    this.running = false;
    this.startText = 'Start';
    this.counter = undefined;
    this.milliseconds = '00',
      this.seconds = '00',
      this.minutes = '00';
    this.laps = [];
    clearInterval(this.timerRef);
    this.customerPhoneNumber = null;
  }

  laps: any = [];
  counter: number = 10;
  timerRef;
  running: boolean = false;
  startText = 'Start';


  startTimer() {
    // const source = timer(0, Date.now());
    // const subscribe = source.subscribe(val => console.log(val));
    this.running = !this.running;
    if (this.running) {
      const startTime = Date.now() - (this.counter || 0);
      this.timerRef = setInterval(() => {
        this.counter = Date.now() - startTime;
        // console.log(Date.now());
        // console.log(startTime);
        // console.log(this.counter);
        this.milliseconds = Math.floor(Math.floor(this.counter % 1000) / 10).toFixed(0);
        this.minutes = Math.floor(this.counter / 60000);
        this.seconds = Math.floor(Math.floor(this.counter % 60000) / 1000).toFixed(0);
        if (Number(this.minutes) < 10) {
          this.minutes = '0' + this.minutes;
        } else {
          this.minutes = '' + this.minutes;
        }
        if (Number(this.milliseconds) < 10) {
          this.milliseconds = '0' + this.milliseconds;
        } else {
          this.milliseconds = '' + this.milliseconds;
        }
        if (Number(this.seconds) < 10) {
          this.seconds = '0' + this.seconds;
        } else {
          this.seconds = '' + this.seconds;
        }
      });
    } else {
      this.startText = 'Resume';
      clearInterval(this.timerRef);
    }
  }
}
