<div id="reset-password" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">

    <div id="reset-password-intro" fxFlex fxHide fxShow.gt-xs>

        <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <img src="assets/images/logos/white-logo.png">
        </div>

        <div class="title" [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}" style="color: white !important;">
            {{'LOGIN.welcomTOSaasQueue' | translate}}
        </div>

        <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
            <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat,
            vel convallis elit fermentum pellentesque. Sed mollis velit facilisis facilisis viverra. -->
        </div>

    </div>

    <div id="reset-password-form-wrapper" fusePerfectScrollbar
         [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div id="reset-password-form">

            <div class="logo" fxHide.gt-xs>
                <img src="assets/images/logos/white-logo.png">
            </div>

            <div class="title">RESET YOUR PASSWORD</div>

            <form name="resetFormForm" [formGroup]="resetFormForm" novalidate>

              
                    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p"  [formGroup]="resetFormForm" >
                        <mat-label>{{'USER_ENTRY.password' | translate}}</mat-label>
                        <input matInput placeholder="{{'USER_ENTRY.password' | translate}}" type="password" #password
                            name="password"
                            matTooltip="{{'USER_ENTRY.passwordPattern' | translate}}"
                            formControlName="password" required matTooltipPosition="above">
                    </mat-form-field> 


                  
                
               
                    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p"  [formGroup]="resetFormForm">
                        <mat-label>{{'USER_ENTRY.confirmPassword' | translate}}</mat-label>
                        <input matInput placeholder="{{'USER_ENTRY.confirmPassword' | translate}}" type="password" #confermPassword
                            name="confirmPassword" formControlName="passwordConfirm" required>
                    </mat-form-field>
               
              


                <button mat-raised-button class="submit-button" color="accent" [disabled]="resetFormForm.invalid" (click)="resetpassword()"
                        aria-label="RESET MY PASSWORD" >
                    RESET MY PASSWORD
                </button>
            </form>

            <!-- <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/pages/auth/login-2'">Go back to login</a>
            </div> -->

        </div>
    </div>
</div>
