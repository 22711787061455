import { FuseUtils } from "@fuse/utils";

export class User {
    constructor(user?)
    {
        user = user || {};
        this.id = user.id || '';
        this.userId = user.userId || '';
        this.userName = user.userName || '';
        this.arName = user.arName || '';
        this.normalizedUserName = user.normalizedUserName || '';
        this.phone = user.phoneNumber || '';
        this.email = user.email || '';
        this.normalizedEmail = user.normalizedEmail || '';
        this.password = user.password || '';
        this.passwordConfirm = user.passwordConfirm || '';
        this.passwordHash = user.passwordHash || '';
        this.tenantId = user.tenantId || '';
        this.locationId = user.locationId || '';
        this.role = user.role || '';
    }
    id : string;   
    userId :string; 
    userName: string;
    arName:string;
    normalizedUserName: string;
    phone :string;
    email: string;
    normalizedEmail: string;
    password: string;
    passwordConfirm: string;
    passwordHash : string;
    tenantId: string;
    locationId: string;
    role:string;
    applicationRoles :any;
}