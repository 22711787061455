<div id="banner" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/banner'">
                    <mat-icon>arrow_back</mat-icon>
                </button>


                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="pageType ==='edit'">
                        <!-- {{tenunt.name}} -->
                    </div>
                    <div class="h2" *ngIf="pageType ==='new'">
                        {{'BANNER_ENTRY.newBanner' | translate}}
                    </div>
                    <div class="subtitle secondary-text">
                        <span> {{'BANNER_ENTRY.bannerDetail' | translate}}</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->
            <!-- [disabled]="bannerForm.invalid" -->
            <div class="button-container">
                <mat-spinner *ngIf="isLoading" strokeWidth="2" [diameter]="25"></mat-spinner>
                <button mat-raised-button class="save-banner-button" [disabled]="bannerForm.status == 'INVALID' || isLoading"
                    *ngIf="pageType ==='new'" (click)="addBanner()">
                    <span>{{'BANNER_ENTRY.add' | translate}}</span>
                </button>

                <button mat-raised-button class="save-banner-button"
                    [disabled]="(bannerForm.status == 'INVALID' && bannerForm.pristine) || isLoading" *ngIf="pageType ==='edit'"
                    (click)="saveBanner()">
                    <span>{{'BANNER_ENTRY.save' | translate}}</span>
                </button>
            </div> 
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="bannerForm" [formGroup]="bannerForm" class="banner w-100-p" fxLayout="column" fxFlex>
                    <table style="width:100% !important">
                        <tr>
                            <td style="width:10%" *ngIf="banner.logo" >
                                <input hidden src="banner.logo" type="file" #fileInput [accept]="allowedFiles"
                                (change)="uploadImage($event , banner.id)" />
                                <img   src="{{'data:image/jpg;base64,' + banner.logo}}" (click)="fileInput.click()"   style=" width: 58%; margin-left: 5px;" />
                                <!-- assets//images//backgrounds/default-upload-image.jpg-->

                            </td>
                            <td style="width:10%" *ngIf="!banner.logo" >
                                <input hidden src="banner.logo" type="file" #fileInput [accept]="allowedFiles"
                                (change)="uploadImage($event , banner.id)"  />
                                <img   src="../../../../assets/images/backgrounds/default-upload-image.jpg" (click)="fileInput.click()"   style=" width: 58%; margin-left: 5px;" />
                                <!-- assets//images//backgrounds/default-upload-image.jpg-->

                            </td>

                            <td style="position: relative !important;top: 8px !important;">
                                <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>{{'BANNER_ENTRY.bannerName' | translate}}</mat-label>
                                    <input matInput placeholder="{{'BANNER_ENTRY.bannerName' | translate}}" name="name"
                                        formControlName="name" required>
                                </mat-form-field>                           
                             </td>
                        </tr>
            
                    </table> 
                   
                   
                   
<!--                    
                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                            <mat-label>{{'BANNER_ENTRY.bannerName' | translate}}</mat-label>
                            <input matInput placeholder="{{'BANNER_ENTRY.bannerName' | translate}}" name="name"
                                formControlName="name" required>
                        </mat-form-field>

                    </div> -->
                </form>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>