import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginVm } from 'app/model/user/login.vm';
import { apiUrls } from 'app/shared/constants/apiUrls';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private _httpClient: HttpClient) { }


  Login(loginModel: LoginVm) {

    const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded;')
    .set("Authorization", "Basic " + btoa("qaas.api:D04CDB91-F305-4997-9F35-BB257786DDAC"));
    var body = "username=" + loginModel.Username.trim() + "&password=" + loginModel.Password +
      "&grant_type=" + loginModel.Grant_type + "&scope=" + '';

      return this._httpClient.post(environment.IdentityUrl + apiUrls.login, body, { headers: headers });

  }
}
