import { NgModule } from '@angular/core';
import { TenantComponent } from './tenant/tenant-list/tenant-list.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { AgmCoreModule } from '@agm/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { TenantEntryComponent } from './tenant/tenant-entry/tenant-entry.component';
import { PrerequisiteEntryComponent } from './Prerequisite/Prerequisite-entry/Prerequisite-entry.component';
import { LocationComponent } from './location/location-list/location-list.component';
import { LocationEntryComponent } from './location/location-entry/location-entry.component';
import { TenantService } from './tenant/tenant.service';
import { LocationService } from './location/location.service';

import { ServiceComponent } from './service/service-list/service-list.component';
import { ServiceEntryComponent } from './service/service-entry/service-entry.component';
import { AssignServiceToLocationComponent } from './service/assign-service-to-location/assign-service-to-location/assign-service-to-location.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { UserComponent } from './user/user-list/user-list.component';
import { UserEntryComponent } from './user/user-entry/user-entry.component';

import { AssignServiceToUserComponent } from './service/assign-service-to-user/assign-service-to-user/assign-service-to-user.component';
import { TranslateModule } from '@ngx-translate/core';
import { NameLanguageEditerModule } from 'app/component/name-language-editer/name-language-editer.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseConfirmDialogModule } from '@fuse/components';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SoltTimeEditorModule } from 'app/component/slot-time-editor/slot-time-editor.module';
import { GroupServiceEntryComponent } from './location/group-service-entry/group-service.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { GroupServiceComponent } from './location/group-service-list/group-service-list.component';
import { ServiceAddRequisiteComponent } from './service/service-addRequisite/service-addRequisite.component';
import { PrerequisiteEditerModule } from 'app/component/prerequisite_editer/prerequisite-editer.module';
import { PrerequisiteComponent } from './Prerequisite/service-list/Prerequisite-list.component';
import { AdvertisementlistComponent } from './Advertisement/Advertisement-list/Advertisement-list.component';
import { AdvertisementEntryComponent } from './Advertisement/Advertisement-entry/Advertisement-entry.component';
// import { AdsmediaitemComponent } from './Advertisement/Adsmediaitem-list/Ads-media-item​.component';
import { AdsmediaitemComponent } from './Advertisement/Adsmediaitem-list/Ads-media-item​.component';
import { AdsMediaItemEntryComponent } from './Advertisement/AdsMediaItem​Enty/AdsMediaItem​Enty.component';

import{AdsMediaItemVidioComponent} from './Advertisement/AdsMediaVidio/AdsMediaItemVidio.component'
const routes = [
  {
    path: 'tenant',
    component: TenantComponent
  },
  {
    path: 'tenant/add',
    component: TenantEntryComponent
  },
  {
    path: 'tenant/edit/:id',
    component: TenantEntryComponent
  },
  // {
  //   path: 'location',
  //   component: LocationComponent
  // },
  // {
  //   path: 'location/add',
  //   component: LocationEntryComponent
  // },
  // {
  //   path: 'location/edit/:id',
  //   component: LocationEntryComponent
  // },
  // {
  //   path: 'location/add/tenant/:tenantId',
  //   component: LocationEntryComponent
  // },
  {
    path: 'tenant/:tenantId/location',
    component: LocationComponent
  }
  ,
  {
    path: 'tenant/:tenantId/location/add',
    component: LocationEntryComponent
  },
  {
    path: 'tenant/:tenantId/location/:id',
    component: LocationEntryComponent
  },

  {
    path: 'service/tenant/:tenantId',
    component: ServiceComponent
  },
  {
    path: 'service/tenant/:tenantId/edit/:id',
    component: ServiceEntryComponent
  },
  {
    path: 'service/tenant/:tenantId/add',
    component: PrerequisiteEntryComponent
  },
  {
    path: 'Prerequisite/service/:serviceId/add',
    component: PrerequisiteEntryComponent
  },

  {
    path: 'Prerequisite/service/:serviceId/edit/:id',
    component: PrerequisiteEntryComponent
  },

  {
    path: 'AddRequisite/tenant/:tenantId/edit/:id',
    component: ServiceAddRequisiteComponent
  },
  {
    path: 'service/edit/:id',
    component: ServiceEntryComponent
  },
  {
    path: "service/tenant/:tenantId/location/:locationId",
    component: AssignServiceToLocationComponent
  },
  {
    path: 'tenant/:tenantId/user',
    component: UserComponent
  },
  {
    path: 'tenant/:tenantId/user/add',
    component: UserEntryComponent
  },
  {
    path: 'tenant/:tenantId/user/:userId',
    component: UserEntryComponent
  },
  {
    path: 'user/:userId',
    component: UserEntryComponent
  },
  {
    path: 'location/:locationId/user',
    component: UserComponent
  },
  {
    path: 'location/:locationId/user/add',
    component: UserEntryComponent
  },
  {
    path: 'location/:locationId/user/:userId',
    component: UserEntryComponent
  },
  {
    path: 'tenant/:tenantId/location/:locationId/user',
    component: UserComponent
  },
  {
    path: 'tenant/:tenantId/location/:locationId/user/add',
    component: UserEntryComponent
  },
  {
    path: 'tenant/:tenantId/location/:locationId/user/:userId',
    component: UserEntryComponent
  },
  {
    path: 'tenant/:tenantId/location/:locationId/user/:userId/Services',
    component: AssignServiceToUserComponent
  },
  {
    path:  'group-service/tenant/:tenantId/location/:locationId/add',
    component: GroupServiceEntryComponent
  },
  {
    path:  'group-service/id/:id/tenant/:tenantId/location/:locationId/edit',
    component: GroupServiceEntryComponent
  },
  {
    path:  'group-service/tenant/:tenantId/location/:locationId',
    component: GroupServiceComponent
  },
  {
    path: 'prerequisite/service/:serviceId',
    component: PrerequisiteComponent
  },
  {
    path: 'Advertisementlist/tenant/:tenantId',
    component: AdvertisementlistComponent
  },
  
  {
    path: 'AdvertisementEntry/tenant/:tenantId/add',
    component: AdvertisementEntryComponent
  },
  {
    path: 'Adsmedia/mediaGroupType/:mediaGroupType/id/:id',
    component: AdsmediaitemComponent
  },
  
  {
    path:'Adsmediaitem/mediaGroupId/:mediaGroupId/add',
    component: AdsMediaItemEntryComponent
  },

  {
    path:'Adsmediaitem/id/:id/mediaGroupId/:mediaGroupId/edit',
    component: AdsMediaItemEntryComponent
  },
  {
    path:'AdsmediaVidio/mediaGroupId/:mediaGroupId/add',
    component: AdsMediaItemVidioComponent
  },
  
  
];
@NgModule({

  declarations: [
    TenantComponent,
    TenantEntryComponent,
    LocationComponent,
    LocationEntryComponent,
    ServiceComponent,
    ServiceEntryComponent,
    AssignServiceToLocationComponent,
    AssignServiceToUserComponent,
    GroupServiceEntryComponent,
    GroupServiceComponent,
    AdvertisementlistComponent,
    AdvertisementEntryComponent,
    AdsMediaItemEntryComponent,
    ServiceAddRequisiteComponent,
    PrerequisiteComponent,
    PrerequisiteEntryComponent,
    AdsmediaitemComponent,
    AdsMediaItemVidioComponent
    
    
  ],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    MatTableModule,
    MatTooltipModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
    }),
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    FuseWidgetModule,
    DragDropModule, 
    NameLanguageEditerModule,
    SoltTimeEditorModule,
    PrerequisiteEditerModule,
    MatCheckboxModule,
    NgxChartsModule,
    FuseConfirmDialogModule,
    MatDialogModule,
    BrowserAnimationsModule,
    CommonModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    NgxSkeletonLoaderModule,
    MatCheckboxModule,
    MatDatepickerModule
    
    
  ],
  providers: [
    TenantService,
    LocationService
  ]

})
export class BranchesManagementModule { }

