import { Component, OnInit, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from '../../service.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ServiceDetailsPopupComponent } from 'app/modal/service-details-popup/service-details-popup/service-details-popup.component';
import { Location } from '@angular/common';
import { Location as LocationModel } from 'app/model/Location';
import { TenantService } from 'app/modules/branches-management/tenant/tenant.service';
import { Tenant } from 'app/model/Tenant';
import { LocationService } from 'app/modules/branches-management/location/location.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { locale as english } from '../../../../../main/home/i18n/en';
import { locale as turkish } from '../../../../../main/home/i18n/tr';
import { locale as arabic } from '../../../../../main/home/i18n/ar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { SoltTimeEditor } from 'app/component/slot-time-editor/slot-time-editor.component';

@Component({
  selector: 'app-assign-service-to-location',
  templateUrl: './assign-service-to-location.component.html',
  styleUrls: ['./assign-service-to-location.component.scss']
})
export class AssignServiceToLocationComponent implements OnInit {
  @ViewChild("soltTimeEditor") soltTimeEditor :SoltTimeEditor;
  TenantId: string;
  Tenant: Tenant;
  LocationId: string;
  Location: LocationModel;
  TenantServices = [];
  LocationServices = [];
  ChangeStatus: boolean;
  allLocationServices: any[];

  constructor(
    private route: ActivatedRoute,
    private serviceService: ServiceService,
    private tenantService: TenantService,
    private locationServices: LocationService,
    public dialog: MatDialog,
    private _location: Location,
    private _matSnackBar: MatSnackBar,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private translate: TranslateService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);
  }

  ngOnInit(): void {
    this.resetServices();
    this.route.paramMap.subscribe(params => {
      if (params.get('tenantId')) {
        this.TenantId = params.get('tenantId');
        this.getTenant();
      }
      if (params.get('locationId')) {
        this.LocationId = params.get('locationId');
        this.getLocation();
        this.getLocationServices(() => { this.getTenantServices() });
      }
    })
  }
  getTenant() {
    this.tenantService.getTenantById(this.TenantId).then(res => {
      this.Tenant = res;
    })
  }
  getLocation() {
    this.locationServices.getLocationById(this.LocationId).then(res => {
      this.Location = res;
    })
  }
  resetServices() {
    this.TenantServices = [];
    this.LocationServices = [];
  }
  getLocationServices(callBack?) {

    this.serviceService.getServicsByLocation(this.LocationId).then(res => {
      this.allLocationServices = res;
      res.forEach(element => {
        this.LocationServices.push({ id: element.id, serviceId: element.service.id, name: element.service.name });
      });
      if (callBack) {
        callBack();
      }
    })
  }

  getTenantServices() {
    this.serviceService.getServicByTenantWithoutPagination(this.TenantId).then(res => {
      if (res) {
        this.TenantServices = res.filter(x => !(this.LocationServices?.map(({ serviceId }) => serviceId).includes(x.id)));
      }
    });
  }


  goBack() {
    this._location.back();
  }

  openDialog(IsOpenDialog: boolean, e): void {
    this.ChangeStatus = true;
    if (IsOpenDialog) {
      if (!this.Tenant) {
        this.route.paramMap.subscribe(params => {
          if (params.get('tenantId')) {
            this.TenantId = params.get('tenantId');
            this.getTenant();
          }
        });
      } else
        this.openDialogTemplate(e);

    } else {
      this.deleteLocationService(e);
    }

  }
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  deleteLocationService(e) {
    let locationServiceId = e?.item?.element?.nativeElement?.name?.split('/')[0];
    if (locationServiceId) {
      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
        disableClose: false
      });
      this.confirmDialogRef.componentInstance.confirmMessage = this.translate.instant('CONFIRM_MSG.deleteLocationService');
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.serviceService.deleteLocationService(locationServiceId).then(() => {
            // Show the success message
            this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.locationServiceDeleted'), this.translate.instant('CONFIRM_MSG.ok'), {
              verticalPosition: 'top',
              duration: 2000
            });
            this.ngOnInit();
          })

        } else {
          this.ngOnInit()
        }
      })
    } else {
      this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.wentWrong'), this.translate.instant('ok'), {
        verticalPosition: 'top',
        duration: 2000
      });
    }
  }

  openDialogTemplate(e , editMood =false) {
    console.log(e);
  
    var dialogRef;
    if(editMood){
      let serviceId = e?.srcElement?.name?.split('/')[0];
      let serviceName = e?.srcElement?.name?.split('/')[1];
      var LocationService =  this.allLocationServices.find(x => x.id == serviceId)

       dialogRef = this.dialog.open(ServiceDetailsPopupComponent, {
        width: '1000px',
        minHeight: '80vh',
        data: { 
          Tenant: this.Tenant.name, 
          tenantId: this.TenantId,
          Location: this.Location.name,
          locationId: this.Location.id,
          Service: serviceName,
          serviceId: LocationService.serviceId,
          priority: LocationService.priority,
          startTime: LocationService.startTime.split('T')[1],
          endTime: LocationService.endTime.split('T')[1],        
          id: LocationService.id, 
          disableMessage: LocationService.disableMessage || "",
          isDisabled : LocationService.isDisabled,
          locationServiceProfile:LocationService.locationServiceProfile,
          slotTime : LocationService.slotTime,
          toleranceTiem : LocationService.toleranceTiem,
          parentService : LocationService.parentService,
          groupId:LocationService.groupId,
          serviceProfile : LocationService.serviceProfile,
          numberOfCounters :LocationService.numberOfCounters,
          inAppURL:LocationService.inAppURL,
          url:LocationService.url
          }
      });  
    }
    else{
      let serviceId = e?.item?.element?.nativeElement?.name?.split('/')[0];
      let serviceName = e?.item?.element?.nativeElement?.name?.split('/')[1];
       dialogRef = this.dialog.open(ServiceDetailsPopupComponent, {
        width: '1000px',
        minHeight: '80vh',
        data: { Tenant: this.Tenant.name, tenantId: this.TenantId, Location: this.Location.name, locationId: this.Location.id, Service: serviceName, serviceId: serviceId }
      });  
    }
    
    
    dialogRef.afterClosed().subscribe(result => {
      if (result == "canceled") {
        this.ngOnInit();
      }

    });
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
  saveService() {
    console.log(this.LocationServices);
  }
}

