export const apiUrls = {
   location : "Location/",
   tenant : "Tenant/" ,
   service : "Service/",
   services : "services/",
   login : "/connect/token",
   category : "Category",
   keypad : "keypad",
   call : "Call",
   ticket : "Ticket",
   transfer : "Transfer",
   transferService : "TransferService",
   transferUser: "TransferUser",
   transferCounter : "TransferCounter",
   closeCounter : "CloseCounter",
   endSession : "EndSession",
   recall : "Recall",
   user : "User",
   Accounts: "Accounts",
   UserServices:"UserServices",
   banner : "Banner",
   permission:"Permission",
   role:"Role",
   locationService : "LocationService",
   refresh : "Refresh",
   feedback : "CustomerFeedBack",
   workingDay:"Location/WorkingDay",
   Report:"Report/Dashboard",
   ReportUrl:"Report",
   callApi:"",
    getTiket: "Ticket/Ticket/",
    uploudexcel: "",
    template: "Template",
    dailyEvents:"DailyEvents",
    dailyEvent:"DailyEvent",
    

    workflow: "Workflow",
    groupService : "Location/ServiceGroup",
    UserImportFromExel : "/User/import-from-excel" ,
    publicHoliday : "/Location/PublicHoliday/",
    complete : "Complete",
    prerequisite:'prerequisite',
    inComplete : "InComplete",
    customerFeedBack:"CustomerFeedBack/",
    mediaGroup:"MediaGroup",
    mediaItem:"MediaItem",
    //mediaGroup:"MediaGroup",
    downloadmediaitem:"DownloadMediaItem",
    hold:"Hold",
    ticketId : "TicketId",
    counterId:"counterId",
    notShowing:"NotShowing",
    questionnaire:"Questionnaire"

};

