import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
// import { Keypad } from 'app/model/keypad';
import { apiUrls } from 'app/shared/constants/apiUrls';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KeypadService implements Resolve<any>{
 
//   keypads : Keypad[] = new Array<Keypad>();
//   keypad : Keypad  =   new Keypad() ;
  onKeypadChanged: BehaviorSubject<any>;
  isKeypadPage: boolean;

   /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
      private _httpClient: HttpClient
  )
  {
      // Set the defaults
      this.onKeypadChanged = new BehaviorSubject({});
  }

   /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getKeypads()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

  
  
  getKeypads(): Promise<any>
  {
      return new Promise((resolve, reject) => {
     
      });
  }

  call(counterId): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl3 + apiUrls.ticket + "/" + apiUrls.call + "/" + counterId , null)
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }


  Recall(TicketId): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl3 + apiUrls.ticket + "/" + apiUrls.recall + "/" + TicketId , null)
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }

  EndSession(TicketId): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl3 + apiUrls.ticket + "/" + apiUrls.endSession + "/" + TicketId , null)
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }

  CloseCounter(counterId): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl3 + apiUrls.ticket + "/" + apiUrls.closeCounter + "/" + counterId , null)
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }


  TransferCounter(ticketId , counterId): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl3+apiUrls.ticket+"/"+apiUrls.transferCounter+"/"+ticketId+"/"+counterId,null)
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }
  TransferService(ticketId , serviceId): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl3+apiUrls.ticket+"/"+apiUrls.transferService+"/"+ticketId+"/"+serviceId,null)
        //   "ticket/ticket-id/trasfer/service/service-id"
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }
  TransferUsers(ticketId , userId): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl3+apiUrls.ticket+"/"+apiUrls.transferUser+"/"+ticketId+"/"+userId,null)
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }
  refresh(): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl3 + apiUrls.ticket + "/" + apiUrls.refresh  , null)
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }

  complete(ticketId,transId): Promise<any>
  
  {
    return new Promise((resolve, reject) => {
        this._httpClient.post(environment.serviceUrl + apiUrls.ticket + "/" + apiUrls.complete + "/" + ticketId + "?numberOftr=" + transId, null)
            .subscribe((response: any) => {
                resolve(response);
            }, reject);
    });
    }
    holdByTicketId(ticketId): Promise<any>
    {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl + apiUrls.ticket + "/" + apiUrls.hold + "?" +apiUrls.ticketId+"="+ ticketId , null)
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }

  inComplete(ticketId): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl + apiUrls.ticket + "/"+ apiUrls.inComplete + "/" + ticketId , null)
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }


getAllHold(): Promise<any>{
    return new Promise((resolve, reject) => {
        this._httpClient.get(environment.serviceUrl3 + apiUrls.ticket + "/" + apiUrls.hold )
            .subscribe((response: any) => {
                resolve(response);
            }, reject);
    });
}
holdByCall(ticketId,counterId): Promise<any>
{
    return new Promise((resolve, reject) => {
        this._httpClient.post(environment.serviceUrl + apiUrls.ticket + "/" + apiUrls.hold + "/" +apiUrls.call+"?"+ apiUrls.ticketId+"="+ ticketId+"&"+apiUrls.counterId+"="+ counterId , null)
            .subscribe((response: any) => {
                resolve(response);
            }, reject);
    });
}


NotShowing(ticketId): Promise<any>
{
    return new Promise((resolve, reject) => {
        this._httpClient.post(environment.serviceUrl + apiUrls.ticket + "/" + apiUrls.notShowing + "/" + ticketId , null)
            .subscribe((response: any) => {
                resolve(response);
            }, reject);
    });
}

getAllNotShowing(): Promise<any>{
    return new Promise((resolve, reject) => {
        this._httpClient.get(environment.serviceUrl3 + apiUrls.ticket + "/" + apiUrls.hold )
            .subscribe((response: any) => {
                resolve(response);
            }, reject);
    });
}
}
