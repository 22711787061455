<form class="import-form">
    <div class="content">
      <div>
          <h2> {{'IMPORT_USERS.Importcontactsfromexcelsheet' | translate}}</h2>
      </div>
      <div class="d-flex align-items-center">
        <div class="file-container">
          <div class="file-overlay"></div>
          <div class="file-wrapper">
            <input type="file" id="fileInput" (change)="selectFile($event)" name="fileInput" class="file-input"/>
            <div class="file-content">
              <div class="file-infos">
                  <div class="file-icon">
                    <img src="assets/images/upload-file.svg" alt=""/>
                    <span class="icon-shadow"></span>
                    <div class="upload-text">
                      <span> <button class="accent">{{'IMPORT_USERS.clickToUpload' | translate}}</button></span>
                      <span class="file-name" *ngIf="!fileName">{{'IMPORT_USERS.NoFileSelected' | translate}}</span>
                      <span class="file-name" *ngIf="fileName">{{fileName}}</span>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="import-info">
          <h4><strong>{{'IMPORT_USERS.ImportyourDetails' | translate}}</strong></h4>
          <p>
            . {{'IMPORT_USERS.Please' | translate}} <a (click)="downloadTemplate()" style="cursor:pointer ;"> {{'IMPORT_USERS.DownloadExcelTempleate' | translate}}</a> {{'IMPORT_USERS.first' | translate}} 
          </p>
          <p> 
            . {{'IMPORT_USERS.Youwillfindtheexelindownloadfile' | translate}}
          </p>
          <p>
            . {{'IMPORT_USERS.OpentheExelandfellyourdata' | translate}} 
          </p>
          <p>
            . {{'IMPORT_USERS.PleaseselectyourTempleateandUpdate' | translate}}
          </p>

          <a (click)="upload()" class="update-btn accent">{{'KEYPAD.update' | translate}}  </a>
        </div>
      </div>
  </div>
</form>
