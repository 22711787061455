<div class="content-card ">
    <div class="content">
        <form name="callApiForm" [formGroup]="callApiForm" fxLayout="column" fxFlex>
            <div class="tab-content" fxLayout="column">
               <h3 > <strong>{{'DYNAMIC_DASHBORD.request' | translate}}</strong></h3>
              <div style="border-style:ridge;">
                <div fxLayout="row" fxLayoutAlign="space-evenly" class="mergen" style="margin-top:10px ;">
                  <mat-form-field appearance="outline" floatLabel="always" fxFlex="92">
                    <mat-label>{{'DYNAMIC_DASHBORD.apiMethode' | translate}}  </mat-label>
                    <mat-select [(ngModel)]="apiMethode"  formControlName="apiMethode">
                        <mat-option *ngFor="let data of methode" [value]="1">{{data}}</mat-option>

                    </mat-select>
                  </mat-form-field>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-evenly" class="mergen">
                  <mat-form-field appearance="outline" floatLabel="always" fxFlex="80">
                    <mat-label>{{'DYNAMIC_DASHBORD.URL' | translate}}   </mat-label>
                    <input [(ngModel)]="url" matInput formControlName="url">
                  </mat-form-field>
                  <button  [disabled]="!url || !apiMethode" mat-raised-button color="warn" style="height: 50px; ;" (clich)="sendRequest()" >{{'DYNAMIC_DASHBORD.send' | translate}} </button>
              </div>

              <div fxLayout="row" fxLayoutAlign="space-evenly"  >
                <div style="border-style:ridge; width: 90%;margin: 20px 20px ;">
                  <mat-tab-group class="mat-tab-group mat-primary" floatLabel="always" fxFlex="100" style="text-align:center ;">
                    <mat-tab label="Header"> 
                      <div>
                        <table>
                          <tr style="margin: right 10px">
                            <td style="width:50% ;">Key</td>
                            <td style="width:50% ;">Value</td>
                            <td> <button mat-mini-fab class="add-file-btn" (click)="addRow()"  aria-label="Add file"
                              [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                              <mat-icon matTooltip="{{'DYNAMIC_DASHBORD.add' | translate}}">add</mat-icon>
                            </button>
                          </td>
                          </tr>
                          <tr *ngFor="let count of rowCounter">
                            <td><input class="text" type="text" id="key" name="key" formControlName="key" style="width:70% ;"></td>
                            <td><input class="text" type="text" id="value" name="value" formControlName="value" style="width:70% ;"></td>
                            <td>
                              <button mat-mini-fab class="add-file-btn" style="background-color:red ;" (click)="deleteRow()"  aria-label="Add file"
                              [@animate]="{value:'*',params:{delay:'275ms',scale:'0.6'}}">
                              <mat-icon   matTooltip="{{'DYNAMIC_DASHBORD.delete' | translate}}">delete_forever</mat-icon>
                            </button>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </mat-tab>
                    <mat-tab label="body" >
                      <div class="example-large-box mat-elevation-z4" fxFlex="100">
                        <textarea class="form-control"  id="txtBody" rows="8" cols="53" style="width:100% ;"></textarea>
                      </div>
                    </mat-tab>
                  </mat-tab-group>
                </div>
              </div>
              </div>
              <h3 > <strong>{{'DYNAMIC_DASHBORD.response' | translate}}</strong></h3>
              <div style="border-style:ridge; height:30% ;">
                <mat-tab-group animationDuration="0ms">
                  <mat-tab label="Body">
                    <div  style="height:140px ;">

                    </div>
                  </mat-tab>
                </mat-tab-group>
            
              </div>
              <br>
              <div style="text-align:right ;">
                <button mat-raised-button color="primary" (click)="onCancelUserDialog()">{{'DYNAMIC_DASHBORD.cancel' | translate}}</button>
                <button mat-raised-button [disabled]="!url || !apiMethode"  color="primary" (click)="ok()" >{{'DYNAMIC_DASHBORD.ok' | translate}}</button>

              </div>
            </div>
        </form>
    </div>
    <!-- CONTENT -->
</div>