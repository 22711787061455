<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<div class="cont">
  <div class="header" [@animate]="{value:'*',params:{scale:'0.2'}}">
    <img src="{{'data:image/jpg;base64,' + logo}}" />
  </div>
  <div class="body">
    <div *ngIf="question">
      <h3 class="fontType">{{ question.name }}</h3>
      <ul>
        <div *ngFor="let answer of answers">
          <label>
              <input type="radio" name="answer" [(ngModel)]="selectedAnswer" [value]="answer.id" />
              {{ answer.name }}
          </label>
      </div>
      </ul>
    </div>
    <button mat-raised-button type="submit" *ngIf="!isSend" class="submit fontType" (click)="sendCustomerFeedback()">
      {{'KEYPAD.send' | translate}} 
    </button>
      <h2 *ngIf="isSend" class="Labelcolor fontType" > {{'KEYPAD.theAnswerHasBeenSentSuccessfully' | translate}} </h2>
  </div>
  
</div>

