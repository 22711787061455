import { Component, ViewEncapsulation } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../../../app/main/home/i18n/en';
import { locale as turkish } from '../../../../app/main/home/i18n/tr';
import { locale as arabic } from  '../../../../app/main/home/i18n/ar';
@Component({
    selector     : 'quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent
{
    date: Date;
    events: any[];
    notes: any[];
    settings: any;

    /**
     * Constructor
     */
    constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService,)
    {
        // Set the defaults
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud : false,
            retro : true
        };
    }
}
