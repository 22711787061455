import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';
import { PermissionService } from '../permission.service';
import { Permission } from 'app/model/Permission';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { locale as english } from '../../../main/home/i18n/en';
import { locale as turkish } from '../../../main/home/i18n/tr';
import { locale as arabic } from '../../../main/home/i18n/ar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-permission-entry',
    templateUrl: './permission-entry.component.html',
    styleUrls: ['./permission-entry.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class PermissionEntryComponent implements OnInit {

    permission: Permission;
    pageType: string;
    permissionForm: FormGroup;
    isLoading: boolean = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {PermissionService} _permissionService
     * @param {FormBuilder} _formBuilder
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        private permissionService: PermissionService,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private route: ActivatedRoute,
        private router: Router,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translate:TranslateService
    ) {
        // Set the default
        this.permission = new Permission();

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);        // Set the private defaults

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            if (params.get('id')) {
                this.permission.id = params.get('id')
                this.pageType = 'edit';
                this.permissionService.getPermissionById(this.permission.id).then((res: Permission) => {
                    this.permission = res[0];
                    this.permissionForm = this.createPermissionForm();

                });
            }
            else {
                this.pageType = 'new';
            }
        });
        this.permissionForm = this.createPermissionForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create permission form
     *
     * @returns {FormGroup}
     */
    createPermissionForm(): FormGroup {
        return this._formBuilder.group({
            id : [this.permission.id],
            name: [this.permission.name]
           
        });
    }

    /**
     * Save permission
     */
    savePermission(): void {
        this.isLoading = true;
        const data = this.permissionForm.getRawValue();
        //data.handle = FuseUtils.handleize(data.name);

        this.permissionService.savePermission(data)
            .then(() => {
                this.isLoading = false;
                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.permissionSaved'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }

    /**
     * Add permission
     */
    addPermission(): void {
        this.isLoading = true;
        const data = this.permissionForm.getRawValue();
        //   data.handle = FuseUtils.handleize(data.name);
        data.id = undefined;
        this.permissionService.addPermission(data)
            .then(() => {

                // Trigger the subscription with new data
                this.permissionService.onPermissionChanged.next(data);
                this.isLoading = false;
                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.permissionAdded'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });

                this.router.navigate(['/permission'])

                // Change the location with new one

            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }
}
