<div mat-dialog-content>
    <form class=" p-24 mr-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
        name="form" [formGroup]="form">


        <div class="h2 mb-24"> {{'KEYPAD.addCounterNumber' | translate}}</div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label> {{'KEYPAD.counterNumber' | translate}} </mat-label>
                <input  matInput formControlName="CounterNumber"  type="number" required [(ngModel)]="data.CounterNumber" >
            </mat-form-field>
        </div>
       
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancelClick()"> {{'KEYPAD.cancel' | translate}}</button>
    <button mat-button cdkFocusInitial (click)="save()" >{{'ok' | translate}}</button>
</div>
<!-- [mat-dialog-close]="data.Tenant"  -->