<div id="keypad" class="page-layout carded fullwidth inner-scroll">
    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card" style="margin-top: 20px;">
            <!-- CONTENT -->
            <div class="content">
                <form name="keypadForm" class="keypad w-100-p" fxLayout="column" fxFlex>
                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <div class="">
                            <div class="keypad-header">
                                <div style="background-color: #233463;">
                                    <div>
                                        <h4>{{'KEYPAD.ticketNumber' | translate}}</h4>
                                        <!-- <div class="h5 secondary-text">{{'KEYPAD.thisIsCurrentTicketNumber' |
                                            translate}} </div> -->
                                        <h2>{{TicketNumber}}</h2>
                                    </div>
                                </div>
                                <div style="background-color: #1BA44C;">
                                    <div class="timer-counter-label">
                                        <h4> {{'KEYPAD.serviceTime' | translate}}</h4>
                                        <h2><span>{{minutes}}:</span><span>{{seconds}}</span></h2>
                                    </div>
                                </div>
                                <div style="background-color: #343434;">
                                    <div class="d-flex align-items-center">
                                        <div class="d-flex align-items-center">
                                            <mat-icon
                                                [style]="waitingTime > 0 ? 'color : red' : 'color: rgb(67, 198, 95)' ">
                                                group
                                            </mat-icon>
                                            <span [style]="waitingTime > 0 ? 'color : red' : 'color: rgb(67, 198, 95)' "
                                                class="ml-3 font-size-30">
                                                {{waitingTime > 0 ? waitingTime : 0}}
                                            </span>
                                        </div>
                                        <div class="mx-2 d-flex align-items-center">
                                            <mat-icon class="ml-8">check_circle_outline</mat-icon>
                                            <span class="font-size-30">
                                                {{ticketNumber > 0 ? ticketNumber : 0}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div  class="phone-number">
                                    <div style="width: 100%;">
                                        <h3>{{'KEYPAD.phoneNumber' | translate}}</h3>
                                        <div style="cursor: pointer;" class="d-flex align-items-center">
                                            <h4 (click)="copyInputMessage(customerPhoneNumber)">
                                                <span> {{customerPhoneNumber}} </span>
                                            </h4>
                                            <mat-icon (click)="copyInputMessage(customerPhoneNumber)" class="mx-2">file_copy</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="keypad-actions">
                            <button (click)="Transfer()" style="background-color: #36d9d8;" class="btn1">
                                <img src="assets/images/keypad/transfer.svg">
                                <span> {{'KEYPAD.transfer' | translate}}</span>
                            </button>
                            <button (click)="Call()" style="background-color: #ffcc40;" class="btn2">
                                <img src="assets/images/keypad/call.svg">
                                <span>{{'KEYPAD.call' | translate}}</span>
                            </button>
                            <button (click)="Recall()" style="background-color: #dbae33;" class="btn3">
                                <img src="assets/images/keypad/recall.svg" style="width: 35px;">
                                <span>{{'KEYPAD.recall' | translate}}</span>
                            </button>
                            <button (click)="EndSession()" style="background-color: #FF1E1E;" class="btn4">
                                <img src="assets/images/keypad/end-session.svg">
                                <span>{{'KEYPAD.endSession' | translate}}</span>
                            </button>
                            <button (click)="CloseCounter()" style="background-color: #E14D2A;" class="btn5">
                                <img src="assets/images/keypad/close.svg">
                                <span> {{'KEYPAD.closeCounter' | translate}}</span>
                            </button>
                            <button style="background-color: #3246d3;" (click)="openNoShowDialog(customerPhoneNumber,TicketNumber,TicketId)" class="btn6">
                                <img src="assets/images/keypad/no-show.svg">
                                <span>{{'KEYPAD.noShow' | translate}}</span>
                            </button>                            
                            <button style="background-color: #000;" (click)="openHoldDialog(customerPhoneNumber,TicketNumber ,TicketId)" class="btn7">
                                <img src="assets/images/keypad/hold.svg">
                                <span>{{'KEYPAD.hold' | translate}}</span>
                            </button>
                            <button style="background-color:#008909;grid-area: 3/4/3/1;" *ngIf="_fuseNavigationService.hasPermission('Complete_Ticket')" (click)="complete(TicketId)" >
                                <img src="assets/images/keypad/complete.svg">
                                <span>{{'KEYPAD.complete' | translate}}</span>
                            </button>
                            <button style="background-color:#ff9102;grid-area: 3/7/3/4;" *ngIf="_fuseNavigationService.hasPermission('Incomplete_Ticket')" (click)="inComplete(TicketId)">
                                <img src="assets/images/keypad/incompelete.svg">
                                <span>{{'KEYPAD.inComplete' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        
            <!-- / CONTENT -->
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>