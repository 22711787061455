<div id="report" class="page-layout carded fullwidth inner-scroll">
<div class="container">
    <h1>{{ 'REPORT.GenerateReport' | translate }}</h1>
    <form [formGroup]="reportForm" (ngSubmit)="generateReport()" novalidate>
        <div class="flex-container">
            <mat-form-field>
                <mat-label>{{ 'REPORT.StartDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="startDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'REPORT.EndDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="picker2" formControlName="endDate" [min]="minEndDate">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
                           
        </div>

        <div class="flex-container">
            <mat-form-field>
                <mat-label>{{ 'REPORT.Location' | translate }}</mat-label>
                <mat-select formControlName="locationId">
                    <mat-option *ngFor="let location of dataSourceLocation" [value]="location.id">{{ location.name }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'REPORT.Language' | translate }}</mat-label>
                <mat-select formControlName="language">
                    <mat-option value="en">{{ 'REPORT.English' | translate }}</mat-option>
                    <mat-option value="ar">{{ 'REPORT.Arabic' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="flex-container">
            <mat-form-field>
                <mat-label>{{ 'REPORT.ReportType' | translate }}</mat-label>
                <mat-select formControlName="reportType">
                    <mat-option value="0">{{ 'REPORT.SummaryReport' | translate }}</mat-option>
                    <mat-option value="1">{{ 'REPORT.CustomerReport' | translate }}</mat-option>
                    <mat-option value="2">{{ 'REPORT.UsersReport' | translate }}</mat-option>
                    <mat-option value="3">{{ 'REPORT.ServicesReport' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="flex-container">
            <button mat-raised-button color="primary" type="submit" [disabled]="isLoading" style="width: 100%;">
                <span *ngIf="!isLoading">{{ 'REPORT.GenerateReport' | translate }}</span> <!-- Visible only when not loading -->
                <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner> <!-- Spinner centered when visible -->
            </button>
                       
        </div>

       
    </form>
</div>
</div>