import { AgmCoreModule } from "@agm/core";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { NgxStarRatingModule } from 'ngx-star-rating';
import { CustomerFeedBackComponent } from "./customer-feedback/customer-feedback.component";
import { CustomerFeedbackService } from "./customer-feedback/customer-feedback.service";



const routes = [
  {
    path     : 'questionnaire/ticketId/:tiketId',
    component: CustomerFeedBackComponent
  }
];
@NgModule({
  declarations: [
    CustomerFeedBackComponent   
  ],
  imports: [
    AgmCoreModule.forRoot({
        apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
    }),
    RouterModule.forChild(routes),
    TranslateModule,
    CommonModule,
    FuseSharedModule,
    NgxStarRatingModule
  ],
  providers:[
    CustomerFeedbackService
  ]
  
})
export class CustomerFeedbackModule { }
