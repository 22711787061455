import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Role } from 'app/model/Role';
import { apiUrls } from 'app/shared/constants/apiUrls';
import { StorageManager } from 'app/shared/utilities/storage-manager';
import { environment } from 'environments/environment';
import { Http2ServerRequest } from 'http2';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService implements Resolve<any>{
 
  roles : Role[] = new Array<Role>();
  role : Role  =   new Role() ;
  onRoleChanged: BehaviorSubject<any>;
  currentPage: number = 1;
  pageSize : number = 10;

   /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
      private _httpClient: HttpClient
  )
  {
      // Set the defaults
      this.onRoleChanged = new BehaviorSubject({});
  }

   /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getRoles()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

  
  getAllRoles() :Observable<any>{
   return this._httpClient.get(environment.serviceUrl3+ apiUrls.role )
  }
  
  getRoles(): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.role )
              .subscribe((response: any) => {
                  this.roles = response;
                  this.onRoleChanged.next(this.roles);
                  resolve(response);
              }, reject);
      });
  }

  getRolesWithParameter(pageIndex ,pageSize): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.role + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize)
              .subscribe((response: any) => {
                  this.roles = response;
                  this.onRoleChanged.next(this.roles);
                  resolve(response);
              }, reject);
      });
  }

  getRolesSerch(pageIndex ,pageSize ,search): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.role + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize + '&search=' + search)
              .subscribe((response: any) => {
                  this.roles = response;
                  this.onRoleChanged.next(this.roles);
                  resolve(response);
              }, reject);
      });
  }

  getRoleById(id): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.role + '/' + id )
              .subscribe((response: any) => {
                  this.role = response;
                  this.onRoleChanged.next(this.role);
                  resolve(response);
              }, reject);
      });
  }

  getRolePermissions(id): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.role + '/' + id +'/' +apiUrls.permission)
              .subscribe((response: any) => {
                  this.role = response;
                  this.onRoleChanged.next(this.role);
                  resolve(response);
              }, reject);
      });
  }
  
 // Delete role
 deleteRole(id): Promise<any>
 {
     return new Promise((resolve, reject) => {
         this._httpClient.delete(environment.serviceUrl + apiUrls.role +'?Id='+ id )
             .subscribe((response: any) => {
                 resolve(response);
             }, reject);
     });
 }

 deletePermissionfromRole(roleId , PermissionId ): Promise<any>
 {
    return new Promise((resolve, reject) => {
        this._httpClient.delete(environment.serviceUrl3 + apiUrls.role + "/"+ roleId + "/permission/" + PermissionId)
          .subscribe((response: any) => {
            resolve(response);
          }, reject);
      });
 }

  saveRole(role): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put(environment.serviceUrl + apiUrls.role , role)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  addRole(role): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl3 + apiUrls.role, role)
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }

  assignPermissionToRole(roleId , PermissionId): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.serviceUrl3 + apiUrls.role + "/"+ roleId + "/permission/" + PermissionId, null)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }


}
