import { FuseUtils } from "@fuse/utils";

export class PublicHoliday {
    constructor(publicHoliday?)
    {
        publicHoliday = publicHoliday || {};
        this.id = publicHoliday.id || 0;//FuseUtils.generateGUID();
        this.name = publicHoliday.name || '';
        this.startDate = publicHoliday.startDate || '';
        this.endDate = publicHoliday.endDate || '';
        
    }
    id : string;    
    name: string;
    startDate : Date;
    endDate : Date;
}