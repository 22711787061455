import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

import { fuseAnimations } from '@fuse/animations';
import { TenantService } from '../tenant.service';
import { Tenant } from 'app/model/Tenant';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CategoryService } from 'app/modules/category/category.service';
import { locale as english } from '../../../../main/home/i18n/en';
import { locale as turkish } from '../../../../main/home/i18n/tr';
import { locale as arabic } from '../../../../main/home/i18n/ar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { NameLanguageEditer } from 'app/component/name-language-editer/name-language-editer.component';

@Component({
    selector: 'app-tenant-entry',
    templateUrl: './tenant-entry.component.html',
    styleUrls: ['./tenant-entry.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class TenantEntryComponent implements OnInit {

    tenant: Tenant;
    pageType: string;
    Categories: [];
    tenantForm: FormGroup;
    @ViewChild("nameLanguageEditor") nameLanguageEditor :NameLanguageEditer;
    allowedFiles: string = 'image/*';
    invalidImage: boolean;
    file :any
    tenantId:string
    isLoading: boolean = false;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {TenantService} _tenantService
     * @param {FormBuilder} _formBuilder
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        private tenantService: TenantService,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private route: ActivatedRoute,
        private router: Router,
        private categoryService: CategoryService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translate :TranslateService
    ) {
        // Set the default
        this.tenant = new Tenant();
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.categoryService.getCategorysWithoutPagenation().then(res => {
            if (res) {
                this.Categories = res;
            }
        });
        this.route.paramMap.subscribe(params => {
            if (params.get('id')) {
                this.tenant.id = params.get('id')
                this.pageType = 'edit';
                this.tenantService.getTenantById(this.tenant.id , true).then((res: Tenant) => {                    
                    this.tenant = res;
                    this.tenantForm = this.createTenantForm();

                });
            }
            else {
                this.pageType = 'new';
            }
        });
        this.tenantForm = this.createTenantForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create tenant form
     *
     * @returns {FormGroup}
     */
    createTenantForm(): FormGroup {
        return this._formBuilder.group({
            id :  [this.tenant.id],
            name: [this.tenant.name],
            email: [this.tenant.email, Validators.email],
            categoryId: [this.tenant.categoryId],
           
        });
    }

    /**
     * Save tenant
     */
    saveTenant(): void {
        this.isLoading = true;
        this.tenant.name = JSON.stringify(this.nameLanguageEditor.deserializedData);
        this.tenantForm.controls["name"].setValue(this.tenant.name);
        const data = this.tenantForm.getRawValue();
        // data.handle = FuseUtils.handleize(data.name);
       
        if(this.file ==undefined ||this.file==null ){
            data.logo= this.tenant.logo;
            }
        this.tenantService.saveTenant(data)
            .then((res) => {

                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.tenantAdded'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
                if(this.file !=undefined ||this.file!=null||this.file!=null  ){
                    this.tenantService.uploadImage(this.file, this.tenantId)
                    }
                    this.isLoading = false;
            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }


    uploadIcon(e, tenantId) {
        if (this.validateFiles(e.target.files)) {
            this.invalidImage = false;
            for (var i = 0; i < e.target.files.length; i++) {
             
                   this.file = e.target.files[i];
                this.tenantId=tenantId;
            }
        } else {
            this.invalidImage = true;

        }

    }

    validateFiles(files: File[]): boolean {
        for (var i = 0; i < files.length; i++) {
            if (!this.isFileTypeValid(files[i])) {
                return false;
            } 
            return true;
        }

    }

    private isFileTypeValid(file: File): boolean {
        let acceptableTypes = this.allowedFiles.split(',').map(type => type.trim());
        for (let type of acceptableTypes) {
            let acceptable = this.isWildcard(type) ? this.getTypeClass(file.type) === this.getTypeClass(type)
                : file.type == type || this.getFileExtension(file).toLowerCase() === type.toLowerCase();

            if (acceptable) {
                return true;
            }
        }

        return false;
    }
    isWildcard(fileType: string): boolean {
        return fileType.indexOf('*') !== -1;
    }
    getTypeClass(fileType: string): string {
        return fileType.substring(0, fileType.indexOf('/'));
    }
    getFileExtension(file: File): string {
        return '.' + file.name.split('.').pop();
    }
   
    isValidForm(){
       return this.tenantForm?.valid  && this.nameLanguageEditor?.manageEntityForm?.valid
    }

    /**
     * Add tenant
     */
    addTenant(): void {
        this.isLoading = true;
        this.tenant.name = JSON.stringify(this.nameLanguageEditor.deserializedData);
        this.tenantForm.controls["name"].setValue(this.tenant.name);
        const data = this.tenantForm.getRawValue();
        data.id = undefined;
        //   data.handle = FuseUtils.handleize(data.name);
        this.tenantService.addTenant(data)
            .then((res) => {

                // Trigger the subscription with new data
                this.tenantService.onTenantChanged.next(data);
                this.isLoading = false;
                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.tenantAdded'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });

                this.router.navigate(['/tenant'])
                this.tenantService.uploadImage(this.file, res.id)
                // Change the location with new one

            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }
}
