import { FuseUtils } from "@fuse/utils";
import { Tenant } from "./Tenant";

export class Service {   
    constructor(service?)
    {
        service = service || {};
        this.id = service.id || 0;//FuseUtils.generateGUID();
        this.name = service.name || '';
        this.discription = service.discription || '' ;
        this.tenant = service.tenant || new Tenant() ;
        this.tenantId = service.tenantId || 0;
    }
    id : string;    
    name: string;
    discription: string;  
    tenant : Tenant;  
    tenantId: string;
}