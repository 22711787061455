import { Component, ElementRef, OnInit  } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { KeypadEntryComponent } from '../keypad-entry/keypad-entry.component';
import { KeypadService } from '../keypad.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { CounterNumberCnfigrationPopupComponent } from 'app/modal/counter-number-configration-popup/counter-number-configration-popup.component';

declare var window: any;

@Component({
  selector: 'app-hold-dialog',
  templateUrl: './hold-dialog.component.html',
  styleUrls: ['./hold-dialog.component.scss']
})
export class HoldDialogComponent implements OnInit {

  Tickets : any;
  customerPhoneNumber: any;
  ticketNumber: any;
  CounterNumberConfigration: number;
  TicketId: any;
  TicketNumber: any = this.translate.instant('KEYPAD.noTickets');
  _start: boolean = true;


  clock: any;
  minutes: any = '00';
  seconds: any = '00';
  milliseconds: any = '00';

  //  start: boolean;
  showTimerControls: boolean;
  waitingTime: any;
  reset: NodeJS.Timeout;

  constructor(private dialogRef: MatDialogRef<KeypadEntryComponent> ,
     private keypadService: KeypadService,private _matSnackBar: MatSnackBar, 
        private translate: TranslateService,    public dialog: MatDialog,)
         { this.checkCounterNumberConfigration();}

  ngOnInit(): void {
    this.counter = undefined;
    // var NoShowDB= window.openDatabase("NotCompleatedTickits", '1.0', "My WebSQL Database", 2 * 1024 * 1024);
    // NoShowDB.transaction((tx) => {
    //   tx.executeSql('SELECT * FROM HoldUsers',
    //   [], (tx, results) => {
    //       if (results.rows.length > 0) {
    //         this.Tickets = results.rows;
    //       }
    //   })
    // });
    this.getHold();
  }

getHold(){

  this.keypadService.getAllHold()
  .then((res) => {  
    if(res){
      this.Tickets=res
    }
    }).catch(err => {
    this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
        verticalPosition: 'top',
        duration: 2000
    });
});
}


  close() {
    document.getElementsByClassName("animate__animated")[0].classList.remove("animate__slideInRight");
    document.getElementsByClassName("animate__animated")[0].classList.add("animate__slideOutRight");
    setTimeout(() => {this.dialogRef.close();}, 1000);
  }


delete(ticketId){
    console.log('Original ticketId:', ticketId);
    //ticketId = ticketId.replace(/"/g, ''); // Remove double quotes
    console.log('Modified ticketId:', ticketId);
    var HoldUsersDB = window.openDatabase("NotCompleatedTickits", '1.0', "My WebSQL Database", 2 * 1024 * 1024);
    HoldUsersDB.transaction((tx) => {
      tx.executeSql('SELECT * FROM HoldUsers WHERE TicketId = ?', [ticketId], (tx, result) => {
        if (result.rows.length > 0) {
          tx.executeSql('DELETE FROM HoldUsers WHERE TicketId = ?', [ticketId], (tx, result) => {
            console.log('Record deleted:', result.rowsAffected);
            this.refresh();
            this.close();
          }, (tx, error) => {
            console.error('Error deleting record:', error);
          });
        } else {
          console.log('No records found with TicketId:', ticketId);
        }
      }, (tx, error) => {
        console.error('Error executing SELECT statement:', error);
      });
    });
  }
  
  

  refresh(){
    var NoShowDB= window.openDatabase("NotCompleatedTickits", '1.0', "My WebSQL Database", 2 * 1024 * 1024);
    NoShowDB.transaction((tx) => {
      tx.executeSql('SELECT * FROM HoldUsers',
      [], (tx, results) => {
          if (results.rows.length > 0) {
            this.Tickets = results.rows;
          }
      })
    });
  }

  checkCounterNumberConfigration() {
    if (localStorage.getItem("CounterNumberConfigration")) {
      this.CounterNumberConfigration = +localStorage.getItem("CounterNumberConfigration");
    }
    else {
      const dialogRef = this.dialog.open(CounterNumberCnfigrationPopupComponent, {
        width: '500px',
        data: { CounterNumber: this.CounterNumberConfigration }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == "saved") {
          this._matSnackBar.open(this.translate.instant('KEYPAD.counterNumberSaved'), this.translate.instant('ok'), {
            verticalPosition: 'top',
            duration: 2000
          });
        }
        this.checkCounterNumberConfigration();


      });
    }
  }

  recallTicket(ticketId,counterNumber) {
    // let tid = ticketId.replace(/"/g, ''); // Remove double quotes
   
    this.keypadService.holdByCall(ticketId,counterNumber).then(res => {
      this.startTimer();
      this.TicketId = res.id
            this.ticketNumber  = res.ticketNumber
            this.TicketNumber = res.service.chr + " - " + res.ticketNumber;
            this.customerPhoneNumber = res.phone;
           // this.waitingTime = res.waiting; 
      
      this._matSnackBar.open(this.translate.instant('KEYPAD.recallSuccess'), this.translate.instant('ok'), {
        verticalPosition: 'top',
        duration: 2000
      });
    }).finally(() => {
      // based call with abd the get hold work delete 
      //this.delete(ticketId); // Call the delete function
      this.close();
    }); 
    
  }

  clearTimer() { 
    this.running = false;
    this.startText = 'Start';
    this.counter = undefined;
    this.milliseconds = '00',
      this.seconds = '00',
      this.minutes = '00';
    this.laps = [];
    clearInterval(this.timerRef);
    this.customerPhoneNumber = null;
  }

  laps: any = [];
  counter: number = 10;
  timerRef;
  running: boolean = false;
  startText = 'Start';


  startTimer() {
    // const source = timer(0, Date.now());
    // const subscribe = source.subscribe(val => console.log(val));
    this.running = !this.running;
    if (this.running) {
      const startTime = Date.now() - (this.counter || 0);
      this.timerRef = setInterval(() => {
        this.counter = Date.now() - startTime;
        // console.log(Date.now());
        // console.log(startTime);
        // console.log(this.counter);
        this.milliseconds = Math.floor(Math.floor(this.counter % 1000) / 10).toFixed(0);
        this.minutes = Math.floor(this.counter / 60000);
        this.seconds = Math.floor(Math.floor(this.counter % 60000) / 1000).toFixed(0);
        if (Number(this.minutes) < 10) {
          this.minutes = '0' + this.minutes;
        } else {
          this.minutes = '' + this.minutes;
        }
        if (Number(this.milliseconds) < 10) {
          this.milliseconds = '0' + this.milliseconds;
        } else {
          this.milliseconds = '' + this.milliseconds;
        }
        if (Number(this.seconds) < 10) {
          this.seconds = '0' + this.seconds;
        } else {
          this.seconds = '' + this.seconds;
        }
      });
    } else {
      this.startText = 'Resume';
      clearInterval(this.timerRef);
    }
  }
}
function ViewChild(arg0: string) {
  throw new Error('Function not implemented.');
}

