<!-- 
    <form #manageEntityForm="ngForm" class="p-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form">
        <div *ngFor="let item of deserializedData;let i = index" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <div class="h2 mb-24" *ngIf="item.added ==0">{{item.dayOfWeek}} </div>

            <mat-form-field appearance="outline" floatLabel="always" class="pl-sm-8 no-errors-spacer" fxFlex="50" >
                <mat-label>{{'SERVICE_DETAILS_POPUP.startTime'  | translate}}</mat-label>
                <input matInput type="time" [(ngModel)]="item.startTime"  required  name="startTime" >
                <mat-datepicker #pickerFrom></mat-datepicker>
            </mat-form-field>
            
            <mat-form-field appearance="outline" floatLabel="always"  class="pl-sm-8 no-errors-spacer" fxFlex="43">
                <mat-label>{{'SERVICE_DETAILS_POPUP.endTime' | translate}}</mat-label>
                <input matInput type="time"  [(ngModel)]="item.endTime"  required name="endTime" >
                <mat-datepicker #pickerTo></mat-datepicker>
            </mat-form-field>

            <mat-icon class="s-20 p-24 mr-24" (click)="removeRow(item)" style="cursor: pointer;">delete</mat-icon>
        </div>
        
 

    <button *ngIf="clickRow" mat-raised-button class="mat-accent mr-16" (click)="addRow()">
    <mat-icon (click)="addfields()" class="s-20 ">add_circle</mat-icon>
    {{'NAME_EDITER.addslotTime' | translate}}

    </button>
        
    </form> -->
    
    <form #manageEntityForm="ngForm" class="p-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form">

        <div *ngFor="let item of deserializedData;let i = index" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <div class="h2 mb-24" *ngIf="item.added ==0">{{item.dayOfWeek}}
                <button *ngIf="clickRow" mat-raised-button class="mat-accent mr-16" 
                (click)="addRow(item.dayOfWeek)"matTooltipPosition="right" matTooltip="{{'NAME_EDITER.addslotTime' | translate}}">
                    <mat-icon class="s-20 ">add_circle</mat-icon>
                               
                </button>
            </div>
           
            <mat-form-field appearance="outline" floatLabel="always" class="pl-sm-8 no-errors-spacer" fxFlex="43" >
                <mat-label>{{'SERVICE_DETAILS_POPUP.startTime'  | translate}}</mat-label>
                <input matInput type="time" [(ngModel)]="item.startTime"    name="name-{{item.startTime ?? i}}" >
                <mat-datepicker #pickerFrom></mat-datepicker>
            </mat-form-field> 
            
            <mat-form-field appearance="outline" floatLabel="always"  class="pl-sm-8 no-errors-spacer" fxFlex="43">
                <mat-label>{{'SERVICE_DETAILS_POPUP.endTime' | translate}}</mat-label>
                <input matInput type="time"  [(ngModel)]="item.endTime"   name="name-{{item.endTime ?? i}}" >
                <mat-datepicker #pickerTo></mat-datepicker>
            </mat-form-field>
            <mat-icon class="s-20 p-24 mr-24" (click)="removeRow(item)" style="cursor: pointer;">delete</mat-icon>
        </div>

    
        
    </form>