import { Component , OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl , FormGroup ,FormBuilder ,Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { FeedBack } from 'app/model/FeedBack';
import { FeedBackService } from './feedback.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {  Output, EventEmitter } from '@angular/core';
import { environment } from 'environments/environment';
declare const atob: any;



@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FeedBackComponent implements OnInit {
  public isshowsmileraiting= environment.IsShowSmileRaiting 
  @Output() ratingSubmitted = new EventEmitter<string>();
  selectedRating: string | null = null;
    feedBack :FeedBack = new FeedBack();
  IsRated :boolean;
  rating: number;
  public form: FormGroup;
  tiketId:any;
  userId:any;
  tenantName : any;
  NameAR : any;
  public txtNote : string = '';
  tenantId:any;
  logo:any;
  

    constructor(
        private route: ActivatedRoute,
        private _feedback : FeedBackService,
        private _fuseConfigService : FuseConfigService,
        private fb: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private translate: TranslateService,
    ) {

      this.rating = 5;
      this.form = this.fb.group({
        rating1: ['', Validators.required],
        rating2: [4]
      });

      this._fuseConfigService.config = {
        layout: {
            navbar   : {
                hidden: true
            },
            toolbar  : {
                hidden: true
            },
            footer   : {
                hidden: true
            },
            sidepanel: {
                hidden: true
            }
        }
    };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
     ngOnInit() {

      this.route.paramMap.subscribe(parms => {
        if(parms.get('tiketId'))
        {
          this.tiketId = parms.get('tiketId').split(':');
          this.feedBack.ticketId = this.tiketId[1];
        }
       if(parms.get('userId'))
        {
          this.userId = parms.get('userId').split(':');
          this.feedBack.userId = this.userId[1];
        }
      });

      this._feedback.getTiketDetails(this.feedBack.ticketId,true).then((response) =>{
        if(response){
          this.IsRated = response.isRated;
          if(this.IsRated){
            this._matSnackBar.open(this.translate.instant('FEEDBACK.SUCCES'), this.translate.instant('ok'), {
              verticalPosition: 'top',
              duration: 2000
            });
          }
          this.tenantId = response.tenantId;
          this.tenantName = response.tenantName;
          
            this._feedback.getTenantDetails(this.tenantId,true).then((response) =>{
              if(response){
                this.logo = response.logo;
                this.NameAR=response.name;
              }
            })
          
        }
      })
     
     
    }
    
    activeImag: number;
    submitRating(val) {

      if(val==1){
        this.rating=5;
        this.activeImag = 1;
      }
      else if(val==2){
        this.rating=3;
        this.activeImag = 2;
      } 
      else if(val==3){
        this.rating=1;        
        this.activeImag = 3;
      }
      else{
        this.rating=5;
        this.activeImag = 3;
      }
      
    
    
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

 
  
        /**
     * Save feadback
     */
      saveFeadback() {
        this.feedBack.comment = this.txtNote;
        this.feedBack.rating = this.rating;
        this.feedBack.feedBackType = 0;
        const data = this.feedBack;
        
      this._feedback.addFeedback(data)
          .then((response) => {
            this.IsRated = true;
            if(response){
              this._matSnackBar.open(this.translate.instant('FEEDBACK.SUCCES'), this.translate.instant('ok'), {
                verticalPosition: 'bottom',
                duration: 2000
              });
            }
          });
  }


}
