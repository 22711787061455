import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '../account.service';
    
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { User } from 'app/model/User';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector     : 'forgot-password-2',
    templateUrl  : './forgot-password-2.component.html',
    styleUrls    : ['./forgot-password-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ForgotPassword2Component implements OnInit
{
    forgotPasswordForm: FormGroup;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
         // Private
  
    tenantId: string;
    locationId: string;
    froLmocation: boolean = false;
    userId: string;
    user: User;
    
    constructor(
        private _matSnackBar: MatSnackBar,
        private accountService: AccountService,
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private translate :TranslateService,
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

   
    ngOnInit(): void
    {
        this.forgotPasswordForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    forgotpassword(email){
       
            this.user=new User();
            this.user.email = email.value;
            if(this.user){
             
                this.accountService.forgotpassword(this.user).then(res => {
                    // this.translate.instant('CONFIRM_MSG.userAdded')
                    this._matSnackBar.open(res.message,  this.translate.instant('ok'), {
                        verticalPosition: 'top',
                        duration: 2000
                    });
    
                })
                }
            
    

        
    }
  
}
