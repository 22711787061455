import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Prerequisite } from 'app/model/Prerequisite';
import { Service } from 'app/model/Service';
import { apiUrls } from 'app/shared/constants/apiUrls';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrerequisiteService implements Resolve<any>{
 
    Prerequisites : Prerequisite[] = new Array<Prerequisite>();
    Prerequisite : Prerequisite  =   new Prerequisite() ;
    onServiceChanged: BehaviorSubject<any>;
  
     /**
       * Constructor
       *
       * @param {HttpClient} _httpClient
       */
      constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.onServiceChanged = new BehaviorSubject({});
    }
  
     /**
       * Resolver
       *
       * @param {ActivatedRouteSnapshot} route
       * @param {RouterStateSnapshot} state
       * @returns {Observable<any> | Promise<any> | any}
       */
      resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
      {
          return new Promise<void>((resolve, reject) => {
  
              Promise.all([
                  this.getServices()
              ]).then(
                  () => {
                      resolve();
                  },
                  reject
              );
          });
      }
  
    
    getAllServices() :Observable<any>{
     return this._httpClient.get(environment.serviceUrl3 + apiUrls.service )
    }
    
    getServices(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.service)
                .subscribe((response: any) => {
                    this.Prerequisites = response;
                    this.onServiceChanged.next(this.Prerequisites);
                    resolve(response);
                }, reject);
        });
    }
  
    getPrerequisiteByServiceId(serviceId :string): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 +apiUrls.prerequisite+"/"+ apiUrls.service+serviceId )
                .subscribe((response: any) => {
                    this.Prerequisites = response;
                    this.onServiceChanged.next(this.Prerequisite);
                    resolve(response);
                }, reject);
        });
    }

    getPrerequisiteById(Id :string): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 +apiUrls.prerequisite+"/"+Id )
                .subscribe((response: any) => {
                    this.Prerequisites = response;
                    this.onServiceChanged.next(this.Prerequisite);
                    resolve(response);
                }, reject);
        });
    }
    addServiceById(service): Promise<any> {
        return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl3 + apiUrls.prerequisite , service)
            .subscribe((response: any) => {
              resolve(response);
            }, reject);
        });
      }

      saveServiceById(service): Promise<any> {
        return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl3 + apiUrls.prerequisite , service)
            .subscribe((response: any) => {
              resolve(response);
            }, reject);
        });
      }




    getServicsByLocation(locationId :string): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.location + locationId + "/" + apiUrls.services + "?isValidate=" + false   )
                .subscribe((response: any) => {
                    this.Prerequisites = response;
                    this.onServiceChanged.next(this.Prerequisite);
                    resolve(response);
                }, reject);
        });
    }
  
    getServiceById(id,isDefaultLanguage = false): Promise<any>
    {
        return new Promise((resolve, reject) => {
          let headers: HttpHeaders = new HttpHeaders();
          if(isDefaultLanguage){
              headers = headers.append("without-accept-language" , "true");
          }
            this._httpClient.get(environment.serviceUrl3 + apiUrls.service +  id , {headers : headers}  )
                .subscribe((response: any) => {
                    this.Prerequisite = response;
                    this.onServiceChanged.next(this.Prerequisite);
                    resolve(response);
                }, reject);
        });
    }
  
   
  
    
   // Delete service
   deleteService(id): Promise<any>
   {
       return new Promise((resolve, reject) => {
           this._httpClient.delete(environment.serviceUrl3 + apiUrls.prerequisite+"?Id=" + id )
               .subscribe((response: any) => {
                   resolve(response);
               }, reject);
       });
   }
  
     // Update Service 
     saveService(service): Promise<any> {
      return new Promise((resolve, reject) => {
        this._httpClient.put(environment.serviceUrl3 + apiUrls.service , service)
          .subscribe((response: any) => {
            resolve(response);
          }, reject);
      });
    }
  
    addService(service): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.serviceUrl3 + apiUrls.service, service )
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    deleteLocationService(locationServiceId): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.delete(environment.serviceUrl3 + apiUrls.location + locationServiceId +  '/services' )
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
  }
  