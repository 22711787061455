import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { fuseAnimations, FuseUtils } from '@fuse/utils';
import { Role } from 'app/model/Role';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RoleService } from '../role.service';
import { locale as english } from '../../../main/home/i18n/en';
import { locale as turkish } from '../../../main/home/i18n/tr';
import { locale as arabic } from '../../../main/home/i18n/ar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'app/shared/services/loader-service';
import {  Router } from '@angular/router';
@Component({
    selector: 'app-role',
    templateUrl: './role-list.component.html',
    styleUrls: ['./role-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class RoleComponent implements OnInit {
    dataSource: any;
    displayedColumns = ["name","code", "actions"];
    pageIndex : number = 1 ;
    pageSize :number = 10;
    pageSizeOptions  =[5, 10, 25, 100];
    length="length";
    search : string = null;

    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    @ViewChild('filter', { static: true })
    filter: ElementRef;

    // Private
    private _unsubscribeAll: Subject<any>;


    constructor(
        public loaderService : LoaderService,
        private roleService: RoleService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private router : Router
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        // ---------------------------------------------------
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);        // Set the private defaults
    }
    //--------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.dataSource =  this.getRole();


        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }

                this.dataSource.filter = this.filter.nativeElement.value;
            });
        this.getRole();
    }
    deleteRole(id) {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });


        this.confirmDialogRef.componentInstance.confirmMessage = this.translate.instant('CONFIRM_MSG.wantToDelete')




        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.roleService.deleteRole(id).then(() => {
                    // Show the success message
                    this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.deleteRole'), this.translate.instant('CONFIRM_MSG.ok'), {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                    this.ngOnInit();
                })

            }
        })

    }

    getRole(){
        this.roleService.getRolesWithParameter(this.roleService.currentPage ,this.roleService.pageSize).then(res => {
            if (res) {
                this.dataSource = res.data;
                this.pageIndex = res.pageNumber;
                this.pageSize = res.pageSize;
                this.length = res.totalItems;
            }
        })
    }

    getRoleSearch(search:string){
        if(search){
            this.roleService.getRolesSerch(this.pageIndex , this.pageSize , search).then(res => {
                if (res) {
                    this.dataSource = res.data;
                    this.pageIndex = res.pageNumber;
                    this.pageSize = res.pageSize;
                    this.length = res.totalItems;
                }
            })
        }
        else
        {
            this.ngOnInit();
        }
        
    }

    getRolePagenation(data:any){
        this.roleService.getRolesWithParameter(data.pageIndex+1 , data.pageSize).then(res => {
            if (res) {
                this.dataSource = res.data;
                this.pageIndex = res.pageNumber;
                this.pageSize = res.pageSize;
                this.length = res.totalItems;
            }
        })
    }

    edit(roliId){
        this.roleService.currentPage = this.pageIndex;
        this.roleService.pageSize = this.pageSize;
        this.router.navigate(['/role/edit/' +  roliId] );
    }

}


export class FilesDataSource extends DataSource<any>
{
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param {roleService} roleService
     * @param {MatPaginator} _matPaginator
     * @param {MatSort} _matSort
     */
    constructor(
        private roleService: RoleService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    ) {
        super();

        this.filteredData = this.roleService.roles;
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this.roleService.onRoleChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges)
            .pipe(
                map(() => {
                    let data = this.roleService.roles.slice();

                    data = this.filterData(data);

                    this.filteredData = [...data];

                    data = this.sortData(data);

                    // Grab the page's slice of data.
                    const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
                    return data.splice(startIndex, this._matPaginator.pageSize);
                }
                ));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Filtered data
    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch (this._matSort.active) {
                case 'id':
                    [propertyA, propertyB] = [a.id, b.id];
                    break;
                case 'name':
                    [propertyA, propertyB] = [a.name, b.name];
                    break;

            }

            const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
        });
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }
}
