// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    serviceUrl: 'https://qaas-api-test.azurewebsites.net/',
    IdentityUrl :'https://qaas-is.azurewebsites.net/' ,
    production: false,
    hmr       : false,
    serviceUrl2: 'https://qaas-rp1.azurewebsites.net/',
    IdentityUrl2 :'https://qaas-is.azurewebsites.net/' ,
    production2: false, 
    hmr2       : false,
    serviceUrl3: 'https://qaas-api-test.azurewebsites.net/',
    IdentityUrl3 :'https://qaas-api-test.azurewebsites.net/' ,
    production3: false,
    hmr3       : false,
    IsShowSmileRaiting:true ,
    serviceUrl4: 'https://waqtakmedia.azurewebsites.net/',
    IdentityUrl4 :'https://waqtakmedia.azurewebsites.net/' ,
    production4: false,
    hmr4  : false,
}
{
    
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.