import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { ProfileService } from 'app/modules/profile/profile.service';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from 'app/modules/role/role.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'app/modules/branches-management/user/user.service';
import { FormBuilder, FormControl, FormGroup , Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { User } from 'app/model/User';
import { Router } from '@angular/router';

@Component({
    selector     : 'profile-about',
    templateUrl  : './about.component.html',
    styleUrls    : ['./about.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ProfileAboutComponent implements OnInit, OnDestroy
{
  aboutform:FormGroup;
    about: any;
    user: User;
    userDetails :any;
    isfirstrow:boolean=false;
    username:any;
    arabicName:any;
    phoneNo:any;
    email:any;
    userData:any;
    locationId: string;
    tenantId: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ProfileService} _profileService
     */
    constructor(
        private userService: UserService,
        private _profileService: ProfileService,
        private translate :TranslateService,
        private _matSnackBar: MatSnackBar,
        private router: Router,

    )
    {
        // Set the private defaults
        //alert(this.userService.userDetails.id);
        this._unsubscribeAll = new Subject();
        this.user = new User();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        this._profileService.aboutOnChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(about => {
                this.about = about;
            });

            this.userService.getUsersDetails().then(data=>{
                this.userDetails = data;
                this.username = data.userName;
                this.phoneNo = data.phone;
                this.arabicName = data.arName;
                this.email = data.email;

               
            });

             this.userData = {
              userName: this.username,
              arname:this.arabicName,
              phone:this.phoneNo,
              email:this.email
            };


            this.aboutform = new FormGroup({
              userName: new FormControl(this.userData.userName, [Validators.required, Validators.pattern("^[a-zA-Z0-9_]*$")]),
              arname: new FormControl(this.userData.arName, Validators.required),
              phone: new FormControl(this.userData.phone, [Validators.required, Validators.pattern("^[0-9]*$")]),
              email: new FormControl(this.userData.email, [Validators.required, Validators.email])
            });
        
        
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    editing = {
      userName: true,
      arname: true,
      phone: true,
      email: true
    };



 
    callFirstshow($event){
      if($event===1)
      this.isfirstrow=true;
      else
      this.isfirstrow=false;
    }
  
    toggleEdit(attribute) {
     
      console.log(attribute);
      this.editing[attribute] = !this.editing[attribute];
      this.userData[attribute] = this.aboutform.get(attribute).value;
    }
  
    accept(param) {
      this.userData[param]=this.aboutform.get(param).value;
      this.editing.userName = true; // or family_name or nickname  or age ....
    }
    toggleCancel(param){
      this.aboutform.get(param).setValue(this.user[param]);
    }

    checkValidForm() {
      if (this.aboutform?.controls) {
          const data = this.aboutform.getRawValue();
          if (!data.userName || !data.arName || !data.email || !data.phoneNumber)
              return true;
          else return false;
      }
  }


  saveUser(): void {
    const data = this.aboutform.getRawValue();
    data.id = this.userDetails.id;
    data.userId = this.userDetails.id;
    data.role = this.userDetails.applicationRoles.name;
    if (this.user.tenantId)
        data.tenantId = this.user.tenantId;
    if (this.user.locationId)
        data.locationId = this.user.locationId;
    this.userService.saveUser(data)
        .then(() => {

            // Show the success message
            this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.userSaved'),  this.translate.instant('ok'), {
                verticalPosition: 'top',
                duration: 2000
            });

            if (this.locationId)
                this.router.navigate(['/tenant/' + this.tenantId + '/location/' + this.locationId + '/user'])
            else if (this.tenantId)
                this.router.navigate(['/tenant/' + this.tenantId + '/user'])
        }).catch(err => {
            this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
                verticalPosition: 'top',
                duration: 2000
            });
        });
}

}
