import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment  } from '../../../environments/environment';
import { AuthenticationService } from 'app/shared/services/authentication.service';
import { HttpStatusCode } from 'app/enum/http-status-code-enum';
import 'rxjs/add/operator/do';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    router: Router;
    spinnersCount = 0;
    IsLoading = false ;

    constructor(private authenticationService: AuthenticationService,
         private _matSnackBar: MatSnackBar,
         private translate: TranslateService,
         private injector: Injector) {
        this.router = this.injector.get(Router);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // TODO : Turn the Loader on 
     
        if (!req.headers.get("skip-authorization") && !req.headers.get("authorization")) {
            if ((req.url.toLowerCase().includes(environment.serviceUrl.toLowerCase())) && this.authenticationService.isAuthunticated()) {              
                req = req.clone({
                    setHeaders: {
                        "authorization": "Bearer " + this.authenticationService.getToken()
                         
                      }
                });
            }
        }
        if(!req.headers.get("without-accept-language")){
            req = req.clone({ 
                setHeaders: {
            "Accept-Language" : localStorage && localStorage.selectedLanguage ?  localStorage.selectedLanguage  : "en"//  localStorage.getItem("defaultLocale")  
                }
            });
        }
        if(req.headers.get("language-ar")){
           
            req = req.clone({ 
                setHeaders: {
            "Accept-Language" : "ar"//  localStorage.getItem("defaultLocale")  
                }
            });
        }

        this.IsLoading = true;
        return next.handle(req).do(
            (next:any) => {
        // TODO : Turn the Loader off  
        this.IsLoading = false;


                // console.log(next)
            },
            (err: any) => {

        // TODO : Turn the Loader off  
        this.IsLoading = false;
        


                if (err instanceof HttpErrorResponse) {
                    // some apis wants to ignore the error, to custom the mesasge
                    if (!req.headers.get("skip-interceptor-error")) {
                        if (err.status === HttpStatusCode.Unauthorized || err.status === HttpStatusCode.Forbidden) {
                            this.authenticationService.logout();                          
                        }
                        else if (err.status === HttpStatusCode.ServiceUnavailable) {
                            // this.router.navigate(['maintenance']);
                        }
                        else{
                            this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.wentWrong'), this.translate.instant('ok'), {
                                verticalPosition: 'top',
                                duration: 2000
                              });
                        }
                    
                    }
                }

               
            }   
        );
    }

    
}