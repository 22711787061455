import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { LocationService } from 'app/modules/branches-management/location/location.service';
import { ServiceService } from 'app/modules/branches-management/service/service.service';
import { ServiceDetailsPopupComponent } from '../service-details-popup/service-details-popup/service-details-popup.component';
import { locale as english } from '../../main/home/i18n/en';
import { locale as turkish } from '../../main/home/i18n/tr';
import { locale as arabic } from  '../../main/home/i18n/ar';
import { Time } from '@angular/common';
import { UserService } from 'app/modules/branches-management/user/user.service';
@Component({
  selector: 'app-popup-users-service',
  templateUrl: './popup-users-service.component.html',
  styleUrls: ['./popup-users-service.component.scss']
})
export class PopupUsersServiceComponent implements OnInit {
  form: FormGroup;
  UserId: any;
  locationServiceId: any;
  today = new Date();
  tomorrow = new Date();
  constructor(
    private _formBuilder: FormBuilder,
    private _matSnackBar: MatSnackBar,
    private _locationService: LocationService,
    private _ServiceService: ServiceService,
    public dialogRef: MatDialogRef<ServiceDetailsPopupComponent>,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    public translate :TranslateService,
    private _userServices: UserService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { 
    }
    ngOnInit(): void {
      this.form = this._formBuilder.group({
        priority: ['']
      });
    this.locationServiceId=this.data.locationServiceId;
    this.UserId=this.data.UserId;
    }
    cancelClick(): void {
      this.dialogRef.close("canceled");
    }
    save() {
      // if (this.form.invalid) {
      //   this.form.get('priority').markAsTouched();
      //   return;
      // }
      var data = this.form.getRawValue();
      var priority =data.priority;
      if (typeof( data.disableMessage) == 'object') {
        data.disableMessage = "";
      }
      if (priority=="" ||priority==''){
        priority=1;
      }else{
        priority=data.priority;
      }
      // if(priority !=null||priority !=''){
        this._userServices.AddUserService(this.locationServiceId, this.UserId,priority).then(() => {
          // Show the success message
          this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.serviceAssignedSuccessfully'), this.translate.instant('CONFIRM_MSG.ok'), {
            verticalPosition: 'top',
            duration: 2000
          });
          this.dialogRef.close("canceled");
        });
      }

    // }  
}
export interface DialogData {
  locationServiceId: string;
  UserId: string;
}
