<div id="groupService" class="page-layout carded fullwidth inner-scroll">

  <!-- TOP BACKGROUND -->
  <div class="top-bg accent"></div>
  <!-- / TOP BACKGROUND -->

  <!-- CENTER -->
  <div class="center">

      <!-- HEADER -->
      <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

          <!-- APP TITLE -->
          <div fxLayout="row" fxLayoutAlign="start center">

              <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/group-service/tenant/'+ TenantId+'/location/' + locatoinId">
                  <mat-icon>{{'arrow_back' | translate}}</mat-icon>
              </button>


              <div fxLayout="column" fxLayoutAlign="start start"
                  [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                  <div class="h2" >
                    {{'GROUPSERVICE_ENTRY.newGroupService' | translate}}
                  </div>
                  <div class="subtitle secondary-text">
                      <span> {{'GROUPSERVICE_ENTRY.GroupServiceDetail' | translate}}</span>
                  </div>
              </div>
          </div>
          <!-- / APP TITLE -->
          <div class="button-container">
              <mat-spinner *ngIf="isLoading" strokeWidth="2" [diameter]="25"></mat-spinner>
              <button mat-raised-button class="save-permission-button"
               [disabled]="!isValidForm()"  *ngIf="pageType ==='new'"
                  (click)="addGroupService()">
                  <span>{{'GROUPSERVICE_ENTRY.add' | translate}}</span>
              </button>

              <button mat-raised-button class="save-tenant-button"
              [disabled]="!isValidForm() || isLoading" *ngIf="pageType ==='edit'"
              (click)="saveGroupService()">
              <span>{{'GROUPSERVICE_ENTRY.save' | translate}}</span>
          </button>
          </div>        
      </div>
      <!-- / HEADER -->

      <!-- CONTENT CARD -->
      <div class="content-card">

          <!-- CONTENT -->
          <div class="content">

              <form name="groupServiceform" [formGroup]="groupServiceform" class="permission w-100-p" fxLayout="column" >
                <div style="width: 80%;">
                    <name-language-editer [data]="groupService.name" #nameLanguageEditor></name-language-editer>
                </div>

                  <div class="tab-content p-24" fusePerfectScrollbar>
                    <mat-form-field appearance="outline" floatLabel="always" fxFlex="100">
                        <mat-label>{{'GROUPSERVICE_ENTRY.startDate' | translate}} *</mat-label>
                        <input matInput type="time"  [(ngModel)]="groupService.startTime" formControlName="startTime" required>
                        <mat-datepicker #pickerFrom></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="tab-content p-24" fusePerfectScrollbar>
                    <mat-form-field appearance="outline" floatLabel="always" fxFlex="100">
                        <mat-label>{{'GROUPSERVICE_ENTRY.endDate' | translate}}*</mat-label>
                        <input matInput type="time" [(ngModel)]="groupService.endTime"  formControlName="endTime" required>
                        <mat-datepicker #pickerTo></mat-datepicker>
                    </mat-form-field>

                </div>

<!-- 
                  <div class="tab-content p-24" fusePerfectScrollbar>
                      <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                          <mat-label>{{'Permission_ENTRY.code' | translate}}</mat-label>
                          <input matInput placeholder="{{'Permission_ENTRY.code' | translate}}" name="code"
                              formControlName="code" required>
                      </mat-form-field>

                  </div>
CONTENT -->
              </form>
          </div>
          <!-- / CONTENT -->

      </div>
      <!-- / CONTENT CARD -->

  </div>
  <!-- / CENTER -->

</div>