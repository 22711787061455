import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Category } from 'app/model/Category';
import { apiUrls } from 'app/shared/constants/apiUrls';
import { StorageManager } from 'app/shared/utilities/storage-manager';
import { environment } from 'environments/environment';
import { Http2ServerRequest } from 'http2';
import { BehaviorSubject, Observable } from 'rxjs';



import { Tenant } from 'app/model/Tenant';




@Injectable({
  providedIn: 'root'
})
export class CategoryService implements Resolve<any>{
 
  categorys : Category[] = new Array<Category>();
  category : Category  =   new Category() ;
  onCategoryChanged: BehaviorSubject<any>;
  currentPage: number = 1;
  pageSize : number = 10;

   /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
      private _httpClient: HttpClient
  )
  {
      // Set the defaults
      this.onCategoryChanged = new BehaviorSubject({});
  }

   /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getCategorysWithoutPagenation()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

  
  getAllCategorys() :Observable<any>{
   return this._httpClient.get(environment.serviceUrl3 + apiUrls.category )
  }


  getCategorysWithoutPagenation(): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.category)
              .subscribe((response: any) => {
                  this.categorys = response;
                  this.onCategoryChanged.next(this.categorys);
                  resolve(response);
              }, reject);
      });
  }
  
  getCategorys(pageIndex ,pageSize): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.category + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize)
              .subscribe((response: any) => {
                  this.categorys = response;
                  this.onCategoryChanged.next(this.categorys);
                  resolve(response);
              }, reject);
      });
  }

  categorySearch(pageIndex ,pageSize,search): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.category + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize  + '&search=' + search)
              .subscribe((response: any) => {
                  this.categorys = response;
                  this.onCategoryChanged.next(this.categorys);
                  resolve(response);
              }, reject);
      });
  }
  

  getCategoryById(id,isDefaultLanguage = false): Promise<any>
  {
      return new Promise((resolve, reject) => {
        let headers: HttpHeaders = new HttpHeaders();
        if(isDefaultLanguage){
            headers = headers.append("without-accept-language" , "true");
        }
          this._httpClient.get(environment.serviceUrl3 + apiUrls.category + '/' + id, {headers : headers} )
              .subscribe((response: any) => {
                  this.category = response;
                  this.onCategoryChanged.next(this.category);
                  resolve(response);
              }, reject);
      });
  }

  
 // Delete category
 deleteCategory(id): Promise<any>
 {
     return new Promise((resolve, reject) => {
         this._httpClient.delete(environment.serviceUrl3 + apiUrls.category +"/"+ id )
             .subscribe((response: any) => {
                 resolve(response);
             }, reject);
     });
 }

  saveCategory(category): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put(environment.serviceUrl3 + apiUrls.category , category)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  addCategory(category): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl3 + apiUrls.category, category)
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }
  uploadIcon(file: any , id :string) {
    const form = new FormData();
        form.append('file', file, file.name);
    return new Promise((resolve, reject) => {
        this._httpClient.post(environment.serviceUrl3 + apiUrls.category + "/UploadIcon/" + id  , form)
            .subscribe((response: any) => {
                resolve(response);
            }, reject);
    });
}

}
