import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { LocationService } from '../location.service';
import { Location } from 'app/model/Location';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { locale as english } from '../../../../main/home/i18n/en';
import { locale as turkish } from '../../../../main/home/i18n/tr';
import { locale as arabic } from '../../../../main/home/i18n/ar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { NameLanguageEditer } from 'app/component/name-language-editer/name-language-editer.component';
import { WorkingDay } from 'app/model/WorkingDay';

@Component({
  selector: 'app-location-entry',
  templateUrl: './location-entry.component.html',
  styleUrls: ['./location-entry.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class LocationEntryComponent implements OnInit {

  location: Location;
  pageType: string;
   locationForm: FormGroup;
  @ViewChild("nameLanguageEditor") nameLanguageEditor: NameLanguageEditer;
  isLoading: boolean = false;
  mediaGroups : string;
  newsGroups :string;

  // Private
  private _unsubscribeAll: Subject<any>;
  extras: any;
  tenantId: string;

  /**
   * Constructor
   *
   * @param {LocationService} _locationService
   * @param {FormBuilder} _formBuilder
   * @param {Location} _location
   * @param {MatSnackBar} _matSnackBar
   */
  constructor(
    private locationService: LocationService,
    private _formBuilder: FormBuilder,
    private _matSnackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private translate:TranslateService
  ) {

    // Set the default
    this.location = new Location();
    this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.route.queryParams.subscribe((params: Params) => {
      this.extras = params['extras'];
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
       this.route.paramMap.subscribe(params => {
      if (params.get('tenantId'))
        this.tenantId = params.get('tenantId')
      if (params.get('id')) {
        this.location.id = params.get('id')
        this.pageType = 'edit';
        this.locationService.getLocationById(this.location.id , true).then((res: Location) => {
          this.location = res;
          this.locationForm = this.createLocationForm();

        });

      }
      else {
        if (params.get('tenantId')) {
          this.location.tenantId = params.get('tenantId');
        }
        this.pageType = 'new';
      }
      this.locationForm = this.createLocationForm();
      this.fetchAndCategorizeGroups();
    });

  }

  private fetchAndCategorizeGroups(): void {
    this.tenantId = this.route.snapshot.paramMap.get('tenantId');
  
    // Assuming you have one method to fetch all groups. Adjust as necessary.
    this.locationService.getMediaGroups(this.tenantId).then(allGroups => {
      this.mediaGroups = allGroups.filter(group => group.mediaGroupType === 0);
      this.newsGroups = allGroups.filter(group => group.mediaGroupType === 1);
    }).catch(error => {
      console.error('Error fetching groups:', error);
      this._matSnackBar.open(this.translate.instant('ERROR_MSG.unableToFetchGroups'), this.translate.instant('ok'), {
        verticalPosition: 'top',
        duration: 2000
      });
    });
  }

  goBack() {
    const options = { relativeTo: this.route, queryParams: { extras: this.extras } };
    this.router.navigate(['../../'], options);
  };

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create location form
   *
   * @returns {FormGroup}
   */
  createLocationForm(): FormGroup {
    return this._formBuilder.group({
      id  :  [this.location.id],
      name: [this.location.name],
      address: [this.location.address],
      tenantId: [this.location.tenantId],
      lat : [this.location.lat],
      lon : [this.location.lon],
      sunday:  [this.location.sunday],
    monday:  [this.location.monday],
    tuesday: [this.location.tuesday],
    wednesday: [this.location.wednesday],
    thursday: [this.location.thursday],
    friday:  [this.location.friday],
    saturday: [this.location.saturday],
    isPublicHolidayApplied:  [this.location.isPublicHolidayApplied],
    mediaGroupId: [this.location.mediaGroupId], // Ensure this.location.mediaGroupId is correctly set
    newsGroupId: [this.location.newsGroupId], // Ensure this.location.newsGroupId is correctly set
    });
  }

  /**
   * Save location
   */
  saveLocation(): void {
    this.isLoading = true;
    this.location.name = JSON.stringify(this.nameLanguageEditor.deserializedData);
    this.locationForm.controls["name"].setValue(this.location.name);
    const data = this.locationForm.getRawValue();
    const locatoin = new Location();
    locatoin.name = data.name;
    locatoin.address = data.address;
    locatoin.tenantId = data.tenantId;
    locatoin.tenant = data.tenant;
    locatoin.lat = data.lat;
    locatoin.lon = data.lon;
    locatoin.mediaGroupId=data.mediaGroup;
    locatoin.newsGroupId=data.newsGroupId;

    console.log(this.locationForm.value);

    //   data.handle = FuseUtils.handleize(data.name);
    this.locationService.saveLocation(data)
      .then(() => {

        // Show the success message
        this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.locationSaved'), this.translate.instant('ok'), {
          verticalPosition: 'top',
          duration: 2000
        });
        this.isLoading = false;
      });
  }

  /**
   * Add location
   */
  addLocation(): void {
    this.isLoading = true;
    this.location.name = JSON.stringify(this.nameLanguageEditor.deserializedData);
    this.locationForm.controls["name"].setValue(this.location.name);
    const data = this.locationForm.getRawValue();
    
    const workingDays = new WorkingDay();
    const locatoin = new Location();
    data.id = undefined;

    // to get data working day only

    workingDays.friday = data.friday;
    workingDays.saturday = data.saturday;
    workingDays.sunday = data.sunday;
    workingDays.monday = data.monday;
    workingDays.tuesday = data.tuesday;
    workingDays.wednesday = data.wednesday;
    workingDays.thursday = data.thursday;
    workingDays.isPublicHolidayApplied = data.isPublicHolidayApplied;

    //   data.handle = FuseUtils.handleize(data.name);

    // save location only
    this.locationService.addLocation(data)
      .then((result) => {
        if(result.id)
        {
          workingDays.locationId = result.id;
          // save working day only
          this.locationService.addLocationWorkingDay(workingDays).then(()=>
          {
            const options = { relativeTo: this.route, queryParams: { extras: this.extras } };
            this.router.navigate(['../'], options);

          }).catch(err => {
            this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title, this.translate.instant('ok'), {
              verticalPosition: 'top',
              duration: 2000
            });
          });

        }

        // Trigger the subscription with new data
        this.locationService.onLocationChanged.next(data);
        this.isLoading = false;

        // Show the success message
        this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.locationAdded'), this.translate.instant('ok'), {
          verticalPosition: 'top',
          duration: 2000
        });

        const options = { relativeTo: this.route, queryParams: { extras: this.extras } };
        this.router.navigate(['../'], options);
      }).catch(err => {
        this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title, this.translate.instant('ok'), {
          verticalPosition: 'top',
          duration: 2000
        });
      });
  }
  
  isValidForm(){
    return this.locationForm.valid  && this.nameLanguageEditor.manageEntityForm.valid
 }
}
