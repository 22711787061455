<div id="permission" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/permission'">
                    <mat-icon>{{'arrow_back' | translate}}</mat-icon>
                </button>


                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="pageType ==='edit'">
                        <!-- {{tenunt.name}} -->
                    </div>
                    <div class="h2" *ngIf="pageType ==='new'">
                        {{'Permission_ENTRY.newPermission' | translate}}
                    </div>
                    <div class="subtitle secondary-text">
                        <span> {{'Permission_ENTRY.permissionDetail' | translate}}</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->
            <div class="button-container">
                <mat-spinner *ngIf="isLoading" strokeWidth="2" [diameter]="25"></mat-spinner>
                <button mat-raised-button class="save-permission-button" [disabled]="permissionForm.status == 'INVALID' || isLoading"
                    *ngIf="pageType ==='new'" (click)="addPermission()">
                    <span>{{'Permission_ENTRY.add' | translate}}</span>
                </button>

                <button mat-raised-button class="save-permission-button"
                    [disabled]="(permissionForm.status == 'INVALID' && permissionForm.pristine) || isLoading" *ngIf="pageType ==='edit'"
                    (click)="savePermission()">
                    <span>{{'Permission_ENTRY.save' | translate}}</span>
                </button>
            </div>        
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="permissionForm" [formGroup]="permissionForm" class="permission w-100-p" fxLayout="column" >
                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                            <mat-label>{{'Permission_ENTRY.permissionName' | translate}}</mat-label>
                            <input matInput placeholder="{{'Permission_ENTRY.permissionName' | translate}}" name="name"
                                formControlName="name" required>
                        </mat-form-field>

                    </div>
  <!-- 
                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                            <mat-label>{{'Permission_ENTRY.code' | translate}}</mat-label>
                            <input matInput placeholder="{{'Permission_ENTRY.code' | translate}}" name="code"
                                formControlName="code" required>
                        </mat-form-field>

                    </div>
CONTENT -->
                </form>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>