import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { fuseAnimations } from '@fuse/animations';
import { TenantService } from '../../tenant/tenant.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CategoryService } from 'app/modules/category/category.service';
import { locale as english } from '../../../../main/home/i18n/en';
import { locale as turkish } from '../../../../main/home/i18n/tr';
import { locale as arabic } from '../../../../main/home/i18n/ar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { PrerequisiteService } from '../prerequisite.service';
import { Prerequisite } from 'app/model/Prerequisite';
import { NameLanguageEditer } from 'app/component/name-language-editer/name-language-editer.component';

@Component({
    selector: 'app-Prerequisite-entry',
    templateUrl: './Prerequisite-entry.component.html',
    styleUrls: ['./Prerequisite-entry.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class PrerequisiteEntryComponent implements OnInit {
    @ViewChild("nameLanguageEditor") nameLanguageEditor: NameLanguageEditer;
    Prerequisite: Prerequisite;
    pageType: string;
    Categories: []; 
    PrerequisiteForm: FormGroup;
    //@ViewChild("nameLanguageEditor") nameLanguageEditor :NameLanguageEditer;
    allowedFiles: string = 'image/*';
    invalidImage: boolean;
    file :any
    tenantId:string
    isLoading: boolean = false;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {TenantService} _tenantService
     * @param {FormBuilder} _formBuilder
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        private tenantService: TenantService,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private route: ActivatedRoute,
        private router: Router,
        private _location: Location,
        private categoryService: CategoryService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translate :TranslateService,
        private prerequisiteService: PrerequisiteService,
    ) {
        // Set the default
        this.Prerequisite = new Prerequisite();
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
      
        this.route.paramMap.subscribe(params => {
            if (params.get('id')) {
                this.Prerequisite.id = params.get('id')
                this.pageType = 'edit';
                this.prerequisiteService.getPrerequisiteById(this.Prerequisite.id ).then((res: Prerequisite) => {                    
                    this.Prerequisite = res;
                    this.PrerequisiteForm = this.createPrerequisiteForm();

                });
            }
            else {
                if (params.get('serviceId')) {
                    this.Prerequisite.serviceId = params.get('serviceId')
                }
                this.pageType = 'new';
            }
        });
        this.PrerequisiteForm = this.createPrerequisiteForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create tenant form
     *
     * @returns {FormGroup}
     */
    createPrerequisiteForm(): FormGroup {
        return this._formBuilder.group({
            name: [this.Prerequisite.name],
            description: [this.Prerequisite.description],
            serviceId: [this.Prerequisite.serviceId],
            id: [this.Prerequisite.id],
            url:[this.Prerequisite.url],
            inAppURL:[this.Prerequisite.inAppURL]
        });
    } 
    savePrerequisite(): void {
        this.isLoading = true;
        this.Prerequisite.name = JSON.stringify(this.nameLanguageEditor.deserializedData);
        this.PrerequisiteForm.controls["name"].setValue( this.Prerequisite.name);        
        const data = this.PrerequisiteForm.getRawValue();
        this.prerequisiteService.deleteService(data.id).then(() => {
            this.prerequisiteService.saveServiceById(data)
            .then((res) => { 
                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.PrerequisiteAdded'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
                if(this.file !=undefined ||this.file!=null||this.file!=null  ){
                    this.tenantService.uploadImage(this.file, this.tenantId)
                    }
                    this.goBack()
            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
         
            // Change the location with new one
        })

        
       
    }
     isValidForm(){
       return this.PrerequisiteForm?.valid  && this.nameLanguageEditor.manageEntityForm.valid
    }

    /**
     *addPrerequisite
     */
    addPrerequisite(): void {
        this.isLoading = true;
        this.Prerequisite.name = JSON.stringify(this.nameLanguageEditor.deserializedData);
        this.PrerequisiteForm.controls["name"].setValue( this.Prerequisite.name);
        const data = this.PrerequisiteForm.getRawValue();
       
        data.id = undefined;

        this.prerequisiteService.addServiceById(data)
            .then((res) => {

                // Trigger the subscription with new data
                this.tenantService.onTenantChanged.next(data);
                this.isLoading = false;
                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.PrerequisiteSaved'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });

              this.goBack()
                // Change the location with new one

            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }
    goBack() {
        this._location.back();
      }
}
