import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { AgmCoreModule } from '@agm/core';
import { ReportComponent } from './report.component'; 
import { DatePipe } from '@angular/common';
import { CalendarModule as AngularCalendarModule, CalendarModule, DateAdapter } from 'angular-calendar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { ReportService } from './report.service';
import { FuseSharedModule } from '@fuse/shared.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

const routes = [
  {
      path     : 'report',
      component: ReportComponent
  }
];
@NgModule({
  
  declarations: [
    ReportComponent   
  ],
  imports: [
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    CalendarModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatTableModule,
    MatDatepickerModule,
    MatTabsModule,
    AgmCoreModule.forRoot({
        apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
    }),
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    DragDropModule,
    NgxSkeletonLoaderModule,
    MatProgressSpinnerModule
   
  ],
  providers:[
    ReportService,
    DatePipe
  ]
  
})
export class ReportModule { }
