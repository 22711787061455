import { Time } from "@angular/common";
import { FuseUtils } from "@fuse/utils";
import { Tenant } from "./Tenant";

export class AdsMedia {   
    constructor(AdsMedia?)
    {
        AdsMedia = AdsMedia || {};
        this.id = AdsMedia.id || 0;//FuseUtils.generateGUID();
        this.name = AdsMedia.name || '';
        this.path = AdsMedia.path || '';
        this.news = AdsMedia.news || '';
        this.createdTime= AdsMedia.createdTime || '' ;
        this.isActivated= AdsMedia.type || true ;
        this.mediaType = AdsMedia.mediaType || '';
        this.mediaGroupId = AdsMedia.mediaGroupId || 0;

    } 
    id : string; 
    name: string;
    path: string; 
    news: string;
    createdTime:Time; 
    isActivated:boolean
    mediaType: string;
    mediaGroupId: string;
   
  

}