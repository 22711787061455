import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { User } from 'app/model/User';
import { apiUrls } from 'app/shared/constants/apiUrls';
import { environment } from 'environments/environment';
import { promise } from 'protractor';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserService implements Resolve<any>{

    users: User[] = new Array<User>();
    user: User = new User();
    onUserChanged: BehaviorSubject<any>;
    currentPage: number = 1;
    pageSize : number = 10;
    // to get current user details
    //  userService.userDetails.location.id 
    userDetails :any;
    roleName : string;
  
    /**
      * Constructor
      *
      * @param {HttpClient} _httpClient
      */
    constructor(
        private _httpClient: HttpClient
    ) {
        // Set the defaults
        this.onUserChanged = new BehaviorSubject({});
    }

    /**
      * Resolver
      *
      * @param {ActivatedRouteSnapshot} route
      * @param {RouterStateSnapshot} state
      * @returns {Observable<any> | Promise<any> | any}
      */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getAllUsers()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }


    getAllUsers(): Observable<any> {
        return this._httpClient.get(environment.serviceUrl3 + apiUrls.user)
    }

    getUsersTenant(tenantId , pageIndex ,pageSize): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.user + '/Tenant/' + tenantId + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize)
                .subscribe((response: any) => {
                    this.users = response;
                    this.onUserChanged.next(this.users);
                    resolve(response);
                }, reject);
        });
    }

    getUsersTenantSearch(tenantId , pageIndex ,pageSize , search): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.user + '/Tenant/' + tenantId + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize + '&search=' + search)
                .subscribe((response: any) => {
                    this.users = response;
                    this.onUserChanged.next(this.users);
                    resolve(response);
                }, reject);
        });
    }

    getUsersLocationWithoutPagination(locationId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.user + '/Location/' + locationId)
                .subscribe((response: any) => {
                    this.users = response;
                    this.onUserChanged.next(this.users);
                    resolve(response);
                }, reject);
        });
    }

    getUsersLocation(locationId , pageIndex ,pageSize ): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.user + '/Location/' + locationId + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize )
                .subscribe((response: any) => {
                    this.users = response;
                    this.onUserChanged.next(this.users);
                    resolve(response);
                }, reject);
        });
    }

    getUsersLocationSearch(locationId , pageIndex ,pageSize ,search): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.user + '/Location/' + locationId + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize+ '&search=' + search )
                .subscribe((response: any) => {
                    this.users = response;
                    this.onUserChanged.next(this.users);
                    resolve(response);
                }, reject);
        });
    }

    getUserById(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.user + '/' + id)
                .subscribe((response: any) => {
                    this.user = response;
                    this.onUserChanged.next(this.user);
                    resolve(response);
                }, reject);
        });
    }


    // Delete user
     deleteUser(id): Promise<any>
     {
         return new Promise((resolve, reject) => {
             this._httpClient.delete(environment.serviceUrl3 + apiUrls.user +'/User/User/'+ id )
                 .subscribe((response: any) => {
                     resolve(response);
                 }, reject);
         });
     }

    saveUser(user): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(environment.serviceUrl3 + apiUrls.user, user)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addUser(user): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.serviceUrl3 + apiUrls.Accounts + '/register', user)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getUserServices(userId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.IdentityUrl3 + apiUrls.UserServices + '/User/' + userId)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    AddUserService(locationServicesId , applicationUserId , priority): Promise<any> {
        var user = { locationServicesId: locationServicesId, applicationUserId: applicationUserId ,priority:priority}
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.IdentityUrl3 + apiUrls.UserServices, user)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

     deleteUserService(userServiceId): Promise<any>
     {
         return new Promise((resolve, reject) => {
             this._httpClient.delete(environment.IdentityUrl3 + apiUrls.UserServices + "?id=" + userServiceId)
                 .subscribe((response: any) => {
                     resolve(response);
                 }, reject);
         });
     }

     getUsersDetails(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.IdentityUrl3 + apiUrls.user + '/Details')
                .subscribe((response: any) => {
                    this.users = response;
                    this.roleName =  response?.applicationRoles?.name;
                    this.onUserChanged.next(this.users);
                    resolve(response);
                }, reject);
        });
    }

    forgotpassword(user): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.IdentityUrl3+apiUrls.Accounts+'/forgotpassword', user)
                .subscribe((response: any) => {
                    this.users = response;
                    this.onUserChanged.next(this.users);
                    resolve(response);
                }, reject);
        });
    }

    resetPassword(data): Promise<any>{
        var accessTokenObj = JSON.parse(localStorage.getItem("currentToken"));
        const headers = new HttpHeaders().set('Content-Type','application/json;')
        .set("Authorization", "bearer " + accessTokenObj);
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.serviceUrl + apiUrls.Accounts + '/forceresetpassword/', data, { headers: headers })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

}
