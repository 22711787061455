import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { LoginService } from './login.service';
import { LoginVm } from 'app/model/user/login.vm';
import { Router } from '@angular/router';
import { StorageManager} from '../../../shared/utilities/storage-manager';
import { locale as english } from '../../../main/home/i18n/en';
import { locale as turkish } from '../../../main/home/i18n/tr';
import { locale as arabic } from  '../../../main/home/i18n/ar';
import { locale as arNav } from  '../../../navigation/i18n/ar';
import { locale as enNav } from  '../../../navigation/i18n/en';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { UserService as UService } from 'app/modules/branches-management/user/user.service';

@Component({
    selector     : 'login',
    templateUrl  : './login.component.html',
    styleUrls    : ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class LoginComponent implements OnInit
{
    loginForm: FormGroup;
    loginModel:LoginVm;
    IsInvalidLogin: boolean = false;
    IsLoading: boolean;
    path : string ;
    password:any;
    show : any;     
    showHide : any;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private loginServices : LoginService,
        private userService  : UService,

        private router: Router,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    )
    {
        this.path = window.location.pathname;
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this.loginModel = new LoginVm();
        this.loginModel.Grant_type = "password" ;
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic, arNav, enNav);

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.loginForm = this._formBuilder.group({
            userName   : ['', [Validators.required ]],
            password: ['', Validators.required]
        });
        this.password = "password";
        this.showHide = "visibility";
        // this.oidcSecurityService.checkAuth().subscribe((auth) => console.log('is authenticated', auth));
    }


    // login() {
    //     this.oidcSecurityService.authorize();
    // }

    // logout() {
    //     this.oidcSecurityService.logoff();
    // }
    forgetpassword()
    {
     
        this.router.navigate(['forget-password'])
 
    }
    login(){
        this.IsLoading = true;
        this.loginServices.Login(this.loginModel).subscribe(
            (result :any ) => {
                StorageManager.set("currentToken" , result.access_token )                
                this.IsLoading = false;
                this.userService.getUsersDetails().then(res => {
                    let url = "";
                    if (this.userService.userDetails && this.userService.userDetails.applicationRoles) {
                        switch (this.userService.userDetails.applicationRoles.name) {
                            case "Teller":
                                url = "keypad"
                                break;
                            case "Tenant_Admin":
                            case "SYS_Admin":

                                url = "tenant/dashboards"
                                break;

                            case "Location_Admin":
                                url = "location/dashboards"
                                break;


                            default:
                                url = "keypad"
                        }
                        this.router.navigate([url]);

                    }
                    else{
                        this.router.navigate(["keypad"]);
                    }


                }, () => {
                    this.router.navigate(["keypad"]);
                });
            },
            (error) => {
                this.IsInvalidLogin = true;
                this.IsLoading = false ;
            }
        )
    }

    showPassword(){
        if (this.password === 'password') {
            this.password = 'text';
            this.showHide = "visibility_off";
          } else {
            this.password = 'password';
            this.showHide = "visibility";
          }

    }
}
