import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

import { fuseAnimations } from '@fuse/animations';
import { ServiceService } from '../service.service';
import { Service } from 'app/model/Service';
import { ActivatedRoute, Router } from '@angular/router';
import { locale as english } from '../../../../main/home/i18n/en';
import { locale as turkish } from '../../../../main/home/i18n/tr';
import { locale as arabic } from '../../../../main/home/i18n/ar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { PrerequisiteEditer } from 'app/component/prerequisite_editer/prerequisite-editer.component';
import {Location} from '@angular/common';
@Component({
    selector: 'app-service-addRequisite',
    templateUrl: './service-addRequisite.component.html',
    styleUrls: ['./service-addRequisite.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ServiceAddRequisiteComponent implements OnInit {

    service: Service;
    pageType: string;
    serviceForm: FormGroup;
    @ViewChild("prerequisiteEditer") prerequisiteEditer :PrerequisiteEditer;


    // Private
    private _unsubscribeAll: Subject<any>;
    extras: any;

    /**
     * Constructor
     *
     * @param {ServiceService} _serviceService
     * @param {FormBuilder} _formBuilder
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        private serviceService: ServiceService,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private route: ActivatedRoute,
        private _location: Location,
        private router: Router,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translate:TranslateService
    ) {
        // Set the default
        this.service = new Service();
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

    }
    goBack() {
        this._location.back();
    };


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create service form
     *
     * @returns {FormGroup}
     */
    createServiceForm(): FormGroup {
        return this._formBuilder.group({
            id  :  [this.service.id],
            name: [this.service.name],
            discription: [this.service.discription],
        });
    }

    isValidForm(){
        return this.serviceForm?.valid  && this.prerequisiteEditer?.manageEntityForm?.valid
     }

    /**
     * Save service
     */
    saveService(): void {
        this.service.name = JSON.stringify(this.prerequisiteEditer.deserializedData);
        this.serviceForm.controls["name"].setValue(this.service.name);
        const data = this.serviceForm.getRawValue();
        // data.handle = FuseUtils.handleize(data.name);

        this.serviceService.saveService(data)
            .then(() => {
                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.serviceAdded'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.message, this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }

    /**
     * Add service
     */
    addService(): void {
        this.service.name = JSON.stringify(this.prerequisiteEditer.deserializedData);
        this.serviceForm.controls["name"].setValue(this.service.name);
        const data = this.serviceForm.getRawValue();
        data.id = undefined;

        //   data.handle = FuseUtils.handleize(data.name);

        this.serviceService.addService(data)
            .then(() => {

                // Trigger the subscription with new data
                this.serviceService.onServiceChanged.next(data);

                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.serviceAdded'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
                const options = { relativeTo: this.route, queryParams: { extras: this.extras } };
                this.router.navigate(['../'], options);

                // Change the location with new one

            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }
}
