<div class="theme-options-panel" fusePerfectScrollbar>

    <div class="header">

        <span class="title">{{'THEME_OPTION.themeOptions' | translate}}<mat-icon></mat-icon></span>

        <button mat-icon-button class="close-button" (click)="toggleSidebarOpen('themeOptionsPanel')">
            <mat-icon>close</mat-icon>
        </button>

    </div>

    <form [formGroup]="form">

        <!-- COLOR THEME -->
        <div class="group">

            <h2> {{'THEME_OPTION.colorThemes' | translate}}</h2>

            <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="colorTheme">
                <mat-radio-button class="mb-12" value="theme-default"> {{'THEME_OPTION.defaultLight' | translate}}</mat-radio-button>
                <mat-radio-button class="mb-12" value="theme-yellow-light">{{'THEME_OPTION.yellowLight' | translate}}</mat-radio-button>
                <mat-radio-button class="mb-12" value="theme-blue-gray-dark">{{'THEME_OPTION.blueGrayDark' | translate}}</mat-radio-button>
                <mat-radio-button class="mb-12" value="theme-pink-dark">{{'THEME_OPTION.pinkDark' | translate}}</mat-radio-button>
            </mat-radio-group>

        </div>

        <!-- LAYOUT STYLES -->
        <div class="group" formGroupName="layout">

            <h2>{{'THEME_OPTION.layoutStyles' | translate}}</h2>

            <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="style">

                <mat-radio-button class="mb-12" value="vertical-layout-1">
                     {{'THEME_OPTION.verticalLayout1' | translate}}
                </mat-radio-button>

                <mat-radio-button class="mb-12" value="vertical-layout-2">
                    {{'THEME_OPTION.verticalLayout2' | translate}}
                </mat-radio-button>

                <mat-radio-button class="mb-12" value="vertical-layout-3">
                    {{'THEME_OPTION.verticalLayout3' | translate}}
                </mat-radio-button>

                <mat-radio-button class="mb-12" value="horizontal-layout-1">
                    {{'THEME_OPTION.horizontalLayout1' | translate}}
                </mat-radio-button>

            </mat-radio-group>

            <!-- DIFFERENT FORMS BASED ON LAYOUT STYLES -->
            <ng-container [ngSwitch]="fuseConfig.layout.style">

                <!-- VERTICAL LAYOUT #1 -->
                <ng-container *ngSwitchCase="'vertical-layout-1'">

                    <!-- LAYOUT WIDTH -->
                    <div class="group mt-32">

                        <h2>{{'THEME_OPTION.layoutWidth' | translate}}</h2>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="width">
                            <mat-radio-button class="mb-12" value="fullwidth">{{'THEME_OPTION.fullWidth' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="boxed">{{'THEME_OPTION.boxed' | translate}}</mat-radio-button>
                        </mat-radio-group>

                    </div>

                    <!-- NAVBAR -->
                    <div class="group" formGroupName="navbar">

                        <h2>{{'THEME_OPTION.navbar' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'THEME_OPTION.hide' | translate}}
                        </mat-slide-toggle>

                        <mat-slide-toggle class="mt-24" formControlName="folded">
                            {{'THEME_OPTION.folded' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'THEME_OPTION.position' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button id="footerLeftPossition" class="mb-16" value="left">{{'THEME_OPTION.left' | translate}}</mat-radio-button>
                            <mat-radio-button id="footerRightPossition" class="mb-16" value="right">{{'THEME_OPTION.right' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="mt-8"> {{'THEME_OPTION.variant' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="variant">
                            <mat-radio-button class="mb-16" value="vertical-style-1">{{'THEME_OPTION.style1' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-16" value="vertical-style-2">{{'THEME_OPTION.style2' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="mt-16 mb-8">{{'THEME_OPTION.primaryBackground' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="primaryBackground"></fuse-material-color-picker>

                        <h3 class="mt-16 mb-8">{{'THEME_OPTION.secondaryBackground' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="secondaryBackground"></fuse-material-color-picker>

                    </div>

                    <!-- TOOLBAR -->
                    <div class="group" formGroupName="toolbar">

                        <h2>{{'THEME_OPTION.toolbar' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'THEME_OPTION.hide' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'THEME_OPTION.position' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="above">{{'THEME_OPTION.above' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below-static">{{'THEME_OPTION.belowStatic' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below-fixed">{{'THEME_OPTION.belowFixed' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox class="mt-24" formControlName="customBackgroundColor">
                             {{'THEME_OPTION.useCustomBackgroundColor' | translate}}
                        </mat-checkbox>

                        <h3 class="mt-24 mb-8">{{'THEME_OPTION.backgroundColor' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="background"></fuse-material-color-picker>

                    </div>

                    <!-- FOOTER -->
                    <div class="group" formGroupName="footer">

                        <h2>{{'THEME_OPTION.footer' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'THEME_OPTION.hide' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'THEME_OPTION.position' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="above">{{'THEME_OPTION.above' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below-static">{{'THEME_OPTION.belowStatic' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below-fixed">{{'THEME_OPTION.belowFixed' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox class="mt-24" formControlName="customBackgroundColor">
                            {{'THEME_OPTION.useCustomBackgroundColor' | translate}}
                        </mat-checkbox>

                        <h3 class="mt-24 mb-8">{{'THEME_OPTION.color' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="background"></fuse-material-color-picker>

                    </div>

                    <!-- SIDE PANEL -->
                    <div class="group" formGroupName="sidepanel">

                        <h2>{{'THEME_OPTION.sidePanel' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'THEME_OPTION.hide' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'THEME_OPTION.position' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="left">{{'THEME_OPTION.left' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="right">{{'THEME_OPTION.right' | translate}}</mat-radio-button>
                        </mat-radio-group>

                    </div>

                </ng-container>

                <!-- VERTICAL LAYOUT #2 -->
                <ng-container *ngSwitchCase="'vertical-layout-2'">

                    <!-- LAYOUT WIDTH -->
                    <div class="group mt-32">

                        <h2>{{'THEME_OPTION.layoutWidth' | translate}}</h2>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="width">
                            <mat-radio-button class="mb-12" value="fullwidth">{{'THEME_OPTION.fullWidth' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="boxed">{{'THEME_OPTION.boxed' | translate}}</mat-radio-button>
                        </mat-radio-group>

                    </div>

                    <!-- NAVBAR -->
                    <div class="group" formGroupName="navbar">

                        <h2>{{'THEME_OPTION.navbar' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'THEME_OPTION.hide' | translate}}
                        </mat-slide-toggle>

                        <mat-slide-toggle class="mt-24" formControlName="folded">
                            {{'THEME_OPTION.folded' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'THEME_OPTION.position' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-16" value="left">{{'THEME_OPTION.left' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-16" value="right">{{'THEME_OPTION.right' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="mt-8">{{'THEME_OPTION.variant' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="variant">
                            <mat-radio-button class="mb-16" value="vertical-style-1">{{'THEME_OPTION.style1' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-16" value="vertical-style-2">{{'THEME_OPTION.style2' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="mt-16 mb-8">{{'THEME_OPTION.primaryBackground' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="primaryBackground"></fuse-material-color-picker>

                        <h3 class="mt-16 mb-8">{{'THEME_OPTION.secondaryBackground' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="secondaryBackground"></fuse-material-color-picker>

                    </div>

                    <!-- TOOLBAR -->
                    <div class="group" formGroupName="toolbar">

                        <h2>{{'THEME_OPTION.toolbar' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'THEME_OPTION.hide' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">Position:</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="above-static">{{'THEME_OPTION.aboveStatic' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="above-fixed">{{'THEME_OPTION.aboveFixed' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below">{{'THEME_OPTION.below' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox class="mt-24" formControlName="customBackgroundColor">
                            {{'THEME_OPTION.useCustomBackgroundColor' | translate}}
                        </mat-checkbox>

                        <h3 class="mt-24 mb-8">{{'THEME_OPTION.backGroundColor' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="background"></fuse-material-color-picker>

                    </div>

                    <!-- FOOTER -->
                    <div class="group" formGroupName="footer">

                        <h2>{{'THEME_OPTION.footer' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'THEME_OPTION.hide' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'THEME_OPTION.position' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="above-static">{{'THEME_OPTION.aboveStatic' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="above-fixed">{{'THEME_OPTION.aboveFixed' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below">{{'THEME_OPTION.horizontalLayout1' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox class="mt-24" formControlName="customBackgroundColor">
                           {{'THEME_OPTION.useCustomBackgroundColor' | translate}}
                        </mat-checkbox>

                        <h3 class="mt-24 mb-8">{{'THEME_OPTION.backgroundColor' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="background"></fuse-material-color-picker>

                    </div>

                    <!-- SIDE PANEL -->
                    <div class="group" formGroupName="sidepanel">

                        <h2>{{'THEME_OPTION.sidePanel' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'THEME_OPTION.hide' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'THEME_OPTION.position' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="left">{{'THEME_OPTION.left' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="right">{{'THEME_OPTION.right' | translate}}</mat-radio-button>
                        </mat-radio-group>

                    </div>

                </ng-container>

                <!-- VERTICAL LAYOUT #3 -->
                <ng-container *ngSwitchCase="'vertical-layout-3'">

                    <!-- LAYOUT WIDTH -->
                    <div class="group mt-32">

                        <h2>{{'THEME_OPTION.layoutWidth' | translate}}</h2>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="width">
                            <mat-radio-button class="mb-12" value="fullwidth">{{'THEME_OPTION.fullWidth' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="boxed">{{'THEME_OPTION.boxed' | translate}}</mat-radio-button>
                        </mat-radio-group>

                    </div>

                    <!-- NAVBAR -->
                    <div class="group" formGroupName="navbar">

                        <h2>{{'THEME_OPTION.navbar' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'THEME_OPTION.hide' | translate}}
                        </mat-slide-toggle>

                        <mat-slide-toggle class="mt-24" formControlName="folded">
                            {{'THEME_OPTION.folded' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'THEME_OPTION.position' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-16" value="left">{{'THEME_OPTION.left' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-16" value="right">{{'THEME_OPTION.right' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="mt-8">{{'THEME_OPTION.variant' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="variant">
                            <mat-radio-button class="mb-16" value="vertical-style-1">{{'THEME_OPTION.style1' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-16" value="vertical-style-2">{{'THEME_OPTION.style2' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="mt-16 mb-8">{{'THEME_OPTION.primaryBackground' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="primaryBackground"></fuse-material-color-picker>

                        <h3 class="mt-16 mb-8">{{'THEME_OPTION.secondaryBackground' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="secondaryBackground"></fuse-material-color-picker>

                    </div>

                    <!-- TOOLBAR -->
                    <div class="group" formGroupName="toolbar">

                        <h2>{{'THEME_OPTION.toolbar' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'THEME_OPTION.hide' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'THEME_OPTION.position' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="above-static">{{'THEME_OPTION.aboveStatic' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="above-fixed">{{'THEME_OPTION.aboveFixed' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox class="mt-24" formControlName="customBackgroundColor">
                            {{'THEME_OPTION.useCustomBackgroundColor' | translate}}
                        </mat-checkbox>

                        <h3 class="mt-24 mb-8">{{'THEME_OPTION.backgroundColor' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="background"></fuse-material-color-picker>

                    </div>

                    <!-- FOOTER -->
                    <div class="group" formGroupName="footer">

                        <h2>{{'THEME_OPTION.footer' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'THEME_OPTION.hide' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'THEME_OPTION.position' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="above-static">{{'THEME_OPTION.aboveStatic' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="above-fixed">{{'THEME_OPTION.aboveFixed' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox class="mt-24" formControlName="customBackgroundColor">
                            {{'THEME_OPTION.useCustomBackgroundColor' | translate}}
                        </mat-checkbox>

                        <h3 class="mt-24 mb-8">{{'THEME_OPTION.backgroundColor' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="background"></fuse-material-color-picker>

                    </div>

                    <!-- SIDE PANEL -->
                    <div class="group" formGroupName="sidepanel">

                        <h2>{{'THEME_OPTION.sidePanel' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'THEME_OPTION.hide' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'THEME_OPTION.position' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="left">{{'THEME_OPTION.left' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="right">{{'THEME_OPTION.right' | translate}}</mat-radio-button>
                        </mat-radio-group>

                    </div>

                </ng-container>

                <!-- HORIZONTAL LAYOUT #1 -->
                <ng-container *ngSwitchCase="'horizontal-layout-1'">

                    <!-- LAYOUT WIDTH -->
                    <div class="group mt-32">

                        <h2>{{'THEME_OPTION.layoutWidth' | translate}}</h2>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="width">
                            <mat-radio-button class="mb-12" value="fullwidth">{{'THEME_OPTION.fullWidth' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="boxed">{{'THEME_OPTION.boxed' | translate}}</mat-radio-button>
                        </mat-radio-group>

                    </div>

                    <!-- NAVBAR -->
                    <div class="group" formGroupName="navbar">

                        <h2>{{'THEME_OPTION.navbar' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'THEME_OPTION.hide' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'THEME_OPTION.position' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-16" value="top">{{'THEME_OPTION.top' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="mt-8">{{'THEME_OPTION.variantVertical' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="variant">
                            <mat-radio-button class="mb-16" value="vertical-style-1">{{'THEME_OPTION.style1' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-16" value="vertical-style-2">{{'THEME_OPTION.style2' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="mt-16 mb-8">{{'THEME_OPTION.prinaryBackground' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="primaryBackground">
                        </fuse-material-color-picker>

                        <h3 class="mt-16 mb-8">{{'THEME_OPTION.secondaryBackgroundVertical' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="secondaryBackground">
                        </fuse-material-color-picker>

                    </div>

                    <!-- TOOLBAR -->
                    <div class="group" formGroupName="toolbar">

                        <h2>{{'THEME_OPTION.toolbar' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'THEME_OPTION.hide' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'THEME_OPTION.position' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="above">{{'THEME_OPTION.above' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below">{{'THEME_OPTION.below' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox class="mt-24" formControlName="customBackgroundColor">
                            {{'THEME_OPTION.useCustomBackgroundColor' | translate}}
                        </mat-checkbox>

                        <h3 class="mt-24 mb-8">{{'THEME_OPTION.backgroundColor' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="background"></fuse-material-color-picker>

                    </div>

                    <!-- FOOTER -->
                    <div class="group" formGroupName="footer">

                        <h2>{{'THEME_OPTION.footer' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'THEME_OPTION.hide' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'THEME_OPTION.position' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="above-fixed">{{'THEME_OPTION.aboveFixed' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="above-static">{{'THEME_OPTION.aboveStatic' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox class="mt-24" formControlName="customBackgroundColor">
                            {{'THEME_OPTION.useCustomBackgroundColor' | translate}}
                        </mat-checkbox>

                        <h3 class="mt-24 mb-8">{{'THEME_OPTION.backgroundColor' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="background"></fuse-material-color-picker>

                    </div>

                    <!-- SIDE PANEL -->
                    <div class="group" formGroupName="sidepanel">

                        <h2>{{'THEME_OPTION.sidePanel' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'THEME_OPTION.hide' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'THEME_OPTION.position' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="left">{{'THEME_OPTION.left' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="right">{{'THEME_OPTION.right' | translate}}</mat-radio-button>
                        </mat-radio-group>

                    </div>

                </ng-container>

            </ng-container>

        </div>

        <!-- CUSTOM SCROLLBARS -->
        <div class="group">

            <h2>{{'THEME_OPTION.customScrollbars' | translate}}</h2>

            <mat-slide-toggle class="mb-12" formControlName="customScrollbars">
                {{'THEME_OPTION.enableCustomScrollbars' | translate}}
            </mat-slide-toggle>

        </div>

    </form>

    </div>
