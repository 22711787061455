<div id="locations" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">



        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/tenant'">
                    <mat-icon>{{'arrow_back' | translate}}</mat-icon>
                </button>
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    location_on
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    {{'LOCATION_LIST.locations' | translate}}
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon> {{'LOCATION_LIST.search' | translate}}</mat-icon>
                    <input (keyup)="getLocationsSearch($event.target.value)" placeholder="{{'LOCATION_LIST.searchForLocation' | translate}}">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <button mat-raised-button [routerLink]="'/tenant/'+ TenantId + '/location/add'"
                class="add-location-button fuse-white mt-24 mt-md-0">
                <span>{{'LOCATION_LIST.addNewLocation' | translate}}</span>
            </button>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">
            <div class="list-skeleton" *ngIf="loaderService.isLoading ">
                <ngx-skeleton-loader
                    [theme]="{ width: '100%', height: '35px'}"
                    animation="progress"
                    count="10">
                </ngx-skeleton-loader>
            </div>
            <mat-table *ngIf="!loaderService.isLoading " class="locations-table custom-table" #table [dataSource]="dataSource" matSort [@animateStagger]="{value:'50'}"
                fusePerfectScrollbar>

                <!-- ID Column -->
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'LOCATION_LIST.id' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let location">
                        <p class="text-truncate">{{location.id}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'LOCATION_LIST.name' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let location">
                        <p class="text-truncate">{{location.name}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="address">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'LOCATION_LIST.address' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let location">
                        <p class="text-truncate">{{location.address}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="tenant">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'LOCATION_LIST.tenant' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let location">
                        <p class="text-truncate">{{location.tenant ? location.tenant.name : Tenant.name}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'LOCATION_LIST.actions' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let location">


                        <mat-icon matTooltip="{{'LOCATION_LIST.services' | translate}}"
                            [routerLink]="'/service/tenant/' + TenantId + '/location/' + location.id "
                            class="logo-icon mx-1" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                            storage
                        </mat-icon>

                        <mat-icon matTooltip="{{'LOCATION_LIST.groupService' | translate}}"
                            [routerLink]="'/group-service/tenant/' + TenantId +'/location/' + location.id"
                            class="logo-icon mx-1" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                            add
                        </mat-icon>

                        <mat-icon class="logo-icon mx-1"
                        (click)="edit(location.id)" 
                            [@animate]="{value:'*',params:{delay:'200ms',scale:'0.4'}}" matTooltip="{{'LOCATION_LIST.editLocation' | translate}}">
                            edit
                        </mat-icon>

                        <mat-icon (click)="deleteLocation(location.id)" class="logo-icon mx-1" *ngIf="userService.roleName == 'SYS_Admin'"
                            [@animate]="{value:'*',params:{delay:'275ms',scale:'0.6'}}" matTooltip="{{'LOCATION_LIST.deleteLocations' | translate}}">
                            delete_forever
                        </mat-icon>

                        <mat-icon class="logo-icon mx-1" [routerLink]="'/tenant/'+ TenantId + '/location/' + location.id + '/user'"
                            [@animate]="{value:'*',params:{delay:'275ms',scale:'0.6'}}" matTooltip="{{'LOCATION_LIST.userLocations' | translate}}">
                            account_circle
                        </mat-icon>

                        <mat-icon class="logo-icon mx-1" [routerLink]="'/import-useres/tenant/'+ TenantId + '/location/' + location.id"
                        [@animate]="{value:'*',params:{delay:'275ms',scale:'0.6'}}" matTooltip="{{'LOCATION_LIST.importUsers' | translate}}">
                        import_export
                        </mat-icon>

                    </mat-cell>
                </ng-container>



                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let Location; columns: displayedColumns;" class="location" matRipple>
                </mat-row>

            </mat-table>

            <mat-paginator 
            [length]="length"
            [pageIndex]="pageIndex - 1"
            [pageSize]="pageSize"
            (page)='getLocationsPagenatoin($event)'
            [pageSizeOptions]="pageSizeOptions">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>