<button (click)="close()" class="close"><img src="assets/images/keypad/end-session.svg"></button>
<h1>Hold Dialog</h1>
<div class="tickets-list">
    <div *ngFor="let count of Tickets" > 
        <div (click)="recallTicket(count.id,count.counterNumber)" style="cursor: pointer;" class="ticket" >
            <h2>{{count.ticketNumber}}</h2>
            <div  class="d-flex align-items-center justify-content-between" >
                <h3>{{count.phone}}</h3>
                <span>{{count.enterDate | date : 'h:mm:ss a'}}</span>
            </div>
            <button (click)="delete(count.id)">
                <img src="assets/images/keypad/delete.svg">
            </button>
        </div>
    </div>
</div>