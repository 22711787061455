<div id="price-tables" class="page-layout simple fullwidth">

    <!-- HEADER -->
    <div class="header" fxLayout="row" fxLayoutAlign="start start">

        <div class="hero-text" fxLayout="column" fxLayoutAlign="center center" fxFlex>

            <div class="h1">Simple Pricing!</div>
            <div class="h3">
                The most advanced customer support tools with a simple and affordable pricing. And you can always try
                for 30 days, free!
            </div>

        </div>

    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content" fxLayout="column">

        <!-- PRICE TABLES -->
        <div class="price-tables" fxLayout="row wrap" fxLayoutAlign="center center">

            <div class="price-table style-3 mat-elevation-z4" fxLayout="column">

                <div class="package-type">
                    <div class="title">Starter</div>
                    <div class="subtitle">For small teams</div>
                </div>

                <div class="price primary-300" fxLayout="row" fxLayoutAlign="center start">
                    <div class="currency">$</div>
                    <div fxLayout="row" fxLayoutAlign="center end">
                        <div class="value">29</div>
                        <div class="period">monthly per user</div>
                    </div>
                </div>

                <div class="terms" fxLayout="column" fxLayoutAlign="start center">
                    <div class="term">Unlimited projects</div>
                    <div class="term">Unlimited pages</div>
                    <div class="term">Unlimited disk space</div>
                    <div class="term">24 / 7 Free support</div>
                </div>

                <button mat-raised-button class="cta-button" color="accent">GET STARTED FREE</button>

                <div class="note">7 day free trial to start</div>

            </div>

            <div class="price-table style-3 mat-elevation-z12" fxLayout="column">

                <div class="package-type">
                    <div class="title">Pro</div>
                    <div class="subtitle">For larger teams</div>
                </div>

                <div class="price primary" fxLayout="row" fxLayoutAlign="center start">
                    <div class="currency">$</div>
                    <div fxLayout="row" fxLayoutAlign="center end">
                        <div class="value">59</div>
                        <div class="period">monthly per user</div>
                    </div>
                </div>

                <div class="terms" fxLayout="column" fxLayoutAlign="start center">
                    <div class="term">Unlimited projects</div>
                    <div class="term">Unlimited pages</div>
                    <div class="term">Unlimited disk space</div>
                    <div class="term">24 / 7 Free support</div>
                    <div class="term mat-warn-fg">Advanced reporting</div>
                    <div class="term mat-warn-fg">Customizable interface</div>
                    <div class="term mat-warn-fg">CRM Integration</div>
                </div>

                <button mat-raised-button class="cta-button" color="accent">GET STARTED FREE</button>

                <div class="note">30 day free trial to start</div>

            </div>

            <div class="price-table style-3 mat-elevation-z4" fxLayout="column">

                <div class="package-type">
                    <div class="title">Enterprise</div>
                    <div class="subtitle">For big teams</div>
                </div>

                <div class="price primary-300" fxLayout="row" fxLayoutAlign="center start">
                    <div class="currency">$</div>
                    <div fxLayout="row" fxLayoutAlign="center end">
                        <div class="value">99</div>
                        <div class="period">monthly per user</div>
                    </div>
                </div>

                <div class="terms" fxLayout="column" fxLayoutAlign="start center">
                    <div class="term">Unlimited projects</div>
                    <div class="term">Unlimited pages</div>
                    <div class="term">Unlimited disk space</div>
                    <div class="term">For full feature list, call us</div>
                </div>

                <button mat-raised-button class="cta-button" color="accent">CALL US</button>

                <div class="note">90 day free trial to start</div>

            </div>

        </div>
        <!-- / PRICE TABLES -->

        <div class="faq" fxLayout="row wrap">

            <div class="title" fxFlex="100">Frequently Asked Questions</div>

            <div class="item" fxFlex="100" fxFlex.gt-xs="50">
                <div class="question">How does free trial work?</div>
                <div class="answer">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur a diam nec augue tincidunt
                    accumsan. In dignissim laoreet ipsum eu interdum.
                </div>
            </div>

            <div class="item" fxFlex="100" fxFlex.gt-xs="50">
                <div class="question">Can I cancel any time?</div>
                <div class="answer">
                    Aliquam erat volutpat. Etiam luctus massa ex, at tempus tellus blandit quis. Sed quis neque tellus.
                    Donec maximus ipsum in malesuada hendrerit.
                </div>
            </div>

            <div class="item" fxFlex="100" fxFlex.gt-xs="50">
                <div class="question">What happens after my trial ended?</div>
                <div class="answer">
                    Aliquam erat volutpat. Etiam luctus massa ex, at tempus tellus blandit quis. Sed quis neque tellus.
                    Donec maximus ipsum in malesuada hendrerit.
                </div>
            </div>

            <div class="item" fxFlex="100" fxFlex.gt-xs="50">
                <div class="question">Can I have a discount?</div>
                <div class="answer">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur a diam nec augue tincidunt
                    accumsan. In dignissim laoreet ipsum eu interdum.
                </div>
            </div>

        </div>

    </div>

</div>

