import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

import { fuseAnimations } from '@fuse/animations';
import { CategoryService } from '../category.service';
import { Category } from 'app/model/Category';
import { ActivatedRoute, Router } from '@angular/router';
import { locale as english } from '../../../main/home/i18n/en';
import { locale as turkish } from '../../../main/home/i18n/tr';
import { locale as arabic } from '../../../main/home/i18n/ar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { NameLanguageEditer } from 'app/component/name-language-editer/name-language-editer.component';

@Component({
    selector: 'app-category-entry',
    templateUrl: './category-entry.component.html',
    styleUrls: ['./category-entry.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class CategoryEntryComponent implements OnInit {
    allowedFiles: string = 'image/*';
    category: Category;
    pageType: string;
    categoryForm: FormGroup;
    @ViewChild("nameLanguageEditor") nameLanguageEditor :NameLanguageEditer;
    invalidImage: boolean;
    // Private
    file :any
    categoryId:string
    path:any;
    private _unsubscribeAll: Subject<any>;
    isLoading: boolean = false;

    /**
     * Constructor
     *
     * @param {CategoryService} _categoryService
     * @param {FormBuilder} _formBuilder
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        private categoryService: CategoryService,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private route: ActivatedRoute,
        private router: Router,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translate:TranslateService
    ) {
        // Set the default
        this.category = new Category();

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);        // Set the private defaults

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

this.path="../../../../assets/images/backgrounds/default-upload-image.jpg";

        this.route.paramMap.subscribe(params => {
            if (params.get('id')) {
                this.category.id = params.get('id')
                this.pageType = 'edit';
                this.categoryService.getCategoryById(this.category.id,true).then((res: Category) => {
                    this.category = res;
                    this.categoryForm = this.createCategoryForm();

                });
            }
            else {
                this.pageType = 'new';
            }
        });
        this.categoryForm = this.createCategoryForm();
    }

   
    uploadIcon(e, categoryId) {
        if (this.validateFiles(e.target.files)) {
            this.invalidImage = false;
            for (var i = 0; i < e.target.files.length; i++) {
             
                   this.file = e.target.files[i];
                this.categoryId=categoryId;
            }
        } else {
            this.invalidImage = true;

        }

    }
      validateFiles(files: File[]): boolean {
        for (var i = 0; i < files.length; i++) {
            if (!this.isFileTypeValid(files[i])) {
                return false;
            } 
            return true;
        }

    }

    private isFileTypeValid(file: File): boolean {
        let acceptableTypes = this.allowedFiles.split(',').map(type => type.trim());
        for (let type of acceptableTypes) {
            let acceptable = this.isWildcard(type) ? this.getTypeClass(file.type) === this.getTypeClass(type)
                : file.type == type || this.getFileExtension(file).toLowerCase() === type.toLowerCase();

            if (acceptable) {
                return true;
            }
        }

        return false;
    }
    isWildcard(fileType: string): boolean {
        return fileType.indexOf('*') !== -1;
    }
    getTypeClass(fileType: string): string {
        return fileType.substring(0, fileType.indexOf('/'));
    }
    getFileExtension(file: File): string {
        return '.' + file.name.split('.').pop();
    }
   
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create category form
     *
     * @returns {FormGroup}
     */
    createCategoryForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.category.id],
            name: [this.category.name],
         
        });
    }

    /**
     * Save category
     */
    saveCategory(): void {
        this.isLoading = true;
        this.category.name = JSON.stringify(this.nameLanguageEditor.deserializedData);
        this.categoryForm.controls["name"].setValue(this.category.name);
        const data = this.categoryForm.getRawValue();
        if(this.file ==undefined ||this.file==null ){
        data.icon=this.category.icon;
        }
        this.categoryService.saveCategory(data)
            .then((res) => {

                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.categorySaved'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
                if(this.file !=undefined ||this.file!=null ){
                    this.categoryService.uploadIcon​(this.file, this.categoryId)
                    }
                  
                this.isLoading = false;
                   
            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });

          
    }

    isValidForm(){
        return this.nameLanguageEditor?.manageEntityForm?.valid
     }

    /**
     * Add category
     */
    addCategory(): void {
        this.isLoading = true;
        this.category.name = JSON.stringify(this.nameLanguageEditor.deserializedData);
        this.categoryForm.controls["name"].setValue(this.category.name);
        const data = this.categoryForm.getRawValue();
        //   data.handle = FuseUtils.handleize(data.name);
        data.id = undefined;

        this.categoryService.addCategory(data)
            .then((res) => {

                // Trigger the subscription with new data
                this.categoryService.onCategoryChanged.next(data);
                this.isLoading = false;
                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.categoryAdded'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });

                this.router.navigate(['/category'])
                this.categoryService.uploadIcon​(this.file, res.id )
                // Change the location with new one
                this.categoryService.getCategoryById(res.id).then((res: Category) => {
                    this.category = res;
                    this.categoryForm = this.createCategoryForm();

                });
            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }
}
