import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';



import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { FilesDataSource } from 'app/modules/dashboards/project/project.component';
import { AccountService } from '../account.service';
import { ResetPasswored } from 'app/model/ResetPasswored';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from 'app/modules/role/role.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../../main/home/i18n/en';
import { locale as turkish } from '../../../main/home/i18n/tr';
import { locale as arabic } from '../../../main/home/i18n/ar';

@Component({
    selector: 'reset-password-2',
    templateUrl: './reset-password-2.component.html',
    styleUrls: ['./reset-password-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ResetPassword2Component implements OnInit, OnDestroy {
    resetFormForm: FormGroup;
    token: string;
    email: string;
    fromLocation: boolean = false;
    tenantId: string;
    dataSource: FilesDataSource | null;
    userId: string;
    resetPasswored: ResetPasswored;
    // Private
    private _unsubscribeAll: Subject<any>;
    /**
       * Constructor
       *
       * @param {UserService} _userService
       * @param {FormBuilder} _formBuilder
       * @param {Location} _location
       * @param {MatSnackBar} _matSnackBar
       */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private _matSnackBar: MatSnackBar,
        private translate: TranslateService,
        private accountService: AccountService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private router: Router
    ) {

        this.token = window.location.href.split("token=")[1];
        this.route.queryParams.subscribe(params => {
            // if (params.token) {
            //     this.token = params.token;
            // }
            if (params.email) {
                this.email = params.email;

            }
        });

        this.resetPasswored = new ResetPasswored();
        this.resetFormForm = this.createUserForm();

        // Set the default

        this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);
        this._unsubscribeAll = new Subject();


        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }



    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

    }
    /**
        * Create user form
        *
        * @returns {FormGroup}
        */
    createUserForm(): FormGroup {
        return this._formBuilder.group({

            password: [this.resetPasswored.password, [Validators.required, Validators.minLength(8)]],
            passwordConfirm: [this.resetPasswored.confirmPassword, [Validators.required]]
        },
            {
                validators: [
                    this.passwordPattern('password'),
                    this.MustMatch('password', 'passwordConfirm')
                ]
            }
        );
    }

    // password must match confirm password
    MustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];
            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                return;
            }
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            } else {
                matchingControl.setErrors(null);
            }
        }
    }
    // password pattern 
    // at least one lower letter
    // at least one capital letter
    // at least one number
    // at least one special character
    passwordPattern(controlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            if (control.errors && !control.errors.mustMatch) {
                return;
            }
            if (!/\d/.test(control.value))
                control.setErrors({ number: true });
            else if (!/[a-z]/.test(control.value))
                control.setErrors({ lower: true });
            else if (!/[A-Z]/.test(control.value))
                control.setErrors({ upper: true });
            else if (!/[!@#$%^&*?_-]/.test(control.value))
                control.setErrors({ special: true });
        }
    }

    resetpassword() {
        this.route.queryParams.subscribe(params => {
            const data = this.resetFormForm.getRawValue();
            this.resetPasswored = new ResetPasswored();
            this.resetPasswored.password = data.password;
            this.resetPasswored.confirmPassword = data.passwordConfirm;
            this.resetPasswored.token = this.token;
            this.resetPasswored.email = this.email;
            this.accountService.resetpassword(this.resetPasswored).then(res => {
                this._matSnackBar.open(res.message, this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });

            }, (error) => {
                this._matSnackBar.open(error.error.message, this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });

            })




        });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

