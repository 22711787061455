import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { PublicHoliday } from 'app/model/public-holiday';
import { apiUrls } from 'app/shared/constants/apiUrls';
import { StorageManager } from 'app/shared/utilities/storage-manager';
import { environment } from 'environments/environment';
import { Http2ServerRequest } from 'http2';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicHolidayService implements Resolve<any>{
 
    publicHolidays : PublicHoliday[] = new Array<PublicHoliday>();
    publicHoliday : PublicHoliday  =   new PublicHoliday() ;
  onPublicHolidayChanged: BehaviorSubject<any>;
  currentPage: number = 1;
  pageSize : number = 10;

   /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
      private _httpClient: HttpClient
  )
  {
      // Set the defaults
      this.onPublicHolidayChanged = new BehaviorSubject({});
  }

   /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getPublicHolidaysWithoutPagenation()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

  
  getAllPublicHolidays() :Observable<any>{
   return this._httpClient.get(environment.serviceUrl + apiUrls.publicHoliday )
  }

  getPublicHolidaysWithoutPagenation(): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.publicHoliday)
              .subscribe((response: any) => {
                  this.publicHolidays = response;
                  this.onPublicHolidayChanged.next(this.publicHolidays);
                  resolve(response);
              }, reject);
      });
  }
  
  getPublicHolidays(pageIndex ,pageSize): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.publicHoliday+ '?pageNumber='+ pageIndex + '&pageSize=' + pageSize  )
              .subscribe((response: any) => {
                  this.publicHolidays = response;
                  this.onPublicHolidayChanged.next(this.publicHolidays);
                  resolve(response);
              }, reject);
      });
  }

  getPublicHolidaysByFillter(pageIndex ,pageSize , search): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.publicHoliday + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize + '&search=' + search)
              .subscribe((response: any) => {
                  this.publicHolidays = response;
                  this.onPublicHolidayChanged.next(this.publicHolidays);
                  resolve(response);
              }, reject);
      });
  }

  getPublicHolidayById(id): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl + apiUrls.publicHoliday + '/' + id )
              .subscribe((response: any) => {
                  this.publicHoliday = response;
                  this.onPublicHolidayChanged.next(this.publicHoliday);
                  resolve(response);
              }, reject);
      });
  }

  
 // Delete publicHoliday
 deletePublicHoliday(publicHoliday): Promise<any>
 {
    const options = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: publicHoliday
      };
     return new Promise((resolve, reject) => {
         this._httpClient.delete(environment.serviceUrl + apiUrls.publicHoliday , options)
             .subscribe((response: any) => {
                 resolve(response);
             }, reject);
     });
 }

  savePublicHoliday(publicHoliday): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put(environment.serviceUrl + apiUrls.publicHoliday, publicHoliday)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  addPublicHoliday(publicHoliday): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl + apiUrls.publicHoliday, publicHoliday)
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }

}
