import { Time } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { LocationService } from 'app/modules/branches-management/location/location.service';
import { ServiceService } from 'app/modules/branches-management/service/service.service';
import { locale as english } from '../../../main/home/i18n/en';
import { locale as turkish } from '../../../main/home/i18n/tr';
import { locale as arabic } from  '../../../main/home/i18n/ar';
import { TranslateModule } from '@ngx-translate/core';
import { values } from 'lodash';
import { log } from 'console';
import { SoltTimeEditor } from 'app/component/slot-time-editor/slot-time-editor.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
@Component({
  selector: 'app-service-details-popup',
  templateUrl: './service-details-popup.component.html',
  styleUrls: ['./service-details-popup.component.scss'],
  
})
export class ServiceDetailsPopupComponent implements  AfterViewInit {
  @ViewChild("soltTimeEditor") soltTimeEditor :SoltTimeEditor;

  
  form: FormGroup;
  locationServices: any;
  locatoinServiceGroup : any;
  serviceProfile : any;
  locationServiceProfile:number;
  locationName: string;
  tenantName: string;
  serviceName: string;
  url:string;
  inAppURL:boolean;
  inTheSameDay :boolean;
  multislot:boolean;
  today = new Date();
  tomorrow = new Date();
  currentLanguage: any;
  listTime:any;
  hideRowsSlot=true
  hideRowsTime=false
  ngOnInit(): void {
    this.multislot = true;
    this.form = this._formBuilder.group({
      id: [{ value: this.data.id, disabled: true }],
      tenantId: [{ value: this.data.tenantId, disabled: true }],
      locationId: [{ value: this.data.locationId, disabled: true }],
      serviceId: [{ value: this.data.serviceId, disabled: true }],
      priority: [],
      startTime: [{  value: this.data.startTime }] ,
      endTime: [{  value: this.data.endDate }],
      startDate: [{ value: new Date() }],
      endDate: [{ value: new Date() }],
      isDisabled : [{ value: this.data.isDisabled }],
      disableMessage : [{ value: this.data.disableMessage }],
      slotTime : [{ value: this.data.slotTime }],
      toleranceTiem : [{ value: this.data.toleranceTiem }],
      parentService :[{ value: this.data.parentService }],
      groupId :[{ value: this.data.groupId }],
      serviceProfile : [{ value: this.data.serviceProfile }],
      numberOfCounters : [{ value: this.data.numberOfCounters }],
      TimeData :[{value:this.data.TimeData }],
      inAppURL:[{value:this.data.inAppURL}],
      url:[ {value:this.data.url}],
     inTheSameDay: [{ value: this.inTheSameDay  }], 
     locationServiceProfile:[{value: this.locationServiceProfile}]
    });
    
    this.tenantName = this.data.Tenant;
    this.locationName = this.data.Location;
    this.serviceName = this.data.Service;

    this._ServiceService.getServicsByLocation(this.data.locationId).then(res => {
      if (res) {
        this.locationServices = res;
      }   
      
    });

    this._locationService.getGroupServiceByLocationIdPagination(this.data.locationId).then(res => {
      if (res) {
        this.locatoinServiceGroup = res;
      }
    });

    this.currentLanguage = localStorage.getItem('selectedLanguage');

    console.log(this.data);
    
  }
  ngAfterViewInit(){
    if(this.data.id){
      this._locationService.getsoltTime(this.data.id,14).then(res => {
        if (res.length >0) {
          this._locationService.Id=this.data.id
            this.hideRowsSlot=true
            this.hideRowsTime=false
   
        this.soltTimeEditor.addlstRow(res,true)
//this.soltTimeEditor.deserializedDeleteData=this.soltTimeEditor.deserializedData;
          }
          else{
         
            const timeIntervals: { startTime: Time; endTime: Time; dayOfWeek: string  }[] = [];
            for (let i = 0; i <= 6; i++) {
              timeIntervals.push({
                startTime:  this.data.startTime,
                endTime: this.data.endTime,
                dayOfWeek:  i.toString(),
              });
}
this.soltTimeEditor.addlstRow(timeIntervals,false)

            // this.hideRowsSlot=false
            // this.hideRowsTime=true

            // if(this.form.controls["startTime"].value.toDateString() ==  this.form.controls["endTime"].value.toDateString()){
            //   this.inTheSameDay = true;
          
            // }
          }
        
      });  
      
      
    }
    else{
      this.form.controls["startDate"].setValue(this.today);
      this.form.controls["endDate"].setValue(this.today);
    }
    
  }
  changeInTheSameDay(e){
  
    if (e.checked){
      this.form.controls["startDate"].setValue(this.today);
      this.form.controls["endDate"].setValue(this.tomorrow);
    }
    else{
      this.form.controls["startDate"].setValue(this.today);
      this.form.controls["endDate"].setValue(this.today);
    }
  }


  date: Date;
  timepicker: any;
  basicDatepicker: any;

  constructor(
    private _formBuilder: FormBuilder,
    private _matSnackBar: MatSnackBar,
    private _locationService: LocationService,
    private _ServiceService: ServiceService,
    public dialogRef: MatDialogRef<ServiceDetailsPopupComponent>,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    public translate :TranslateService,
    
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { 
      this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);
      this.tomorrow.setDate(this.today.getDate() + 1)
      
    }

  cancelClick(): void {
    this.dialogRef.close("canceled");
  }


  save() {
    // if(this.soltTimeEditor.manageEntityForm.valid){
    //   return;
    // }
    
    var data = this.form.getRawValue();
    if (this.hideRowsTime==false) {

      data.startTime="01:51"
      data.endTime="02:51"
      this.form.controls["startDate"].setValue(this.today);
      this.form.controls["endDate"].setValue(this.today);
  
      for (var i in  this.soltTimeEditor.deserializedData) {
        var StartTime = new Date(data.startDate?.value ||data.startDate|| new Date() )
        if(!this.soltTimeEditor.deserializedData[i].startTime){
          this.soltTimeEditor.deserializedData[i].startTime="00:00:00"
        }
        StartTime.setUTCHours (this.soltTimeEditor.deserializedData[i].startTime.split(":")[0]);
        StartTime.setUTCMinutes(this.soltTimeEditor.deserializedData[i].startTime.split(":")[1]);
        this.soltTimeEditor.deserializedData[i].startTime= StartTime
  
  
        var EndTime = new Date(data.endDate?.value ||data.endDate|| new Date() )
        if(!this.soltTimeEditor.deserializedData[i].endTime){
          this.soltTimeEditor.deserializedData[i].endTime="00:00:00"
        }
       
        EndTime.setUTCHours (this.soltTimeEditor.deserializedData[i].endTime.split(":")[0]);
        EndTime.setUTCMinutes(this.soltTimeEditor.deserializedData[i].endTime.split(":")[1]);
        this.soltTimeEditor.deserializedData[i].endTime= EndTime
    }}
    
    if (data.startTime) {
      var startTime = new Date(data.startDate?.value || data.startDate || new Date() )
      startTime.setUTCHours(data.startTime.split(":")[0]);
      startTime.setUTCMinutes(data.startTime.split(":")[1]);
      data.startTime = startTime;
    }
    if (data.endTime) {
      var endTime = new Date(data.endDate?.value || data.endDate || new Date());
      endTime.setUTCHours(data.endTime.split(":")[0]);
      endTime.setUTCMinutes(data.endTime.split(":")[1]); 
      data.endTime = endTime;
    }

 // Check if the url field is empty or not needed and remove it if so
 if (!data.url || data.url.trim() === '') {
  delete data.url;  // Remove the url key from data object if it's empty or not provided
}

// Check if inAppURL is not needed and remove it if so
if (!data.inAppURL) {
  delete data.inAppURL;  // Remove the inAppURL key if it's not true or needed
}

// Check if locationServiceProfile is not needed and remove it if so
if (!data.locationServiceProfile) {
  delete data.locationServiceProfile;  // Remove the locationServiceProfile key if it's not set
}
    if (!data.parentService) {
      delete data.parentService;
    }
    if (!data.startTime) {
      delete data.startTime;
    }
    if (!data.endTime) {
      delete data.endTime;
    }
    if (!data.priority) {
      data.priority = 0;
    }
    if (!data.groupId) {
      delete data.groupId;
    }

    delete data.startDate;
    delete data.endDate;

    if (typeof( data.disableMessage) == 'object') {
      data.disableMessage = "";
    }

    if(data.id){
      this._locationService.saveLocationService(data)
      .then((res) => {
        // Object.location = this.lo
        // slotsService.save(object)
        // Show the success message
        this._matSnackBar.open(this.translate.instant('SERVICE_DETAILS_POPUP.serviceLocationAdded'), this.translate.instant('ok'), {
          verticalPosition: 'top',
          duration: 2000
        });
        this.dialogRef.close("canceled");

        this.soltTimeEditor.deserializedData.forEach((element, index) => {
       //   if(element.id === item.id) {
           //   items[index] = item;
        //  }
      });
      var deserializedData = [];
      var deserializedDataUpdate = [];
         this.soltTimeEditor.deserializedData.forEach(object => {
          if(!object?.id){
          deserializedData.push({entityId:data.id,entityType:14,eventType:1,dayOfWeek:Number(object.dayOfWeekId) , endTime :object.endTime ,startTime:object.startTime })
        }
        else{
          deserializedDataUpdate.push({id:object.id,entityId:data.id,entityType:14,eventType:1,dayOfWeek:Number(object.dayOfWeekId) , endTime :object.endTime ,startTime:object.startTime })
          deserializedDataUpdate.forEach(object => {
            this._locationService.Updateslot(object)
            .then((res) => {
             // this.soltTimeEditor.deserializedDeleteData=[];
        
             });
          });
        }
        });
    
        this.soltTimeEditor.deserializedDeleteData.forEach(object => {
          this._locationService.deletedailyEvent(object)
          .then((res) => {
            this.soltTimeEditor.deserializedDeleteData=[];
      
           });
        });
       if(deserializedData.length>0)
       {    
         this._locationService.savesoltTime(deserializedData)
        .then((res) => {
        });}
   
      });     
      
    }
    else{
      this._locationService.addLocationService(data)
      .then((res) => {
        // Show the success message
        this._matSnackBar.open(this.translate.instant('SERVICE_DETAILS_POPUP.serviceLocationAdded'), this.translate.instant('ok'), {
          verticalPosition: 'top',
          duration: 2000
        });
        this.dialogRef.close("canceled");

        this.soltTimeEditor.deserializedData.forEach((element, index) => {
          //   if(element.id === item.id) {
              //   items[index] = item;
           //  }
         });
         var deserializedData = [];
        this.soltTimeEditor.deserializedData.forEach(object => {
          deserializedData.push({entityId:data.id,entityType:14,eventType:1,dayOfWeek:object.dayOfWeekId , endTime :object.endTime ,startTime:object.startTime })
        });
        this._locationService.savesoltTime(deserializedData)
        .then((res) => {
        });
      });

    }
   
  }

//   showOptions(event:MatCheckboxChange): void {

//     if(event.checked){
//       this.hideRowsSlot=true
//       this.hideRowsTime=false
//       if(this.data.id){
//         this._locationService.getsoltTime(this.data.serviceId,14).then(res => {
//           if (res.length >0) {
        
//               this.hideRowsSlot=true
//               this.hideRowsTime=false
     
//         //  this.soltTimeEditor.addlstRow(res)
  
//             }
//             else{
//               this.hideRowsSlot=false
//               this.hideRowsTime=true
  
//               if(this.form.controls["startTime"].value.value.toDateString() ==  this.form.controls["endTime"].value.value.toDateString()){
//                 this.inTheSameDay = true;
            
//               }
//             }
          
//         });  
        
        
//       }
//     }
//     else{
//       this.hideRowsSlot=false
//       this.hideRowsTime=true
//     }

// }
}

export interface DialogData {
  tenantId: string;
  Tenant: string;
  locationId: string;
  Location: string;
  serviceId: string;
  Service: string;
  priority: number;
  startTime: Time;
  endTime: Time;
  startDate: string;
  endDate: string;
  id : string;
  disableMessage : string;
  isDisabled : boolean;
  locationServiceProfile:number;
  slotTime : number;
  toleranceTiem : number;
  parentService :string;
  groupId : string;
  serviceProfile : number;
  numberOfCounters : number;
  TimeData:string; 
  url:string;
  inAppURL:boolean;
}
