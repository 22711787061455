export const locale = {
    lang: 'ar',
    data: {
        'search': 'search',
        'itemsPerPage': 'لكل صفحة',
        'nextPageLabel': 'التالي',
        'previousPageLabel': 'السابق',
        'customFunction': 'تخصيص المميزات',
        'customize': 'تخصيص',
        'keyboard_arrow_left': 'keyboard_arrow_left',
        'arrow_back': 'arrow_forward',
        'of': 'من أصل',
        'ok': 'موافق',
        'HOME': {
            'HELLO': 'مرحبا '
        },
        'THEME_OPTION': {
            'themeOptions': 'خيارات المظهر ',
            'colorThemes': 'لون المظهر',
            'defaultLight': 'الضوء الافتراضي',
            'yellowLight': 'الضوء الاصفر',
            'blueGrayDark': 'الازرق-الرمادي الداكن',
            'pinkDark': 'زهري داكنزهري داكن',
            'layoutStyles': 'تنسيق الخلفية',
            'verticalLayout1': 'خلفية عامودية #1',
            'verticalLayout2': 'خلفية عامودية #2',
            'verticalLayout3': 'خافية عامودية #3',
            'horizontalLayout1': 'خلفية افقية #1',
            'layoutWidth': 'عرض الخلفية',
            'fullWidth': 'العرض كامل',
            'boxed': 'صندوقي',
            'navbar': 'اللوحة',
            'hide': 'إخفاء',
            'folded': 'مطوي',
            'position': 'الموقع :',
            'left': 'يسار',
            'right': 'يمين',
            'variant': 'متغير :',
            'style1': 'تنسيق 1',
            'style2': 'تنسيق 2',
            'primaryBackground': 'الخلفية الأولية',
            'secondaryBackground': 'الخلفية الثانوية',
            'toolbar': 'شريط الأدوات',
            'above': 'فوق',
            'belowStatic': 'ثابت من الأسفل',
            'belowFixed': 'ثابت من الاسفل',
            'useCustomBackgroundColor': 'تخصيص لون الخلفية',
            'backgroundColor': 'لون الخلفية :',
            'footer': 'ذيل الصفحة',
            'color': 'اللون :',
            'sidePanel': 'اللوحة الجانبية',
            'aboveStatic': 'ثابت من فوق',
            'aboveFixed': 'ثابت من فوق',
            'below': 'ادناة',
            'backGroundColor': 'لون الخلفية :',
            'top': 'فوق',
            'variantVertical': 'متغير (عمودي ) :',
            'secondaryBackgroundVertical': 'خلفية ثانوية (عمودية)',
            'enableCustomScrollbars': 'تفعيل شريط التمرير',
            'customScrollbars': 'تخصيص التمرير'
        },
        'TOOLBAR': {
            'myProfile': 'الملف الشخصي',
            'inbox': 'البريد',
            'logout': 'تسجيل الخروج',
            'mr16':'ml-16',
            'mr8':'ml-8'
        }, 'SHORTCUT': {
            'addRemoveShortcut': 'إضغط لإضافة او حذف الاختصارات',
            'calender': 'التقويم',
            'mail': 'البريد',
            'contacts': 'جهات الاتصال',
            'todo': 'لنفعلها',
            'searchforAppOrPage': 'البحث عن تطبيق او صفحة'
        },
        'REPORT':{
            'StartDate':'تاريخ البدء',
            'EndDate':'تاريخ النهاية',
            'Location':'الفرع',
            'Language':'اللغة',
            'ReportType':'نوع التقرير',
            'SummaryReport':'تقرير ملخص',
            'CustomerReport':'تقرير عملاء',
            'UsersReport':'تقرير الموظفين',
            'ServicesReport':'تقرير الخدمات',
            'GenerateReport':'تصدير التقرير',
            'English':'English',
            'Arabic':'العربية'
        },
        'SERVICE_DETAILS_POPUP': {
            'tenantName': 'اسم الشركة',
            'serviceDetails': 'تفاصيل الخدمة',
            'locationName': 'الموقع',
            'serviceName': 'اسم الخدمة',
            'parentService': 'الحدمة الاب',
            'priority': 'الاولوية',
            'priorityRequired': 'الرجاء ادخال الاولوية',
            'startTime': 'تبدأ بــ',
            'endTime': 'تنتهي بــ',
            'startDate': 'تبدأ بتاريخ',
            'endDate': 'تنتهي بتاريخ',
            'cancel':'إلغاء',
            'ok':'موافق',
            'serviceLocationAdded':'الخدمة اضيفت للموقع بنجاح',
            'inTheSameDay' : "في يوم اخر ؟",
            "isDisabled" : "خدمة مغلقة ؟",
            "disableMessage" : "رسالة ايقاف الخدمة",
            "toleranceTiem" : "وقت السماحية",
            "slotTime" : "وقت خدمة الكبينة",
            "serviceGroup" : "مجموعة الخدمات",
            "serviceProfile":"ملف تعريف الخدمة",
            "both" : "كلاهما",
            "appointmentOnly" : "التعيين فقط",
            "walkInOnly" : "القادمن للفرع",
            'numberOfCounters' : 'عدد المكاتب',
            "ismultiTime":"متعدد الوقت",
            "multislot":"Multi slot",
            'inAppUrl': 'فتح الرابط في التطبيق',
            'textUrl':'الرابط',
            'appointment':'مواعيد',
            'instantTicket':'تذكرة فورية',
            'url':'رابط خارجي'
          
        },
        'QUICKPANEL': {
            'today': 'اليوم',
            'events': 'الأحداث',
            'notes': 'الملاحظات',
            'quicksettings': 'الاإعدادات السريعة',
            'notifications': 'الإشعارات',
            'cloudSync': 'مزامنة السحابة',
            'retroThrusters': 'ريترو الدفاعات'
        },
        'LOGIN': {
            'welcomTOSaasQueue': 'مرحبا بكم في وقتك',
            'loginToAccount': 'الدخول للحساب',
            'welcomTOSaasuserNameQueue': 'اسم المستخدم',
            'userName': 'إسم المستخدم',
            'userNameRequired': 'الرجاء ادخال اسم المستخدم',
            'password': 'الرمز السري',
            'passwordRequired': 'الرجاء ادخال الرمز السري',
            'rememberMe': 'تذكرني',
            'forgetPassword': 'هل نسيت الرمز السري ؟',
            'login': 'الدخول',
            'userNamePasswordInvalid': 'اسم المستخدم او الرمز السري غير صحيح',
            'or': 'أو',
            'loginWithGoogle': 'الدخول من Google',
            'loginWithFacebook': 'الدخول من Facebook',
            'dontHaveAccount': 'لا تمتلك حساب ؟',
            'createAccount': 'إنشاء حساب جديد'
        },
        'TENANT_LIST': {
            'Tenants': 'الشركة',
            'searchForTenant': 'البحث عن الشركة',
            'search': 'بحث',
            'addNewTenant': 'إضافة شركة',
            'logo': 'الشعار',
            'add': 'إضافه',
            'name': 'الإسم',
            'email': 'البريد الإلكتروني',
            'actions': 'الإجراءات',
            'locations': 'المواقع',
            'services': 'الخدمات',
            'editTenant': 'تعديل الشركة',
            'deleteTenant': 'حذف الشركة',
            'tenantUser': 'مستخدمين الشركة'
        }, 'TENANT_ENTRY': {
            'newTenant': 'شركة جديدة',
            'tenantDetails': 'تفاصيل الشركة',
            'add': 'إضافة',
            'save': 'حفظ',
            'tenantName': 'إسم الشركة',
            'tenantEmail': 'البريد الإلكتروني',
            'enterValidEmail': 'الرجاء التأكد من البريد الإلكتروني',
            'category': 'الفئة',
            'categoryRequired': 'الفئة متطلب إجباري'
        },
        'LOCATION_ENTRY': {
            'newLocation': 'موقع جديد',
            'locationDetail': 'تفاصيل الموقع',
            'add': 'إضافة',
            'save': 'حفظ',
            'locationName': 'إسم الموقع',
            'locationAddress': 'الموقع',
            'locationTenant': 'موثع الشركة' ,
            "Latitude" : "خط الطول ",
            "Longitude" : "خط العرض " 
        },
        'LOCATION_LIST': {
            'locations': 'ألمواقع',
            'searchForLocation': 'البحث عن موقع',
            'addNewLocation': 'إضافة موقع جديد',
            'id': 'الرمز',
            'name': 'الإسم',
            'address': 'العنوان',
            'tenant': 'الشركة',
            'actions': 'الإجراءات',
            'services': 'الخدمات',
            'editLocation': 'تعديل الموقع',
            'deleteLocations': 'حذف الموقع',
            'userLocations': 'مسخدمين الموقع',
            'search': 'search',
            'groupService': 'Group Service',
            'importUsers' : 'Import Users'
        },
        'USER_ENTRY': {
            'newUser': 'إضافة مستخدم',
            'userDetail': 'تفاصيل المستخدم',
            'add': 'إضافة',
            'save': 'حفظ',
            'userName': 'اسم المستخدم',
            'arName':'الأسم بالعربي ',
            'emailAddress': 'البريد الإلكتروني',
            'phoneNumber': 'رقم الهاتف',
            'password': 'الرمز السري',
            'confirmPassword': 'تأكيد الرمز السري',
            'role': 'الدور',
            'roleRequired': 'الدور متطلب إجباري',
            'passwordPattern': 'ألرمز السري يجب ان يحتوي على حروف كبيره , حروف صغيره , ارقام و رموز خاصة'
        },
        'USER_LIST': {
            'users': 'المستخدمين',
            'searchForUser': 'البحث عن مستخدم',
            'addNewUser': 'إضافة مستخدم جديد',
            'userName': 'اسم المستخدم',
            'emailAddress': 'البريد الإلكتروني',
            'phoneNumber': 'رقم الهاتف',
            'actions': 'الإجراءات',
            'editUser': 'تعديل المستخدم',
            'deleteUser': 'حذف المستخدم',
            'services': 'الخدمات',
            'resetPassword' : "إعادة تعيين كلمة المرور",
            'ImportUsers': 'تحميل المستخدمين',
            'locations': 'المواقع',
           
        },
        'SERVICE_ENTRY': {
            'newService': 'خدمة جديدة',
            'serviceDetail': 'تفاصيل الخدمة',
            'add': 'إضافة',
            'save': 'حفظ',
            'serviceName': 'اسم الخدمة',
            'serviceDescription': 'وصف الخدمة',
            'serviceTenant': 'الشركة'
        },
        'SERVICE_LIST': {
            'services': 'الحدمات',
            'searchForService': 'البحث عن خدمة',
            'addNewService': 'إضافة خدمة جديدة',
            'id': 'الرمز',
            'name': 'الإسم',
            'description': 'الوصف',
            'tenant': 'الشركة',
            'actions': 'الإجراءات',
            'editService': 'تعديل الخدمة',
            'deleteService': 'حذف الخدمة',
            'addRequisite':'اضف شرط'
        },
        'ASSIGN_SERVICE_TO_LOCATION': {
            'addServiceToLocation': 'اضافة خدمة للموقع',
            'tenantServicesAvailable': 'الخدمات المتاحة من الشركة',
            'locationServices': 'خدمات الموقع',
            'locationServiceGroup' : 'مجموعة خدمات الموقع'
        },
        'ASSIGN_SERVICE_TO_USER': {
            'addServiceToUser': 'أضافة خدمات للمستخدمين',
            'locationServicesAvailable': 'الخدمات المتاحة للمستخدم',
            'userServices': 'خدمات المستخدم'
        },
        'CATEGORY_LIST': {
            'categories': 'الفئات',
            'searchForCategory': 'البحث عن فئة',
            'addNewCategory': 'إضافة فئة جديدة',
            'id': 'الرمز',
            'name': 'الإسم',
            'actions': 'الإجراءات',
            'locations': 'المواقع',
            'services': 'الخدمات',
            'editCategory': 'تعديل الفئة',
            'deleteCategory': 'حذف الفئة'
        },
        'CATEGORY_ENTRY': {
            'categoryName': 'إسم الفئة',
            'save': 'حفظ',
            'add': 'إضافة',
            'categoryDetail': 'تفاصيل الفئة',
            'newCategory': 'فئة جديدة'
        },
        'CONFIRM_MSG': {
            'assignServiceToUser': 'هل انت متاكد من منح هذة الخدمة للمستخدم ؟',
            'wantToDelete': 'هل انت متاكد من عملية الحذف ؟',
            'deleteLocationService': 'هل انت متاكد من حذف هذه الخدمة من الموقع ؟',
            'deleteUserService': 'هل انت متأكد من حذف الخدمة من المستخدم ؟',
            'ok': 'موافق',
            'categoryDeleted': 'تم إضافة الفئة بنجاح',
            'tenantDeleted': 'تم حذف الشركة بنجاح',
            'serviceDeleted': 'تم حذف الخدمة بنجاح',
            'userServiceDeleted': 'تم حذف خدمة المستخدم بنجاح',
            'serviceAssignedSuccessfully': 'تم إضافة الخدمة بنجاح',
            'locationServiceDeleted': 'تم حذف الخدمة من الموقع بنجاح',
            'locationDeleted': 'تم حذف الموقع بنجاح',
            'locationSaved':'تمت العملية بنجاح',
            'locationAdded':'تمت العملية بنجاح',
            'error':'خطأ: ',
            'wentWrong':'حدث خطأ بالنظام',
            'serviceAdded':'تم إضافة الخدمة بنجاح',
            'tenantAdded':'تم إضافة الشركة بنجاح',
            'userSaved':'تمت العملية بنجاح',
            'userAdded':'تمت العملية بنجاح',
            'categorySaved':'تم إضافة الفئة بنجاح',
            'categoryAdded':'تم تعديل الفئة بنجاح',
            'deleteRolePermission': 'هل انت متأكد من حذف هذه الصلاحية لهذا الدور ؟',
            'permissionDeleted': 'تم حذف الصلاحية بنجاح',
            'deleteRolePublicHoliday': 'هل انت متأكد من حذف هذه الاجازة ؟',
            'PublicHolidayDeleted': 'تم حذف الاجازة بنجاح',
            'PublicHolidaySaved': 'تم اضافة الاجازة بنجاح',
            "Copied" :"تم النسخ",
            'workflowSaved':'تم الحفظ بنجاح',
            'workflowAdd':'تم الاضافة بنجاح',
            'workflowDeleted': 'تم الحذف بنجاح',
            'groupServiceDeleted' : 'تم الحذف بنجاح',
            'deleteRole': 'تم حذف الدور بنجاح',
            'roleAdded':'تمت إضافة الدور بنجاح',
            'PublicHolidayAdd' : 'تم اضافة الاجازة بنجاح',
            'PrerequisiteAdded':'تمت إضافة المتطلبات الأساسية بنجاح'

        },
        'KEYPAD':{
            'counterNumberSaved':'تم حفظ رقم العداد',
            'recallSuccess':'تم أعادة الاتصال بنجاح',
            'endSessionSuccess':'تم إنهاء الاتصال بنجاح',
            'closeCounterSuccess':'تم إغلاق العداد',
            'transferSuccess':'تمت عملية التحويل بنجاح',
            'keypad':'اللوحة',
            'keypadPage':'اللوحة',
            'ticketNumber':'رقم التذكرة',
            'thisIsCurrentTicketNumber':'الرقم الحالي',
            'transfer':'تحويل',
            'recall':'إعادة النداء',
            'serviceTime':'وقت الخدمة',
            'endSession':'إنهاء الخدمة',
            'closeCounter':'اغلاق العداد',
            'call':'نداء',
            'noTickets':'لا يوجد تذاكر',
            'transferToAnotherCounter':'تحويل الى مكتب اخر',
            'transferCounterNumber':'تحويل مكتب رقم',
            'cancel':'إلغاء',
            'counterNumber':'رقم مكتب',
            'addCounterNumber':'إضافة رقم مكتب',
            "customerPhoneNumber" : "رقم الهاتف",
            "users": "مستخدمون",
            "services": "خدمات",
            "counter": "مكتب",
            "next": "التالي",
            "previous": "السابق",
            "transferToService": "التحويل إلى خدمة",
            "transferToUser": "التحويل إلى مستخدم",
            'transferWrong':'الرجاء اختيار تذكرة قبل عملية التحويل',
            'complete':'منجزة',
            'inComplete':'غير منجزة',
            'number':'الرقم',
            'confirmSuccess':'تمت العملية بنجاح',
            'theTicketIsSetAsIncomplete':"تمت تعيين التذكرة كغير متكملة",
            'areYouSureYouWantToConfirm':'هل انت متأكد انك تريد التأكيد ؟',
            'send':'إرسال',
            'theAnswerHasBeenSentSuccessfully':'تم إرسال الاجابة بنجاح',
            'thereIsNoTicketCalled':'لا يوجد تذكرة مناداة',
            'pleaseSelectAnswer':'الرجاء اختيار اجابة',
            'hold':'احتجاز',
            'noShow':'عدم العرض',
            'phoneNumber':'رقم الهاتف'

        },
        'CONFIRM_DIALOG':{
            'confirm':'موافق',
            'cancel':'الغاء'
        },
        'Permission_LIST': {
            'Permissions': 'الصلاحيات',
            'searchForPermission': 'البحث عن صلاحية',
            'addNewPermission': 'إضافة صلاحية جديدة',
            'id': 'الرمز',
            'name': 'الإسم',
            'actions': 'الإجراءات',
            'editPermission': 'تعديل الصلاحية',
            'deletePermission': 'حذف الصلاحية'
        },
        'Permission_ENTRY': {
            'permissionName': 'اسم الصلاحية',
            'save': 'حفظ',
            'add': 'اضافة',
            'permissionDetail': 'تفاصيل الصلاحية',
            'newPermission': 'صلاحية جديدة'
        },
        'ROLE_LIST': {
            'roles': 'الوظائف',
            'searchForRole': 'البحث عن وظيفة',
            'addNewRole': 'إضافة وظيفة جديدة',
            'id': 'الرمز',
            'name': 'الإسم',
            'actions': 'الإجراءات',
            'editRole': 'تعديل وظيفة',
            'deleteRole': 'حذف وظيفة', 
            'code':'الرمز',
            "Permission":'Permissions'
        },
        'ROLE_ENTRY': {
            'roleName': 'إسم الوظيفة',
            'save': 'حفظ',
            'add': 'إضافة',
            'roleDetail': 'تفاصيل الوظيفة',
            'newRole': 'إضافة وظيفة',
            'code':'الرمز'
        },
        'BANNER_LIST': {
            'banners': 'الاعلانات',
            'searchForBanner': 'البحث في الاعلانات',
            'addNewBanner': 'اضافة اعلان جديد',
            'id': 'الرمز',
            'name': 'الإسم',
            'actions': 'الإجراءات',
            'locations': 'المواقع',
            'services': 'الخدمات',
            'editBanner': 'تعديل الاعلان',
            'deleteBanner': 'مسح الاعلان'
        },
        'BANNER_ENTRY': {
            'bannerName': 'اسم الاعلان',
            'save': 'حفظ',
            'add': 'إضافة',
            'bannerDetail': 'تفاصيل الاعلان',
            'newBanner': 'اعلان جديد'
        },
        'ERRORS' :{
           "serviceCurrentlyUnavailable" : 'الخدمة غير متاحة حاليا'
        },
        'NAME_EDITER':{
            "name" : "الاسم",
            "language" : "اللغة",
            "delete" : "حذف",
            "addName" : "اضافة اسم",
            "addslotTime":"اضافة وقت خدمة الكبينة",
            "addprerequisite":"إضافة شرط مسبق"
         },
         'ABOUT':{
            "generalInformation" : "معلومات عامة",
            "userName" : "اسم االمستخدم",
            "arabicName" : "الاسم العربي",
            "phone" : "الرقم",
            "email" : "البريد الإلكتروني",
            "work" : "العمل",
            "tenant" : "الشركة",
            "editUserName" : "تعديل اسم المستخدم",
            "saveUser" : "حفظ المعلومات",
            "editArabicName" : "تعديل الاسم العربي",
            "editPhone" : "تعديل رقم الهاتف",
            "editEmail" : "تعديل البريد الالكتروني",
            "location" : "الموقع",
            "about" : "معلومات",
            "resetPassword" : "تغيير كلمة السر"

        },
        'tenantUserData': {
            "userName": "اسم االمستخدم",
            "avarage_Service": "متوسط ​​الخدمة",
            "client_Count": "عدد العملاء",
            "count_5u": "count 5u",
            "count_5n": "count 5n",
            "UserData": "بيانات المستخدم",
            "TicketseInfo": "معلومات التذاكر"
        },
        'tenantdatabranch': {
            "tenantName": "اسم الشركة",
            "branchName": "اسم الفرع",
            "noTicketsServiceAbove5min": "عدد تذاكر الخدمة فوق 5 دقائق",
            "noTicketsServiceBelow5min": "عدد تذاكر الخدمة أقل من 5 دقائق",
            "noTicketsWaitingAbove5min": "عدد التذاكر المنتظرة أكثر من 5 دقائق",
            "noTicketsWaitingBelow5min": "عدد التذاكر المنتظرة أقل من 5 دقائق",
            "averageService_time": "متوسط ​​وقت الخدمة",
            "averageWaiting_time": "متوسط ​​وقت الانتظار",
            "no_showTickets": "عدد تذاكر العرض",
            "total_clients": "إجمالي العملاء",
            "averageTicketPerTeller": "متوسط ​​سعر التذكرة لكل صراف"
        },
        'DYNAMIC_DASHBORD':{
           "Request" : "Request",
           "eventExecution" : "Event Execution",
           "apiMethode" : "Api Methode",
           "URL" : "URL",
           "send" : "Send",
           "key" : "Key",
           "value" : "Value",
           "add" : "Add",
           "response" : "Response",
           "cancel" : "Cancel",
           "ok" : "Ok",
           "delete" : "Delete"
        },
       
        'PRINT_REPORT':{
            "REPORT" :"تقرير",
            "PRINT":"طباعة",
            "DATE_FROM":"التاريخ من",
            "DATE_TO":"التاريخ الى ", 
            "LOCATION":"الموقع",
            "FORMAT":"النوع",
            "PDF":"PDF",
            "EXCEL":"اكسل",
            "REQUIRED":"مطلوب !",
            "MSG_DATE":"تاريخ البدء أكبر من تاريخ الانتهاء"
         },
         'FEEDBACK':{
            'SUCCES':'تم عملية التقييم بنجاح',
            'cancel':'الغاء'
         }
         ,
        'WORKFLOW_LIST': {
            'workflows': 'سير العمل ',
            'searchForWorkflow': 'البحث عن سير عمل',
            'addNewWorkflow': 'اضافة سير عمل جديد',
            'id': 'الرمز',
            'name': 'الإسم',
            'actions': 'الإجراءات',
            'locations': 'المواقع',
            'services': 'الخدمات',
            'editWorkflow': 'تعديل سير العمل',
            'deleteWorkflow': 'حذف سير العمل'
        },
        'GROUPSERVICE_ENTRY': {
            'newGroupService': 'خدمات مجموعة جديدة',
            'GroupServiceDetail': 'تفاصيل خدمة المجموعة',
            'add': 'إضافة',
            'transalationName': 'الاسم',
            'startDate': 'وقت البدء',
            'endDate': 'وقت النهاية',
            'save' : 'حفظ',
            'groupService': 'خدمة مجموعة',
            'searchGroupService': 'البحث عن خدمة المجموعة',
            'addNewGroupService': 'اضافة خدمة مجموعة',
            'id' : 'رقم التعريف',
            'name': 'الاسم',
            'actions': 'أجراءات',
            'editGroupService': 'تعديل خدمة المجموعة',
            'deleteGroupService': 'حذف خدمة المجموعة'
        },
        'RESET_PASSWORD_POPUP': {
            'resetPassword':  "تغيير كلمة السر",
            'newPassword': 'كلمة سر جديدة',
            'confirmPassword': 'تأكيد كلمة السر',
            'cancel':'الغاء',
            'ok':'موافق',
            'newPasswordNotEqualConfirmPassword' : 'كلمات المرور يجب ان تكون متطابقة',
            'passwordSetsuccessfully' : 'تم تغيير كلمة المرور بنجاح'
        },
        'IMPORT_USERS': {
            'Importcontactsfromexcelsheet': 'تحميل المعلومات عن طريق الملف',
            'clickToUpload': 'انقر للتحميل',
            'NoFileSelected': 'لا يوجد ملف',
            'ImportyourDetails':'تحميل المعلومات الخاصة بك',
            'DownloadExcelTempleate':'تنزيل الملف',
            'first' : 'اولا',
            'Please' : 'الرجاء',
            'Youwillfindtheexelindownloadfile':' سوف تجد الملف الذي قمت بتنزيله في (donload file)',
            'OpentheExelandfellyourdata':'فتح الملف وقم بتعبئة البيانات',
            'PleaseselectyourTempleateandUpdate':'الرجاء اختيار الملف',
            'upload':'تحميل',
            'SavedSuccsfully': 'تمت العملية بنجاح',
            'Filemustbexlsx':'الملف يجب ان يكون xlsx',
            'PleaseSelectFile':'الرجاء اختيار ملف'
        },
        'PublicHoliday_LIST': {
            'PublicHolidays': 'إجازة عامة',
            'searchForPublicHoliday': 'البحث عن إجازة عامة',
            'addNewPublicHoliday': 'إضافة إجازة عامة جديدة',
            'id': 'الرمز',
            'name': 'الإسم',
            'actions': 'الإجراءات',
            'editPublicHoliday': 'تعديل إجازة عامة',
            'deletePublicHoliday': 'حذف إجازة عامة'
        },
        'PublicHoliday_ENTRY': {
            'PublicHolidayName': 'اسم إجازة عامة',
            'save': 'حفظ',
            'add': 'اضافة',
            'PublicHolidayDetail': 'تفاصيل إجازة عامة',
            'newPublicHoliday': 'إجازة عامة جديدة',
            'startDate': 'تبدأ بتاريخ',
            'endDate': 'تنتهي بتاريخ',
        }
        ,
        'ADVERTISEMENT_ENTRY': {
            'name': 'الإسم',
            'type': 'النوع',
            'video':'فيديو',
            'text':'نص'      ,
            'addmedia' :'إضافة وسائل الإعلام'     ,
            'groups' :'مجموعات',
            'addnewgroup' :'اضافة مجموعة جديدة'  ,
            'searchForgroup': 'البحث عن مجموعة',
            'image': 'صورة',
            'media' :'وسائط',
            'searchFormedia': 'بحث عن وسائل الإعلام',
            'editmedia': 'تعديل وسائط',
            'addnews':'إضافة أخبار'  ,
            'addvideo':'أضف فيديو',
            'download':'تحميل'


            
        }
    }
};
