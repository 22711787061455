import { FuseUtils } from "@fuse/utils";
import { Tenant } from "./Tenant";

export class Location {
    constructor(location?)
    {
        location = location || {};
        this.id = location.id || 0;
        this.name = location.name || '';
        this.address = location.address || '' ;
        this.tenant = location.tenant || new Tenant() ;
        this.tenantId = location.tenantId || 0;
        this.lat=location.lat||'';
        this.lon=location.lon||'';
        this.sunday = location.sunday||false;
        this.monday = location.monday||false;
        this.tuesday =  location.tuesday||false;
        this.wednesday =   location.wednesday||false;
        this.thursday = location.thursday||false;
        this.friday =  location.friday||false;
        this.saturday =  location.saturday||false;
        this.isPublicHolidayApplied =  location.isPublicHolidayApplied||false;
        this.newsGroupId=location.newsGroupId ||'';
        this.mediaGroupId=location.mediaGroup ||'';     

    }
    id : string;    
    name: string;
    address: string;  
    tenant : Tenant;  
    tenantId : string;
    lat : string;
    lon :string;
    sunday: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    isPublicHolidayApplied: boolean;
    mediaGroupId:string;
    newsGroupId:string; 
}