<div id="user" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16"
                    (click)="goBack()">
                    <mat-icon>{{'arrow_back' | translate}}</mat-icon>
                </button>
             

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="pageType ==='edit'">
                        <!-- {{tenunt.name}} -->
                    </div>
                    <div class="h2" *ngIf="pageType ==='new'">
                        {{'USER_ENTRY.newUser' | translate}}
                    </div>
                    <div class="subtitle secondary-text">
                        <span>{{'USER_ENTRY.userDetail' | translate}}</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->
            <!-- [disabled]="userForm.invalid" -->
            <div class="button-container">
                <mat-spinner *ngIf="isLoading" strokeWidth="2" [diameter]="25"></mat-spinner>
                <button mat-raised-button class="save-user-button" [disabled]="userForm.status == 'INVALID' || isLoading"
                    *ngIf="pageType ==='new'" (click)="addUser()">
                    <span>{{'USER_ENTRY.add' | translate}}</span>
                </button>

                <button mat-raised-button class="save-user-button" [disabled]="checkValidForm() || isLoading" *ngIf="pageType ==='edit'"
                    (click)="saveUser()">
                    <span>{{'USER_ENTRY.save' | translate}}</span>
                </button>
            </div>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content p-24">

                <form name="userForm" [formGroup]="userForm">
                    <div class="tab-content" fusePerfectScrollbar>
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                            <mat-label>{{'USER_ENTRY.userName' | translate}}</mat-label>
                            <input matInput placeholder="{{'USER_ENTRY.userName' | translate}}" name="name"
                                formControlName="userName" required>
                        </mat-form-field>
                    </div>

                    <div class="tab-content " fusePerfectScrollbar>
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                            <mat-label>{{'USER_ENTRY.arName' | translate}}</mat-label>
                            <input matInput placeholder="{{'USER_ENTRY.arName' | translate}}" name="arName"
                                formControlName="arName" required>
                        </mat-form-field>
                    </div>

                    
                    <div class="tab-content" fusePerfectScrollbar>
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                            <mat-label>{{'USER_ENTRY.emailAddress' | translate}}</mat-label>
                            <input matInput placeholder="{{'USER_ENTRY.emailAddress' | translate}}" name="email"
                                formControlName="email" required>
                        </mat-form-field>

                    </div>
                    <div class="tab-content" fusePerfectScrollbar>
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                            <mat-label>{{'USER_ENTRY.phoneNumber' | translate}}</mat-label>
                            <input matInput placeholder="{{'USER_ENTRY.phoneNumber' | translate}}" name="phone"
                                formControlName="phoneNumber" required>
                        </mat-form-field>

                    </div>
                    <div class="tab-content"   *ngIf="pageType ==='new'"  fusePerfectScrollbar>
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                            <mat-label>{{'USER_ENTRY.password' | translate}}</mat-label>
                            <input matInput placeholder="{{'USER_ENTRY.password' | translate}}" type="password"
                                name="password"
                                matTooltip="{{'USER_ENTRY.passwordPattern' | translate}}"
                                formControlName="password" required matTooltipPosition="above">
                        </mat-form-field> 
                    </div>
                    <div class="tab-content"   *ngIf="pageType ==='new'"  fusePerfectScrollbar>
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                            <mat-label>{{'USER_ENTRY.confirmPassword' | translate}}</mat-label>
                            <input matInput placeholder="{{'USER_ENTRY.confirmPassword' | translate}}" type="password"
                                name="confirmPassword" formControlName="passwordConfirm" required>
                        </mat-form-field>
                    </div>
                    <div class="tab-content" fusePerfectScrollbar>

                        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                            <mat-label>{{'USER_ENTRY.role' | translate}}</mat-label>
                            <mat-select formControlName="role" required>
                                <mat-option *ngFor="let role of roles" [value]="role?.id">
                                    {{role?.name}}
                                </mat-option>
                            </mat-select>
                            <mat-icon matSuffix class="secondary-text">sort</mat-icon>
                            <mat-error>{{'USER_ENTRY.roleRequired' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                </form>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>