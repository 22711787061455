<div id="service" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16"
                    [routerLink]="'/tenant/'+ TenantId +'/location/' + locationId + '/user'">
                    <mat-icon>{{'arrow_back' | translate}}</mat-icon>
                </button>


                <div fxLayout="column" fxLayoutAlign="start start">

                    <div  *ngIf="userData"  class="subtitle secondary-text">
                        <span> <h2>{{userData.userName}}</h2></span>
                        
                    </div>
                    <div class="subtitle secondary-text">
                        <span>{{'ASSIGN_SERVICE_TO_USER.addServiceToUser' | translate}}</span>
                    </div>
                   
                </div>
            </div>
            <!-- / APP TITLE -->
            <!-- [disabled]="serviceForm.invalid" -->

            <!-- <button mat-raised-button class="save-service-button"
                *ngIf="ChangeStatus"
                (click)="saveService()">
                <span>SAVE</span>
            </button> -->
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content p-24">




                <div cdkDropListGroup>
                    <div class="example-container ">
                        <h2> {{'ASSIGN_SERVICE_TO_USER.locationServicesAvailable' | translate}}</h2>

                        <div cdkDropList [cdkDropListData]="LocationServices" class="example-list"
                            cdkDropListSortingDisabled (cdkDropListDropped)="drop($event)"
                            (cdkDropListEntered)="openDialog(false , $event)">
                            <div [name]="item.id + '/'+item?.service?.name" class="example-box"
                                *ngFor="let item of LocationServices" cdkDrag>{{item?.service?.name}}</div>
                        </div>
                    </div>

                    <div class="example-container">
                        <h2>{{'ASSIGN_SERVICE_TO_USER.userServices' | translate}}</h2>
                        <div cdkDropList [cdkDropListData]="UserServices" class="example-list"
                            (cdkDropListEntered)="openDialog(true , $event)" (cdkDropListDropped)="drop($event)">
                            <div [name]="item.id + '/'+ item.name" class="example-box" *ngFor="let item of UserServices"
                                cdkDrag>{{item.name}}  - Priority  {{item.priority}}</div>
                        </div>
                    </div>
                </div>


            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>