import { Component, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'app/modules/branches-management/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ResetPasswordPopupComponent } from 'app/modal/reset-password-popup/reset-password-popup.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { locale as english } from 'app/main/home/i18n/en';
import { locale as turkish } from 'app/main/home/i18n/en';
import { locale as arabic } from 'app/main/home/i18n/en';
import { Router } from '@angular/router';
import { User } from 'app/model/User';

@Component({
    selector     : 'profile',
    templateUrl  : './profile.component.html',
    styleUrls    : ['./profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ProfileComponent
{
    userDetails:any;
    user: User;
    objEmail :any;
    /**
     * Constructor
     */
    constructor(    private userService: UserService,
                 private router: Router,
                 private _matSnackBar: MatSnackBar,
                 private translate :TranslateService,
                 private _matDialog: MatDialog,
                 private _fuseTranslationLoaderService: FuseTranslationLoaderService,)
    {
        //this.user = new User();
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);  

    }

    ngOnInit(): void
    {


            this.userService.getUsersDetails().then(data=>{
                this.userDetails = data;
            })
        
    }

    resetPassword( editMood =false , userId){
        var dialogRef;
            if(editMood){
               dialogRef = this._matDialog.open(ResetPasswordPopupComponent, {
                width: '500px',
                data: { userId: userId, }
              });  
            }
            else{
               dialogRef = this._matDialog.open(ResetPasswordPopupComponent, {
                width: '500px',
                data: { userId: userId, }
              });  
            }
            dialogRef.afterClosed().subscribe(result => {
              if (result == "canceled") {
                this.ngOnInit();
              }
        
            });
        
    };
       
}
