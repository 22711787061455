<div id="category" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/category'">
                    <mat-icon>{{'arrow_back' | translate}}</mat-icon>
                </button>


                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="pageType ==='edit'">
                        <!-- {{tenunt.name}} -->
                    </div>
                    <div class="h2" *ngIf="pageType ==='new'">
                        {{'CATEGORY_ENTRY.newCategory' | translate}}
                    </div>
                    <div class="subtitle secondary-text">
                        <span> {{'CATEGORY_ENTRY.categoryDetail' | translate}}</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->
            <!-- [disabled]="categoryForm.invalid" -->
            <div class="button-container">
                <mat-spinner *ngIf="isLoading" strokeWidth="2" [diameter]="25"></mat-spinner>
                <button mat-raised-button class="save-category-button"  [disabled]="!isValidForm() || isLoading"
                    *ngIf="pageType ==='new'" (click)="addCategory()">
                    <span>{{'CATEGORY_ENTRY.add' | translate}}</span>
                </button>
                <button mat-raised-button class="save-category-button"
                [disabled]="!isValidForm() || isLoading" *ngIf="pageType ==='edit'"
                    (click)="saveCategory()">
                    <span>{{'CATEGORY_ENTRY.save' | translate}}</span>
                </button>
            </div>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">
            
                <form name="categoryForm" [formGroup]="categoryForm" class="category w-100-p" fxLayout="column" fxFlex>
                    <div>
                        <div class="d-flex ">
                            <div style="width:20%" *ngIf="category.icon" class="p-24">
                                <input hidden src="category.icon" type="file" #fileInput [accept]="allowedFiles"
                                (change)="uploadIcon($event , category.id )" />
                                <img   src="{{'data:image/jpg;base64,' + category.icon}}" (click)="fileInput.click()"   style=" width: 80%;" />
                                <!-- assets//images//backgrounds/default-upload-image.jpg-->

                            </div>
                            <div style="width:20%"*ngIf="!category.icon" class="p-24">
                                <input hidden src="category.icon" type="file" #fileInput [accept]="allowedFiles"
                                    (change)="uploadIcon($event , category.id )" />
                                <img   src="../../../../assets/images/backgrounds/default-upload-image.jpg" (click)="fileInput.click()"   style=" width: 80%;" />
                                <!-- assets//images//backgrounds/default-upload-image.jpg-->

                            </div>

                            <div style="width: 80%;">
                                <name-language-editer [data]="category.name" #nameLanguageEditor></name-language-editer>
                            </div>
                        </div>
            
                    </div>
            
            
            
                </form>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>