import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

import { fuseAnimations } from '@fuse/animations';
import { AdvertisementService } from '../Advertisement.service';
import { Service } from 'app/model/Service';
import { ActivatedRoute, Router } from '@angular/router';
import { locale as english } from '../../../../main/home/i18n/en';
import { locale as turkish } from '../../../../main/home/i18n/tr';
import { locale as arabic } from '../../../../main/home/i18n/ar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { NameLanguageEditer } from 'app/component/name-language-editer/name-language-editer.component';
import { Advertisement } from 'app/model/Advertisement';
import { AdsMedia } from 'app/model/AdsMedia';
import { Location } from '@angular/common';

import { HttpClient } from '@angular/common/http';
@Component({
    selector: 'app-AdsMediaItemVidio',
    templateUrl: './AdsMediaItemVidio.component.html',
    styleUrls: ['./AdsMediaItemVidio.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AdsMediaItemVidioComponent implements OnInit {
  
  
    selectedFile: File | null = null;
    pageType: string;
    MediaType:any;
    advertisement:AdsMedia
    tenantID:string
    advertisementForm: FormGroup;
    @ViewChild("nameLanguageEditor") nameLanguageEditor :NameLanguageEditer;


    // Private
    private _unsubscribeAll: Subject<any>;
    extras: any;

    /**
     * Constructor
     *
     * @param {ServiceService} _serviceService
     * @param {FormBuilder} _formBuilder
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        private advertisementService: AdvertisementService,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private route: ActivatedRoute,
        private router: Router,
        private _location: Location,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translate:TranslateService,
        private httpClient: HttpClient
    ) {
        // Set the default
        this.advertisement = new AdsMedia();
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            if (params.get('mediaGroupId')) {
                this.advertisement.mediaGroupId = params.get('mediaGroupId')
                // this.tenantID= params.get('tenantId')
                this.pageType = 'new';
                // this.advertisementService.getServiceById(this.advertisement.mediaGroupId, true).then((res: Advertisement) => {
                //     this.advertisement = res;
                //     this.advertisementForm = this.createServiceForm();
                // });
            }
            else {
                if (params.get('id')) {
                    this.advertisement.mediaGroupId = params.get('id');
                }
                this.pageType = 'edit';
            }
            this.advertisementForm = this.createServiceForm();
        });

    }
    goBack() {
        this._location.back();
    };

//     handleFileInput(files: FileList) {
//     this.fileToUpload = files.item(0);
// }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create service form
     *
     * @returns {FormGroup}
     */
    createServiceForm(): FormGroup {
        return this._formBuilder.group({
            name: [this.advertisement.name],
            //type: [this.advertisement.type],
            news: [this.advertisement.news],
            createdTime: [this.advertisement.createdTime],
            isActivated: [this.advertisement.isActivated],
            mediaGroupId: [this.advertisement.mediaGroupId],
            file: [Validators],

        });
    }

    isValidForm(){
        return this.advertisementForm?.valid 
     }

    /**
     * Save service
     */
    saveService(): void {
        this.advertisement.name = JSON.stringify(this.nameLanguageEditor.deserializedData);
        this.advertisementForm.controls["name"].setValue(this.advertisement.name);
        const data = this.advertisementForm.getRawValue();
        // data.handle = FuseUtils.handleize(data.name);

        this.advertisementService.saveService(data)
            .then(() => {
                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.serviceAdded'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.message, this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }

    /**
     * Add service
     */
    addAdsmediaitem(): void {
      
        
        const data = this.advertisementForm.getRawValue();
        const data2 =null;
        data.id = undefined;
        data.createdTime=new Date().toISOString();
        data.mediaGroupType=Number(data.mediaGroupType) ;
        //   data.handle = FuseUtils.handleize(data.name);
        if (!this.selectedFile) {
            console.error('No file selected');
            return;
          }
     
          
          const formData = new FormData(); 
        formData.append('Name',data.name);
        formData.append('News','');
        formData.append('isActivated','true');
        formData.append('MediaType',this.MediaType);
        formData.append('File',this.selectedFile);
        formData.append('mediaGroupId',data.mediaGroupId);
       // formData.append('MediaGroup.tenantId',this.tenantID);


        //   this.myObject.Name=data.name;
        //   this.myObject.News='';
        //   this.myObject.isActivated=true;
        //   this.myObject.MediaType=1;
        //   this.myObject.File= this.selectedFile;
        //   this.myObject.mediaGroupId=data.mediaGroupId;
        //   this.myObject.tenantId=ta




      
          // Replace 'your-upload-api-url' with the actual API endpoint for video upload
         
        this.advertisementService.addAdsmediaitemv(formData)
            .then(() => {

                // Trigger the subscription with new data
                this.advertisementService.onServiceChanged.next(data);

                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.serviceAdded'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
                this.goBack()

                // Change the location with new one

            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }
    onFileSelected(event: any): void {
        this.selectedFile = event.target.files[0] ;

        if (this.selectedFile)
        {
           // Check the MIME type of the selected file
           const fileType = this.selectedFile.type;

           if (fileType.startsWith('image/')) {
               this.selectedFile = event.target.files[0]
               this.MediaType=0
               // Handle image file
           } else if (fileType.startsWith('video/')) {
               this.selectedFile = event.target.files[0] as File;
               this.MediaType=1
           }

        }


      }
      

    
}
