import { Component , OnInit, VERSION, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { ImportExportService } from './import-export.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Injector } from "@angular/core";


declare var window: any;
@Component({
    selector: 'app-import-export.',
    templateUrl: './import-export.component.html',
    styleUrls: ['./import-export.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ImportExportComponent implements OnInit {


    name = "Angular " + VERSION.major;
    display: FormControl = new FormControl("", Validators.required);
    file_store: FileList;
    file_list: Array<string> = [];
    currentFile: File;
    fileName: any;
    file: File;
    importing : boolean = false;
    tenantId : any;
    locationId : any ;

    data: any = [
      {
        Email: '',
        Password:'',
        UserName: '',
        ArabicName: '',
        PhoneNumber: '',
        IsAdmin: ''
      }];
    
     constructor(private _fuseConfigService : FuseConfigService ,
                 private _importExportService : ImportExportService,
                 private _matSnackBar: MatSnackBar,
                 private route: ActivatedRoute,
                 private translate: TranslateService) {


                 }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
     ngOnInit() {
      this.route.paramMap.subscribe(params => {
        if (params.get('tenantId')) {
            this.tenantId = params.get('tenantId');
        }
        if(params.get('locationId')){
          this.locationId = params.get('locationId');
        }
    });
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    selectFile(event: any): void {
        if (event.target.files && event.target.files[0]) {
          this.file = event.target.files[0];
          this.currentFile = this.file;
          this.fileName = this.currentFile.name;
         }
      }
      
    upload(){
      if (this.file) {
        if (this.file.name.split('.').pop() == 'xlsx') {
            this.importing = true;
            this._importExportService.upload(this.file , this.locationId , this.tenantId).then(result => {
                if (result) {
                  this._matSnackBar.open(this.translate.instant('IMPORT_USERS.SavedSuccsfully'), this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                  });
                }
            }).catch(result => {
                this.importing = false;
            });

        }
        else {
          this._matSnackBar.open(this.translate.instant('IMPORT_USERS.Filemustbexlsx'), this.translate.instant('ok'), {
            verticalPosition: 'top',
            duration: 2000
          });
        }
    }
    else {
      this._matSnackBar.open(this.translate.instant('IMPORT_USERS.PleaseSelectFile'), this.translate.instant('ok'), {
        verticalPosition: 'top',
        duration: 2000
      });
    }
    }

    downloadTemplate(){
      this._importExportService.exportAsExcelFile(this.data, 'Template');
    }

    
}

export class LocatorService {
    static injector: Injector;
}
