import { Time } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { validateBasis } from '@angular/flex-layout';
import { FormBuilder, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { LocationService } from 'app/modules/branches-management/location/location.service';
import { KeypadService } from 'app/modules/keypad/keypad.service';
import { StorageManager } from 'app/shared/utilities/storage-manager';
import { UserService } from 'app/modules/branches-management/user/user.service';
import { ServiceService } from 'app/modules/branches-management/service/service.service';
import { MatStepper } from '@angular/material/stepper';
@Component({
  selector: 'app-transfer-service-to-counter-popup',
  templateUrl: './transfer-service-to-counter-popup.component.html',
  styleUrls: ['./transfer-service-to-counter-popup.component.scss']
})
export class TransferServiceToCounterPopupComponent implements OnInit {
  userDetails :any;
  form: FormGroup;
  transferType: string;
  locationServices: any;
  locationUsers: any;
  ngOnInit(): void {

    this.form = this._formBuilder.group({
      TicketId: [{ value: this.data.TicketId }],
      CounterNumber: [{ value: this.data.CounterNumber }],
      serviceId: [{ value: "" }],
      userId: [{ value: "" }]
    });

    this.userService.getUsersDetails().then(data=>{
      this._ServiceService.getServicsByLocation(data.location.id).then(res => {
        if (res) {
          this.locationServices =[];
          res.forEach(element => {
            if (element) {
            this.locationServices.push({ id: element.id, serviceId: element.service.id, name: element.service.name });
          }
          });
        }
        
      });
      this.userService.getUsersLocationWithoutPagination(data.location.id).then(res => {
        if (res) {
          this.locationUsers =[];
          res.forEach(element => {
            if (element) {
            this.locationUsers.push({ id: element.id, userName: element.userName});
          }
          });
        }
        
      });
  });
  }


  date: Date;
  timepicker: any;
  basicDatepicker: any;

  constructor(
    private _formBuilder: FormBuilder,
    private _matSnackBar: MatSnackBar,
    private locationService: LocationService,
    private keypadService: KeypadService,
    private userService: UserService,
    private _ServiceService: ServiceService,
    public dialogRef: MatDialogRef<TransferServiceToCounterPopupComponent>,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  cancelClick(): void {
    this.dialogRef.close("canceled");
  }

  goForward(stepper: MatStepper){
    stepper.next();
}
  save() {
    this.keypadService.TransferCounter(this.data.TicketId, this.data.CounterNumber)
      .then(() => {
        // Show the success message
        this._matSnackBar.open(this.translate.instant('KEYPAD.transferSuccess'), 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
      });
    this.dialogRef.close("saved");
  }
  saveTransferService() {
    var data = this.form.getRawValue();
    if(data && this.data.TicketId){
    this.keypadService.TransferService(this.data.TicketId, data.serviceId)
    .then(() => {
      // Show the success message
      this._matSnackBar.open(this.translate.instant('KEYPAD.transferSuccess'), 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
    });
    this.dialogRef.close("saved");
  }
  else{
        // Show the success message
        this._matSnackBar.open(this.translate.instant('KEYPAD.transferWrong'), 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
  }
  }
  saveTransferUsers() {
    var data = this.form.getRawValue();
    if(data && this.data.TicketId){
    this.keypadService.TransferUsers(this.data.TicketId, data.userId)
    .then(() => {
      // Show the success message
      this._matSnackBar.open(this.translate.instant('KEYPAD.transferSuccess'), 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
    });
    this.dialogRef.close("saved");
  }
  else{
        // Show the success message
        this._matSnackBar.open(this.translate.instant('KEYPAD.transferWrong'), 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
  }
  }
  close() {
    this.dialogRef.close();
  }

}

export interface DialogData {
  TicketId: number;
  CounterNumber: number;

}
