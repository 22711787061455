import { Component, OnInit } from '@angular/core';
import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ActivatedRoute } from '@angular/router';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ServiceDetailsPopupComponent } from 'app/modal/service-details-popup/service-details-popup/service-details-popup.component';
import { Location } from '@angular/common';
import { Location as LocationModel } from 'app/model/Location';

import { TenantService } from 'app/modules/branches-management/tenant/tenant.service';
import { Tenant } from 'app/model/Tenant';
import { LocationService } from 'app/modules/branches-management/location/location.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { locale as english } from '../../../main/home/i18n/en';
import { locale as turkish } from '../../../main/home/i18n/tr';
import { locale as arabic } from '../../../main/home/i18n/ar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from 'app/modules/role/role.service';
import { PermissionService } from '../permission.service';
import { Role } from 'app/model/Role';
import { Permission } from 'app/model/Permission';

@Component({
  selector: 'app-assign-Permission-to-Role',
  templateUrl: './assign-Permission-to-Role.component.html',
  styleUrls: ['./assign-Permission-to-Role.component.scss']
})
export class AssignPermissionToRoleComponent implements OnInit {
  RoleId: string;
  Role: Role;
  PermissionId: string;
  Permission: Permission;
  RoleServices = [];
  RolePermissions = [];
  ChangeStatus: boolean;
  AllPermissions: any;

  constructor(
    private route: ActivatedRoute,
    private permissionService: PermissionService,
    private roleService: RoleService,
    public dialog: MatDialog,
   private _location: Location,
    private _matSnackBar: MatSnackBar,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private translate: TranslateService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);
  }

  ngOnInit(): void {
    this.resetServices();
   
    this.route.paramMap.subscribe(params => {
      if (params.get('roleId')) {
        this.RoleId = params.get('roleId');
        this.getRole();
      }
    })
  }
  getRole() {
    this.roleService.getRolePermissions(this.RoleId).then(res => {
      this.RolePermissions = res;
      this.getPermission();
    })
  }
  getPermission() {
    this.permissionService.getPermissionsWithoutPagenation().then(res => {
      this.AllPermissions = res?.data?.filter(x => !(this.RolePermissions?.map(({ applicationPermission  }) => applicationPermission?.id ).includes(x.id)));

    })
  }
  resetServices() {
    this.RoleServices = [];
    this.RolePermissions = [];
  }
  getPermissionServices(callBack?) {

    this.permissionService.getPermissionById(this.PermissionId).then(res => {
      res.forEach(element => {
        this.RolePermissions.push({ id: element.id, serviceId: element.service.id, name: element.service.name });
      });
      if (callBack) {
        callBack();
      }
    })
  }
  getRoleServices() {
    this.roleService.getRolePermissions(this.RoleId).then(res => {
      if (res) {
        this.RoleServices = res.filter(x => !(this.RolePermissions?.map(({ PermissionId }) => this.PermissionId).includes(x.id)));
      }
    });
  }


  goBack() {
    this._location.back();
  }

  openDialog(IsOpenDialog: boolean, e): void {
    this.ChangeStatus = true;
    if (IsOpenDialog) {    
        this.openDialogTemplate(e);
    } 
    else {
      this.deleteLocationService(e);
    }

  }
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  deleteLocationService(e) {
    let permissionId  = e?.item?.element?.nativeElement?.name?.split('/')[0];
    
    if (permissionId) {
      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
        disableClose: false
      });
      this.confirmDialogRef.componentInstance.confirmMessage = this.translate.instant('CONFIRM_MSG.deleteRolePermission');
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.roleService.deletePermissionfromRole(this.RoleId , permissionId).then(res => {           
            // Show the success message
            this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.permissionDeleted'), this.translate.instant('CONFIRM_MSG.ok'), {
              verticalPosition: 'top',
              duration: 2000
            });
            this.ngOnInit();
          })

        } else {
          this.ngOnInit()
        }
      })
    } else {
      this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.wentWrong'), this.translate.instant('ok'), {
        verticalPosition: 'top',
        duration: 2000
      });
    }
  }

  openDialogTemplate(e) {
    
    let permissionId  = e?.item?.element?.nativeElement?.name?.split('/')[0];
    this.roleService.assignPermissionToRole(this.RoleId , permissionId).then(res => {
      this.ngOnInit();
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
  saveService() {
    console.log(this.permissionService);
  }
}

