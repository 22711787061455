<div id="workflows" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    account_balance
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    {{'WORKFLOW_LIST.workflows' | translate}}
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon> {{'search' | translate}}</mat-icon>
                    <input #filter placeholder="{{'WORKFLOW_LIST.searchForWorkflow' | translate}}">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <button mat-raised-button [routerLink]="'/workflow/add'"
                class="add-workflow-button fuse-white mt-24 mt-md-0">
                <span>{{'WORKFLOW_LIST.addNewWorkflow' | translate}}</span>
            </button>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">
            <div class="list-skeleton" *ngIf="loaderService.isLoading ">
                <ngx-skeleton-loader
                    [theme]="{ width: '100%', height: '35px'}"
                    animation="progress"
                    count="10">
                </ngx-skeleton-loader>
            </div>
            <mat-table  *ngIf="!loaderService.isLoading "
            class="workflows-table custom-table" #table [dataSource]="dataSource" matSort [@animateStagger]="{value:'50'}"
                fusePerfectScrollbar>

                <!-- ID Column -->
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'WORKFLOW_LIST.id' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let workflow" [routerLink]="'/workflow/edit/' + workflow.id">
                        <p class="text-truncate">{{workflow.id}}</p>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="image">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{'TENANT_LIST.logo' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let workflow" class="image-column">
                        <!-- ADD FILE BUTTON -->
                        <div *ngIf="workflow.icon ==null" class="file-uploader">
                            <button mat-mini-fab class="add-file-btn" (click)="fileInput.click()" aria-label="Add file"
                                [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                                <mat-icon>add</mat-icon>

                            </button>
                    
                        </div>
                    </mat-cell>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'WORKFLOW_LIST.name' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let workflow" [routerLink]="'/workflow/edit/' + workflow.id">
                        <p class="text-truncate">{{workflow.name}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'WORKFLOW_LIST.actions' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let workflow">


                        <mat-icon class="logo-icon" [routerLink]="'/workflow/edit/' + workflow.id"
                            [@animate]="{value:'*',params:{delay:'200ms',scale:'0.4'}}"
                            matTooltip="{{'WORKFLOW_LIST.editWorkflow' | translate}}">
                            edit
                        </mat-icon>

                        <mat-icon class="logo-icon mx-1" (click)="deleteWorkflow(workflow.id)"
                            [@animate]="{value:'*',params:{delay:'275ms',scale:'0.6'}}"
                            matTooltip="{{'WORKFLOW_LIST.deleteWorkflow' | translate}}">
                            delete_forever
                        </mat-icon>

                    </mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let workflow; columns: displayedColumns;" class="workflow" matRipple>
                </mat-row>

            </mat-table>

            <mat-paginator #paginator [length]="dataSource ? dataSource : 0" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>