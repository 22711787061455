import { Component, Input, OnChanges, SimpleChanges, ViewChild , OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { List } from 'lodash';
import { v4 as uuid } from 'uuid';


@Component({
    selector: 'prerequisite-editer',
    templateUrl: './prerequisite-editer.component.html',
})
export class PrerequisiteEditer  implements OnChanges , OnInit {
@ViewChild("manageEntityForm") manageEntityForm: FormGroup;
@Input("data") data = "";
deserializedData= [];


constructor(
    private activeRouter: Router,  
    private activeRoute: ActivatedRoute,
    private translate:TranslateService
    ) {
       
}
    ngOnInit(): void {
       this.addRow();
    }
    generateGuid(){
        return uuid();
    }
  
    removeRow(row) {
        this.deserializedData.splice(this.deserializedData.indexOf(row), 1);
        this.deserializedData = [...this.deserializedData];
    }

    ngOnChanges(changes: SimpleChanges): void {   
        if(this.data == ""){
            return;
        } 
        try{
            this.deserializedData =  JSON.parse(this.data);                    
        } 
        catch{

            this.deserializedData.push({name : this.data , description:"" });        
        }
    }

    addRow(){
        this.deserializedData.push({name : "" , description:"" })
    }
    
}   