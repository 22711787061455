import { AgmCoreModule } from "@agm/core";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { NgxStarRatingModule } from 'ngx-star-rating';
import { WorkFlowComponent } from "./workflow/workflow.component";
import { DiagramComponent } from './diagram/diagram.component';
import { WorkFlowListComponent } from "./wowflow-list/workflow-list.component";
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { MatDialogModule } from '@angular/material/dialog';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { WorkFlowService } from "./workflow.service";
import { NameLanguageEditerModule } from 'app/component/name-language-editer/name-language-editer.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PrerequisiteEditerModule } from "app/component/prerequisite_editer/prerequisite-editer.module";

const routes = [
  {
    path     : 'workflow',
    component: WorkFlowListComponent
},
{
  path     : 'workflow/add',
  component: WorkFlowComponent
},
{
  path: 'workflow/edit/:id',
  component: WorkFlowComponent
}
];
@NgModule({
  declarations: [
    WorkFlowComponent,
    DiagramComponent  ,
    WorkFlowListComponent 
  ],
  imports: [
    AgmCoreModule.forRoot({
        apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
    }),
    RouterModule.forChild(routes),
    TranslateModule,
    CommonModule,
    FuseSharedModule,
    NgxStarRatingModule,
    MatPaginatorModule,
    MatIconModule,
    MatProgressSpinnerModule,
    NgxSkeletonLoaderModule,
    NameLanguageEditerModule,
    PrerequisiteEditerModule,
    DragDropModule,
    MatDialogModule,
    FuseWidgetModule,
    MatTabsModule,
    MatTableModule,
    MatSnackBarModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule
  ],
  providers:[
         WorkFlowService
  ]
  
})
export class WorkFlowModule { }
