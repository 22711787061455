import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Permission } from 'app/model/Permission';
import { apiUrls } from 'app/shared/constants/apiUrls';
import { StorageManager } from 'app/shared/utilities/storage-manager';
import { environment } from 'environments/environment';
import { Http2ServerRequest } from 'http2';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionService implements Resolve<any>{
 
    permissions : Permission[] = new Array<Permission>();
    permission : Permission  =   new Permission() ;
  onPermissionChanged: BehaviorSubject<any>;
  currentPage: number = 1;
  pageSize : number = 10;

   /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
      private _httpClient: HttpClient
  )
  {
      // Set the defaults
      this.onPermissionChanged = new BehaviorSubject({});
  }

   /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getPermissionsWithoutPagenation()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

  
  getAllPermissions() :Observable<any>{
   return this._httpClient.get(environment.serviceUrl3 + apiUrls.permission )
  }

  getPermissionsWithoutPagenation(): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.permission)
              .subscribe((response: any) => {
                  this.permissions = response;
                  this.onPermissionChanged.next(this.permissions);
                  resolve(response);
              }, reject);
      });
  }
  
  getPermissions(pageIndex ,pageSize): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.permission+ '?pageNumber='+ pageIndex + '&pageSize=' + pageSize)
              .subscribe((response: any) => {
                  this.permissions = response;
                  this.onPermissionChanged.next(this.permissions);
                  resolve(response);
              }, reject);
      });
  }

  getPermissionsByFillter(pageIndex ,pageSize , search): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.permission + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize + '&search=' + search)
              .subscribe((response: any) => {
                  this.permissions = response;
                  this.onPermissionChanged.next(this.permissions);
                  resolve(response);
              }, reject);
      });
  }

  getPermissionById(id): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.permission + '/' + id )
              .subscribe((response: any) => {
                  this.permission = response;
                  this.onPermissionChanged.next(this.permission);
                  resolve(response);
              }, reject);
      });
  }

  
 // Delete permission
 deletePermission(id): Promise<any>
 {
     return new Promise((resolve, reject) => {
         this._httpClient.delete(environment.serviceUrl3 + apiUrls.permission + '?'+"Id=" +id )
             .subscribe((response: any) => {
                 resolve(response);
             }, reject);
     });
 }

  savePermission(permission): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.serviceUrl3 + apiUrls.permission + permission.id, permission)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  addPermission(permission): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl3 + apiUrls.permission, permission)
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }

}
