<div id="banners" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    account_balance
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    {{'BANNER_LIST.banners' | translate}}
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon> {{'search' | translate}}</mat-icon>
                    <input (keyup)="applyFilter($event.target.value)" placeholder="{{'BANNER_LIST.searchForBanner' | translate}}">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <button mat-raised-button [routerLink]="'/banner/add'"
                class="add-banner-button fuse-white mt-24 mt-md-0">
                <span>{{'BANNER_LIST.addNewBanner' | translate}}</span>
            </button>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="banners-table" #table [dataSource]="dataSource" matSort [@animateStagger]="{value:'50'}"
                fusePerfectScrollbar>

                <!-- ID Column -->
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'BANNER_LIST.id' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let banner" [routerLink]="'/banner/edit/' + banner.id">
                        <p class="text-truncate">{{banner.id}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="image">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{'TENANT_LIST.logo' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let banner">
                        <!-- ADD FILE BUTTON -->
                        <div class="file-uploader" *ngIf="banner.logo==null"  >
                            <input hidden type="file" #fileInput [accept]="allowedFiles"
                                (change)="uploadImage($event , banner.id)" />
                            <button mat-fab (click)="fileInput.click()" aria-label="Add file"
                                [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                                <mat-icon>add</mat-icon>

                            </button>

                        </div>
                        <div *ngIf="banner.logo !=null">
                            <img src="{{'data:image/jpg;base64,' + banner.logo}}" />
                        </div>
                    </mat-cell>
                </ng-container>
                <!-- / ADD FILE BUTTON -->

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'BANNER_LIST.name' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let banner" [routerLink]="'/banner/edit/' + banner.id">
                        <p class="text-truncate">{{banner.name}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'BANNER_LIST.actions' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let banner">


                        <mat-icon class="logo-icon s-32 mr-16" (click)="edit(banner.id)" 
                            [@animate]="{value:'*',params:{delay:'200ms',scale:'0.4'}}"
                            matTooltip="{{'BANNER_LIST.editBanner' | translate}}">
                            edit
                        </mat-icon>

                        <mat-icon class="logo-icon s-32 mr-16" (click)="deleteBanner(banner.id)"
                            [@animate]="{value:'*',params:{delay:'275ms',scale:'0.6'}}"
                            matTooltip="{{'BANNER_LIST.deleteBanner' | translate}}">
                            delete_forever
                        </mat-icon>

                    </mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let banner; columns: displayedColumns;" class="banner" matRipple>
                    <!-- [routerLink]="'/banner/add/' + banner.id" -->
                </mat-row>

            </mat-table>

            <mat-paginator 
            [length]="length"
            [pageIndex]="pageIndex - 1"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>