<div id="service" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" (click)="goBack()">
                    <mat-icon>{{'arrow_back' | translate}}</mat-icon>
                </button>


                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="pageType ==='edit'">
                        <!-- {{tenunt.name}} -->
                    </div>
                    <div class="h2" *ngIf="pageType ==='new'">
                        {{'ADVERTISEMENT_ENTRY.addnewgroup' | translate}}
                    </div>
                  
                </div>
            </div>
            <!-- / APP TITLE -->
            <!-- [disabled]="serviceForm.invalid" -->

            <button mat-raised-button class="save-service-button" [disabled]="!isValidForm()"
                *ngIf="pageType ==='new'" (click)="addAvertisement()">
                <span>{{'SERVICE_ENTRY.add' | translate}}</span>
            </button>

            <button mat-raised-button class="save-service-button"
                [disabled]="!isValidForm()" *ngIf="pageType ==='edit'"
                (click)="saveService()">
                <span> {{'SERVICE_ENTRY.save' | translate}}</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

             

                <form name="advertisementForm" [formGroup]="advertisementForm" class="service w-100-p" fxLayout="column" fxFlex>
                    <div class="tab-content" fusePerfectScrollbar>
                    <div class="p-24">
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                            <mat-label>{{'ADVERTISEMENT_ENTRY.name' | translate}}</mat-label>
                            <input matInput placeholder="Name" name="name" formControlName="name"
                                required>
                            <mat-error>
                                {{'ADVERTISEMENT_ENTRY.name' | translate}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                            <mat-label>{{'ADVERTISEMENT_ENTRY.type' | translate}}</mat-label>
                            <mat-select formControlName="mediaGroupType" name="mediaGroupType" required>
                                <mat-option value="1">{{'ADVERTISEMENT_ENTRY.text' | translate}}</mat-option>
                                <mat-option value="0">{{'ADVERTISEMENT_ENTRY.video' | translate}}</mat-option>
                            </mat-select>
                            <mat-icon matSuffix class="secondary-text">sort</mat-icon>
                            <mat-error>{{'ADVERTISEMENT_ENTRY.type'| translate}} {{'PRINT_REPORT.REQUIRED' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                    </div>
                </form>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>