<div id="profile" class="page-layout simple tabbed">

    <!-- HEADER -->
    <div class="header p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
         fxLayoutAlign.gt-sm="space-between end">

        <div class="user-info" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
             fxLayoutAlign.gt-sm="start center">
            <img class="profile-image avatar huge" src="assets/images/avatars/Velazquez.jpg"
                 [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
            <div class="name" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                {{userDetails.userName | uppercase }}
            </div>
        </div>

        <div class="actions" fxLayout="row" fxLayoutAlign="end center" [@animate]="{value:'*',params:{delay:'200ms'}}">
            <button mat-raised-button color="accent" aria-label="resetPassword" (click)="resetPassword(true , userDetails.id)">{{'ABOUT.resetPassword' | translate}}</button>
    
        </div>

    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content">

        <mat-tab-group dynamicHeight="true">


            <mat-tab label="{{'ABOUT.about' | translate}}">
                <profile-about></profile-about>
            </mat-tab>


        </mat-tab-group>

    </div>
    <!-- / CONTENT -->

</div>
