<div fxFlex fxLayout="column" fxLayoutAlign="center center">

    <div class="big-circle app-logo" fxLayout="column" fxLayoutAlign="center center"
         [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
        <mat-icon class="s-64 s-md-128 mat-accent">chat</mat-icon>
    </div>

    <span class="app-title mt-48 mb-8" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
        Chat App
    </span>

    <span fxHide fxShow.gt-md class="app-message" [@animate]="{value:'*',params:{delay:'200ms',y:'50px'}}">
        Select a contact to start a chat!
    </span>

    <button mat-raised-button fxHide.gt-md fuseMatSidenavToggler="chat-left-sidenav">
        Select a contact to start a chat!
    </button>

</div>
