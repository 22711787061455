import { FuseUtils } from "@fuse/utils";

export class Role {
    constructor(role?)
    {
        role = role || {};
        this.id = role.id || 0;//FuseUtils.generateGUID();
        this.name = role.name || '';
    }
    id : string;    
    name: string
}