<div mat-dialog-content>
    <form class=" p-24 mr-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form" [formGroup]="form" [ngClass]="currentLanguage == 'ar' ? 'arabic' : ''">


        <div class="h2 mb-24"> {{'SERVICE_DETAILS_POPUP.serviceDetails' | translate}} </div>
        <mat-horizontal-stepper #stepper labelPosition="bottom">
            <mat-step>
                <ng-template matStepLabel></ng-template>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" style="margin-top: 25px;">
                    <mat-form-field appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="50">
                        <mat-label> {{'SERVICE_DETAILS_POPUP.tenantName' | translate}} </mat-label>
                        <input disabled matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="tenantName" required>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="50">
                        <mat-label> {{'SERVICE_DETAILS_POPUP.locationName'|translate}} </mat-label>
                        <input disabled matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="locationName" required>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="50">
                        <mat-label> {{'SERVICE_DETAILS_POPUP.serviceName' | translate}} </mat-label>
                        <input matInput disabled [ngModelOptions]="{standalone: true}" [(ngModel)]="serviceName" required>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="50">
                        <mat-label> {{'SERVICE_DETAILS_POPUP.parentService' | translate}} </mat-label>
                        <mat-select [(ngModel)]="data.parentService" formControlName="parentService">
                            <mat-option [value]="0">
                                Without
                            </mat-option>
                            <mat-option *ngFor="let service of locationServices" [value]="service?.id">
                                {{service?.service?.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="50">
                        <mat-label> {{'SERVICE_DETAILS_POPUP.serviceGroup' | translate}} </mat-label>
                        <mat-select [(ngModel)]="data.groupId" formControlName="groupId">
                            <mat-option [value]="0">
                                Without
                            </mat-option>
                            <mat-option *ngFor="let service of locatoinServiceGroup" [value]="service?.id">
                                {{service?.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="50">
                        <mat-label> {{'SERVICE_DETAILS_POPUP.priority' | translate}} </mat-label>
                        <mat-select formControlName="priority" [(ngModel)]="data.priority">
                            <mat-option [value]="0">
                                Without
                            </mat-option>
                            <mat-option [value]="1">
                                1
                            </mat-option>
                            <mat-option [value]="2">
                                2
                            </mat-option>
                            <mat-option [value]="3">
                                3
                            </mat-option>
                            <mat-option [value]="4">
                                4
                            </mat-option>
                            <mat-option [value]="5">
                                5
                            </mat-option>
                        </mat-select>
                        <mat-icon matSuffix class="secondary-text">sort</mat-icon>
                        <mat-error> {{'SERVICE_DETAILS_POPUP.priorityRequired' | translate}} </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="50">
                        <mat-label> {{'SERVICE_DETAILS_POPUP.slotTime' | translate}} </mat-label>
                        <input matInput type="number" formControlName="slotTime" [(ngModel)]="data.slotTime" required>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="50">
                        <mat-label> {{'SERVICE_DETAILS_POPUP.toleranceTiem' | translate}} </mat-label>
                        <input matInput type="number" formControlName="toleranceTiem" [(ngModel)]="data.toleranceTiem" required>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="50">
                        <mat-label> {{'SERVICE_DETAILS_POPUP.serviceProfile' | translate}} </mat-label>
                        <mat-select [(ngModel)]="data.serviceProfile" formControlName="serviceProfile">
                            <mat-option [value]="0">
                                {{'SERVICE_DETAILS_POPUP.both' | translate}}
                            </mat-option>
                            <mat-option [value]="1">
                                {{'SERVICE_DETAILS_POPUP.appointmentOnly' | translate}}
                            </mat-option>
                            <mat-option [value]="2">
                                {{'SERVICE_DETAILS_POPUP.walkInOnly' | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="50">
                        <mat-label> {{'SERVICE_DETAILS_POPUP.numberOfCounters' | translate}} </mat-label>
                        <input matInput type="number" formControlName="numberOfCounters" [(ngModel)]="data.numberOfCounters" required>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="end" fxFlex="1 0 auto">
                    <button mat-button matStepperNext type="button">Next</button>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel></ng-template> 

                <!-- <div fxLayout="row" fxFlex="1 0 auto">
                    <mat-checkbox class="mb-24" (change)="showOptions($event)"   [(ngModel)]="multislot"
                    formControlName="multislot">
                        {{'SERVICE_DETAILS_POPUP.multislot' | translate}}
                    </mat-checkbox>
                </div> -->
                <div *ngIf="hideRowsTime">
                    
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" style="margin-top: 25px;"  >
                    <mat-form-field appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="50">
                        <mat-label> {{'SERVICE_DETAILS_POPUP.startTime' | translate}} </mat-label>
                        <input matInput type="time" formControlName="startTime" [(ngModel)]="data.startTime" required>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="50">
                        <mat-label> {{'SERVICE_DETAILS_POPUP.endTime' | translate}} </mat-label>
                        <input matInput type="time" formControlName="endTime" [(ngModel)]="data.endTime" required>
                    </mat-form-field>
                </div>
            </div>
                <div *ngIf="hideRowsSlot">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" style="margin-top: 25px;"  >
                    <slot-time-editor [data]="service" #soltTimeEditor></slot-time-editor>

                </div>
            </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-checkbox class="mb-24" (change)="changeInTheSameDay($event)" [(ngModel)]="inTheSameDay"
                        formControlName="inTheSameDay">
                        {{'SERVICE_DETAILS_POPUP.inTheSameDay' | translate}}
                    </mat-checkbox>
                </div>
               
                <div fxLayout="row" fxFlex="1 0 auto" style="justify-content: space-between;">
                    <button mat-button matStepperPrevious type="button">Back</button>
                    <button mat-button matStepperNext type="button">Next</button>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel></ng-template>
                <div style="margin-top: 25px;">
                    <mat-checkbox class="mb-24" style="display: block;"  [(ngModel)]="data.isDisabled"
                        formControlName="isDisabled">
                        {{'SERVICE_DETAILS_POPUP.isDisabled' | translate}}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-form-field *ngIf="data.isDisabled" appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="100">
                        <mat-label> {{'SERVICE_DETAILS_POPUP.disableMessage' | translate}} </mat-label>
                        <input matInput formControlName="disableMessage"  [ngModelOptions]="{standalone: true}" [(ngModel)]="data.disableMessage" required>
                    </mat-form-field> 
                    
                </div>

            <!-- Dropdown for locationServiceProfile -->
<div fxLayout="column" fxLayoutAlign="start Left" fxFlex="1 0 auto">
    <mat-form-field appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="100">
        <mat-label>{{'SERVICE_DETAILS_POPUP.serviceProfile' | translate}}</mat-label>
        <mat-select formControlName="locationServiceProfile" [(ngModel)]="data.locationServiceProfile">
            <mat-option [value]="0">{{'SERVICE_DETAILS_POPUP.appointment' | translate}}</mat-option>
            <mat-option [value]="1">{{'SERVICE_DETAILS_POPUP.instantTicket' | translate}}</mat-option>
            <mat-option [value]="2">{{'SERVICE_DETAILS_POPUP.url' | translate}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<!-- Wrapper div with fxLayout column to ensure vertical alignment -->
<div fxLayout="column" fxLayoutAlign="start start" *ngIf="data.locationServiceProfile == 2">
    <!-- URL input -->
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="100">
            <mat-label>{{'SERVICE_DETAILS_POPUP.textUrl' | translate}}</mat-label>
            <input matInput formControlName="url" [(ngModel)]="data.url" required>
        </mat-form-field>
    </div>

    <!-- InAppURL checkbox -->
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-checkbox class="pl-sm-8" formControlName="inAppURL" [(ngModel)]="data.inAppURL">
            {{'SERVICE_DETAILS_POPUP.inAppUrl' | translate}}
        </mat-checkbox>
    </div>
</div>



    
    
                <div mat-dialog-actions fxLayout="row" fxFlex="1 0 auto" style="justify-content: space-between;">
                    <button mat-button matStepperPrevious type="button">Back</button>
                    <div class="d-flex">
                        <button mat-button (click)="cancelClick()"> {{'SERVICE_DETAILS_POPUP.cancel' | translate}} </button>
                        <button mat-button cdkFocusInitial (click)="save()"> {{'SERVICE_DETAILS_POPUP.ok' | translate}} </button>
                    </div>
                </div> 
            </mat-step>
        </mat-horizontal-stepper>
    </form>
</div>
