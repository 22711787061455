import { AutoCompleteModule } from '@syncfusion/ej2-angular-dropdowns';
import { MapsAllModule } from '@syncfusion/ej2-angular-maps';
import { ChartAllModule, AccumulationChartAllModule, RangeNavigatorAllModule } from '@syncfusion/ej2-angular-charts';
import { TextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { SidebarModule } from '@syncfusion/ej2-angular-navigations';
import { CheckBoxModule, ButtonAllModule} from '@syncfusion/ej2-angular-buttons';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './dynamic-dashboard.component';
import { FuseConfigService } from '@fuse/services/config.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
const routes = [
    {
        path     : 'dynamic-dashboad',
        component: AppComponent,
        // resolve  : {
        //     profile: DynamicDashboardService
        // }

    }
];

@NgModule({ declarations: [ AppComponent ], 
    imports: [
    RouterModule.forChild(routes),

    NumericTextBoxModule,
     MapsAllModule,
     AutoCompleteModule,
     MatCheckboxModule,
     TranslateModule,
     DashboardLayoutModule,
     BrowserAnimationsModule ,
     MatIconModule,
     CheckBoxModule,
     MatTooltipModule,
     SidebarModule,
     ButtonAllModule,
     TextBoxModule, 
     ChartAllModule, 
     AccumulationChartAllModule,
     RangeNavigatorAllModule,
     BrowserModule], 
    providers: [FuseConfigService], bootstrap: [AppComponent]
})
export class DynamicDashboardModule
{
}
