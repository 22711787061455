import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { HomeComponent } from './home.component';
import { TenantComponent } from 'app/modules/branches-management/tenant/tenant-list/tenant-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


// const routes = [
//     {
//         path     : '',
//         component: HomeComponent
//     },  

// ];

@NgModule({
    declarations: [
        HomeComponent
    ],
    imports     : [
        BrowserAnimationsModule,
        // RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule
    ],
    exports     : [
        HomeComponent
    ]
})

export class HomeModule
{
}
