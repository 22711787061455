<div id="publicHolidays" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    account_balance
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    {{'PublicHoliday_LIST.PublicHolidays' | translate}}
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon> {{'search' | translate}}</mat-icon>
                    <input (keyup)="getPublicHolidaysBySearch($event.target.value)" placeholder="{{'PublicHoliday_LIST.searchForPublicHoliday' | translate}}">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <button mat-raised-button [routerLink]="'/public-holiday/add'"
                class="add-publicHoliday-button fuse-white mt-24 mt-md-0">
                <span>{{'PublicHoliday_LIST.addNewPublicHoliday' | translate}}</span>
            </button>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">
            <div class="list-skeleton" *ngIf="loaderService.isLoading">
                <ngx-skeleton-loader
                    [theme]="{ width: '100%', height: '35px'}"
                    animation="progress"
                    count="10">
                </ngx-skeleton-loader>
            </div>
            <mat-table *ngIf="!loaderService.isLoading" class="publicHolidays-table custom-table" #table [dataSource]="dataSource" matSort [@animateStagger]="{value:'50'}"
                fusePerfectScrollbar>

                <!-- ID Column -->
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'PublicHoliday_LIST.id' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let publicHoliday"  [routerLink]="'/public-holiday/edit/' + publicHoliday.id">
                        <p class="text-truncate">{{publicHoliday.id}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'PublicHoliday_LIST.name' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let publicHoliday" [routerLink]="'/public-holiday/edit/' + publicHoliday.id">
                        <p class="text-truncate">{{publicHoliday.name}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'PublicHoliday_LIST.actions' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let publicHoliday">
                        <mat-icon class="logo-icon mx-1" (click)="edit(publicHoliday.id)" 
                            [@animate]="{value:'*',params:{delay:'200ms',scale:'0.4'}}"
                            matTooltip="{{'PublicHoliday_LIST.editPublicHoliday' | translate}}">
                            edit
                        </mat-icon>

                        <mat-icon class="logo-icon mx-1" (click)="deletePublicHolidays(publicHoliday)"
                            [@animate]="{value:'*',params:{delay:'275ms',scale:'0.6'}}"
                            matTooltip="{{'PublicHoliday_LIST.deletePublicHoliday' | translate}}">
                            delete_forever
                        </mat-icon>

                    </mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let publicHoliday; columns: displayedColumns;" class="publicHoliday" matRipple>
                    <!-- [routerLink]="'/publicHoliday/add/' + publicHoliday.id" -->
                </mat-row>

            </mat-table>

            <mat-paginator 
            [length]="length"
            [pageIndex]="pageIndex - 1"
            [pageSize]="pageSize"
            (page)='getPublicHolidaysBypagenation($event)'
            [pageSizeOptions]="pageSizeOptions">
            </mat-paginator>
            

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>