import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AgmCoreModule } from '@agm/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { KeypadEntryComponent } from './keypad-entry/keypad-entry.component';
import { KeypadService } from './keypad.service';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { NoShowDialogComponent } from './no-show-dialog/no-show-dialog.component';
import { HoldDialogComponent } from './hold-dialog/hold-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const routes = [
  {
    path: 'keypad',
    component: KeypadEntryComponent
  }
];
@NgModule({

  declarations: [
    KeypadEntryComponent,
    NoShowDialogComponent,
    HoldDialogComponent
  ],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
    }),
    RouterModule.forChild(routes),
    TranslateModule,
    CommonModule,
    FuseSharedModule,
    FuseWidgetModule,
    MatMenuModule,
    MatDialogModule,
    BrowserAnimationsModule
  ],
  providers: [
    KeypadService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],

})
export class KeypadModule { }
