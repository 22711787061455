import { Time } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { validateBasis } from '@angular/flex-layout';
import { FormBuilder, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { LocationService } from 'app/modules/branches-management/location/location.service';
import { KeypadService } from 'app/modules/keypad/keypad.service';
import { StorageManager } from 'app/shared/utilities/storage-manager';
import { UserService } from 'app/modules/branches-management/user/user.service';
import { ServiceService } from 'app/modules/branches-management/service/service.service';
import { MatStepper } from '@angular/material/stepper';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
@Component({
  selector: 'app-complete-keypad-popup',
  templateUrl: './complete-keypad-popup.component.html',
  styleUrls: ['./complete-keypad-popup.component.scss']
})
export class CompleteKeypadPopupComponent implements OnInit {
  form: FormGroup;

  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  
  ngOnInit(): void {

    this.form = this._formBuilder.group({
      TicketId: [{ value: this.data.TicketId }],
      TransNumber: [1, Validators.required],      
    });
    
  }


  constructor(
    private _formBuilder: FormBuilder,
    private _matSnackBar: MatSnackBar,
    private keypadService: KeypadService,
    public dialogRef: MatDialogRef<CompleteKeypadPopupComponent>,
    private _matDialog: MatDialog,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  cancelClick(): void {
    this.dialogRef.close("canceled");
  }

  close() {
    this.dialogRef.close();
  }

  confirm(){
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
  });
  this.confirmDialogRef.componentInstance.confirmMessage = this.translate.instant('KEYPAD.areYouSureYouWantToConfirm');

  this.confirmDialogRef.afterClosed().subscribe(result => {
    if (result) {
      this.keypadService.complete(this.data.TicketId, this.form.get("TransNumber").value)
      .then(() => {
        this._matSnackBar.open(this.translate.instant('KEYPAD.confirmSuccess'), 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
      });
    this.dialogRef.close("saved");
    }
})

  }

}

export interface DialogData {
  TicketId: number;
  CounterNumber: number;
}
