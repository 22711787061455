import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrls } from 'app/shared/constants/apiUrls';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FeedBackService {
  ticketDetails:any;
  tenantDetails :any;

   /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient )
  {}

  
  addFeedback(feedback): Promise<any>{
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl3 + apiUrls.feedback, feedback)
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }
  getTiketDetails(tiketId,isDefaultLanguage = false): Promise<any>{
    return new Promise((resolve, reject) => {

      let headers: HttpHeaders = new HttpHeaders();
      if(isDefaultLanguage){
          headers = headers.append("language-ar" , "true");
      }
   
      this._httpClient.get(environment.serviceUrl3 + apiUrls.getTiket + tiketId, {headers : headers})
          .subscribe((response: any) => {
              this.ticketDetails = response;
              resolve(response);
          }, reject);
  });
}
getTenantDetails(tenantIId,isDefaultLanguage = false): Promise<any>{
  return new Promise((resolve, reject) => {
    let headers: HttpHeaders = new HttpHeaders();
    if(isDefaultLanguage){
        headers = headers.append("language-ar" , "true");
    }
 
    this._httpClient.get(environment.serviceUrl3 + apiUrls.tenant + tenantIId, {headers : headers})
        .subscribe((response: any) => {
            this.tenantDetails = response;
            resolve(response);
        }, reject);
});

}
}
