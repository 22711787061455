import { FuseUtils } from "@fuse/utils";

export class Tenant {
    constructor(tenant?)
    {
        tenant = tenant || {};
        this.id = tenant.id || 0;//FuseUtils.generateGUID();
        this.name = tenant.name || '';
        this.email = tenant.email || '' ;
        this.logo = tenant.email || '' ;
    }
    id : string;    
    name: string;
    email: string;    
    categoryId :string;
    logo:string;
}