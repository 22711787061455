<div id="tenant" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/tenant'">
                    <mat-icon>{{'arrow_back' | translate}}</mat-icon>
                </button>


                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="pageType ==='edit'">
                        <!-- {{tenunt.name}} -->
                    </div>
                    <div class="h2" *ngIf="pageType ==='new'">
                        {{'TENANT_ENTRY.newTenant' | translate}}
                    </div>
                    <div class="subtitle secondary-text">
                        <span>{{'TENANT_ENTRY.tenantDetails' | translate}}</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->
            <!-- [disabled]="tenantForm.invalid" -->
            <div class="button-container">
                <mat-spinner *ngIf="isLoading" strokeWidth="2" [diameter]="25"></mat-spinner>
                <button mat-raised-button class="save-tenant-button" [disabled]="!isValidForm() || isLoading"
                    *ngIf="pageType ==='new'" (click)="addTenant()">
                    <span>{{'TENANT_ENTRY.add' | translate}}</span>
                </button>

                <button mat-raised-button class="save-tenant-button"
                    [disabled]="!isValidForm() || isLoading" *ngIf="pageType ==='edit'"
                    (click)="saveTenant()">
                    <span>{{'TENANT_ENTRY.save' | translate}}</span>
                </button>
            </div>

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="tenantForm" [formGroup]="tenantForm" class="tenant w-100-p" fxLayout="column" fxFlex>
                    <div class="tab-content" fusePerfectScrollbar>

                        <div style="width:100% !important">
                            <div class="d-flex">
                                <div style="width:20%" *ngIf="tenant.logo" class="p-24">
                                    <input hidden src="tenant.logo" type="file" #fileInput [accept]="allowedFiles"
                                         (change)="uploadIcon($event , tenant.id )" />
                                    <img   src="{{'data:image/jpg;base64,' + tenant.logo}}" (click)="fileInput.click()"   style=" width: 80%;" />
                                    <!-- assets//images//backgrounds/default-upload-image.jpg-->
    
                                </div>
                                <div style="width:20%" *ngIf="!tenant.logo" class="p-24">
                                    <input hidden src="tenant.logo" type="file" #fileInput [accept]="allowedFiles"
                                         (change)="uploadIcon($event , tenant.id )" />
                                    <img   src="../../../../assets/images/backgrounds/default-upload-image.jpg" (click)="fileInput.click()"   style=" width: 80%;" />
                                    <!-- assets//images//backgrounds/default-upload-image.jpg-->
    
                                </div>
    
                                <div style="width: 80%;">
                                    <name-language-editer [data]="tenant.name" #nameLanguageEditor></name-language-editer>
                                </div>
                            </div>
                
                        </div>

                    
                        <!-- <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                            <mat-label>{{'TENANT_ENTRY.tenantName' | translate}}</mat-label>
                            <input matInput placeholder="Tenant Name" name="name" formControlName="name" required>
                        </mat-form-field> -->
                        <div class="p-24">
                            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                <mat-label>{{'TENANT_ENTRY.tenantEmail' | translate}}</mat-label>
                                <input matInput placeholder="Example@keylife.com" name="email" formControlName="email"
                                    required>
                                <mat-error *ngIf="tenantForm.get('email').hasError('email')">
                                    {{'TENANT_ENTRY.enterValidEmail' | translate}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                <mat-label>{{'TENANT_ENTRY.category' | translate}}</mat-label>
                                <mat-select formControlName="categoryId" required>
                                    <mat-option *ngFor="let Category of Categories" [value]="Category?.id">
                                        {{Category?.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-icon matSuffix class="secondary-text">sort</mat-icon>
                                <mat-error>{{'TENANT_ENTRY.categoryRequired' | translate}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>