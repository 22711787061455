import { FuseUtils } from "@fuse/utils";

export class Category {
    constructor(category?)
    {
        category = category || {};
        this.id = category.id || 0;//FuseUtils.generateGUID();
        this.name = category.name || '';
        this.icon = category.icon || '';
    }
    id : string;    
    name: string;
    icon: string;
}