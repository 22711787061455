import { FuseUtils } from "@fuse/utils";

export class Workflow {
    constructor(workflow?)
    {
        workflow = workflow || {};
        this.id = workflow.id || 0;//FuseUtils.generateGUID();
        this.name = workflow.name || '';
     
    }
    id : string;    
    name: string;
}