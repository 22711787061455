




<div id="workflow" class="page-layout carded fullwidth inner-scroll">

  <!-- TOP BACKGROUND -->
  <div class="top-bg accent"></div>
  <!-- / TOP BACKGROUND -->

  <!-- CENTER -->
  <div class="center">

      <!-- HEADER -->
      <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

          <!-- APP TITLE -->
          <div fxLayout="row" fxLayoutAlign="start center">

              <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/workflow'">
                  <mat-icon>{{'arrow_back' | translate}}</mat-icon>
              </button>


              <div fxLayout="column" fxLayoutAlign="start start"
                  [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                  <div class="h2" *ngIf="pageType ==='edit'">
                      <!-- {{tenunt.name}} -->
                  </div>
                  <div class="h2" *ngIf="pageType ==='new'">
                      {{'WORKFLOW.newworkflow' | translate}}
                  </div>
                  <div class="subtitle secondary-text">
                      <span> {{'WORKFLOW.WorkflowDetail' | translate}}</span>
                  </div>
              </div>
          </div>
          <!-- / APP TITLE -->
          <!-- [disabled]="categoryForm.invalid" -->
          <div class="button-container">
              <mat-spinner *ngIf="isLoading" strokeWidth="2" [diameter]="25"></mat-spinner>
              <button mat-raised-button class="save-category-button" 
                  *ngIf="pageType ==='new'" (click)="addWorkflow()">
                  <span>{{'WORKFLOW.add' | translate}}</span>
              </button>
              <button mat-raised-button class="save-category-button"
              [disabled]="!isValidForm() || isLoading" *ngIf="pageType ==='edit'"
                  (click)="saveWorkflow()">
                  <span>{{'WORKFLOW.save' | translate}}</span>
              </button>
          </div>
      </div>
      <!-- / HEADER -->

      <!-- CONTENT CARD -->
      <div class="content-card">

          <!-- CONTENT -->
          <div class="content">
          
         
                <div class="diagram-parent">
                    <app-diagram [url]="diagramUrl" (importDone)="handleImported($event)"></app-diagram>
                  </div>
      
         
          </div>
          <!-- / CONTENT -->

      </div>
      <!-- / CONTENT CARD -->

  </div>
  <!-- / CENTER -->

</div>

