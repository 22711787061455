import { FuseUtils } from "@fuse/utils";

export class ResetPasswored {
    constructor(ResetPasswored?)
    {
        ResetPasswored = ResetPasswored || {};
       //  this.userId = ResetPasswored.userId || '';
        this.password = ResetPasswored.Password || '';
        this.confirmPassword = ResetPasswored.confirmPassword || '';
        this.email = ResetPasswored.email || '';
        this.token = ResetPasswored.token || '';
       
    }
   
  //  userId :string;
  password:string;
  confirmPassword: string; 
  email: string;
  token :string;
  
}