<div id="permission" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->
    <!-- CENTER -->
    <div class="center">
        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">
            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">
                <button mat-icon-button class="mr-0 mr-sm-16" (click)="goBack()">
                    <mat-icon>{{'arrow_back' | translate}}</mat-icon>                </button>
                <div fxLayout="column" fxLayoutAlign="start start">
                    <div class="subtitle secondary-text">
                        <span>{{'ASSIGN_Permission_TO_Role.addPermissionToRole' | translate}}</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->
            <!-- [disabled]="serviceForm.invalid" -->

            <!-- <button mat-raised-button class="save-service-button"
                *ngIf="ChangeStatus"
                (click)="saveService()">
                <span>SAVE</span>
            </button> -->
        </div>
        <!-- / HEADER -->
        <!-- CONTENT CARD -->
        <div cdk-scrollable class="content-card">
            <!-- CONTENT -->
            <div cdk-scrollable class="content p-24">

                <div cdkDropListGroup>
                    <div class="container ">
                        <h2>{{'ASSIGN_Permission_TO_Role.permissionavailable' | translate}} </h2>
                        <div cdkDropList [cdkDropListData]="AllPermissions" class="list"
                            cdkDropListSortingDisabled (cdkDropListDropped)="drop($event)"
                            (cdkDropListEntered)="openDialog(false , $event)">
                            <div [name]="item.id + '/'+item.name" class="box"
                                *ngFor="let item of AllPermissions" cdkDrag>{{item.name}}</div>
                        </div>
                    </div>

                    <div class="container">
                        <h2>{{'ASSIGN_Permission_TO_Role.rolepermission' | translate}}</h2>
                        <div cdkDropList [cdkDropListData]="RolePermissions" class="list"
                            (cdkDropListEntered)="openDialog(true , $event)" (cdkDropListDropped)="drop($event)">
                            <div [name]="item.applicationPermission.id + '/'+item.name" class="box"
                                *ngFor="let item of RolePermissions" cdkDrag>{{item?.applicationPermission?.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- / CONTENT -->
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>