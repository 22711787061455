import { FuseUtils } from "@fuse/utils";

export class DynamicDashbord {
    constructor(dynamicDashbord?)
    {
        dynamicDashbord = dynamicDashbord || {};
        //this.id = feedback.id || 0;//FuseUtils.generateGUID();
        this.event = dynamicDashbord.event || '';
        this.apiMethode = dynamicDashbord.apiMethode || '';
        this.url = dynamicDashbord.url || '' ;
        this.key = dynamicDashbord.key || '' ;
        this.value = dynamicDashbord.value || '' ;
        this.body = dynamicDashbord.body || '' ;
    }
    //id : string;    
    event: string;
    url: string;    
    key :string;
    value:string;
    apiMethode : string;
    body : string
}