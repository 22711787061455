import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ResetPasswored } from 'app/model/ResetPasswored';
import { User } from 'app/model/User';
import { apiUrls } from 'app/shared/constants/apiUrls';
import { StorageManager } from 'app/shared/utilities/storage-manager';
import { environment } from 'environments/environment';
import { Http2ServerRequest } from 'http2';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AccountService implements Resolve<any>{
    users: User[] = new Array<User>();
    resetPasswored: ResetPasswored[] = new Array<ResetPasswored>();
    
    user: User = new User();
    onUserChanged: BehaviorSubject<any>;
    userDetails :any;
    roleName : string;

    /**
      * Constructor
      *
      * @param {HttpClient} _httpClient
      */
    constructor(
        private _httpClient: HttpClient
    ) {
        // Set the defaults
        this.onUserChanged = new BehaviorSubject({});
    }

    /**
      * Resolver
      *
      * @param {ActivatedRouteSnapshot} route
      * @param {RouterStateSnapshot} state
      * @returns {Observable<any> | Promise<any> | any}
      */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getAllUsers()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }


    getAllUsers(): Observable<any> {
        return this._httpClient.get(environment.serviceUrl3 + apiUrls.user)
    }

    getUsersTenant(tenantId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.user + '/Tenant/' + tenantId)
                .subscribe((response: any) => {
                    this.users = response;
                    this.onUserChanged.next(this.users);
                    resolve(response);
                }, reject);
        });
    }

    getUsersLocation(locationId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.user + '/Location/' + locationId)
                .subscribe((response: any) => {
                    this.users = response;
                    this.onUserChanged.next(this.users);
                    resolve(response);
                }, reject);
        });
    }

    getUserById(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.user + '/' + id)
                .subscribe((response: any) => {
                    this.user = response;
                    this.onUserChanged.next(this.user);
                    resolve(response);
                }, reject);
        });
    }


    // Delete user
    //  deleteUser(id): Promise<any>
    //  {
    //      return new Promise((resolve, reject) => {
    //          this._httpClient.delete(environment.serviceUrl3 + apiUrls.user + id )
    //              .subscribe((response: any) => {
    //                  resolve(response);
    //              }, reject);
    //      });
    //  }

    saveUser(user): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(environment.serviceUrl3 + apiUrls.user, user)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addUser(user): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.serviceUrl3 + apiUrls.Accounts + '/register', user)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getUserServices(userId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.UserServices + '/User/' + userId)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    AddUserService(locationServicesId, applicationUserId): Promise<any> {
        var user = { locationServicesId: locationServicesId, applicationUserId: applicationUserId }
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.serviceUrl3 + apiUrls.UserServices, user)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

     deleteUserService(userServiceId): Promise<any>
     {
         return new Promise((resolve, reject) => {
             this._httpClient.delete(environment.serviceUrl3 + apiUrls.UserServices + "?id=" + userServiceId)
                 .subscribe((response: any) => {
                     resolve(response);
                 }, reject);
         });
     }

     getUsersDetails(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.user + '/Details')
                .subscribe((response: any) => {
                    this.users = response;
                    this.roleName =  response?.applicationRoles?.name;
                    this.onUserChanged.next(this.users);
                    resolve(response);
                }, reject);
        });
    }

    forgotpassword(user :object): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.serviceUrl3+apiUrls.Accounts+'/forgotpassword', user)
                .subscribe((response: any) => {
                    this.users = response;
                    this.onUserChanged.next(this.users);
                    resolve(response);
                }, reject);
        });
    }
    
    resetpassword(resetPasswored :object): Promise<any> {
        return new Promise((resolve, reject) => {
           this._httpClient.post(environment.serviceUrl3+apiUrls.Accounts+'/resetpassword', resetPasswored)
                .subscribe((response: any) => {
                    this.resetPasswored = response;
                    this.onUserChanged.next(this.resetPasswored);
                    resolve(response);
                }, reject);
        });
    }

}
