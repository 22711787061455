import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { FloatLabelType } from '@syncfusion/ej2-angular-inputs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DynamicDashbord } from 'app/model/DynamicDashbord';

@Component({
  selector: 'app-call-api',
  templateUrl: './call-api.component.html',
  styleUrls: ['./call-api.component.scss']
})
export class CallApiComponent implements OnInit {

  url:any;
  apiMethode:any;
  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl('auto' as FloatLabelType);
  options = this._formBuilder.group({
    hideRequired: this.hideRequiredControl,
    floatLabel: this.floatLabelControl,
  });
  callApiForm:FormGroup;
  form: FormGroup;

  rowCounter: any[] = [];
  methode: string[] = ['Post', 'Put', 'Get' , 'Delete'];
  selectedFW = new FormControl();

  constructor(private _fuseConfigService : FuseConfigService,private _formBuilder: FormBuilder ,
    public dialogRef: MatDialogRef<CallApiComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DynamicDashbord) {

  //   this._fuseConfigService.config = {
  //     layout: {
  //         navbar   : {
  //             hidden: true
  //         },
  //         toolbar  : {
  //             hidden: true
  //         },
  //         footer   : {
  //             hidden: true
  //         },
  //         sidepanel: {
  //             hidden: true
  //         }
  //     }
  // };
   }

  ngOnInit(): void {

    this.callApiForm = new FormGroup({
      apiMethode: new FormControl(this.data.apiMethode),
      url: new FormControl(this.data.url),
      key: new FormControl(this.data.key),
      value: new FormControl(this.data.value),
      body: new FormControl(this.data.body),
    });
  }


  getFloatLabelValue(): FloatLabelType {
    return this.floatLabelControl.value || 'auto';
  }

  onCancelUserDialog(): void {
    this.dialogRef.close();
  }

  addRow(){
     this.rowCounter.push(1);
    console.log(this.rowCounter);
  }

  deleteRow(){
    this.rowCounter.pop();
   console.log(this.rowCounter);
 }

 sendRequest()
 {
  this.dialogRef.close();
 }

 ok()
 {
  this.dialogRef.close();
 }

}
