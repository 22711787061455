import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Service } from 'app/model/Service';
import { apiUrls } from 'app/shared/constants/apiUrls';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceService implements Resolve<any>{
 
  services : Service[] = new Array<Service>();
  service : Service  =   new Service() ;
  onServiceChanged: BehaviorSubject<any>;
  currentPage: number = 1;
  pageSize : number = 10;

   /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
      private _httpClient: HttpClient
  )
  {
      // Set the defaults
      this.onServiceChanged = new BehaviorSubject({});
  }

   /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getServicesWithoutPagenation()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

  
  getAllServices() :Observable<any>{
   return this._httpClient.get(environment.serviceUrl3 + apiUrls.service )
  }

  getServicesWithoutPagenation(): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.service)
              .subscribe((response: any) => {
                  this.services = response;
                  this.onServiceChanged.next(this.services);
                  resolve(response);
              }, reject);
      });
  }
  
  getServices(pageIndex ,pageSize): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.service + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize)
              .subscribe((response: any) => {
                  this.services = response;
                  this.onServiceChanged.next(this.services);
                  resolve(response);
              }, reject);
      });
  }

  getServicesByFilter(pageIndex ,pageSize,search): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.service + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize + '&search=' + search)
              .subscribe((response: any) => {
                  this.services = response;
                  this.onServiceChanged.next(this.services);
                  resolve(response);
              }, reject);
      });
  }

  getServicByTenant(tenantId :string , pageIndex ,pageSize): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.service + tenantId + "/" + apiUrls.tenant + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize)
              .subscribe((response: any) => {
                  this.services = response;
                  this.onServiceChanged.next(this.service);
                  resolve(response);
              }, reject);
      });
  }

  getServicByTenantWithoutPagination(tenantId :string ): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.service + tenantId + "/" + apiUrls.tenant)
              .subscribe((response: any) => {
                  this.services = response;
                  this.onServiceChanged.next(this.service);
                  resolve(response);
              }, reject);
      });
  }
  getServicsByLocation(locationId :string): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.location + locationId + "/" + apiUrls.services + "?isValidate=" + false   )
              .subscribe((response: any) => {
                  this.services = response;
                  this.onServiceChanged.next(this.service);
                  resolve(response);
              }, reject);
      });
  }

  getServiceById(id,isDefaultLanguage = false): Promise<any>
  {
      return new Promise((resolve, reject) => {
        let headers: HttpHeaders = new HttpHeaders();
        if(isDefaultLanguage){
            headers = headers.append("without-accept-language" , "true");
        }
          this._httpClient.get(environment.serviceUrl3 + apiUrls.service +  id , {headers : headers}  )
              .subscribe((response: any) => {
                  this.service = response;
                  this.onServiceChanged.next(this.service);
                  resolve(response);
              }, reject);
      });
  }

  
 // Delete service
 deleteService(id): Promise<any>
 {
     return new Promise((resolve, reject) => {
         this._httpClient.delete(environment.serviceUrl3 + apiUrls.service + id )
             .subscribe((response: any) => {
                 resolve(response);
             }, reject);
     });
 }

   // Update Service 
   saveService(service): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put(environment.serviceUrl3 + apiUrls.service , service)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  addService(service): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl3 + apiUrls.service, service )
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }
  deleteLocationService(locationServiceId): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.delete(environment.serviceUrl3 + apiUrls.location + locationServiceId +  '/services' )
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }
}
