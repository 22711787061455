<div id="publicHoliday" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/public-holiday'">
                    <mat-icon>{{'arrow_back' | translate}}</mat-icon>
                </button>


                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="pageType ==='edit'">
                        <!-- {{tenunt.name}} -->
                    </div>
                    <div class="h2" *ngIf="pageType ==='new'">
                        {{'PublicHoliday_ENTRY.newPublicHoliday' | translate}}
                    </div>
                    <div class="subtitle secondary-text">
                        <span> {{'PublicHoliday_ENTRY.PublicHolidayDetail' | translate}}</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->
            <div class="button-container">
                <mat-spinner *ngIf="isLoading" strokeWidth="2" [diameter]="25"></mat-spinner>
                <button mat-raised-button class="save-publicHoliday-button" [disabled]="publicHolidayForm.status == 'INVALID' || isLoading"
                    *ngIf="pageType ==='new'" (click)="addPublicHoliday()">
                    <span>{{'PublicHoliday_ENTRY.add' | translate}}</span>
                </button>

                <button mat-raised-button class="save-publicHoliday-button"
                    [disabled]="(publicHolidayForm.status == 'INVALID' && publicHolidayForm.pristine) || isLoading" *ngIf="pageType ==='edit'"
                    (click)="savePublicHoliday()">
                    <span>{{'PublicHoliday_ENTRY.save' | translate}}</span>
                </button>
            </div>        
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="publicHolidayForm" [formGroup]="publicHolidayForm" class="publicHoliday w-100-p" fxLayout="column" >
                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                            <mat-label>{{'PublicHoliday_ENTRY.PublicHolidayName' | translate}}</mat-label>
                            <input matInput placeholder="{{'PublicHoliday_ENTRY.PublicHolidayName' | translate}}" name="name"
                                formControlName="name" required>
                        </mat-form-field>

                    </div>
                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <mat-form-field appearance="outline" floatLabel="always"class="w-100-p">
                            <mat-label>{{'PublicHoliday_ENTRY.startDate' | translate}} </mat-label>
                            <input matInput type="date"  [(ngModel)]="publicHoliday.startDate" formControlName="startDate" required>
                            <mat-datepicker #pickerFrom></mat-datepicker>
                        </mat-form-field>

                    </div>
                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                            <mat-label>{{'PublicHoliday_ENTRY.endDate' | translate}} </mat-label>
                            <input matInput type="date"  [(ngModel)]="publicHoliday.endDate" formControlName="endDate" required>
                            <mat-datepicker #pickerFrom></mat-datepicker>
                        </mat-form-field>

                    </div>

                </form>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>