import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { AgmCoreModule } from '@agm/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { MatDialogModule } from '@angular/material/dialog';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { CategoryComponent } from './category-list/category-list.component';
import { CategoryEntryComponent } from './category-entry/category-entry.component';
import { CategoryService } from './category.service';
import { TranslateModule } from '@ngx-translate/core';
import { NameLanguageEditerModule } from 'app/component/name-language-editer/name-language-editer.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SoltTimeEditorModule } from 'app/component/slot-time-editor/slot-time-editor.module';
import { PrerequisiteEditerModule } from 'app/component/prerequisite_editer/prerequisite-editer.module';


const routes = [
  {
      path     : 'category',
      component: CategoryComponent
  },
  {
    path     : 'category/add',
    component: CategoryEntryComponent
  },
  {
    path: 'category/edit/:id',
    component: CategoryEntryComponent
  }
];
@NgModule({
  
  declarations: [
    CategoryComponent,
    CategoryEntryComponent   
  ],
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatDialogModule,
    AgmCoreModule.forRoot({
        apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
    }),
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    FuseWidgetModule,
    DragDropModule,
    NameLanguageEditerModule,
    PrerequisiteEditerModule,
    NgxSkeletonLoaderModule,
    MatProgressSpinnerModule
   
  ],
  providers:[
    CategoryService     
  ]
  
})
export class CategoryModule { }
