import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AdsMedia } from 'app/model/AdsMedia';
import { Advertisement } from 'app/model/Advertisement';
import { Service } from 'app/model/Service';
import { apiUrls } from 'app/shared/constants/apiUrls';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdvertisementService implements Resolve<any>{
 
    advertisements : Advertisement[] = new Array<Advertisement>();
    advertisement : Advertisement  =   new Advertisement() ;

    AdsMedias : AdsMedia[] = new Array<AdsMedia>();
    AdsMedia : AdsMedia  =   new AdsMedia() ;
  onServiceChanged: BehaviorSubject<any>;

   /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
      private _httpClient: HttpClient
  )
  {
      // Set the defaults
      this.onServiceChanged = new BehaviorSubject({});
  }

   /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getServices()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

  
  getAllServices() :Observable<any>{
   return this._httpClient.get(environment.serviceUrl + apiUrls.service )
  }
  
  getServices(): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl + apiUrls.service)
              .subscribe((response: any) => {
                  this.advertisements = response;
                  this.onServiceChanged.next(this.advertisements);
                  resolve(response);
              }, reject);
      });
  }

  getMediaGroup(tenantId :string): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl4 + apiUrls.mediaItem + "/" +apiUrls.mediaGroup + "?tenantId=" + tenantId )
              .subscribe((response: any) => {
                  this.advertisements = response;
                  this.onServiceChanged.next(this.advertisement);
                  resolve(response);
              }, reject);
      });
  }
  getMediaItem(id :string): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl4 + apiUrls.mediaItem + "/" +apiUrls.mediaItem + "?groupId=" + id )
              .subscribe((response: any) => {
                  this.advertisements = response;
                  this.onServiceChanged.next(this.advertisement);
                  resolve(response);
              }, reject);
      });
  }
  downloadMedia(id): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl4 + apiUrls.mediaItem + "/" +apiUrls.downloadmediaitem + "?mediaItemId=" + id )
              .subscribe((response: any) => {
                  this.advertisements = response;
                  this.onServiceChanged.next(this.advertisement);
                  resolve(response);                                                                                        
                  
              }, reject);
      });
  }
  getServicsByLocation(locationId :string): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl + apiUrls.location + locationId + "/" + apiUrls.services + "?isValidate=" + false   )
              .subscribe((response: any) => {
                  this.advertisements = response;
                  this.onServiceChanged.next(this.advertisement);
                  resolve(response);
              }, reject);
      });
  }

  getServiceById(id,isDefaultLanguage = false): Promise<any>
  {
      return new Promise((resolve, reject) => {
        let headers: HttpHeaders = new HttpHeaders();
        if(isDefaultLanguage){
            headers = headers.append("without-accept-language" , "true");
        }
          this._httpClient.get(environment.serviceUrl + apiUrls.service +  id , {headers : headers}  )
              .subscribe((response: any) => {
                  this.advertisement = response;
                  this.onServiceChanged.next(this.advertisement);
                  resolve(response);
              }, reject);
      });
  }

  
 // Delete service
 deleteService(id): Promise<any>
 {
     return new Promise((resolve, reject) => {
         this._httpClient.delete(environment.serviceUrl + apiUrls.service + id )
             .subscribe((response: any) => {
                 resolve(response);
             }, reject);
     });
 }

   // Update Service 
   saveService(service): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put(environment.serviceUrl + apiUrls.service , service)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  addAdvertisement(advertisement): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl4 + apiUrls.mediaItem + "/" + apiUrls.mediaGroup, advertisement )
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }

  addAdsmediaitem(adsmediaitem): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl4 + apiUrls.mediaItem + "/" + apiUrls.mediaItem, adsmediaitem )
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }

  updateAdsmediaitem(adsmediaitem): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.put(environment.serviceUrl4 + apiUrls.mediaItem + "/" + apiUrls.mediaItem, adsmediaitem )
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }
  addAdsmediaitemv(adsmediaitem): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl4 + apiUrls.mediaItem + "/" + apiUrls.mediaItem+"/"+"File", adsmediaitem )
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }
  deleteLocationService(locationServiceId): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.delete(environment.serviceUrl + apiUrls.location + locationServiceId +  '/services' )
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }

  getMediaItemById(id :string): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl4 + apiUrls.mediaItem + "/" +apiUrls.mediaItem+ "/"+id )
              .subscribe((response: any) => {
                  this.advertisements = response;
                  this.onServiceChanged.next(this.advertisement);
                  resolve(response);
              }, reject);
      });
  }
}
