import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MatPaginatorIntlCro extends MatPaginatorIntl {

    constructor(private translate: TranslateService) {
        super();
    }
    itemsPerPageLabel = this.translate.instant('itemsPerPage');
    nextPageLabel = this.translate.instant('nextPageLabel');
    previousPageLabel = this.translate.instant('Previous');

    getRangeLabel = function (page, pageSize, length) {
        let zero = '0';
        if (length === 0 || pageSize === 0) {
            return ' 0 / ' + this.translate.instant('of') + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + this.translate.instant('of') + length;
    };

}