<div id="login" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">

    <div id="login-intro" fxFlex fxHide fxShow.gt-xs>

        <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <img src="assets/images/logos/white-logo.png">
        </div>

        <div class="title" [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}">
            {{'LOGIN.welcomTOSaasQueue' | translate}}
        </div>

        <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
            
        </div>

        

    </div>

    <div id="login-form-wrapper" fusePerfectScrollbar
         [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div id="login-form">

            <div class="logo" fxHide.gt-xs>
                <img src="assets/images/logos/white-logo.png">
            </div>

            <div class="title"> {{'LOGIN.loginToAccount' | translate}}</div>

            <form (submit)="login()" name="loginForm" [formGroup]="loginForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>{{'LOGIN.userName' | translate}}</mat-label>
                    <input matInput formControlName="userName" [(ngModel)]="loginModel.Username">
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error *ngIf="loginForm.get('userName').hasError('required')">
                        {{'LOGIN.userNameRequired' | translate}}
                    </mat-error>
                 
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label> {{'LOGIN.password' | translate}}</mat-label>
                    <input matInput [type]="password" formControlName="password"  [(ngModel)]="loginModel.Password">
                    <mat-icon matSuffix class="secondary-text icon" (click)="showPassword()" >{{showHide}}</mat-icon>
                    <mat-error>
                         {{'LOGIN.passwordRequired' | translate}}
                    </mat-error>
                </mat-form-field>

                <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
                     fxLayoutAlign="space-between center">
                    <mat-checkbox class="remember-me" aria-label="Remember Me">
                         {{'LOGIN.rememberMe' | translate}}
                    </mat-checkbox>

                    <a class="forgot-password"  (click)="forgetpassword()"  style="cursor: hand;cursor: pointer;">
                         {{'LOGIN.forgetPassword' | translate}}
                    </a>
                </div>
                    
                <!-- routerLink="home"  -->
                <button mat-raised-button color="accent" (click)="login()" class="submit-button center" aria-label="LOGIN" 
                        [disabled]="loginForm.invalid || IsLoading">
                    <mat-spinner *ngIf="IsLoading" strokeWidth="10" [diameter]="40"></mat-spinner>
                    <p *ngIf="!IsLoading">  {{'LOGIN.login' | translate}}</p> 
                </button>
               
                
                

                <mat-error *ngIf="IsInvalidLogin">
                    {{'LOGIN.userNamePasswordInvalid' | translate}}
                 </mat-error>

            </form>

            <!-- <div class="separator">
                <span class="text"> {{'LOGIN.or' | translate}}</span>
            </div> -->

            <!-- <div fxLayout="column" fxLayoutAlign="start center">

                <button mat-raised-button class="google">
                     {{'LOGIN.loginWithGoogle' | translate}}
                </button>

                <button mat-raised-button class="facebook">
                     {{'LOGIN.loginWithFacebook' | translate}}
                </button>

            </div> -->

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text"> {{'LOGIN.dontHaveAccount' | translate}}</span>
                <a class="link" [routerLink]="'/pages/auth/register-2'"> {{'LOGIN.createAccount' | translate}}</a>
            </div>
        </div>
    </div>
</div>
