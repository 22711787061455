import { FuseUtils } from "@fuse/utils";
import { Tenant } from "./Tenant";

export class WorkingDay {
    constructor(workingDay?)
    {
        workingDay = workingDay || {};
        this.locationId = workingDay.locationId || 0;
        this.sunday = workingDay.sunday||false;
        this.monday = workingDay.monday||false;
        this.tuesday =  workingDay.tuesday||false;
        this.wednesday =   workingDay.wednesday||false;
        this.thursday = workingDay.thursday||false;
        this.friday =  workingDay.friday||false;
        this.saturday =  workingDay.saturday||false;
        this.isPublicHolidayApplied =  workingDay.isPublicHolidayApplied||false;

       

    }
    locationId : string;    
    sunday: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    isPublicHolidayApplied: boolean
    


}