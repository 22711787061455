import { FuseUtils } from "@fuse/utils";
import { Tenant } from "./Tenant";

export class Prerequisite {   
    constructor(Prerequisite?)
    {
        Prerequisite = Prerequisite || {};
        this.serviceId = Prerequisite.serviceId || 0;//FuseUtils.generateGUID();
        this.name = Prerequisite.name || '';
        this.description = Prerequisite.description || '' ;
        this.id = Prerequisite.id || '' ;
        this.url = Prerequisite.url || '';
        this.inAppURL = Prerequisite.inAppURL||false;
    }
    serviceId : string;    
    name: string;
    description: string;  
    id: string;  
    url: string;
    inAppURL:boolean;
}