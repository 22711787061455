import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {

  isLoading = false;

  constructor() {
  }

  show() {
     this.isLoading = true;
  }

  hide() {
     this.isLoading = false;
  }
}