import { Component, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';
import { KeypadService } from '../keypad.service';
// import { Keypad } from 'app/model/Keypad';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { StorageManager } from 'app/shared/utilities/storage-manager';
import { MatDialog } from '@angular/material/dialog';
import { CounterNumberCnfigrationPopupComponent } from 'app/modal/counter-number-configration-popup/counter-number-configration-popup.component';
import { TransferServiceToCounterPopupComponent } from 'app/modal/transfer-service-to-counter-popup/transfer-service-to-counter-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../../main/home/i18n/en';
import { locale as turkish } from '../../../main/home/i18n/tr';
import { locale as arabic } from '../../../main/home/i18n/ar';
import { NoShowDialogComponent } from '../no-show-dialog/no-show-dialog.component';
import { HoldDialogComponent } from '../hold-dialog/hold-dialog.component';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { openDatabase } from 'websql/custom';
import { DateTime } from '@syncfusion/ej2-angular-charts';
import { CompleteKeypadPopupComponent } from 'app/modal/complete-keypad-popup/complete-keypad-popup.component';
import { UserService } from 'app/modules/branches-management/user/user.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';



declare var window: any;
@Component({
  selector: 'app-keypad-entry',
  templateUrl: './keypad-entry.component.html',
  styleUrls: ['./keypad-entry.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class KeypadEntryComponent implements OnInit {

  //   keypad: Keypad;
  pageType: string;
    keypadForm: FormGroup;

  // Private
  private _unsubscribeAll: Subject<any>;
  CounterNumberConfigration: number;
  TicketId: any = null ;
  TicketNumber: any = this.translate.instant('KEYPAD.noTickets');
  customerPhoneNumber : any;
  _start: boolean = true;


  clock: any;
  minutes: any = '00';
  seconds: any = '00';
  milliseconds: any = '00';

  //  start: boolean;
  showTimerControls: boolean;
  ticketNumber: any;
  waitingTime: any;
  reset: NodeJS.Timeout;


  /**
  * Constructor
  *
  * @param {KeypadService} _keypadService
  * @param {FormBuilder} _formBuilder
  * @param {Location} _location
  * @param {MatSnackBar} _matSnackBar
  * @param {FuseNavigationService} _fuseNavigationService
  */
  constructor(
    private keypadService: KeypadService,
    private _formBuilder: FormBuilder,
    private _matSnackBar: MatSnackBar,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private userService: UserService,
    public _fuseNavigationService : FuseNavigationService

  ) {
    this.keypadService.isKeypadPage = true;
   
    // Set the default
    //   this.keypad = new Keypad();

    // Set the private defaults
    this.checkCounterNumberConfigration();
    this._unsubscribeAll = new Subject();
    this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);        // Set the private defaults
    
    this.userService.getUsersDetails().then(res => {
        this.userService.userDetails = res;        
    })
  }


  laps: any = [];
  counter: number = 10;
  timerRef;
  running: boolean = false;
  startText = 'Start';


  startTimer() {
    // const source = timer(0, Date.now());
    // const subscribe = source.subscribe(val => console.log(val));
    this.running = !this.running;
    if (this.running) {
      const startTime = Date.now() - (this.counter || 0);
      this.timerRef = setInterval(() => {
        this.counter = Date.now() - startTime;
        // console.log(Date.now());
        // console.log(startTime);
        // console.log(this.counter);
        this.milliseconds = Math.floor(Math.floor(this.counter % 1000) / 10).toFixed(0);
        this.minutes = Math.floor(this.counter / 60000);
        this.seconds = Math.floor(Math.floor(this.counter % 60000) / 1000).toFixed(0);
        if (Number(this.minutes) < 10) {
          this.minutes = '0' + this.minutes;
        } else {
          this.minutes = '' + this.minutes;
        }
        if (Number(this.milliseconds) < 10) {
          this.milliseconds = '0' + this.milliseconds;
        } else {
          this.milliseconds = '' + this.milliseconds;
        }
        if (Number(this.seconds) < 10) {
          this.seconds = '0' + this.seconds;
        } else {
          this.seconds = '' + this.seconds;
        }
      });
    } else {
      this.startText = 'Resume';
      clearInterval(this.timerRef);
    }
  }
  lapTimeSplit() {
    let lapTime = this.minutes + ':' + this.seconds + ':' + this.milliseconds;
    this.laps.push(lapTime);
  }

  clearTimer() {
    this.running = false;
    this.startText = 'Start';
    this.counter = undefined;
    this.milliseconds = '00',
      this.seconds = '00',
      this.minutes = '00';
    this.laps = [];
    clearInterval(this.timerRef);
    this.customerPhoneNumber = null;
  }



  checkCounterNumberConfigration() {
    if (localStorage.getItem("CounterNumberConfigration")) {
      if(Number(localStorage.getItem("CounterNumberConfigration")) > 0){ 
        this.CounterNumberConfigration = +localStorage.getItem("CounterNumberConfigration");
      }
      else{
        const dialogRef = this.dialog.open(CounterNumberCnfigrationPopupComponent, {
          width: '500px',
          data: { CounterNumber: this.CounterNumberConfigration }
        });
  
        dialogRef.afterClosed().subscribe(result => {
          if (result == "saved") {
            this._matSnackBar.open(this.translate.instant('KEYPAD.counterNumberSaved'), this.translate.instant('ok'), {
              verticalPosition: 'top',
              duration: 2000
            });
          }
          this.checkCounterNumberConfigration();
  
  
        });
      }
    
    }
    else {
      const dialogRef = this.dialog.open(CounterNumberCnfigrationPopupComponent, {
        width: '500px',
        data: { CounterNumber: this.CounterNumberConfigration }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == "saved") {
          this._matSnackBar.open(this.translate.instant('KEYPAD.counterNumberSaved'), this.translate.instant('ok'), {
            verticalPosition: 'top',
            duration: 2000
          });
        }
        this.checkCounterNumberConfigration();


      });
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.refresh()
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.keypadService.isKeypadPage = false
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.reset) {
      clearInterval(this.reset);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create keypad form
   *
   * @returns {FormGroup}
   */

  Call() {
      this.clearTimer();
      if(this.CounterNumberConfigration > 0){
        this.keypadService.call(this.CounterNumberConfigration).then(res => {
          if (res) {
            this.startTimer();
            console.log(res);
            this.TicketId = res.id
            this.ticketNumber  = res.ticketNumber
            this.TicketNumber = res.service.chr + " - " + res.ticketNumber;
            this.customerPhoneNumber = res.phone;
             this.waitingTime = res.waiting; 
    
          } else {
            this.TicketId = null;
            this.TicketNumber = this.translate.instant('KEYPAD.noTickets');
          }
        });
      }
      else{
        const dialogRef = this.dialog.open(CounterNumberCnfigrationPopupComponent, {
          width: '500px',
          data: { CounterNumber: this.CounterNumberConfigration }
        });
  
        dialogRef.afterClosed().subscribe(result => {
          if (result == "saved") {
            this._matSnackBar.open(this.translate.instant('KEYPAD.counterNumberSaved'), this.translate.instant('ok'), {
              verticalPosition: 'top',
              duration: 2000
            });
          }
          this.checkCounterNumberConfigration();
  
  
        });
      }
      
   
  }

  Recall() {
    if(this.TicketId){
      this.keypadService.Recall(this.TicketId).then(res => {
        console.log(res);
        this.startTimer();
        this.customerPhoneNumber = res.phone;
        this.ticketNumber  = res.ticketNumber
        this._matSnackBar.open(this.translate.instant('KEYPAD.recallSuccess'), this.translate.instant('ok'), {
          verticalPosition: 'top',
          duration: 2000
        });
      })
    }
    else{
      this._matSnackBar.open(this.translate.instant('KEYPAD.thereIsNoTicketCalled'), this.translate.instant('ok'), {
        verticalPosition: 'top',
        duration: 2000
      });
    }
   
  }

  EndSession() {
    if(this.TicketId){      
      this.keypadService.EndSession(this.TicketId).then(res => {
        console.log(res);
        this.clearTimer();
        this.TicketId = null;
        this.TicketNumber = this.translate.instant('KEYPAD.noTickets');
        this._matSnackBar.open(this.translate.instant('KEYPAD.endSessionSuccess'), this.translate.instant('ok'), {
          verticalPosition: 'top',
          duration: 2000
        });
      })
    }
    else{
      this._matSnackBar.open(this.translate.instant('KEYPAD.thereIsNoTicketCalled'), this.translate.instant('ok'), {
        verticalPosition: 'top',
        duration: 2000
      });
    }
   
  }
  CloseCounter() {
    this.clearTimer()

    this.keypadService.CloseCounter(this.CounterNumberConfigration).then(res => {
      console.log(res);

      this._matSnackBar.open(this.translate.instant('KEYPAD.closeCounterSuccess'), this.translate.instant('ok'), {
        verticalPosition: 'top',
        duration: 2000
      });
    })
  }
  Transfer() {
    if(this.TicketId)
    {
      const dialogRef = this.dialog.open(TransferServiceToCounterPopupComponent, {
        width: '80vw',
        height: '80vh',
        panelClass: 'custom-stepper',
        data: { TicketId: this.TicketId }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == "saved") {
          this.clearTimer();
          this.TicketId = null;
          this.TicketNumber = this.translate.instant('KEYPAD.noTickets');
  
        }
      })
    }    
    else{
      this._matSnackBar.open(this.translate.instant('KEYPAD.thereIsNoTicketCalled'), this.translate.instant('ok'), {
        verticalPosition: 'top',
        duration: 2000
      });
    }
  }

  start() {
    this._start = true;
  }

  clear() {
    this._start = false;
  }

  copyInputMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    // Show the success message
    this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.Copied'), this.translate.instant('CONFIRM_MSG.ok'), {
      verticalPosition: 'top',
      duration: 2000
    });
  }

 refresh(){ 
  this.reset = setInterval(() => {
   this.keypadService.refresh().then(x => {
    this.waitingTime = x;
   })
  }, 30000);
}

  openNoShowDialog(customerPhoneNumber , ticketNumber ,ticketId) {
    // if(ticketId){
    //   this.EndSession();
    //   const CreationDate = new Date();
    //   var NoShowDB= window.openDatabase("NotCompleatedTickits", '1.0', "My WebSQL Database", 2 * 1024 * 1024);
    //   NoShowDB.transaction((tx) => {
    //     //tx.executeSql("delete from HoldUsers where not CreationDate =" + CreationDate.toLocaleString())
    //     tx.executeSql("CREATE TABLE IF NOT EXISTS NoShowDBUsers (TicketId VARCHAR(100) ,  PhoneNumber VARCHAR(100),TicketNumber VARCHAR(100), CreationDate datetime) ");
    //     if(!customerPhoneNumber){
    //       tx.executeSql("INSERT INTO NoShowDBUsers (TicketId , PhoneNumber, TicketNumber,CreationDate ) values (?,?,?,?)",
    //       [JSON.stringify(ticketId),JSON.stringify(customerPhoneNumber),JSON.stringify(ticketNumber) ,JSON.stringify(CreationDate.toLocaleString()) ])
    //     }
    //     else
    //     {
    //       tx.executeSql("INSERT INTO NoShowDBUsers (TicketId , TicketNumber,CreationDate ) values (?,?,?)",
    //       [JSON.stringify(ticketId),JSON.stringify(ticketNumber) ,JSON.stringify(CreationDate.toLocaleString()) ])
    //     }
    //     tx.executeSql('SELECT * FROM NoShowDBUsers',
    //     [], (tx, results) => {
    //       if (results) {
    //         this.noShowialog();
    //           }
    //         })
    //       });
    // }
    if(ticketId){
      this.EndSession();
      this.keypadService.NotShowing(ticketId)
      .then((res) => {  
        if(res){
          this.noShowialog();
        }
        }).catch(err => {
        this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
            verticalPosition: 'top',
            duration: 2000
        });
    });
    }
    /*else {
      this.TicketId = null;
      this.ticketNumber = this.translate.instant('KEYPAD.noTickets');
    }*/
    else{
      this._matSnackBar.open(this.translate.instant('KEYPAD.thereIsNoTicketCalled'), this.translate.instant('ok'), {
        verticalPosition: 'top',
        duration: 2000
      });
    }
  }
  
  openHoldDialog( customerPhoneNumber , ticketNumber ,ticketId) {
    // if(ticketId){
    //   this.EndSession();
    //   const CreationDate = new Date();
    //   var HoldUsersDB= window.openDatabase("NotCompleatedTickits", '1.0', "My WebSQL Database", 2 * 1024 * 1024);
    //   HoldUsersDB.transaction((tx) => {
    //     //tx.executeSql("delete from HoldUsers where not CreationDate =" + CreationDate.toLocaleString())
    //     tx.executeSql("CREATE TABLE IF NOT EXISTS HoldUsers (TicketId VARCHAR(100) , PhoneNumber VARCHAR(100),TicketNumber VARCHAR(100) , CreationDate datetime)");
    //     if(!customerPhoneNumber){
    //       tx.executeSql("INSERT INTO HoldUsers (TicketId, TicketNumber,CreationDate) values (?,?,?)",
    //       [JSON.stringify(ticketId),JSON.stringify(ticketNumber),JSON.stringify(CreationDate.toLocaleString())  ])
    //     }
    //     else
    //     {
    //       tx.executeSql("INSERT INTO HoldUsers (TicketId,PhoneNumber, TicketNumber,CreationDate) values (?,?,?,?)",
    //       [JSON.stringify(ticketId),JSON.stringify(customerPhoneNumber),JSON.stringify(ticketNumber),JSON.stringify(CreationDate.toLocaleString())  ])
    //     }
    //     tx.executeSql('SELECT * FROM HoldUsers',
    //     [], (tx, results) => {
    //        if (results.rows[0].TicketId != 'undefined') {
    //               this.holdDialog();
    //             }
    //         })
    //     });
    // }
    if(ticketId){
      this.EndSession();
      this.keypadService.holdByTicketId(ticketId)
      .then((res) => {  
        if(res==null){
          this.holdDialog();
        }
        }).catch(err => {
        this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
            verticalPosition: 'top',
            duration: 2000
        });
    });
    }  
    else{
      this._matSnackBar.open(this.translate.instant('KEYPAD.thereIsNoTicketCalled'), this.translate.instant('ok'), {
        verticalPosition: 'top',
        duration: 2000
    /*else {
      this.TicketId = null;
      this.ticketNumber = this.translate.instant('KEYPAD.noTickets');
    }*/
      });
    }  
  }

  complete(ticketId){
    if(ticketId){
    const dialogRef = this.dialog.open(CompleteKeypadPopupComponent, {
      width: '30vw',
      height: '35vh',
      panelClass: 'custom-stepper',
      data: { TicketId: ticketId }
    });
  }
  else{
    this._matSnackBar.open(this.translate.instant('KEYPAD.thereIsNoTicketCalled'), this.translate.instant('ok'), {
      verticalPosition: 'top',
      duration: 2000
    });
  }
  }

  inComplete(ticketId){
    if(ticketId){
        this.keypadService.inComplete(ticketId)
        .then(() => {
          this._matSnackBar.open(this.translate.instant('KEYPAD.theTicketIsSetAsIncomplete'), 'OK', {
            verticalPosition: 'top',
            duration: 2000
          });
          this.clearTimer();
        this.TicketId = null;
        this.TicketNumber = this.translate.instant('KEYPAD.noTickets');
        });
      }
      else{
        this._matSnackBar.open(this.translate.instant('KEYPAD.thereIsNoTicketCalled'), this.translate.instant('ok'), {
          verticalPosition: 'top',
          duration: 2000
        });
      }
  }  

  holdDialog(){
    const dialogRef = this.dialog.open(HoldDialogComponent, {
      panelClass:['animate__animated', 'animate__slideInRight', 'side_dialog'],
      position: {right: '0'},
      height: '100%',
      width: '25%'
    });
  }

  noShowialog(){
    const dialogRef = this.dialog.open(NoShowDialogComponent, {
      panelClass:['animate__animated', 'animate__slideInRight', 'side_dialog'],
      position: {right: '0'},
      height: '100%',
      width: '25%'
    });
  }
}
