export const locale = {
    lang: 'ar',
    data: {
        'NAV': {
            'TITLE': 'الشركة',
            'APPLICATIONS': 'التطبيق',
            'DASHBOARDS': 'الواجهة',
            'DASHBOARD':{
                'Tenant':'الشركة',
                'Location':'الموقع',
                'Dynamic' : 'Dashboard Dynamic'
            },
            'CALENDAR': 'التقويم',
            'IMPORT' : 'تحميل المستخدمين',
            'REPORT': 'تقرير',
            'WORKFLOW': 'Work Flow',
            'Permission': 'صلاحيات',
            'MAIL': {
                'TITLE': 'البريد',
                'BADGE': '12'
            },
            'HOME': {
                'TITLE': 'البداية',
                'BADGE': '25'
            },
            "TENANT": "الشركة",
            "BRANCHESMANAGEMENT": {
                'TITLE': 'ادارة الفرع',
                "TENANT": "الشركة",

            },
            'BRANCHESMANAGEMENT_TENANT': 'الشركة',
            'CHAT': 'الدردشة',
            "ROLE": "الوظائف",
            "CATEGORY": "الفئة",
            "KEYPAD": "اللوحة",
            'PUBLICHOLIDAY':'إجازة عامة'
        }
    }
};
