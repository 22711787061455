<div mat-dialog-content style="height: 100%;">
    <button (click)="close()" class="close"><img src="assets/images/keypad/end-session.svg"></button>

    <div class="center-div">
        <form class="" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
            name="form" [formGroup]="form">
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label> {{'KEYPAD.number' | translate}} </mat-label>
                    <input matInput formControlName="TransNumber" type="number" required>
                </mat-form-field>
            </div> 
        </form>
    </div>

    <div style="text-align: end;">
        <div>
            <button mat-button (click)="cancelClick()" type="button"> {{'KEYPAD.cancel' | translate}}</button>
            <button mat-button cdkFocusInitial (click)="confirm()"> {{'ok' | translate}}</button>
        </div>
    </div>
    
</div>