<div id="location" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">
                <!-- [routerLink]="'/location'" -->
                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/tenant/' + tenantId + '/location'" >
                    <mat-icon>{{'arrow_back' | translate}}</mat-icon>
                </button>


                <div fxLayout="column" fxLayoutAlign="start start"
                     [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="pageType ==='edit'">
                        <!-- {{tenunt.name}} -->
                    </div>
                    <div class="h2" *ngIf="pageType ==='new'">
                        {{'LOCATION_ENTRY.newLocation' | translate}}   
                    </div>
                    <div class="subtitle secondary-text">
                        <span>{{'LOCATION_ENTRY.locationDetail' | translate}}</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->
            <!-- [disabled]="locationForm.invalid" -->
            <div class="button-container">
                <mat-spinner *ngIf="isLoading" strokeWidth="2" [diameter]="25"></mat-spinner>
                <button mat-raised-button
                        class="save-location-button"    
                        [disabled]="!isValidForm() || isLoading"               
                        *ngIf="pageType ==='new'" (click)="addLocation()">
                    <span>{{'LOCATION_ENTRY.add' | translate}}</span>
                </button>

                <button mat-raised-button
                        class="save-location-button"      
                        [disabled]="!isValidForm() || isLoading"               
                        *ngIf="pageType ==='edit'" (click)="saveLocation()">
                    <span>{{'LOCATION_ENTRY.save' | translate}}</span>
                </button>
            </div>

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form  name="locationForm" [formGroup]="locationForm" class="location w-100-p" fxLayout="column" fxFlex>            
                            <div class="tab-content" fusePerfectScrollbar>

                        <name-language-editer #nameLanguageEditor [data]="location.name"></name-language-editer>

                                <!-- <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                    <mat-label>{{'LOCATION_ENTRY.locationName' | translate}}</mat-label>
                                    <input matInput placeholder="{{'LOCATION_ENTRY.locationName' | translate}}"
                                           name="name"
                                           formControlName="name"
                                           required>
                                </mat-form-field>        -->
                                <div class="p-24">
                                    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                        <mat-label>{{'LOCATION_ENTRY.locationAddress' | translate}}</mat-label>
                                        <input matInput placeholder="{{'LOCATION_ENTRY.locationAddress' | translate}}"
                                            name="address"
                                            formControlName="address"
                                            required >                                     
                                    </mat-form-field>  

                                    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                        <mat-label>{{'LOCATION_ENTRY.Longitude' | translate}}</mat-label>
                                        <input matInput placeholder="{{'LOCATION_ENTRY.Longitude' | translate}}"
                                            name="Longitude"
                                            formControlName="lon"
                                                >                                     
                                    </mat-form-field>  
                                    
                                    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                        <mat-label>{{'LOCATION_ENTRY.Latitude' | translate}}</mat-label>
                                        <input matInput placeholder="{{'LOCATION_ENTRY.Latitude' | translate}}"
                                            name="Latitude"
                                            formControlName="lat"
                                            >                                     
                                    </mat-form-field>  

                                

                                    <div [hidden]="true">
                                        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                            <mat-label >{{'LOCATION_ENTRY.locationTenant' | translate}}</mat-label>
                                            <input matInput placeholder="Tenant" name="tenantId" formControlName="tenantId" required>
                                        </mat-form-field>
                                    </div>
                                    

                                    <div class="w-100-p h2">{{'LOCATION_ENTRY.workingDays' | translate}} : </div>

                                    <mat-checkbox class="m-24 w-10-p"  formControlName="sunday">
                                        {{'LOCATION_ENTRY.sunday' | translate}}
                                    </mat-checkbox>

                                    <mat-checkbox class="m-24 w-10-p" formControlName="monday">
                                        {{'LOCATION_ENTRY.monday' | translate}}
                                    </mat-checkbox>

                                    <mat-checkbox class="m-24 w-10-p"  formControlName="tuesday">
                                        {{'LOCATION_ENTRY.tuesday' | translate}}
                                    </mat-checkbox>

                                    <mat-checkbox class="m-24 w-10-p" formControlName="wednesday">
                                        {{'LOCATION_ENTRY.wednesday' | translate}}
                                    </mat-checkbox>

                                    <mat-checkbox class="m-24 w-10-p"  formControlName="thursday">
                                        {{'LOCATION_ENTRY.thursday' | translate}}
                                    </mat-checkbox>

                                    <mat-checkbox class="m-24 w-10-p" formControlName="friday">
                                        {{'LOCATION_ENTRY.friday' | translate}}
                                    </mat-checkbox>

                                    <mat-checkbox class="m-24 w-10-p"formControlName="saturday">
                                        {{'LOCATION_ENTRY.saturday' | translate}}
                                    </mat-checkbox>

                                    <mat-checkbox class="m-24 w-10-p"  formControlName="isPublicHolidayApplied">
                                        {{'LOCATION_ENTRY.isPublicHolidayApplied' | translate}}
                                    </mat-checkbox>
                                </div>
                                
                                <div fxLayout="row" fxLayoutGap="20px">
                                    <mat-form-field appearance="fill" fxFlex>
                                      <mat-label>Media Group</mat-label>
                                      <mat-select formControlName="mediaGroupId">
                                        <mat-option *ngFor="let group of mediaGroups" [value]="group.id">{{ group.name }}</mat-option>

                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                                  <div fxLayout="row" fxLayoutGap="20px">
                                    <mat-form-field appearance="fill" fxFlex>
                                      <mat-label>News Group</mat-label>
                                      <mat-select formControlName="newsGroupId">
                                        <mat-option *ngFor="let group of newsGroups" [value]="group.id">
                                          {{ group.name }}
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>   
                              </div>                   
                </form>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>
