import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { BannerService } from '../banner.service';
import { Banner } from 'app/model/Banner';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { locale as english } from '../../../main/home/i18n/en';
import { locale as turkish } from '../../../main/home/i18n/tr';
import { locale as arabic } from '../../../main/home/i18n/ar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
    selector: 'app-banner-entry',
    templateUrl: './banner-entry.component.html',
    styleUrls: ['./banner-entry.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class BannerEntryComponent implements OnInit {
    invalidImage: boolean;
    allowedFiles: string = 'image/*';
    banner: Banner;
    pageType: string;
    bannerForm: FormGroup;
    file :any
    bannerId:string
    isLoading: boolean = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {BannerService} _bannerService
     * @param {FormBuilder} _formBuilder
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        private bannerService: BannerService,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private route: ActivatedRoute,
        private router: Router,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    ) {
        // Set the default
        this.banner = new Banner();

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);        // Set the private defaults

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            if (params.get('id')) {
                this.banner.id = params.get('id')
                this.pageType = 'edit';
                this.bannerService.getBannerById(this.banner.id).then((res: Banner) => {
                    this.banner = res;
                    this.bannerForm = this.createBannerForm();

                });
            }
            else {
                this.pageType = 'new';
            }
        });
        this.bannerForm = this.createBannerForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create banner form
     *
     * @returns {FormGroup}
     */
    createBannerForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.banner.id],
            name: [this.banner.name]
        });
    }

    /**
     * Save banner
     */
    saveBanner(): void {
        this.isLoading = true;
        const data = this.bannerForm.getRawValue();
        if(this.file ==undefined ||this.file==null ){
          data.logo =this.banner.logo;
        }

        this.bannerService.saveBanner(data)
            .then(() => {
                this.isLoading = false;
                // Show the success message
                this._matSnackBar.open('Banner saved', 'OK', {
                    verticalPosition: 'top',
                    duration: 2000
                });
                if(this.file !=undefined ||this.file!=null||this.file!=null  ){
                    this.bannerService.uploadImage(this.file, this.bannerId ).then(res => {
                        this.getBannerById(this.bannerId );
                        })                    }
                    

                    
            }).catch(err => {
                this._matSnackBar.open('Error : ' + err.error.title, 'OK', {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }

    /**
     * Add banner
     */
    addBanner(): void {
        this.isLoading = true;
        const data = this.bannerForm.getRawValue();
        //   data.handle = FuseUtils.handleize(data.name);
        data.id = undefined;

        this.bannerService.addBanner(data)
            .then((res) => {

                // Trigger the subscription with new data
                this.bannerService.onBannerChanged.next(data);
                this.isLoading = false;
                // Show the success message
                this._matSnackBar.open('Banner added', 'OK', {
                    verticalPosition: 'top',
                    duration: 2000
                });
                this.bannerService.uploadImage(this.file, res.id).then(val => {
                    this.getBannerById(res.id);
                    })  
                this.router.navigate(['/banner'])

                // Change the location with new one

            }).catch(err => {
                this._matSnackBar.open("Error : " + err.error.title, 'OK', {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }
    getBannerById(bannerId){
    this.bannerService.getBannerById(bannerId).then((res: Banner) => {
        this.banner = res;
    

    });
    
}
    uploadImage(e, bannerId) {
        if (this.validateFiles(e.target.files)) {
            this.invalidImage = false;
            for (var i = 0; i < e.target.files.length; i++) {
                this.file = e.target.files[i];
                this.bannerId=bannerId;
            }
        } else {
            this.invalidImage = true;

        }
    }

    validateFiles(files: File[]): boolean {
        for (var i = 0; i < files.length; i++) {
            if (!this.isFileTypeValid(files[i])) {
                return false;
            }
            return true;
        }

    }
    private isFileTypeValid(file: File): boolean {
        let acceptableTypes = this.allowedFiles.split(',').map(type => type.trim());
        for (let type of acceptableTypes) {
            let acceptable = this.isWildcard(type) ? this.getTypeClass(file.type) === this.getTypeClass(type)
                : file.type == type || this.getFileExtension(file).toLowerCase() === type.toLowerCase();

            if (acceptable) {
                return true;
            }
        }

        return false;
    }
    getFileExtension(file: File): string {
        return '.' + file.name.split('.').pop();
    }

    getTypeClass(fileType: string): string {
        return fileType.substring(0, fileType.indexOf('/'));
    }

    isWildcard(fileType: string): boolean {
        return fileType.indexOf('*') !== -1;
    }

}
