export const locale = {
    lang: 'en',
    data: {
        'search': 'search',
        'itemsPerPage': 'Items Per Page',
        'nextPageLabel': 'Next',
        'previousPageLabel': 'Previous',
        'customFunction': 'Custom Function',
        'customize': 'Customize',
        'keyboard_arrow_left': 'keyboard_arrow_right',
        'arrow_back': 'arrow_back',
        'of': 'of',
        'ok': 'OK',
        'HOME': {
            'HELLO': 'Hello, World!'
        },
        'THEME_OPTION': {
            'themeOptions': 'Theme Options',
            'colorThemes': 'Color themes',
            'defaultLight': 'Default Light',
            'yellowLight': 'Yellow Light',
            'blueGrayDark': 'Blue-Gray Dark',
            'pinkDark': 'Pink Dark',
            'layoutStyles': 'Layout Styles',
            'verticalLayout1': 'Vertical Layout #1',
            'verticalLayout2': 'Vertical Layout #2',
            'verticalLayout3': 'Vertical Layout #3',
            'horizontalLayout1': 'Horizontal Layout #1',
            'layoutWidth': 'Layout Width',
            'fullWidth': 'Fullwidth',
            'boxed': 'Boxed',
            'navbar': 'Navbar',
            'hide': 'Hide',
            'folded': 'Folded',
            'position': 'Position:',
            'left': 'Left',
            'right': 'Right',
            'variant': 'Variant:',
            'style1': 'Style 1',
            'style2': 'Style 2',
            'primaryBackground': 'Primary background:',
            'secondaryBackground': 'Secondary background:',
            'toolbar': 'Toolbar',
            'above': 'Above',
            'belowStatic': 'Below Static',
            'belowFixed': 'Below Fixed',
            'useCustomBackgroundColor': 'Use custom background color',
            'backgroundColor': 'Background color:',
            'footer': 'Footer',
            'color': 'Color:',
            'sidePanel': 'Side Panel',
            'aboveStatic': 'Above Static',
            'aboveFixed': 'Above Fixed',
            'below': 'Below',
            'backGroundColor': 'Background color:',
            'top': 'Top',
            'variantVertical': 'Variant (Vertical):',
            'secondaryBackgroundVertical': 'Secondary background (Vertical):',
            'enableCustomScrollbars': 'Enable custom scrollbars',
            'customScrollbars': 'Custom scrollbars'
        },
        'TOOLBAR': {
            'myProfile': 'My Profile',
            'inbox': 'Inbox',
            'logout': 'Logout',
            'mr16':'mr-16',
            'mr8':'mr-8'
        },
        'SHORTCUT': {
            'addRemoveShortcut': 'Click to add/remove shortcut',
            'calender': 'Calendar',
            'mail': 'Mail',
            'contacts': 'Contacts',
            'todo': 'To-Do',
            'searchforAppOrPage': 'Search for an app or a page'
        },
        'SERVICE_DETAILS_POPUP': {
            'tenantName': 'Tenant Name',
            'serviceDetails': 'Service Details',
            'locationName': 'Location Name',
            'serviceName': 'Service Name',
            'parentService': 'Parent Services',
            'priority': 'priority',
            'priorityRequired': 'priority is required!',
            'startTime': 'Start time',
            'endTime': 'End time',
            'startDate': 'Start Date',
            'endDate': 'End Date',
            'cancel':'Cancel',
            'ok':'Ok',
            'serviceLocationAdded':'Service Location Added Successfully',
            'inTheSameDay' : "In the another day ?",
            "isDisabled" : "Disabled Service ?",
            "disableMessage" : "Disable Message",
            "toleranceTiem" : "Tolerance Time",
            "slotTime" : "Slot Time",
            "ismultiTime":"is multi Time",
            "serviceGroup" : "Service Group",
            "multislot":"Multi slot",
            "serviceProfile":"Service Profile",
            "both" : "Both",
            "appointmentOnly" : "AppointmentOnly",
            "walkInOnly" : "WalkInOnly",
            'numberOfCounters' : 'Number Of Counters',
            'inAppUrl': 'In Url In App',
            'textUrl':'URL',            
            'appointment':'Appointment',
            'instantTicket':'Instant Ticket',
            'url':'External Url'
        },
        'REPORT':{
            'StartDate':'Start Date',
            'EndDate':'End Date',
            'Location':'Branch',
            'Language':'Language',
            'ReportType':'Report Type',
            'SummaryReport':'Summary Report',
            'CustomerReport':'Customer Report',
            'UsersReport':'Users Report',
            'ServicesReport':'Services Report',
            'GenerateReport':'Generate Report',
            'English':'English',
            'Arabic':'العربية'
        },
        'QUICKPANEL': {
            'today': 'Today',
            'events': 'Events',
            'notes': 'Notes',
            'quicksettings': 'Quick Settings',
            'notifications': 'Notifications',
            'cloudSync': 'Cloud Sync',
            'retroThrusters': 'Retro Thrusters'
        },
        'LOGIN': {
            'welcomTOSaasQueue': 'Welcome to the WAQTAK!',
            'loginToAccount': 'LOGIN TO YOUR ACCOUNT',
            'welcomTOSaasuserNameQueue': 'User Name',
            'userName': 'User Name',
            'userNameRequired': 'Username is required',
            'password': 'Password',
            'passwordRequired': 'Password is required',
            'rememberMe': 'Remember Me',
            'forgetPassword': 'Forgot Password?',
            'login': 'LOGIN',
            'userNamePasswordInvalid': ' Username or Password is invalid',
            'or': 'OR',
            'loginWithGoogle': 'Log in with Google',
            'loginWithFacebook': 'Log in with Facebook',
            'dontHaveAccount': 'Dont have an account?',
            'createAccount': 'Create an account'
        },
        'TENANT_LIST': {
            'Tenants': 'Tenants',
            'searchForTenant': 'Search for a tenant ',
            'search': 'search',
            'addNewTenant': 'ADD NEW TENANT',
            'logo': 'Logo',
            'add': 'add',
            'name': 'Name',
            'email': 'Email',
            'actions': 'Actions',
            'locations': 'locations',
            'services': 'Service',
            'editTenant': 'Edit Tenant',
            'deleteTenant': 'Delete Tenant',
            'tenantUser': 'Tenant User'
        },
        'TENANT_ENTRY': {
            'newTenant': 'New Tenant',
            'tenantDetails': 'Tenant Detail',
            'add': 'ADD',
            'save': 'SAVE',
            'tenantName': 'Tenant Name',
            'tenantEmail': 'Tenant Email',
            'enterValidEmail': 'Please enter a valid email address',
            'category': 'Categories',
            'categoryRequired': 'Categories is required!',
            'prerequisiteDetails': 'Prerequisite Detail',
        },
        'LOCATION_ENTRY': {
            'newLocation': 'New Location',
            'locationDetail': 'Location Detail',
            'add': 'ADD',
            'save': 'SAVE',
            'locationName': 'Location Name',
            'locationAddress': 'Location Address',
            'locationTenant': 'Location Tenant',
            "Latitude" : "Latitude",
            "Longitude" : "Longitude" ,
            "sunday": "Sunday",
            "monday": "Monday",
            "tuesday": "Tuesday",
            "wednesday": "Wednesday",
            "thursday": "Thursday",
            "friday": "Friday",
            "saturday": "Saturday",
            "isPublicHolidayApplied": "Is Public Holiday Applied",
            "workingDays" : "Working Days"
        },
        'LOCATION_LIST': {
            'locations': 'Locations',
            'searchForLocation': 'Search for a location',
            'addNewLocation': 'ADD NEW LOCATION',
            'id': 'ID',
            'name': 'Name',
            'address': 'Address',
            'tenant': 'Tenant',
            'actions': 'Actions',
            'services': 'Service',
            'editLocation': 'Edit Location',
            'deleteLocations': 'Delete Location',
            'userLocations': 'User Location',
            'search': 'search',
            'groupService': 'Group Service',
            'importUsers' : 'Import Users'
        },
        'USER_ENTRY': {
            'newUser': 'New User',
            'userDetail': 'User Detail',
            'add': 'ADD',
            'save': 'SAVE',
            'userName': 'User Name',
            'arName':'User Name Arabic',
            'emailAddress': 'Email Address',
            'phoneNumber': 'Phone Number',
            'password': 'Password',
            'confirmPassword': 'Confirm Password',
            'role': 'Role',
            'roleRequired': 'Role is required!',
            'passwordPattern': 'Password must have at least 1 upper case letter , 1 lower case letter , number and special character'
        },
        'USER_LIST': {
            'users': 'Users',
            'searchForUser': 'Search for a user',
            'addNewUser': 'ADD NEW USER',
            'userName': 'User Name',
            'emailAddress': 'Email Address',
            'phoneNumber': 'Phone Number',
            'actions': 'Actions',
            'editUser': 'Edit user',
            'deleteUser': 'Delete User',
            'services': 'Service',
            'resetPassword' : "Reset Password",
            'ImportUsers': 'Import Users',
            'locations' : 'Location'
        },
        'SERVICE_ENTRY': {
            'newService': 'New Service',
            'serviceDetail': 'Service Detail',
            'add': 'ADD',
            'save': 'SAVE',
            'serviceName': 'Service Name',
            'serviceDescription': 'Service Discription',
            'serviceTenant': 'Service Tenant'
        },
        'SERVICE_LIST': {
            'services': 'Services',
            'searchForService': 'Search for a service',
            'addNewService': 'ADD NEW SERVICE',
            'id': 'ID',
            'name': 'Name',
            'description': 'discription',
            'tenant': 'Tenant',
            'actions': 'Actions',
            'editService': 'Edit Service',
            'deleteService': 'Delete Service',
            'addRequisite':'Add Requisite'
        },
        'ASSIGN_Permission_TO_Role': {
            'addPermissionToRole': 'Add Permission To Role',
            'permissionavailable': 'Permission Available',
            'rolepermission': 'Role Permission'
        },
        'ASSIGN_SERVICE_TO_LOCATION': {
            'addServiceToLocation': 'Add Services To Location',
            'tenantServicesAvailable': 'Tenant Services Available',
            'locationServices': 'Location Services',
            'locationServiceGroup' : 'Location Service Group'
        },
        'ASSIGN_SERVICE_TO_USER': {
            'addServiceToUser': 'Add Services To User',
            'locationServicesAvailable': 'Location Services Available',
            'userServices': 'User Services'
        },
        'CATEGORY_LIST': {
            'categories': 'Categorys',
            'searchForCategory': 'Search for a category',
            'addNewCategory': 'ADD NEW CATEGORY',
            'id': 'ID',
            'name': 'Name',
            'actions': 'Actions',
            'locations': 'locations',
            'services': 'Service',
            'editCategory': 'Edit Category',
            'deleteCategory': 'Delete Category'
        },
        'CATEGORY_ENTRY': {
            'categoryName': 'Category Name',
            'save': 'SAVE',
            'add': 'ADD',
            'categoryDetail': 'Category Detail',
            'newCategory': 'New Category'
        },
        'CONFIRM_MSG': {
            'assignServiceToUser': 'Are you sure you want to assign service to user?',
            'wantToDelete': 'Are you sure you want to delete?',
            'deleteLocationService': 'Are you sure you want to delete location service?',
            'deleteUserService': 'Are you sure you want to delete user service?',
            'ok': 'OK',
            'categoryDeleted': 'Category Deleted Successfully',
            'tenantDeleted': 'Tenant Deleted Successfully',
            'serviceDeleted': 'Service Deleted Successfully',
            'userServiceDeleted': 'User Service Deleted Successfully',
            'serviceAssignedSuccessfully': 'Service Assigned Successfully',
            'locationServiceDeleted': 'Location Service Deleted Successfully',
            'locationDeleted': 'Location Deleted Successfully',
            'locationSaved':'Location saved',
            'locationAdded':'Location added',
            'error':'Error : ',
            'wentWrong':'some thing went wrong',
            'serviceAdded':'Service saved',
            'tenantAdded':'Tenant saved',
            'userSaved':'User saved',
            'userAdded':'User added',
            'categorySaved':'Category saved',
            'categoryAdded':'Category added',
            'deleteRolePermission': 'Are you sure you want to delete Role Permission?',
            'permissionDeleted': 'Permission deleted successfully',
            'deleteRolePublicHoliday': 'Are you sure you want to delete Public Holiday?',
            'PublicHolidayDeleted': 'Public Holiday deleted successfully',
            "Copied" : "Copied",
            'workflowSaved':'Workflow saved',
            'workflowAdd':'Workflow added',
            'workflowDeleted': 'Workflow Deleted Successfully',
            'groupServiceSaved':'Group Service saved',
            'groupServiceAdd':'Group Service added',
            'groupServiceDeleted' : 'Group Service Deleted Successfully',
            'deleteRole': 'Delete Role Successfully',
            'roleAdded':'Added  Role Successfully',
            'PublicHolidaySaved' : 'Public Holiday Saved Successfully',
            'PublicHolidayAdd' : 'Public Holiday Add Successfully',
            'PrerequisiteAdded':'Prerequisite Added Successfully',
            'permissionAdded' : 'permission Add Successfully'

        },
        'KEYPAD':{ 
            'counterNumberSaved':'Counter Number Saved',
            'recallSuccess':'Recall Success',
            'endSessionSuccess':'End Session Success',
            'closeCounterSuccess':'Close Counter Success',
            'transferSuccess':'Transfer Success',
            'keypad':'Keypad',
            'keypadPage':'Keypad Page',
            'ticketNumber':'Ticket Number',
            'thisIsCurrentTicketNumber':'This Is Current Ticket Number',
            'transfer':'Transfer',
            'recall':'Recall',
            'serviceTime':'Service Time',
            'endSession':'End Session',
            'closeCounter':'Close Counter',
            'call':'Call',
            'noTickets':'No Tickets',
            'transferToAnotherCounter':'Transfer To Another Counter',
            'transferCounterNumber':'Transfer Counter Number',
            'cancel':'Cancel',
            'counterNumber':'Counter Number',
            'addCounterNumber':'Add Counter Number',
            "customerPhoneNumber" : "Phone Number",
            "users": "Users",
            "services": "Services",
            "counter": "Counter",
            "next": "Next",
            "previous": "Previous",
            "transferToService": "Transfer to Service",
            "transferToUser": "Transfer to User",
            'transferWrong':'Please select a ticket before transfer',
            'complete':'Complete',
            'inComplete':'InComplete',
            'number':'Number',
            'confirmSuccess':'Confirm Success',
            'theTicketIsSetAsIncomplete':"The ticket is set as incomplete",
            'areYouSureYouWantToConfirm':'Are you sure you want to confirm?',
            'send':'Send',
            'theAnswerHasBeenSentSuccessfully':'The answer has been sent successfully',
            'thereIsNoTicketCalled':'there is no ticket called',
            'pleaseSelectAnswer':'Please Select Answer',
            'hold':'Hold',
            'noShow':'No Show',
            'phoneNumber':'Phone Number'
        },
        'CONFIRM_DIALOG':{
            'confirm':'Confirm',
            'cancel':'Cancel'
        },
        'Permission_LIST': {
            'Permissions': 'Permissions',
            'searchForPermission': 'Search For Permission',
            'addNewPermission': 'Add New Permission',
            'id': 'ID',
            'name': 'Name',
            'actions': 'Actions',
            'editPermission': 'Edit Permission',
            'deletePermission': 'Delete Permission'
        },
        'ROLE_LIST': { 
            'roles': 'Roles',
            'searchForRole': 'Search for a role',
            'addNewRole': 'ADD NEW ROLE',
            'id': 'ID',
            'name': 'Name',
            'actions': 'Actions',
            'locations': 'locations',
            'services': 'Service',
            'editRole': 'Edit Role',
            'deleteRole': 'Delete Role',
            'code':'Code',
            'permission':'permission'
        },
        'Permission_ENTRY': {
            'permissionName': 'Permission Name',
            'save': 'SAVE',
            'add': 'ADD',
            'permissionDetail': 'Permission Detail',
            'newPermission': 'New Permission'
        },
        'ROLE_ENTRY': {
            'roleName': 'Role Name',
            'save': 'SAVE',
            'add': 'ADD',
            'roleDetail': 'Role Detail',
            'newRole': 'New Role',
            'code':'Code'
        },
        'BANNER_LIST': {
            'banners': 'Banners',
            'searchForBanner': 'Search for a banner',
            'addNewBanner': 'ADD NEW BANNER',
            'id': 'ID',
            'name': 'Name',
            'actions': 'Actions',
            'locations': 'locations',
            'services': 'Service',
            'editBanner': 'Edit Banner',
            'deleteBanner': 'Delete Banner'
        },
        'BANNER_ENTRY': {
            'bannerName': 'Banner Name',
            'save': 'SAVE',
            'add': 'ADD',
            'bannerDetail': 'Banner Detail',
            'newBanner': 'New Banner'
        },
        'ERRORS' :{
            "serviceCurrentlyUnavailable" : 'Service currently unavailable'
         },
         'NAME_EDITER':{
            "name" : "Name",
            "language" : "Language",
            "delete" : "Delete",
            "addName" : "Add Name",
            "addslotTime":"Add Slot Time",
            "addprerequisite":"Add Prerequisite"
         },
         'tenantUserData' :{
            "userName":"user Name",
            "avarage_Service":"Avarage Service",
            "client_Count":"Client Count",
            "count_5u":"count 5u",
            "count_5n":"count 5n",
            "UserData":"User Data",
            "BranchData":"Branch Data",
            "TicketseInfo":"Ticketse Info"
         },
         'tenantdatabranch':{
            "tenantName":"Tenant Name",
            "branchName":"Branch Name",
            "noTicketsServiceAbove5min":"Number of Tickets Service Above 5 min",
            "noTicketsServiceBelow5min":"Number of Tickets Service Below 5 min",
            "noTicketsWaitingAbove5min":"Number of Tickets Waiting Above 5 min",
            "noTicketsWaitingBelow5min":"Number of Tickets Waiting Below 5 min",
            "averageService_time":"Average Service Time",
            "averageWaiting_time":"Average Waiting Time",
            "no_showTickets":"Number of Show Tickets",
            "total_clients":"Total Clients",
            "averageTicketPerTeller":"Average Ticket Per Teller"
         }
         ,
         'ABOUT':{
            "generalInformation" : "General Information",
            "userName" : "User Name",
            "arabicName" : "Arabic Name",
            "phone" : "Phone",
            "email" : "Email",
            "work" : "Work",
            "tenant" : "Tenant",
            "editUserName" : "Edit User Name",
            "saveUser" : "Save User",
            "editArabicName" : "Edit Arabic Name",
            "editPhone" : "Edit Phone",
            "editEmail" : "Edit Email",
            "location" : "Location",
            "about" : "About",
            "resetPassword" : "Reset Password"
         },
         'DYNAMIC_DASHBORD':{
            "request" : "Request",
            "eventExecution" : "Event Execution",
            "apiMethode" : "Api Methode",
            "URL" : "URL",
            "send" : "Send",
            "key" : "Key",
            "value" : "Value",
            "add" : "Add",
            "response" : "Response",
            "cancel" : "Cancel",
            "ok" : "Ok",
            "delete" : "Delete"
         },
         
         'PRINT_REPORT':{
            "REPORT" :"REPORT",
            "PRINT":"Print",
            "DATE_FROM":"Date From",
            "DATE_TO":"Date To",
            "LOCATION":"Location", 
            "FORMAT":"Format",
            "PDF":"PDF",
            "EXCEL":"EXCEL",
            "REQUIRED":"is required!",
            "MSG_DATE":"start date is greater than end date"

         },
         'FEEDBACK':{
             'SUCCES':'تم عملية التقييم بنجاح',
             'cancel':'الغاء'
         }
         ,
        'WORKFLOW_LIST': {
            'workflows': 'Workflows',
            'searchForWorkflow': 'Search for a Workflow',
            'addNewWorkflow': 'ADD NEW WORKFLOW',
            'id': 'ID',
            'name': 'Name',
            'actions': 'Actions',
            'locations': 'locations',
            'services': 'Service',
            'editWorkflow': 'Edit Workflow',
            'deleteWorkflow': 'Delete Workflow'
        },
        'WORKFLOW': {
            'workflowName': 'Workflow Name',
            'save': 'SAVE',
            'add': 'ADD',
            'WorkflowDetail': 'Workflow Detail',
            'newworkflow': 'New Workflow'
        },
        'GROUPSERVICE_ENTRY': {
            'newGroupService': 'New Group Service',
            'GroupServiceDetail': 'Group Service Details',
            'add': 'ADD',
            'transalationName': 'Transalation Name',
            'startDate': 'Start Time',
            'endDate': 'End Time',
            'save' : 'Save',
            'groupService': 'Group Service',
            'searchGroupService': 'Search for a Group Service',
            'addNewGroupService': 'ADD NEW GROUP SERVICE',
            'id' : 'ID',
            'name': 'Name',
            'actions': 'Actions',
            'editGroupService': 'Edit Group Service',
            'deleteGroupService': 'Delete Group Service'
        },
        
        'ADVERTISEMENT_ENTRY': {
            'name': 'Name',
            'type': 'Type',
            'video':'Video',
            'text':'Text'    ,
            'addvideo':'Add Video',
            'addnews':'Add News' ,
            'addmedia' :'Add Media',
            'groups' :'Groups' ,
            'addnewgroup' :'Add new group' ,
            'searchForgroup': 'Search for a group',
            'image': 'image',
            'media' :'Media',
            'searchFormedia': 'search For media',
            'editmedia': 'Edit Media',
            'download':'Download'
            
        }
        ,
        'RESET_PASSWORD_POPUP': {
            'resetPassword': 'Reset Password',
            'newPassword': 'New Password',
            'confirmPassword': 'Confirm Password',
            'cancel':'Cancel',
            'ok':'Ok',
            'newPasswordNotEqualConfirmPassword' : 'New Password Should Equal Confirm Password',
            'passwordSetsuccessfully' : 'Password Reset successfully'
        },
        'IMPORT_USERS': {
            'Importcontactsfromexcelsheet': 'Import contacts From Excel Sheet',
            'clickToUpload': 'click To Upload',
            'NoFileSelected': 'No File Selected',
            'ImportyourDetails':'Import your Details',
            'DownloadExcelTempleate':'Download Excel Templeate',
            'first' : 'First',
            'Please' : 'Please',
            'Youwillfindtheexelindownloadfile':'You will find the exel in Download File',
            'OpentheExelandfellyourdata':'Open the Exel and fell your data',
            'PleaseselectyourTempleateandUpdate':'Please select your Templeate and uploadDetails',
            'upload':'Upload',
            'SavedSuccsfully': 'Users Saved successfully',
            'Filemustbexlsx':'File must be xlsx',
            'PleaseSelectFile':'Please Select File'

        },
        'PublicHoliday_LIST': {
            'PublicHolidays': 'Public Holiday',
            'searchForPublicHoliday': 'Search For Public Holiday',
            'addNewPublicHoliday': 'Add New Public Holiday',
            'id': 'ID',
            'name': 'Name',
            'actions': 'Actions',
            'editPublicHoliday': 'Edit Public Holiday',
            'deletePublicHoliday': 'Delete Public Holiday'
        },
        'PublicHoliday_ENTRY': {
            'PublicHolidayName': 'Public Holiday Name',
            'save': 'SAVE',
            'add': 'ADD',
            'PublicHolidayDetail': 'Public Holiday Detail',
            'newPublicHoliday': 'New Public Holiday',
            'startDate' : 'Start Date',
            'endDate' : 'End Date'
        },
        'PREREQUISITE_ENTRY': {
            'add': 'ADD',
            'save': 'Save',
            'name': 'Name',
            'prerequisiteDetails': 'Prerequisite Detail',
            'description': 'Description',
            'addNewprerequisite': 'ADD NEW PREREQUISITE',
        }

    }
};
