import { Component , OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl , FormGroup ,FormBuilder ,Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GroupService } from 'app/model/GroupService';
import { NameLanguageEditer } from 'app/component/name-language-editer/name-language-editer.component';
import { LocationService } from '../location.service';
import { DateTime } from '@syncfusion/ej2-angular-charts';
declare const atob: any;



@Component({
    selector: 'app-group-service',
    templateUrl: './group-service.component.html',
    styleUrls: ['./group-service.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GroupServiceEntryComponent implements OnInit {

  public groupServiceform: FormGroup;
  isLoading: boolean = false;
  groupService : GroupService;
  pageType: string;
  locatoinId : any;
  TenantId : any;
  extras: any;
  allGroupService: any;

  @ViewChild("nameLanguageEditor") nameLanguageEditor :NameLanguageEditer;

    constructor(
        private route: ActivatedRoute,
        private _fuseConfigService : FuseConfigService,
        private locationService : LocationService,
        private fb: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private translate: TranslateService,
        private router: Router,
    ) {
        this.groupService = new GroupService();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
     ngOnInit() {
        this.route.paramMap.subscribe(params => {
            if(params.get('tenantId')){
                this.TenantId = params.get('tenantId');
            }
            this.locatoinId = params.get('locationId');
            if (params.get('id')) {
                this.groupService.id = params.get('id');
                this.pageType = 'edit';
                this.locationService.getGroupServiceById(this.groupService.id).then((res: GroupService) => {
                    this.groupService = res;  
                    this.allGroupService = res;
                    this.groupService.endTime = this.allGroupService.endTime.split('T')[1];
                    this.groupService.startTime = this.allGroupService.startTime.split('T')[1];
                    
                    this.groupServiceform = this.createGroupServiceForm();

                });
            }
            else {
                this.pageType = 'new';
            }
        });
        this.groupServiceform = this.createGroupServiceForm();
    }


    
    createGroupServiceForm(): FormGroup {
        return this.fb.group({
            name: [this.groupService.name],
            startTime : [this.groupService.startTime],
            endTime : [this.groupService.endTime]
           
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    addGroupService(): void {
        this.isLoading = true;
        this.groupService.name = JSON.stringify(this.nameLanguageEditor.deserializedData);
        this.groupServiceform.controls["name"].setValue( this.groupService.name);
        const data = this.groupServiceform.getRawValue();
        if (data.startTime) {
            var startTime = new Date(data.startDate?.value || data.startDate || new Date() )
            startTime.setUTCHours(data.startTime.split(":")[0]);
            startTime.setUTCMinutes(data.startTime.split(":")[1]);
            data.startTime = startTime;
          }
          if (data.endTime) {
            var endTime = new Date(data.endDate?.value || data.endDate || new Date());
            endTime.setUTCHours(data.endTime.split(":")[0]);
            endTime.setUTCMinutes(data.endTime.split(":")[1]); 
            data.endTime = endTime;
          } 
        data.locationId = this.locatoinId;
        this.locationService.addGroupService(data)
            .then(() => {
                this.isLoading = false;
                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.groupServiceAdd'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
                const options = { relativeTo: this.route, queryParams: { extras: this.extras } };
                this.router.navigate(['../'], options);
            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.message,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }


    saveGroupService(): void {
        this.isLoading = true;
        this.groupService.name = JSON.stringify(this.nameLanguageEditor.deserializedData);
        this.groupServiceform.controls["name"].setValue(this.groupService.name);
        const data = this.groupServiceform.getRawValue();
        if (data.startTime) {
            var startTime = new Date(data.startDate?.value || data.startDate || new Date() )
            startTime.setUTCHours(data.startTime.split(":")[0]);
            startTime.setUTCMinutes(data.startTime.split(":")[1]);
            data.startTime = startTime;
          }
          if (data.endTime) {
            var endTime = new Date(data.endDate?.value || data.endDate || new Date());
            endTime.setUTCHours(data.endTime.split(":")[0]);
            endTime.setUTCMinutes(data.endTime.split(":")[1]); 
            data.endTime = endTime;
          } 
        data.locationId = this.locatoinId;
        data.id = this.groupService.id;
        // data.handle = FuseUtils.handleize(data.name);
       
        this.locationService.saveGroupService(data)
            .then((res) => {
                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.groupServiceAdd'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
                this.isLoading = false;
            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.title,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }

    isValidForm(){
        return this.groupServiceform?.valid  && this.nameLanguageEditor?.manageEntityForm?.valid
     }
}
