export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'TITLE': 'Tenant',
            'APPLICATIONS': 'Applications',
            'DASHBOARDS': 'Dashboards',
            'DASHBOARD':{
                'Tenant':'Dashboard Tenant',
                'Location':'Daashboard Location',
                'Dynamic' : 'Dashboard Dynamic'
            },
            'CALENDAR': 'Calendar',
            'IMPORT' : 'Import Useres',
            'REPORT': 'REPORT',
            'WORKFLOW': 'Work Flow',
            'MAIL': {
                'TITLE': 'Mail',
                'BADGE': '12'
            },
            'HOME': {
                'TITLE': 'Home',
                'BADGE': '25'
            },
            "TENANT": "Tenant",

            "BRANCHESMANAGEMENT": {
                'TITLE': 'Branches Management',
                "TENANT": "Tenant",

            },
            'BRANCHESMANAGEMENT_TENANT': 'Tenant',
            'CHAT': 'Chat',
            "ROLE": "Role",
            "Permission":"Permission",
            "CATEGORY": "Category",
            "KEYPAD": "Keypad",
            "BANNER":"Banner",
            'PUBLICHOLIDAY':'Public Holiday'
        }

    }
};
