<div id="service" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" 
                [routerLink]="'/service/tenant/'+ service.tenantId">
                    <mat-icon>{{'arrow_back' | translate}}</mat-icon>
                </button>


                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="pageType ==='edit'">
                        <!-- {{tenunt.name}} -->
                    </div>
                    <div class="h2" *ngIf="pageType ==='new'">
                        {{'SERVICE_ENTRY.newService' | translate}}
                    </div>
                    <div class="subtitle secondary-text">
                        <span>{{'SERVICE_ENTRY.serviceDetail' | translate}}</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->
            <!-- [disabled]="serviceForm.invalid" -->

            <button mat-raised-button class="save-service-button" [disabled]="!isValidForm()"
                *ngIf="pageType ==='new'" (click)="addService()">
                <span>{{'SERVICE_ENTRY.add' | translate}}</span>
            </button>

            <button mat-raised-button class="save-service-button"
                [disabled]="!isValidForm()" *ngIf="pageType ==='edit'"
                (click)="saveService()">
                <span> {{'SERVICE_ENTRY.save' | translate}}</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="serviceForm" [formGroup]="serviceForm" class="service w-100-p" fxLayout="column" fxFlex>
                    <div class="tab-content" fusePerfectScrollbar>
                        <name-language-editer [data]="service.name" #nameLanguageEditor></name-language-editer>

                        <!-- <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                            <mat-label>{{'SERVICE_ENTRY.serviceName' | translate}}</mat-label>
                            <input matInput placeholder="{{'SERVICE_ENTRY.serviceName' | translate}}" name="name"
                                formControlName="name" required>
                        </mat-form-field> -->
                        <div class="p-24">
                            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                <mat-label>{{'SERVICE_ENTRY.serviceDescription' | translate}}</mat-label>
                                <input matInput name="discription" formControlName="discription"
                                    placeholder="{{'SERVICE_ENTRY.serviceDescription' | translate}} ">

                            </mat-form-field>
                        </div>
                        <div [hidden]="true">
                            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                                <mat-label>{{'SERVICE_ENTRY.serviceTenant' | translate}}</mat-label>
                                <input matInput placeholder="{{'SERVICE_ENTRY.serviceTenant' | translate}}"
                                    name="tenantId" formControlName="tenantId" required>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>