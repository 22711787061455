<div mat-dialog-content class="dialog-content">
    <button (click)="close()" class="close"><img src="assets/images/keypad/end-session.svg"></button>
    <mat-horizontal-stepper #stepper>
        <mat-step >
            <ng-template matStepLabel></ng-template>
            <div>
                   <p>{{'KEYPAD.selectTypeTransfer' | translate}}</p>
                <mat-radio-group [(ngModel)]="transferType" (change)="goForward(stepper)">
                    <mat-radio-button value="user"  ><img src="assets/images/users.svg"> <span>{{'KEYPAD.users' | translate}}</span></mat-radio-button>
                    <mat-radio-button value="service"><img src="assets/images/services.svg"> <span>{{'KEYPAD.services' | translate}}</span></mat-radio-button>
                    <mat-radio-button value="counter"><img src="assets/images/counter.svg"> <span>{{'KEYPAD.counter' | translate}}</span></mat-radio-button>
                </mat-radio-group>        
            </div>
            <!-- <div class="d-flex justify-content-end mt-5" style="width: 100%; margin-top: 25px; justify-content: flex-end;">    
                <button mat-button matStepperNext class="next" type="button">{{'KEYPAD.next' | translate}}</button>
            </div> -->
        </mat-step>
        <mat-step>
            <ng-template matStepLabel></ng-template>
            <div *ngIf="transferType == 'counter'" class="content">
                <div>
                    <form class="" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
                        name="form" [formGroup]="form">
                        <div class="h2 mb-24"> {{'KEYPAD.transferToAnotherCounter' | translate}}</div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label> {{'KEYPAD.transferCounterNumber' | translate}} </mat-label>
                                <input  matInput formControlName="CounterNumber"  type="number" required [(ngModel)]="data.CounterNumber" >
                            </mat-form-field>
                        </div>
                    </form>
                </div>
                <div mat-dialog-actions class="actions">
                    <button mat-button matStepperPrevious type="button">{{'KEYPAD.previous' | translate}}</button>
                    <div class="d-flex">
                        <button mat-button (click)="cancelClick()" type="button"> {{'KEYPAD.cancel' | translate}}</button>
                        <button mat-button cdkFocusInitial (click)="save()" > {{'ok' | translate}}</button>
                    </div>
                </div>
            </div>
            <div *ngIf="transferType == 'service'" class="content">
                <div>
                    <form class="" fxLayout="column" fxLayoutAlign="start"
                        name="form" [formGroup]="form">
                        <div class="h2 mb-2">{{'KEYPAD.transferToService' | translate}}</div>
                        <div class="inner-radio-group">
                            <mat-radio-group  formControlName="serviceId">
                                <mat-radio-button *ngFor="let service of locationServices" [value]="service?.id"  ><img src="assets/images/services.svg"> <span>{{service?.name}}</span></mat-radio-button>
                            </mat-radio-group>  
                        </div>
                    </form>
                </div>
                <div mat-dialog-actions class="actions">
                    <button mat-button matStepperPrevious type="button">{{'KEYPAD.previous' | translate}}</button>
                    <div class="d-flex">
                        <button mat-button (click)="cancelClick()"  type="button"> {{'KEYPAD.cancel' | translate}}</button>
                        <button mat-button cdkFocusInitial (click)="saveTransferService()"> {{'ok' | translate}}</button>
                    </div>
                </div>
            </div>
            <div *ngIf="transferType == 'user'" class="content">
                <div>
                    <form class="" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
                        name="form" [formGroup]="form">
                        <div class="h2 mb-24">{{'KEYPAD.transferToUser' | translate}}</div>
                        <div class="inner-radio-group" >
                            <mat-radio-group formControlName="userId">
                                <mat-radio-button *ngFor="let service of locationUsers"  [value]="service?.id"><img src="assets/images/users.svg"> <span>{{service?.userName}}</span> <span class="online"><span></span> online</span></mat-radio-button>
                            </mat-radio-group>  
                        </div>
                    </form>
                </div>
                <div mat-dialog-actions class="actions">
                    <button mat-button matStepperPrevious type="button">Back</button>
                    <div class="d-flex">
                        <button mat-button (click)="cancelClick()"type="button"> {{'KEYPAD.cancel' | translate}}</button>
                        <button mat-button cdkFocusInitial (click)="saveTransferUsers()"> {{'ok' | translate}}</button>
                    </div>
                </div>
            </div>

        </mat-step>
    </mat-horizontal-stepper>
</div>

<!-- [mat-dialog-close]="data.Tenant"  -->