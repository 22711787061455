<div mat-dialog-content>
    <form class=" p-24 mr-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form" [formGroup]="form" [ngClass]="currentLanguage == 'ar' ? 'arabic' : ''">


        <div class="h2 mb-24"> {{'RESET_PASSWORD_POPUP.resetPassword' | translate}} </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

            <mat-form-field appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="100">
                <mat-label> {{'RESET_PASSWORD_POPUP.newPassword' | translate}} </mat-label>
                <input [type]="password" matInput [(ngModel)]="newPassword" formControlName="password" required>
                <mat-icon matSuffix class="secondary-text icon" (click)="showPassword()" >{{showHidePass}}</mat-icon>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="100">
                <mat-label> {{'RESET_PASSWORD_POPUP.confirmPassword' | translate}} </mat-label>
                <input [type]="confirmPass" matInput [(ngModel)]="confirmPassword" formControlName="confirmPassword" required>
                <mat-icon matSuffix class="secondary-text icon" (click)="showConfirmPass()" >{{showHideConfirmPass}}</mat-icon>
            </mat-form-field>
        </div>
        <mat-label *ngIf="newPassword != confirmPassword" style="color: red;"> {{'RESET_PASSWORD_POPUP.newPasswordNotEqualConfirmPassword' | translate}} </mat-label>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancelClick()"> {{'RESET_PASSWORD_POPUP.cancel' | translate}} </button>
    <button mat-button cdkFocusInitial (click)="restePassword()"> {{'RESET_PASSWORD_POPUP.ok' | translate}} </button>
</div>