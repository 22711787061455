  <form #manageEntityForm="ngForm" class="p-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
        name="form">
        <div *ngFor="let item of deserializedData;let i = index">
<div *ngIf="visible"></div>
           
            <mat-form-field appearance="outline" floatLabel="always" class="name-field">
                <mat-label>{{'NAME_EDITER.name' | translate}}</mat-label>
                <input matInput [(ngModel)]="item.name"  placeholder="{{'NAME_EDITER.name' | translate}}" name="name-{{item.guid ?? i}}" required>
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" class="lang-field">
                <mat-label>{{'NAME_EDITER.language' | translate}}</mat-label>
                <mat-select  required class="simplified font-size-16" [ngModelOptions]="{standalone: true}"
                [(ngModel)]="item.languageCode" > 
                    <mat-option *ngFor="let language of languages" [value]="language?.code">
                        {{language?.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-icon class="s-20 p-24 mr-24" (click)="removeRow(item)" style="cursor: pointer;">delete</mat-icon>

    
        </div>

    <button *ngIf="deserializedData?.length < languages?.length" mat-raised-button class="mat-accent mr-16" (click)="addRow()">
    <mat-icon (click)="addfields()" class="s-20 ">add_circle</mat-icon>
    {{'NAME_EDITER.addName' | translate}}

    </button>
        
    </form>