import { Time } from "@angular/common";
import { FuseUtils } from "@fuse/utils";

export class GroupService {
    constructor(groupService?)
    {
        groupService = groupService || {};
        this.id = groupService.id || 0;//FuseUtils.generateGUID();
        this.name = groupService.name || '';
        this.startTime = groupService.startTime || '';
        this.endTime = groupService.endTime || '';
        
    }
    id : string;    
    name: string;
    startTime : Time;
    endTime: Time;

}