import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';
import { RoleService } from '../role.service';
import { Role } from 'app/model/Role';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { locale as english } from '../../../main/home/i18n/en';
import { locale as turkish } from '../../../main/home/i18n/tr';
import { locale as arabic } from '../../../main/home/i18n/ar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-role-entry',
    templateUrl: './role-entry.component.html',
    styleUrls: ['./role-entry.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class RoleEntryComponent implements OnInit {

    role: Role;
    pageType: string;
    roleForm: FormGroup;
    isLoading: boolean = false;
    name:string=''
    id:string
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {RoleService} _roleService
     * @param {FormBuilder} _formBuilder
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        private roleService: RoleService,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private route: ActivatedRoute,
        private router: Router,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translate:TranslateService
    ) {
        // Set the default
        this.role = new Role();

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);        // Set the private defaults

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            if (params.get('id')) {
                this.role.id = params.get('id')
                this.id= params.get('id')
                this.pageType = 'edit';
                this.roleService.getRoleById(this.role.id).then((res: Role) => {
                    this.role = res;
                   // this.name=this.role[0].name;
                    this.roleForm =  this.createRoleForm();

                });
            }
            else {
                this.pageType = 'new';
            }
        });
        this.roleForm = this.createRoleForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create role form
     *
     * @returns {FormGroup}
     */
    createRoleForm(): FormGroup {
        return this._formBuilder.group({
            id : [this.role.id],
            name: [this.role.name]
           
        });
    }

    /**
     * Save role
     */
    saveRole(): void {
        this.isLoading = true;
        const data = this.roleForm.getRawValue();
       // data.normalizedName = data.name.toLocaleUpperCase();
        data.id=this.id 

        this.roleService.saveRole(data)
            .then(() => {
                this.isLoading = false;
                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.roleSaved'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }

    /**
     * Add role
     */
    addRole(): void {
        this.isLoading = true;
        const data = this.roleForm.getRawValue();
        //   data.handle = FuseUtils.handleize(data.name);

        this.roleService.addRole(data)
            .then(() => {

                // Trigger the subscription with new data
                this.roleService.onRoleChanged.next(data);
                this.isLoading = false;
                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.roleAdded'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });

                this.router.navigate(['/role'])

                // Change the location with new one

            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }
}
