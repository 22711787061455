import { Component , OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl , FormGroup ,FormBuilder ,Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { FeedBack } from 'app/model/FeedBack';
import { WorkFlowService } from '../workflow.service';
import { TranslateService } from '@ngx-translate/core';
import { Workflow } from 'app/model/Workflow';
import { NameLanguageEditer } from 'app/component/name-language-editer/name-language-editer.component';
import { Subject } from 'rxjs';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../../main/home/i18n/en';
import { locale as turkish } from '../../../main/home/i18n/tr';
import { locale as arabic } from '../../../main/home/i18n/ar';

@Component({
    selector: 'app-workflow',
    templateUrl: './workflow.component.html',
    styleUrls: ['./workflow.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WorkFlowComponent implements OnInit {

  title = 'bpmn-js-angular';
  diagramUrl = 'https://cdn.staticaly.com/gh/bpmn-io/bpmn-js-examples/dfceecba/starter/diagram.bpmn';
  importError?: Error;

  allowedFiles: string = 'image/*';
  workflow: Workflow;
  pageType: string;
  workflowForm: FormGroup;
  @ViewChild("nameLanguageEditor") nameLanguageEditor :NameLanguageEditer;
  invalidImage: boolean;
  // Private
  file :any
  workflowId:string
  path:any;
  private _unsubscribeAll: Subject<any>;
  isLoading: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private _fuseConfigService : FuseConfigService,
        private fb: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private translate: TranslateService,
        private workflowService: WorkFlowService,
        private _formBuilder: FormBuilder,
        private router: Router,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    ) {
      this.workflow = new Workflow();

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic); 
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
     ngOnInit() {
      this.path="../../../../assets/images/backgrounds/default-upload-image.jpg";

        this.route.paramMap.subscribe(params => {
            if (params.get('id')) {
                this.workflow.id = params.get('id')
                this.pageType = 'edit';
                this.workflowService.getWorkflowById(this.workflow.id,true).then((res: Workflow) => {
                    this.workflow = res;
                    this.workflowForm = this.createWorkflowForm();

                });
            }
            else {
                this.pageType = 'new';
            }
        });
        this.workflowForm = this.createWorkflowForm();
     }
    
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    handleImported(event) {

      const {
        type,
        error,
        warnings
      } = event;
  
      if (type === 'success') {
        console.log(`Rendered diagram (%s warnings)`, warnings.length);
      }
  
      if (type === 'error') {
        console.error('Failed to render diagram', error);
      }
  
      this.importError = error;
    }

    uploadIcon(e, workflowId) {
      if (this.validateFiles(e.target.files)) {
          this.invalidImage = false;
          for (var i = 0; i < e.target.files.length; i++) {
           
                 this.file = e.target.files[i];
              this.workflowId=workflowId;
          }
      } else {
          this.invalidImage = true;

      }

  }
    validateFiles(files: File[]): boolean {
      for (var i = 0; i < files.length; i++) {
          if (!this.isFileTypeValid(files[i])) {
              return false;
          } 
          return true;
      }

  }

  private isFileTypeValid(file: File): boolean {
      let acceptableTypes = this.allowedFiles.split(',').map(type => type.trim());
      for (let type of acceptableTypes) {
          let acceptable = this.isWildcard(type) ? this.getTypeClass(file.type) === this.getTypeClass(type)
              : file.type == type || this.getFileExtension(file).toLowerCase() === type.toLowerCase();

          if (acceptable) {
              return true;
          }
      }

      return false;
  }
  isWildcard(fileType: string): boolean {
      return fileType.indexOf('*') !== -1;
  }
  getTypeClass(fileType: string): string {
      return fileType.substring(0, fileType.indexOf('/'));
  }
  getFileExtension(file: File): string {
      return '.' + file.name.split('.').pop();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
}

createWorkflowForm(): FormGroup {
  return this._formBuilder.group({
      id: [this.workflow.id],
      name: [this.workflow.name],
   
  });
}


saveWorkflow(): void {
  this.isLoading = true;
  this.workflow.name = JSON.stringify(this.nameLanguageEditor.deserializedData);
  this.workflowForm.controls["name"].setValue(this.workflow.name);
  const data = this.workflowForm.getRawValue();
  if(this.file ==undefined ||this.file==null ){
  }
  this.workflowService.saveWorkflow(data)
      .then((res) => {

          // Show the success message
          this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.workflowSaved'),  this.translate.instant('ok'), {
              verticalPosition: 'top',
              duration: 2000
          });
          if(this.file !=undefined ||this.file!=null ){
            
              }
            
          this.isLoading = false;
             
      }).catch(err => {
          this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error,  this.translate.instant('ok'), {
              verticalPosition: 'top',
              duration: 2000
          });
      });

    
}

isValidForm(){
  return this.nameLanguageEditor?.manageEntityForm?.valid
}

/**
* Add workflow
*/
addWorkflow(): void {
  this.isLoading = true;
  this.workflow.name = JSON.stringify(this.nameLanguageEditor.deserializedData);
  this.workflowForm.controls["name"].setValue(this.workflow.name);
  const data = this.workflowForm.getRawValue();
  //   data.handle = FuseUtils.handleize(data.name);
  data.id = undefined;

  this.workflowService.addWorkflow(data)
      .then((res) => {

          // Trigger the subscription with new data
          this.workflowService.onWorkflowChanged.next(data);
          this.isLoading = false;
          // Show the success message
          this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.workflowAdd'),  this.translate.instant('ok'), {
              verticalPosition: 'top',
              duration: 2000
          });

          this.router.navigate(['/workflow'])
          // Change the location with new one
          this.workflowService.getWorkflowById(res.id).then((res: Workflow) => {
              this.workflow = res;
              this.workflowForm = this.createWorkflowForm();

          });
      }).catch(err => {
          this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
              verticalPosition: 'top',
              duration: 2000
          });
      });
}

}
