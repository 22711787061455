<div id="dashboard-project" class="page-layout blank ">
    <!-- HEADER -->
    <div class="center" fusePerfectScrollbar>
    <div class="header accent p-24 pb-0 "  fxLayout="column" fxLayoutAlign="space-between">

        <div fxLayout="row" fxLayoutAlign="space-between start">

            <span class="mat-display-1 my-0 my-sm-24 welcome-message"
                  [@animate]="{value:'*',params:{x:'50px'}}">Welcome back,{{tenantName}}
            </span>

            <button mat-icon-button class="sidebar-toggle mr-8" fxHide.gt-md
                    (click)="toggleSidebar('project-dashboard-right-sidebar-1')">
                <mat-icon>menu</mat-icon>
            </button>
        </div>
  
        <div fxLayout="row">

            <div class="selected-project">{{selectedLocation}}</div>

            <button mat-icon-button class="project-selector" [matMenuTriggerFor]="projectsMenu"
                    aria-label="Select project">
                <mat-icon>more_horiz</mat-icon>
            </button>

            <mat-menu #projectsMenu="matMenu">
                <button mat-menu-item *ngFor="let location of dataSourceLocation" (click)="selectedlocation(location)">
                    <span>{{location.name}}</span>
                </button>
            </mat-menu>
        </div>
    </div>
</div>
    <!-- / HEADER -->
    <div class="content">

        <div class="left mr-lg-32">

            <!-- <div class="pb-24 font-size-18 font-weight-300">
                How are your active users trending over time?
            </div> -->
            <div class="dashboard-skeleton" *ngIf="loaderService.isLoading ">
                <ngx-skeleton-loader
                    [theme]="{ height: '118px'}"
                    animation="progress"
                    count="6">
                </ngx-skeleton-loader>
            </div>
            <div *ngIf="!loaderService.isLoading ">
                <div fxLayout="column" fxLayoutAlign="start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start">
                    <fuse-widget class="widgetCarid" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">
                        <!-- Front -->
                        <div class="fuse-widget-front mat-elevation-z2" style="border-left: 3px solid #ed4337;">
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div>
                                    <div style="color: #ed4337;">{{'tenantdatabranch.noTicketsServiceAbove5min' | translate}}</div>
                                    <h2 style="color: #ed4337;">{{noTicketsServiceAbove5min}}</h2>
                                </div>
                                <!-- <button mat-icon-button fuseWidgetToggle aria-label="more">
                                    <mat-icon>more_vert</mat-icon>
                                </button> -->
                            </div>
                        </div>
                    </fuse-widget>
                    <fuse-widget class="widgetCarid" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">
                        <!-- Front -->
                        <div class="fuse-widget-front mat-elevation-z2" style="border-left: 3px solid #4fbf26;">
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div>
                                    <div style="color: #4fbf26;">{{'tenantdatabranch.noTicketsServiceBelow5min' | translate}}</div>
                                    <h2 style="color: #4fbf26;">{{noTicketsServiceBelow5min}}</h2>
                                </div>
                                <!-- <button mat-icon-button fuseWidgetToggle aria-label="more">
                                    <mat-icon>more_vert</mat-icon>
                                </button> -->
                            </div>
                        </div>
                        <!-- / Front -->
                    </fuse-widget>
                    <fuse-widget class="widgetCarid" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">
                        <!-- Front -->
                        <div class="fuse-widget-front mat-elevation-z2 " style="border-left: 3px solid #ed4337;">
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div>
                                    <div style="color: #ed4337;">{{'tenantdatabranch.noTicketsWaitingAbove5min' | translate}}</div>
                                    <h2 style="color: #ed4337;">{{noTicketsWaitingAbove5min}}</h2>
                                </div>
                                <!-- <button mat-icon-button fuseWidgetToggle aria-label="more">
                                    <mat-icon>more_vert</mat-icon>
                                </button> -->
                            </div>
                        </div>
                        <!-- / Front -->
                    </fuse-widget>
                </div>
                <div fxLayout="column" fxLayoutAlign="start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start">
                    <fuse-widget class="widgetCarid" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">
                        <!-- Front -->
                        <div class="fuse-widget-front mat-elevation-z2" style="border-left: 3px solid #4fbf26;">
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div>
                                    <div style="color: #4fbf26;">{{'tenantdatabranch.noTicketsWaitingBelow5min' | translate}}</div>
                                    <h2 style="color: #4fbf26;">{{noTicketsWaitingBelow5min}}</h2>
                                </div>
                                <!-- <button mat-icon-button fuseWidgetToggle aria-label="more">
                                    <mat-icon>more_vert</mat-icon>
                                </button> -->
                            </div>
                        </div>
                        <!-- / Front -->
                    </fuse-widget>
                    <fuse-widget class="widgetCarid" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">
                        <!-- Front -->
                        <div class="fuse-widget-front mat-elevation-z2" style="border-left: 3px solid #FFDE00;">
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div>
                                    <div style="color: #FFDE00;">{{'tenantdatabranch.averageService_time' | translate}}</div>
                                    <h2 style="color: #FFDE00;">{{averageService_time}}</h2>
                                </div>
                                <!-- <button mat-icon-button fuseWidgetToggle aria-label="more">
                                    <mat-icon>more_vert</mat-icon>
                                </button> -->
                            </div>
                        </div>
                        <!-- / Front -->
                    </fuse-widget>
                    <fuse-widget class="widgetCarid" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">
                        <!-- Front -->
                        <div class="fuse-widget-front mat-elevation-z2" style="border-left: 3px solid #FFDE00;">
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div>
                                    <div style="color: #FFDE00;">{{'tenantdatabranch.averageService_time' | translate}}</div>
                                    <h2 style="color: #FFDE00;">{{averageWaiting_time}}</h2>
                                </div>
                            </div>
                        </div>
                        <!-- / Front -->
                    </fuse-widget>
                </div>
            </div>
            <div class="list-skeleton" *ngIf="loaderService.isLoading ">
                <ngx-skeleton-loader
                    [theme]="{ height: '48px'}"
                    animation="progress"
                    count="6">
                </ngx-skeleton-loader>
            </div>
            <div class="content-card ClassList" *ngIf="!loaderService.isLoading ">

                <div class="widget-group" fxLayout="row wrap" fxFlex="100" *fuseIfOnDom
                    [@animateStagger]="{value:'50'}">

                    <!-- WIDGET 11 -->
                    <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="row" fxFlex="100">

                        <!-- Front -->
                        <div class="fuse-widget-front">

                            <div class="p-24 mb-8 border-bottom" fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="h2">{{'tenantUserData.UserData' | translate}}</div>

                            </div>



                            <mat-table class="categorys-table custom-table" #table [dataSource]="dataSource" matSort
                                [@animateStagger]="{value:'50'}" fusePerfectScrollbar>


                                <!--Name Column -->
                                <ng-container matColumnDef="userName">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'tenantUserData.userName' |
                                        translate}}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let userData">
                                        <p class="text-truncate">{{userData.userName}}</p>
                                    </mat-cell>
                                </ng-container>

                                <!--Name Column -->
                                <ng-container matColumnDef="avarage_Service">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        {{'tenantUserData.avarage_Service' | translate}}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let userData">
                                        <p class="text-truncate">{{userData.avarage_Service}}</p>
                                    </mat-cell>
                                </ng-container>


                                <ng-container matColumnDef="client_Count">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'tenantUserData.client_Count' |
                                        translate}}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let userData">
                                        <p class="text-truncate">{{userData.client_Count}}</p>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="count_5u">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'tenantUserData.count_5u' |
                                        translate}}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let userData">
                                        <p class="text-truncate">{{userData.count_5u}}</p>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="count_5n">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'tenantUserData.count_5n' |
                                        translate}}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let userData">
                                        <p class="text-truncate">{{userData.count_5n}}</p>
                                    </mat-cell>
                                </ng-container>


                                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                                <mat-row *matRowDef="let userData; columns: displayedColumns;" class="userData"
                                    matRipple>
                                    <!-- [routerLink]="'/category/add/' + category.id" -->
                                </mat-row>

                            </mat-table>

                            <mat-paginator #paginator [length]="dataSource ? dataSource : 0" [pageIndex]="0"
                                [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>
                        </div>
                    </fuse-widget>
                </div>
            </div>
        </div>

        <div class="right">

            <div fxLayout="row wrap" fxLayout.gt-md="column">

                <!-- Widget 7 -->

                <!-- / Widget 7 -->

                <!-- Widget 8 -->
                <div class="mb-48" [ngClass.lt-lg]="'mr-32'" [ngClass.xs]="'mr-0'">
 


                    <div class="fuse-card">

                        <div class="light-blue-600" style="padding-top: 10px;">

                            <div class="h1 px-16" fxLayoutAlign="space-between center">{{'tenantUserData.TicketseInfo' | translate}}</div>


                            <table class="simple clickable">

                                <tbody>
                                    <tr>
                                        <td>{{'tenantdatabranch.branchName' | translate}}</td>
                                        <td class="text-right">{{branchName}}</td>

                                    </tr>

                                    <tr>
                                        <td>{{'tenantdatabranch.total_clients' | translate}}</td>
                                        <td class="text-right">{{total_clients}}</td>

                                    </tr>
                                    <tr>
                                        <td>{{'tenantdatabranch.no_showTickets' | translate}}</td>
                                        <td class="text-right">{{no_showTickets}}</td>

                                    </tr>

                                    <tr>
                                        <td>{{'tenantdatabranch.averageTicketPerTeller' | translate}}</td>
                                        <td class="text-right">{{averageTicketPerTeller}}</td>

                                    </tr>
                                </tbody>
                            </table>

                            <div class="card-divider full-width"></div>




                        </div>



                    </div>

                </div>

                <!-- / Widget 8 -->

                <!-- Widget 9 -->

                <!-- / widget 9 -->

            </div>

        </div>


    </div>

</div>