<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<div class="cont">
  <div class="header" [@animate]="{value:'*',params:{scale:'0.2'}}">
    <img src="{{'data:image/jpg;base64,' + logo}}" >
  </div>
  <div class="body" *ngIf="!IsRated">
    <h2 class="Labelcolor fontType" > شكراً لزيارتك {{tenantName}}<br/> يرجى تقييم الخدمة المقدمة </h2>
    
    <div *ngIf="isshowsmileraiting">
      <div class="keypad-actions">
     
        <button  (click)="submitRating(1)">
          <img [class.active]="activeImag ==1" src="assets/images/Emoji/6.png">
         
      </button>
      <button (click)="submitRating(2)">
        <img  [class.active]="activeImag ==2" src="assets/images/Emoji/5.png">
       
    </button>   
    <button (click)="submitRating(3)">
      <img  [class.active]="activeImag ==3" src="assets/images/Emoji/4.png">
     
  </button>
      </div>
    </div>
    <div *ngIf="!isshowsmileraiting"> <div class="stars">
      <ngx-star-rating [(ngModel)]="rating" [id]="'rating'" ></ngx-star-rating >
    </div>
    </div>

   
  
    <h3 class="Labelcolor fontType"> {{ rating }}: التقييم </h3>
    <br>

    <div> 
      <h3 class="fontType"> لمقتراحاتكم التي تساهم في تحسين الخدمة  </h3>

      <textarea class="form-control" [(ngModel)]="txtNote" id="txtNote" rows="6" cols="40"></textarea>
    </div>
    <br>
    <button mat-raised-button type="submit" class="submit fontType" (click)="saveFeadback()">إرسال</button>
  </div>
  <div class="body" *ngIf="IsRated">

    <h2 class="Labelcolor fontType" > تمت عملية التقييم بنجاح </h2>

  </div>
</div>

