import { AgmCoreModule } from "@agm/core";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { FeedBackComponent } from "./feedback/feedback.component";
import { FeedBackService } from "./feedback/feedback.service";
import { NgxStarRatingModule } from 'ngx-star-rating';



const routes = [
  {
    path     : 'feedback/tiket/:tiketId/user/:userId',
    component: FeedBackComponent
  }
];
@NgModule({
  declarations: [
    FeedBackComponent   
  ],
  imports: [
    AgmCoreModule.forRoot({
        apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
    }),
    RouterModule.forChild(routes),
    TranslateModule,
    CommonModule,
    FuseSharedModule,
    NgxStarRatingModule
  ],
  providers:[
    FeedBackService     
  ]
  
})
export class FeedBackModule { }
