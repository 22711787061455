import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AgmCoreModule } from '@agm/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FuseConfirmDialogModule } from '@fuse/components';
import { MatDialogModule } from '@angular/material/dialog';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { PermissionComponent } from './permission-list/permission-list.component';
import { PermissionEntryComponent } from './permission-entry/permission-entry.component';
import { AssignPermissionToRoleComponent } from './assign-permission-to-Role/assign-permission-to-Role.component';
import { PermissionService } from './permission.service';
import { TranslateModule } from '@ngx-translate/core';
import { RoleService } from '../role/role.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';


const routes = [
  {
      path     : 'permission',
      component: PermissionComponent
  },
  {
    path     : 'permission/add',
    component: PermissionEntryComponent
  },
  {
    path: 'permission/edit/:id',
    component: PermissionEntryComponent
  }
  /*,
  {
    path: 'role/:RoleId/Permission/:PermissionId',
    component: AssignPermissionToRoleComponent
  }
  */
];
@NgModule({
  
  declarations: [
    PermissionComponent,
    PermissionEntryComponent,
    AssignPermissionToRoleComponent   
  ],
  imports: [
    MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    NgxChartsModule,
    FuseConfirmDialogModule,
    MatDialogModule,
    AgmCoreModule.forRoot({
        apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
    }),
    RouterModule.forChild(routes),
    TranslateModule,
    CommonModule,
    FuseSharedModule,
    FuseWidgetModule,
    BrowserAnimationsModule,
    DragDropModule,
    MatProgressSpinnerModule,
    NgxSkeletonLoaderModule
   
  ],
  providers:[
    PermissionService,
    RoleService    
  ]
  
})
export class PermissionModule { }
