import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        permission : "Applications_Menu",
        children: [
            {
                id: 'tenant',
                title: 'tenant',
                translate: 'NAV.TITLE',
                type: 'group',
                icon: 'home',
                url: '',
                permission: "Tenant_Menu",
                
            }
            ,
            {
                id: 'dashboards',
                title: 'Dashboards',
                translate: 'NAV.DASHBOARDS',
                type: 'collapsable',
                icon: 'dashboard',
                permission: "Dashboards_Menu",
                children: [
                    {
                        id: 'tenant',
                        translate:'NAV.DASHBOARD.Tenant',
                        title: 'Dashboard Tenant',
                        type: 'item',
                        url: '/tenant/dashboards',
                        permission: "Dashboard_Tenant_Menu",

                    },
                    {
                        id: 'location',
                        translate:'NAV.DASHBOARD.Location',
                        title: 'Dashboard Location',
                        type: 'item',
                        url: '/location/dashboards',
                        permission: "Dashboard_Location_Menu",

                    }
                    ,
                    {
                        id: 'dashboardDynamic',
                        translate:'NAV.DASHBOARD.Dynamic',
                        title: 'Dashboard Dynamic',
                        type: 'item',
                        url: '/dynamic-dashboad',
                        permission: "Dashboard_Location_Menu",

                    }
                ]
            },
            {
                id: 'category',
                title: 'Category',
                translate: 'NAV.CATEGORY',
                type: 'item',
                icon: 'category',
                url: '/category',
                permission: "Category_Menu",

            },
            {
                id: 'BranchesManagement',
                title: 'Branches Management',
                translate: 'NAV.BRANCHESMANAGEMENT.TITLE',
                type: 'collapsable',
                icon: 'account_balance',
                permission: "Branches_Management_Menu",
                children: [
                    {
                        id: 'tenant',
                        title:'Tenant',
                        translate: 'NAV.TENANT',
                        type: 'item',
                        url: './tenant',
                        permission: "Tenant_menu"
                        
                    }
                    // {
                    //     id   : 'Location',
                    //     title: 'Location',
                    //     type : 'item',
                    //     url  : './location'
                    // }
                ]
            },
            {
                id: 'importuseres',
                title: 'Import Useres',
                translate: 'NAV.IMPORT',
                type: 'item',
                icon: 'import_export',
                url: '/import-useres',
                permission : "Import_menu"

            },
            {
                id: 'calendar',
                title: 'Calendar',
                translate: 'NAV.CALENDAR',
                type: 'item',
                icon: 'today',
                url: '/calendar',
                permission : "Calendar_menu"

            },
            // {
            //     id: 'mail',
            //     title: 'Mail',
            //     translate: 'NAV.MAIL.TITLE',
            //     type: 'item',
            //     icon: 'email',
            //     url: '/apps/mail/inbox',
            //     badge: {
            //         title: '25',
            //         translate: 'NAV.MAIL.BADGE',
            //         bg: '#F44336',
            //         fg: '#FFFFFF'
            //     }
            // }, {
            //     id: 'chat',
            //     title: 'Chat',
            //     translate: 'NAV.CHAT',
            //     type: 'item',
            //     icon: 'chat',
            //     url: '/chat',
            //     badge: {
            //         title: '13',
            //         bg: '#09d261',
            //         fg: '#FFFFFF'
            //     }
            // },
            {
                id: 'role',
                title: 'Role',
                translate: 'NAV.ROLE',
                type: 'item',
                icon: 'timeline',
                url: '/role',
                permission : "Role_Menu"

            },
            {
                id: 'permission',
                title: 'Permission',
                translate: 'NAV.Permission',
                type: 'item',
                icon: 'how_to_reg',
                url: '/permission',
                permission : "Permission_menu"
            },
            {
                id: 'banner',
                title: 'Banner',
                translate: 'NAV.BANNER',
                type: 'item',
                icon: 'stay_primary_portrait',
                url: '/banner',
                permission : "Banner_All"
            },

            {
                id: 'keypad',
                title: 'Keypad',
                translate: 'NAV.KEYPAD',
                type: 'item',
                icon: 'dialpad',
                url: '/keypad',
                permission : 'Ticket_Write'

            },

            {
                id: 'report',
                title: 'Report',
                translate: 'NAV.REPORT',
                type: 'item',
                icon: 'print',
                url: '/report',
                permission : "Report_Menue"
            },
            {
                id: 'workflow',
                title: 'Work Flow',
                translate: 'NAV.WORKFLOW',
                type: 'item',
                icon: 'workflow-diagram',
                url: '/workflow',
                permission : "WorkFlow"
            }
            ,
            {
                id: 'publicHoliday',
                title: 'Public Holiday',
                translate: 'NAV.PUBLICHOLIDAY',
                type: 'item',
                icon: 'pool',
                url: '/public-holiday',
                permission : "Public-Holiday"
            }
        ]
    },

];
