import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable  ,BehaviorSubject } from 'rxjs';
import { apiUrls } from 'app/shared/constants/apiUrls';
import { environment } from 'environments/environment';
import { DynamicDashbord } from 'app/model/DynamicDashbord';

@Injectable()
export class CallApiService implements Resolve<any>
{

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
    }

    dynamicDashbords : DynamicDashbord[] = new Array<DynamicDashbord>();
    dynamicDashbord : DynamicDashbord  =   new DynamicDashbord() ;
    onTenantChanged: BehaviorSubject<any>;

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }


    getAllTenants(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.callApi)
                .subscribe((response: any) => {
                    this.dynamicDashbords = response;
                    this.onTenantChanged.next(this.dynamicDashbords);
                    resolve(response);
                }, reject);
        });
    }
  
    getRoleTenants(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serviceUrl3 + apiUrls.tenant + apiUrls.role)
                .subscribe((response: any) => {
                    this.dynamicDashbords = response;
                    this.onTenantChanged.next(this.dynamicDashbords);
                    resolve(response);
                }, reject);
        });
    }

    // Delete 
 delete(id): Promise<any>
 {
     return new Promise((resolve, reject) => {
         this._httpClient.delete(environment.serviceUrl3 + apiUrls.callApi + id )
             .subscribe((response: any) => {
                 resolve(response);
             }, reject);
     });
 }

 // update 
  save(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put(environment.serviceUrl3 + apiUrls.callApi , data)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

   // save 
  add(data): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl3 + apiUrls.callApi, data)
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }

}
