import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { AgmCoreModule } from '@agm/core';
import { ChartsModule } from 'ng2-charts';
import { DataSource } from '@angular/cdk/collections';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { AnalyticsDashboardComponent } from 'app/modules/dashboards/analytics/analytics.component';
import { AnalyticsDashboardService } from 'app/modules/dashboards/analytics/analytics.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CategoryService } from 'app/modules/category/category.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FuseConfirmDialogModule } from '@fuse/components';
import { TranslateModule } from '@ngx-translate/core';
import { NameLanguageEditerModule } from 'app/component/name-language-editer/name-language-editer.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SoltTimeEditorModule } from 'app/component/slot-time-editor/slot-time-editor.module';
import { PrerequisiteEditerModule } from 'app/component/prerequisite_editer/prerequisite-editer.module';






const routes: Routes = [
    {
        path     : 'tenant/dashboards',
        component: AnalyticsDashboardComponent,
        resolve  : {
            data: AnalyticsDashboardService
        }
    }
];

@NgModule({
    declarations: [
        AnalyticsDashboardComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatTooltipModule,
        NgxChartsModule,
        FuseConfirmDialogModule,
        MatDialogModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
        }),
        RouterModule.forChild(routes),
        TranslateModule,
        CommonModule,
        FuseSharedModule,
        FuseWidgetModule,
        BrowserAnimationsModule,
        DragDropModule,
        NameLanguageEditerModule,
        PrerequisiteEditerModule,
        SoltTimeEditorModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatSelectModule,
        MatTabsModule,
        MatPaginatorModule,
        NgCircleProgressModule.forRoot({
            "radius": 60,
            "space": -10,
            "outerStrokeGradient": true,
            "outerStrokeWidth": 10,
            "outerStrokeColor": "#4882c2",
            "outerStrokeGradientStopColor": "#53a9ff",
            "innerStrokeColor": "#e7e8ea",
            "innerStrokeWidth": 10,
            "title": "No Tickets Service Above 5 min",
            "animateTitle": false,
            "animationDuration": 1000,
            "showUnits": false,
            "showBackground": false,
            "clockwise": false,
            "startFromZero": false,
           
            "lazy": true}),
       
        //...
     
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCD5Pl7RjrPLZcXyt_SMp6qjVRl38pk-H4'
        }),
        ChartsModule,
        NgxChartsModule,
 
        FuseSharedModule,
        FuseWidgetModule,
        NgxSkeletonLoaderModule
    ],
    providers   : [
        AnalyticsDashboardService,
        CategoryService
    ]
})
export class AnalyticsDashboardModule
{
}

