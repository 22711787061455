<div id="tenants" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    account_balance
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    {{'TENANT_LIST.Tenants' | translate}}
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>{{'search' | translate}}</mat-icon>
                    <input (keyup)="tenantSearch($event.target.value)" placeholder="{{'TENANT_LIST.searchForTenant' | translate}}">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <button mat-raised-button [routerLink]="'/tenant/add'" class="add-tenant-button fuse-white mt-24 mt-md-0">
                <span>{{'TENANT_LIST.addNewTenant' | translate}}</span>
            </button>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">
            <div class="list-skeleton" *ngIf="loaderService.isLoading">
                <ngx-skeleton-loader
                    [theme]="{ width: '100%', height: '35px'}"
                    animation="progress"
                    count="10">
                </ngx-skeleton-loader>
            </div>
            <mat-table *ngIf="!loaderService.isLoading" class="tenants-table custom-table" #table [dataSource]="dataSource" matSort [@animateStagger]="{value:'50'}"
                fusePerfectScrollbar>

                <!-- ID Column -->
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
                    <mat-cell *matCellDef="let tenant" [routerLink]="'/tenant/edit/' + tenant.id">
                        <p class="text-truncate">{{tenant.id}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="image">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{'TENANT_LIST.logo' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let tenant" class="image-column">
                        <!-- ADD FILE BUTTON -->
                        <div *ngIf="tenant.logo ==null"   class="file-uploader">
                            <input hidden type="file" #fileInput [accept]="allowedFiles"
                                (change)="uploadImage($event , tenant.id)" />
                            <button mat-mini-fab class="add-file-btn" (click)="fileInput.click()" aria-label="Add file"
                                [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                                <mat-icon>add</mat-icon>

                            </button>
                    
                        </div>
                        <div *ngIf="tenant.logo !=null">
                            <img src="{{'data:image/jpg;base64,' + tenant.logo}}" />
                        </div>
                    </mat-cell>
                </ng-container>
                <!-- / ADD FILE BUTTON -->
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'TENANT_LIST.name' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let tenant" [routerLink]="'/tenant/edit/' + tenant.id">
                        <p class="text-truncate">{{tenant.name}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'TENANT_LIST.email' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let tenant" [routerLink]="'/tenant/edit/' + tenant.id">
                        <p class="text-truncate">{{tenant.email}}</p>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'TENANT_LIST.actions' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let tenant">

                        <mat-icon matTooltip="{{'TENANT_LIST.locations' | translate}}"  
                            (click)="tenantLocation(tenant.id)" 
                            class="logo-icon mx-1"
                            [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                            location_on
                        </mat-icon>

                        <mat-icon matTooltip="{{'TENANT_LIST.services' | translate}}"
                            (click)="tenantServices(tenant.id)" 
                            [routerLink]="'/service/tenant/' + tenant.id" class="logo-icon mx-1"
                            [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                            storage
                        </mat-icon>

                        <mat-icon class="logo-icon mx-1" *ngIf="userService.roleName == 'SYS_ADMIN'"
                            (click)="edit(tenant.id)" 
                            [@animate]="{value:'*',params:{delay:'200ms',scale:'0.4'}}"
                            matTooltip="{{'TENANT_LIST.editTenant' | translate}}">
                            edit
                        </mat-icon>

                        <mat-icon class="logo-icon mx-1" *ngIf="userService.roleName == 'SYS_ADMIN'" (click)="deleteTenant(tenant.id)"
                            [@animate]="{value:'*',params:{delay:'275ms',scale:'0.6'}}"
                            matTooltip="{{'TENANT_LIST.deleteTenant' | translate}}">
                            delete_forever
                        </mat-icon>

                        <mat-icon class="logo-icon mx-1" [routerLink]="'/tenant/' + tenant.id + '/user'"
                            (click)="tenantUser(tenant.id)" 
                            [@animate]="{value:'*',params:{delay:'275ms',scale:'0.6'}}"
                            matTooltip="{{'TENANT_LIST.tenantUser' | translate}}">
                            account_circle
                        </mat-icon>

                        <mat-icon matTooltip="{{'ADVERTISEMENT_ENTRY.addnewgroup' | translate}}"
                        [routerLink]="'/Advertisementlist/tenant/' + tenant.id" class="logo-icon mx-1"
                        [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                        add_circle
                    </mat-icon>

                    </mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let tenant; columns: displayedColumns;" class="tenant" matRipple>
                    <!-- [routerLink]="'/tenant/add/' + tenant.id" -->
                </mat-row>

            </mat-table>

            <mat-paginator  *ngIf="length > 0" [length]="length" [pageIndex]="pageIndex - 1" [pageSize]="pageSize"
              (page)='getTenantBypagenation($event)'
              [pageSizeOptions]="pageSizeOptions">
            </mat-paginator>
           
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>