import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { ProjectDashboardComponent } from 'app/modules/dashboards/project/project.component';
import { ProjectDashboardService } from 'app/modules/dashboards/project/project.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { NameLanguageEditerModule } from 'app/component/name-language-editer/name-language-editer.module';
import { ChartsModule } from 'ng-uikit-pro-standard';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SoltTimeEditorModule } from 'app/component/slot-time-editor/slot-time-editor.module';
import { PrerequisiteEditerModule } from 'app/component/prerequisite_editer/prerequisite-editer.module';

const routes: Routes = [
    {
        path     : 'location/dashboards',
        component: ProjectDashboardComponent,
        resolve  : {
            data: ProjectDashboardService
        }
    }
];

@NgModule({
    declarations: [
        ProjectDashboardComponent,

    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatSelectModule,
        MatTableModule,
        MatTabsModule,
        ChartsModule,
        NgxChartsModule,
        FuseSharedModule,
        FuseWidgetModule,
        FuseSidebarModule,
        TranslateModule,
        CommonModule,
        BrowserAnimationsModule,
        DragDropModule,
        NameLanguageEditerModule,     
        PrerequisiteEditerModule,
        MatPaginatorModule,
        NgxSkeletonLoaderModule
    ],
    providers   : [
        ProjectDashboardService
    ]
})
export class ProjectDashboardModule
{
}

