import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { fuseAnimations, FuseUtils } from '@fuse/utils';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WorkFlowService } from '../workflow.service';
import { locale as english } from '../../../main/home/i18n/en';
import { locale as arabic } from '../../../main/home/i18n/ar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { TenantService } from 'app/modules/branches-management/tenant/tenant.service';
import { LoaderService } from 'app/shared/services/loader-service';
@Component({
    selector: 'app-workflow-list',
    templateUrl: './workflow-list.component.html',
    styleUrls: ['./workflow-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class WorkFlowListComponent implements OnInit {
    dataSource: FilesDataSource | null;
    displayedColumns = ["image","name", "actions"];
    allowedFiles: string = 'image/*';
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    @ViewChild('filter', { static: true })
    filter: ElementRef;

    // Private
    private _unsubscribeAll: Subject<any>;
    invalidImage: boolean;

    constructor(
        public loaderService : LoaderService,
        private workflowService: WorkFlowService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private tenantService: TenantService,
    ) {
        
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        // ---------------------------------------------------
        this._fuseTranslationLoaderService.loadTranslations(english, arabic);        // Set the private defaults
    }
    //--------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.dataSource = new FilesDataSource(this.workflowService, this.paginator, this.sort);


        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }

                this.dataSource.filter = this.filter.nativeElement.value;
            });
      this.getCatigories();
    }

    getCatigories() {
        this.workflowService.getWorkflow().then(res => {
            if (res) {
                this.dataSource = res;
            }
        })
    }   

    validateFiles(files: File[]): boolean {
        for (var i = 0; i < files.length; i++) {
            if (!this.isFileTypeValid(files[i])) {
                return false;
            } 
            return true;
        }

    }

    private isFileTypeValid(file: File): boolean {
        let acceptableTypes = this.allowedFiles.split(',').map(type => type.trim());
        for (let type of acceptableTypes) {
            let acceptable = this.isWildcard(type) ? this.getTypeClass(file.type) === this.getTypeClass(type)
                : file.type == type || this.getFileExtension(file).toLowerCase() === type.toLowerCase();

            if (acceptable) {
                return true;
            }
        }

        return false;
    }
    isWildcard(fileType: string): boolean {
        return fileType.indexOf('*') !== -1;
    }
    getTypeClass(fileType: string): string {
        return fileType.substring(0, fileType.indexOf('/'));
    }
    getFileExtension(file: File): string {
        return '.' + file.name.split('.').pop();
    }
    deleteWorkflow(id) {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });


        this.confirmDialogRef.componentInstance.confirmMessage = this.translate.instant('CONFIRM_MSG.wantToDelete')




        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.workflowService.deleteWorkflow(id).then(() => {
                    // Show the success message
                    this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.workflowDeleted'), this.translate.instant('CONFIRM_MSG.ok'), {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                    this.ngOnInit();
                })

            }
        })

    }
    
   
}

export class FilesDataSource extends DataSource<any>
{
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param {MatPaginator} _matPaginator
     * @param {MatSort} _matSort
     */
    constructor(
        private workflowService: WorkFlowService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    ) {
        super();

        this.filteredData = this.workflowService.workflows;
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this.workflowService.onWorkflowChanged,
            this._matPaginator.page,
            this._filterChange,
            // this._matSort.sortChange
        ];

        return merge(...displayDataChanges)
            .pipe(
                map(() => {
                    let data = this.workflowService.workflows.slice();

                    data = this.filterData(data);

                    this.filteredData = [...data];

                    data = this.sortData(data);

                    // Grab the page's slice of data.
                    const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
                    return data.splice(startIndex, this._matPaginator.pageSize);
                }
                ));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Filtered data
    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch (this._matSort.active) {
                case 'id':
                    [propertyA, propertyB] = [a.id, b.id];
                    break;
                case 'name':
                    [propertyA, propertyB] = [a.name, b.name];
                    break;

            }

            const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
        });
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }
}



