import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'app/modules/branches-management/user/user.service';
import { locale as english } from '../../main/home/i18n/en';
import { locale as turkish } from '../../main/home/i18n/tr';
import { locale as arabic } from  '../../main/home/i18n/ar';

@Component({
  selector: 'app-reset-password-popup',
  templateUrl: './reset-password-popup.component.html',
  styleUrls: ['./reset-password-popup.component.scss']
})
export class ResetPasswordPopupComponent implements OnInit {

  form: FormGroup;
  newPassword: string = null;
  confirmPassword : string = null;
  currentLanguage: any;
  userId : any ;
  showMessage : boolean = false;
  password:any;
  confirmPass:any
  show : any;     
  showHidePass : any;
  showHideConfirmPass : any;

  ngOnInit(): void {

    this.form = this._formBuilder.group({
      password: [{ value: this.data.password}],
      confirmPassword: [{ value: this.data.confirmPassword }]
    });
    this.currentLanguage = localStorage.getItem('selectedLanguage');
    this.password = "password";
    this.confirmPass = "password";
    this.showHidePass = "visibility";
    this.showHideConfirmPass = "visibility";
  }


  constructor(
    private _formBuilder: FormBuilder,
    private _matSnackBar: MatSnackBar,
    private _userService: UserService,
    public dialogRef: MatDialogRef<ResetPasswordPopupComponent>,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    public translate :TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { 
      this.userId = data.userId;
      this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);
    }

  cancelClick(): void {
    this.dialogRef.close("canceled");
  }


  restePassword() {
    var data = this.form.getRawValue();
    if(this.newPassword != null && this.confirmPassword != null){
      if(this.newPassword == this.confirmPassword && this.userId){
        data.userId = this.userId;
        this._userService.resetPassword(data)
        .then(() => {
          // Show the success message
          this._matSnackBar.open(this.translate.instant('RESET_PASSWORD_POPUP.passwordSetsuccessfully'), this.translate.instant('ok'), {
            verticalPosition: 'top',
            duration: 2000
          });
          this.dialogRef.close("canceled");
        });
  
      }
    }

  }


  showPassword(){
    if (this.password === 'password') {
        this.password = 'text';
        this.showHidePass = "visibility_off";
      } else {
        this.password = 'password';
        this.showHidePass = "visibility";
      }
}


showConfirmPass(){
  if (this.confirmPass === 'password') {
      this.confirmPass = 'text';
      this.showHideConfirmPass = "visibility_off";
    } else {
      this.confirmPass = 'password';
      this.showHideConfirmPass = "visibility";
    }
}

}

export interface DialogData {
  userId : string;
  password: string;
  confirmPassword: string;
}
