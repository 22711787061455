<div id="services" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/tenant'">
                    <mat-icon>{{'arrow_back' | translate}}</mat-icon>
                </button>
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    account_balance
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    {{'SERVICE_LIST.services' | translate}}
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon> {{'search' | translate}}</mat-icon>
                    <input #filter placeholder="{{'SERVICE_LIST.searchForService' | translate}}">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <button mat-raised-button [routerLink]="'/Prerequisite/service/'+ serviceId + '/add'"
                class="add-service-button fuse-white mt-24 mt-md-0">
                <span>{{'PREREQUISITE_ENTRY.addNewprerequisite' | translate}}</span>
            </button>
            <!-- / ADD BUTTON -->
            

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">
            <div class="list-skeleton" *ngIf="loaderService.isLoading">
                <ngx-skeleton-loader
                    [theme]="{ width: '100%', height: '35px'}"
                    animation="progress"
                    count="10">
                </ngx-skeleton-loader>
            </div>
            <mat-table *ngIf="!loaderService.isLoading " class="services-table custom-table" #table [dataSource]="dataSource" matSort [@animateStagger]="{value:'50'}"
                fusePerfectScrollbar>

                <!-- ID Column -->
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'SERVICE_LIST.id' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let service">
                        <p class="text-truncate">{{service.id}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'SERVICE_LIST.name' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let service">
                        <p class="text-truncate">{{service.name}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'SERVICE_LIST.description' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let service">
                        <p class="text-truncate">{{service.description}}</p>
                    </mat-cell>
                </ng-container>

                
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'SERVICE_LIST.actions' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let service">


                        <mat-icon class="logo-icon mx-1"
                            [routerLink]="'/Prerequisite/service/'+ serviceId + '/edit/' + service.id"
                            [@animate]="{value:'*',params:{delay:'200ms',scale:'0.4'}}"
                            matTooltip="{{'SERVICE_LIST.editService' | translate}}">
                            edit
                        </mat-icon>

                        <mat-icon class="logo-icon mx-1" (click)="deleteService(service.id)"
                            [@animate]="{value:'*',params:{delay:'275ms',scale:'0.6'}}"
                            matTooltip="{{'SERVICE_LIST.deleteService' | translate}}">
                            delete_forever
                        </mat-icon>

                    </mat-cell> 
                </ng-container>
    

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let service; columns: displayedColumns;" class="service" matRipple>
                    <!-- [routerLink]="'/service/add/' + service.id" -->
                </mat-row>

            </mat-table>

            <mat-paginator #paginator [length]="dataSource ? dataSource : 0" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>