import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { fuseAnimations } from '@fuse/animations';
import { AdvertisementService } from '../Advertisement.service';
import { Service } from 'app/model/Service';
import { ActivatedRoute, Router } from '@angular/router';
import { locale as english } from '../../../../main/home/i18n/en';
import { locale as turkish } from '../../../../main/home/i18n/tr';
import { locale as arabic } from '../../../../main/home/i18n/ar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { NameLanguageEditer } from 'app/component/name-language-editer/name-language-editer.component';
import { Advertisement } from 'app/model/Advertisement';
import { AdsMedia } from 'app/model/AdsMedia';

@Component({
    selector: 'app-AdsMediaItem​Enty',
    templateUrl: './AdsMediaItem​Enty.component.html',
    styleUrls: ['./AdsMediaItem​Enty.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AdsMediaItemEntryComponent implements OnInit {
     data2 = {
        id: '',
        name: '',
        news: '',
        mediaGroupId:'',
        isActivated: true,
        mediaType: 2,

      };

    
    pageType: string;
    advertisement:AdsMedia
    advertisementForm: FormGroup;
    @ViewChild("nameLanguageEditor") nameLanguageEditor :NameLanguageEditer;


    // Private
    private _unsubscribeAll: Subject<any>;
    extras: any;

    /**
     * Constructor
     *
     * @param {ServiceService} _serviceService
     * @param {FormBuilder} _formBuilder
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        private advertisementService: AdvertisementService,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private route: ActivatedRoute,
        private router: Router,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translate:TranslateService,
        private _location: Location,
    ) {
        // Set the default
        this.advertisement = new AdsMedia();
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            if (params.get('id')) { //edit
                this.advertisement.id = params.get('id')
                this.pageType = 'edit';
                var data=  this.advertisementService.getMediaItemById(this.advertisement.id )
                .then((rss) => {
                  this.advertisement.name= rss.name ;
                  this.advertisement.news=rss.news
                  this.advertisement.mediaGroupId = params.get('mediaGroupId');
                  this.advertisementForm = this.createServiceForm();
  
              //   this.goBack()
                  // Change the location with new one
  
              });
   
                this.advertisement.mediaGroupId 
            }
            else {
                if (params.get('mediaGroupId')) {//save
                    this.advertisement.mediaGroupId = params.get('mediaGroupId');
                }
                this.pageType = 'new';
            }
            this.advertisementForm = this.createServiceForm();
        });

    }
    goBack() {
        this._location.back();
    };


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create service form
     *
     * @returns {FormGroup}
     */
    createServiceForm(): FormGroup {
        return this._formBuilder.group({
            name: [this.advertisement.name],
            id: [this.advertisement.id],
            news: [this.advertisement.news],
            createdTime: [this.advertisement.createdTime],
            isActivated: [this.advertisement.isActivated],
            mediaGroupId: [this.advertisement.mediaGroupId],
            mediaType: [this.advertisement.mediaGroupId],
        });
    }

    isValidForm(){
        return this.advertisementForm?.valid 
     }

    /**
     * Save service
     */
    saveService(): void {
        
        
        const data = this.advertisementForm.getRawValue();
       
        this.data2.id=data.id
        this.data2.name=data.name
        this.data2.news=data.news
        this.data2.isActivated=data.isActivated
        this.data2.mediaGroupId=data.mediaGroupId
        this.data2.mediaType=2 ; 
          this.advertisementService.updateAdsmediaitem(this.data2)
            .then(() => {

                // Trigger the subscription with new data
                this.advertisementService.onServiceChanged.next(data);

                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.serviceAdded'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
              this.goBack()
                // Change the location with new one

            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }

    /**
     * Add service
     */
    addAdsmediaitem(): void {
        const data = this.advertisementForm.getRawValue();
        data.id = undefined;
        data.createdTime=new Date().toISOString();
        data.mediaGroupType=Number(data.mediaGroupType) ;
        data.mediaType=2 ;
        //   data.handle = FuseUtils.handleize(data.name);

        this.advertisementService.addAdsmediaitem(data)
            .then(() => {

                // Trigger the subscription with new data
                this.advertisementService.onServiceChanged.next(data);

                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.serviceAdded'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
              this.goBack()
                // Change the location with new one

            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }
}
