import { AgmCoreModule } from "@agm/core";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { NgxStarRatingModule } from 'ngx-star-rating';
import { ImportExportComponent } from "./import-export.component";
import { ImportExportService } from "./import-export.service";


const routes = [
  {
    path     : 'import-useres/tenant/:tenantId/location/:locationId',
    component: ImportExportComponent
  }
];
@NgModule({
  declarations: [
    ImportExportComponent   
  ],
  imports: [
    AgmCoreModule.forRoot({
        apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
    }),
    RouterModule.forChild(routes),
    TranslateModule,
    CommonModule,
    FuseSharedModule,
    NgxStarRatingModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatToolbarModule,
  ],
  providers:[
    ImportExportService  ]
  
})
export class ImportExportModule { }
