import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from '../../service.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { User as UserModel } from 'app/model/User';
import { PopupUsersServiceComponent } from 'app/modal/popup-users-service/popup-users-service.component';

import { TenantService } from 'app/modules/branches-management/tenant/tenant.service';
import { Tenant } from 'app/model/Tenant';
import { UserService } from 'app/modules/branches-management/user/user.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocationService } from 'app/modules/branches-management/location/location.service';
import { locale as english } from '../../../../../main/home/i18n/en';
import { locale as turkish } from '../../../../../main/home/i18n/tr';
import { locale as arabic } from '../../../../../main/home/i18n/ar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-assign-service-to-user',
  templateUrl: './assign-service-to-user.component.html',
  styleUrls: ['./assign-service-to-user.component.scss']
})
export class AssignServiceToUserComponent implements OnInit {
  TenantId: string;
  Tenant: Tenant;
  UserId: string;
  User: UserModel;
  LocationServices = [];
  UserServices = [];
  ChangeStatus: boolean;
  locationId: string;
  userData : any;

  constructor(
    private route: ActivatedRoute,
    private serviceService: ServiceService,
    private tenantService: TenantService,
    private _userServices: UserService,
    private _locationService: LocationService,
    public dialog: MatDialog,
    private _matSnackBar: MatSnackBar,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private translate : TranslateService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);
  }

  ngOnInit(): void {
    this.resetServices();
    this.route.paramMap.subscribe(params => {
      if (params.get('tenantId')) {
        this.TenantId = params.get('tenantId');
      }
      if (params.get('userId')) {
        this.UserId = params.get('userId');
        this.getUserServices(() => { this.getLocationServices() });
        this.getUserDetails(this.UserId);
      }
      if (params.get('locationId')) {
        this.locationId = params.get('locationId');
        this.getLocationServices();
      }
    })
  }
  resetServices() {
    this.LocationServices = [];
    this.UserServices = [];
  }
  getUserServices(callBack?) {

    this._userServices.getUserServices(this.UserId).then(res => {
      res.forEach(element => {
        this.UserServices.push({ id: element.id, serviceId: element?.locationServices?.service?.id, name: element?.locationServices?.service?.name,priority : element?.priority });
      });
      if(callBack)
        callBack();
    })
  }

  getUserDetails(userId) {

    this._userServices.getUserById(this.UserId).then(res => {
      if(res){
        this.userData =res;
      }
    })
  }

  getLocationServices() {
    this.serviceService.getServicsByLocation(this.locationId).then(res => {
      if (res) {
        this.LocationServices = res.filter(x => x.chr != " " && !(this.UserServices?.map(({ serviceId }) => serviceId).includes(x.service.id)));
      }
    });
  }

  openDialog(IsOpenDialog: boolean, e): void {
    this.ChangeStatus = true;
    let locationServiceId = e?.item?.element?.nativeElement?.name?.split('/')[0];
    if (IsOpenDialog) {
      if (locationServiceId) {
        this.confirmUsersDialogRef = this.dialog.open(PopupUsersServiceComponent, {
          width: '500px',
          data: { 
            UserId: this.UserId,
            locationServiceId: locationServiceId,
          
            }
        });  
       // this.confirmDialogRef.componentInstance.confirmMessage =  this.translate.instant('CONFIRM_MSG.assignServiceToUser');
        this.confirmUsersDialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.ngOnInit();
          }
        });
      } else {
        this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.wrong'), this.translate.instant('ok'), {
          verticalPosition: 'top',
          duration: 2000
        });
      }
    } else {
      this.deleteUserService(e);
    }

  }
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  confirmUsersDialogRef: MatDialogRef<PopupUsersServiceComponent>;
  deleteUserService(e) {
    let userServiceId = e?.item?.element?.nativeElement?.name?.split('/')[0];
    if (userServiceId) {
      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
        disableClose: false
      });
      this.confirmDialogRef.componentInstance.confirmMessage = this.translate.instant('CONFIRM_MSG.deleteUserService');
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this._userServices.deleteUserService(userServiceId).then(() => {
            // Show the success message
            this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.userServiceDeleted'), this.translate.instant('CONFIRM_MSG.ok'), {
              verticalPosition: 'top',
              duration: 2000
            });
            this.ngOnInit();
          })

        } else {
          this.ngOnInit()
        }
      })
    } else {
      this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.wrong'), this.translate.instant('ok'), {
        verticalPosition: 'top',
        duration: 2000
      });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
}

