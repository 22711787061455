import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Tenant } from 'app/model/Tenant';
import { apiUrls } from 'app/shared/constants/apiUrls';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TenantService implements Resolve<any>{
    uploadImage(file: any , id :string) {
        const form = new FormData();
            form.append('file', file, file.name);
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.serviceUrl3 + apiUrls.tenant + "/UploadFile?id=" + id  , form)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
 
  tenants : Tenant[] = new Array<Tenant>();
  tenant : Tenant  =   new Tenant() ;
  onTenantChanged: BehaviorSubject<any>;
  currentPage: number = 1;
  pageSize : number = 10;

   /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
      private _httpClient: HttpClient
  )
  {
      // Set the defaults
      this.onTenantChanged = new BehaviorSubject({});
  }

   /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getRoleTenantsWithoutPagenation()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

  
  getAllTenants(): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.tenant)
              .subscribe((response: any) => {
                  this.tenants = response;
                  this.onTenantChanged.next(this.tenants);
                  resolve(response);
              }, reject);
      });
  }


  getRoleTenantsWithoutPagenation(): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.tenant + apiUrls.role  )
              .subscribe((response: any) => {
                  this.tenants = response;
                  this.onTenantChanged.next(this.tenants);
                  resolve(response);
              }, reject);
      });
  }

  getRoleTenants(pageIndex ,pageSize): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.tenant + apiUrls.role + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize )
              .subscribe((response: any) => {
                  this.tenants = response;
                  this.onTenantChanged.next(this.tenants);
                  resolve(response);
              }, reject);
      });
  }

  getRoleTenantByFillter(pageIndex ,pageSize , search): Promise<any>{
    return new Promise((resolve, reject) => {
        this._httpClient.get(environment.serviceUrl3 + apiUrls.tenant + apiUrls.role + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize + '&search=' + search )
            .subscribe((response: any) => {
                this.tenants = response;
                this.onTenantChanged.next(this.tenants);
                resolve(response);
            }, reject);
    });
  }

  getTenantById(id , isDefaultLanguage = false): Promise<any>
  {
      return new Promise((resolve, reject) => {
        let headers: HttpHeaders = new HttpHeaders();
        if(isDefaultLanguage){
            headers = headers.append("without-accept-language" , "true");
        }
    //    let header :HttpHeaders = new HttpHeaders();
    //    header.append("without-accept-language" , "true")
          this._httpClient.get(environment.serviceUrl3 + apiUrls.tenant +  id , {headers : headers} )
              .subscribe((response: any) => {
                  this.tenant = response;
                  this.onTenantChanged.next(this.tenant);
                  resolve(response);
              }, reject);
      });
  }

 

  
 // Delete tenant
 deleteTenant(id): Promise<any>
 {
     return new Promise((resolve, reject) => {
         this._httpClient.delete(environment.serviceUrl3 + apiUrls.tenant + id )
             .subscribe((response: any) => {
                 resolve(response);
             }, reject);
     });
 }

  saveTenant(tenant): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put(environment.serviceUrl3 + apiUrls.tenant , tenant)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  addTenant(tenant): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl3 + apiUrls.tenant, tenant)
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }

}
