import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { fuseAnimations, FuseUtils } from '@fuse/utils';
import { User } from 'app/model/User';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../user.service';
import { ActivatedRoute } from '@angular/router';
import { locale as english } from '../../../../main/home/i18n/en';
import { locale as turkish } from '../../../../main/home/i18n/tr';
import { locale as arabic } from '../../../../main/home/i18n/ar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'app/shared/services/loader-service';
import { ResetPasswordPopupComponent } from 'app/modal/reset-password-popup/reset-password-popup.component';
import {  Router} from '@angular/router';
@Component({
    selector: 'app-user',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class UserComponent implements OnInit {
    [x: string]: any;
    dataSource: any;
    displayedColumns = ["userName", "email", "locations" , "phoneNumber", "actions"];
    pageIndex : number = 1 ;
    pageSize :number = 10;
    pageSizeOptions  =[5, 10, 25, 100];
    length="length";
    search : string = null;

    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    @ViewChild('filter', { static: true })
    filter: ElementRef;

    // Private
    private _unsubscribeAll: Subject<any>;
    tenantId: string;
    locationId: string;
    fromLocation: boolean = false;
    userId: string;
    user: User;

    constructor(
        private userService: UserService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private route: ActivatedRoute,
        private translate :TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        public loaderService : LoaderService,
        private router : Router
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        // ---------------------------------------------------
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);
    }
    //--------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        //this.dataSource = new FilesDataSource(this.userService, this.paginator, this.sort);
        
        this.route.paramMap.subscribe(params => {
            if (params.get('userId')) {
                this.userId = params.get('userId')
            }
            if (params.get('locationId')) {
                this.locationId = params.get('locationId')
                this.fromLocation = true;
            }
            if (params.get('tenantId')) {
                this.tenantId = params.get('tenantId')
            }
            if (this.locationId) {
                this.userService.getUsersLocation(this.locationId,this.userService.currentPage ,this.userService.pageSize).then(res => {
                    if (res) {
                        this.dataSource = res.data;
                        this.pageIndex = res.pageNumber;
                        this.pageSize = res.pageSize;
                        this.length = res.totalItems;

                    }
                })
            } else {
                this.userService.getUsersTenant(this.tenantId , this.userService.currentPage ,this.userService.pageSize).then(res => {
                    if (res) {
                        this.dataSource = res.data;
                        this.pageIndex = res.pageNumber;
                        this.pageSize = res.pageSize;
                        this.length = res.totalItems;
                    }
                })
            }

        });
    }

    forgotpassword(id){
       
        this.userService.getUserById(id).then((res: User) => {
            this.user = res;
            if(this.user){
             
                this.userService.forgotpassword(this.user).then(res => {
                    // this.translate.instant('CONFIRM_MSG.userAdded')
                    this._matSnackBar.open(res.message,  this.translate.instant('ok'), {
                        verticalPosition: 'top',
                        duration: 2000
                    });
    
                })
                }
            
        });

        
    }
    
    deleteUser(id) {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });


        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';




        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.userService.deleteUser(id).then(() => {
                    // Show the success message
                    this._matSnackBar.open('User Deleted', 'OK', {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                    this.ngOnInit();
                })

            }
        })

    }
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    resetPassword(editMood =false , userId){
        console.log(userId);
            var dialogRef;
            if(editMood){
               dialogRef = this._matDialog.open(ResetPasswordPopupComponent, {
                width: '500px',
                data: { userId: userId, }
              });  
            }
            else{
               dialogRef = this._matDialog.open(ResetPasswordPopupComponent, {
                width: '500px',
                data: { userId: userId, }
              });  
            }
            dialogRef.afterClosed().subscribe(result => {
              if (result == "canceled") {
                this.ngOnInit();
              }
            });
    }

    getUsers(data : any){
        if (this.locationId) {
            this.userService.getUsersLocation(this.locationId , data.pageIndex+1 , data.pageSize ).then(res => {
                if (res) {
                    this.dataSource = res.data;
                    this.pageIndex = res.pageNumber;
                    this.pageSize = res.pageSize;
                    this.length = res.totalItems;

                }
            })
        } else {
            this.userService.getUsersTenant(this.tenantId , data.pageIndex+1 , data.pageSize).then(res => {
                if (res) {
                    this.dataSource = res.data;
                    this.pageIndex = res.pageNumber;
                    this.pageSize = res.pageSize;
                    this.length = res.totalItems;
                }
            })
        }
    }

    getUsersSearch(search : any){
        if(search)
        {
            if (this.locationId) {
                this.userService.getUsersLocationSearch(this.locationId,this.pageIndex,this.pageSize,search ).then(res => {
                    if (res) {
                        this.dataSource = res.data;
                        this.pageIndex = res.pageNumber;
                        this.pageSize = res.pageSize;
                        this.length = res.totalItems;
    
                    }
                })
            } else {
                this.userService.getUsersTenantSearch(this.tenantId,this.pageIndex,this.pageSize,search).then(res => {
                    if (res) {
                        this.dataSource = res.data;
                        this.pageIndex = res.pageNumber;
                        this.pageSize = res.pageSize;
                        this.length = res.totalItems;
                    }
                })
            }
        }
        else{
            this.ngOnInit();
        }
        
       
    }

    edit(userId){
        this.userService.currentPage = this.pageIndex;
        this.userService.pageSize = this.pageSize;
        this.router.navigate(['/tenant/' + this.tenantId + '/user/' + userId] );
    }

}


export class FilesDataSource extends DataSource<any>
{
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param {userService} userService
     * @param {MatPaginator} _matPaginator
     * @param {MatSort} _matSort
     */
    constructor(
        private userService: UserService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    ) {
        super();

        this.filteredData = this.userService.users;
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this.userService.onUserChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges)
            .pipe(
                map(() => {
                    let data = this.userService.users.slice();

                    data = this.filterData(data);

                    this.filteredData = [...data];

                    data = this.sortData(data);

                    // Grab the page's slice of data.
                    const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
                    return data.splice(startIndex, this._matPaginator.pageSize);
                }
                ));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Filtered data
    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch (this._matSort.active) {
                case 'id':
                    [propertyA, propertyB] = [a.id, b.id];
                    break;
                case 'userName':
                    [propertyA, propertyB] = [a.userName, b.userName];
                    break;
                case 'email':
                    [propertyA, propertyB] = [a.email, b.email];
                    break;

            }

            const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
        });
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }
}
