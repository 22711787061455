import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors, ValidatorFn} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from './report.service';
import { UserService } from '../branches-management/user/user.service';
import { LocationService } from '../branches-management/location/location.service';


const dateRangeValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
  const startDate = formGroup.get('startDate')?.value;
  const endDate = formGroup.get('endDate')?.value;
  return endDate > startDate ? null : { dateRangeError: true };
};
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})

export class ReportComponent implements OnInit {
    reportForm: FormGroup;
    dataSourceLocation: any[] = []; // Assuming this is an array
    tenantId: string;
    locationId?: string;
    isLoading = false;
    minEndDate: Date; // This will hold the minimum date for the end date picker

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private reportService: ReportService,
    private locationService: LocationService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute  // Inject ActivatedRoute to access route parameters
  ) {}

  ngOnInit() {
    this.initForm();
    this.loadUserAndLocations();
  }

  initForm() {
    this.reportForm = this.fb.group({
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      locationId: [null, Validators.required],
      format: ['PDF', Validators.required], // Set default format if needed
      reportType: [null, Validators.required],
      language: ['en', Validators.required]
    }, { validators: dateRangeValidator });
    // Listen to changes in the startDate field to update the minEndDate dynamically
    this.reportForm.get('startDate').valueChanges.subscribe(startDate => {
      this.minEndDate = startDate ? new Date(startDate) : null;
      // Reset endDate if it's less than the new minEndDate
      if (this.reportForm.get('endDate').value < this.minEndDate) {
        this.reportForm.get('endDate').setValue(null);
      }
    });
  }


  loadUserAndLocations() {
    this.userService.getUsersDetails()
      .then(resDetails => {
        if (!resDetails && !resDetails.location && !resDetails.tenantId) {
          throw new Error('Failed to obtain user details or tenant information');
        }
        if(resDetails.tenantId){  this.tenantId = resDetails.tenantId;}
      
       
  
        return this.locationService.getLocationsByTenantId(this.tenantId);
      })
      .then(res => {
        if (!res) {
          throw new Error('No locations found for this tenant.');
        }
        this.dataSourceLocation = res;
      })
      .catch(error => {
        this.snackBar.open(error.message, 'Close', { duration: 3000 });
      });
  }
  
  /*
  loadLocationsBasedOnRoute() {
    this.userService.getUsersDetails().then(resDetails => {});
    // Subscribe to route parameters
    this.route.paramMap.subscribe(params => {
      const tenantId = params.get('tenantId'); // Get the tenantId from route parameters
      if (tenantId) {
        this.loadLocations(tenantId);
      } else {
        this.snackBar.open('Tenant ID not found in route parameters', 'Close', { duration: 3000 });
      }
    });
  }
  */

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1), // getMonth() is zero-based
        day = '' + d.getDate(),
        year = d.getFullYear();
  
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
  
    return [year, month, day].join('-');
  }

  generateReport() {
    if (this.reportForm.valid) {
        this.isLoading = true;
        const formData = this.reportForm.value;

        // Convert date formats
        formData.startDate = this.formatDate(formData.startDate);
        formData.endDate = this.formatDate(formData.endDate);

        this.reportService.generateReport({
            tenantId: this.tenantId,
            locationId: formData.locationId,
            start: formData.startDate,
            end: formData.endDate,
            renderFormat: formData.format,
            reportType: formData.reportType,
            language: formData.language
        }).subscribe(blob => {
            this.downloadFile(blob);
            this.isLoading = false;  // Set loading to false after the process
        }, error => {
            console.error('Error generating report:', error);
            this.snackBar.open('Error generating report', 'Close', { duration: 3000 });
            this.isLoading = false;  // Ensure to set loading to false even on error
        });
    } else {
        this.snackBar.open('Please ensure all fields are filled correctly.', 'OK', { duration: 3000 });
    }
}



  downloadFile(data: Blob) {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'report.' + this.reportForm.value.format.toLowerCase(); // Ensure the extension matches the format
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    this.isLoading = false;
  }
}
