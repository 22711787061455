import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as shape from 'd3-shape';
import { TranslateService } from '@ngx-translate/core';
import { fuseAnimations } from '@fuse/animations';
import { UserService } from 'app/modules/branches-management/user/user.service';
import { ProjectDashboardService } from 'app/modules/dashboards/project/project.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { LocationService } from 'app/modules/branches-management/location/location.service';
import { LoaderService } from 'app/shared/services/loader-service';

@Component({
    selector: 'project-dashboard',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ProjectDashboardComponent implements OnInit {
    averageService_time: number;
    averageTicketPerTeller: number;
    averageWaiting_time: number;
    noTicketsServiceAbove5min: number;
    noTicketsServiceBelow5min: number;
    noTicketsWaitingBelow5min: number;
    noTicketsWaitingAbove5min: number;
    total_clients: number;
    no_showTickets: number;
    tenantName: string;
    branchName: string;
    loctopnId: string;
    dataSource: FilesDataSource | null;
    dataSourceLocation: FilesDataSource | null;
    displayedColumns = ["userName", "avarage_Service", "client_Count", "count_5u", "count_5n"];
    private _unsubscribeAll: Subject<any>;

    selectedLocation: any;
    /**
     * Constructor
     *
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {ProjectDashboardService} _projectDashboardService
     */
    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _projectDashboardService: ProjectDashboardService,
        private loctionService: ProjectDashboardService,
        private userService: UserService,
        private locationService: LocationService,
        public loaderService : LoaderService,
    ) {
       
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void { 

        this._unsubscribeAll = new Subject();
        this.userService.getUsersDetails().then(resDetails => {
            this.getUserLocation(resDetails.location.id);
            this.locationService.getLocationsByTenantId(resDetails.location.tenantId).then(res => {
                if (res) {
                    this.dataSourceLocation = res;
                    this.selectedLocation = res[0].name;
                }
            });
        })

    }
    getUserLocation(loctopnId) {
        this.loctionService.getUsersLocation(loctopnId).then(res => {
            if (res) {
                this.averageService_time = res.averageService_time;
                this.averageTicketPerTeller = res.averageTicketPerTeller;
                this.averageWaiting_time = res.averageWaiting_time;
                this.noTicketsServiceAbove5min = res.noTicketsServiceAbove5min;
                this.noTicketsServiceBelow5min = res.noTicketsServiceBelow5min;
                this.noTicketsWaitingBelow5min = res.noTicketsWaitingBelow5min;
                this.noTicketsWaitingAbove5min = res.noTicketsWaitingAbove5min;
                this.total_clients = res.total_clients;
                this.no_showTickets = res.no_showTickets;
                this.branchName = res.branchName;
                this.tenantName = res.tenantName;
                this.dataSource = res.userData;
                // this.dataSourcebranch = res.branchData;

            }


        });
    }


    selectedlocation(val) {
        if (val) {
            this.getUserLocation(val.id);
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }
}

export class FilesDataSource extends DataSource<any>
{
    /**
     * Constructor
     *
     * @param _widget11
     */
    constructor(private _widget11) {
        super();
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this._widget11.onContactsChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }
}

