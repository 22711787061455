export class StorageManager {
    static set(key, date) {
        var jsonData = JSON.stringify(date);

        localStorage.setItem(key, jsonData);
    }

    static get(key) {
        var data;

        var jsonData = localStorage.getItem(key);
        if (jsonData) {
            data = JSON.parse(jsonData);
        }

        return data;
    }

    static remove(key) {
        localStorage.removeItem(key);
    }
}