import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseSharedModule } from '@fuse/shared.module';
import { LoginComponent } from './login/login.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { ForgotPassword2Component } from './forget-password/forgot-password-2.component';
import { FuseConfigService } from '@fuse/services/config.service';
import { ResetPassword2Component } from './reset-password/reset-password-2.component';
import { FormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatTooltipModule } from '@angular/material/tooltip';







const routes = [
    {
        path: 'Login',
        component: LoginComponent
    },
    {
        path: 'forget-password',
        component: ForgotPassword2Component
    }
    ,
    {
     path: 'reset-password',
   //  path: 'resetpassword',
    component: ResetPassword2Component
    }
];

@NgModule({
    declarations: [
        LoginComponent,
        ForgotPassword2Component,
        ResetPassword2Component
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        TranslateModule,
        MatFormFieldModule,
        FuseSharedModule,
        MatCheckboxModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,

        FuseSharedModule,
        FormsModule,
        MatMomentDateModule,
        MatTooltipModule
    ],
    providers : [
        FuseConfigService
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class LoginModule {
}
