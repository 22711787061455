<div id="users" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <button *ngIf="fromLocation" mat-icon-button class="mr-0 mr-sm-16"
                    [value]="'/tenant/'+ tenantId +'/location/' + locationId + '/user'"
                    [routerLink]="'/tenant/'+ tenantId +'/location'">
                    <mat-icon>{{'arrow_back' | translate}}</mat-icon>
                </button>
                <button *ngIf="!fromLocation" mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/tenant'">
                    <mat-icon>{{'arrow_back' | translate}}</mat-icon>
                </button>
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    account_circle
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    {{'USER_LIST.users' | translate}}
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon> {{'search' | translate}}</mat-icon>
                    <input (keyup)="getUsersSearch($event.target.value)" placeholder="{{'USER_LIST.searchForUser' | translate}}">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <button *ngIf="!locationId" mat-raised-button [routerLink]="'/tenant/' + tenantId + '/user/add'"
                class="add-user-button fuse-white mt-24 mt-md-0">
                <span>{{'USER_LIST.addNewUser' | translate}}</span>
            </button>

            <button *ngIf="locationId" mat-raised-button
                [routerLink]="'/tenant/' + tenantId +'/location/' + locationId + '/user/add'"
                class="add-user-button fuse-white mt-24 mt-md-0">
                <span>{{'USER_LIST.addNewUser' | translate}}</span>
            </button>

            <button *ngIf="locationId"  mat-raised-button
            [routerLink]="'/import-useres/tenant/'+ tenantId + '/location/' + locationId"
            class="add-user-button fuse-white mt-24 mt-md-0">
            <span>{{'USER_LIST.ImportUsers' | translate}}</span>
            </button>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">
            <div class="list-skeleton" *ngIf="loaderService.isLoading">
                <ngx-skeleton-loader
                    [theme]="{ width: '100%', height: '35px'}"
                    animation="progress"
                    count="10">
                </ngx-skeleton-loader>
            </div>
            <mat-table *ngIf="!loaderService.isLoading" class="users-table custom-table" #table [dataSource]="dataSource" matSort [@animateStagger]="{value:'50'}"
                fusePerfectScrollbar>

                <!-- USER_NAME Column -->
                <ng-container matColumnDef="userName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'USER_LIST.userName' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <p class="text-truncate">{{user.userName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- EMAIL Column -->
                <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'USER_LIST.emailAddress' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <p class="text-truncate">{{user.email}}</p>
                    </mat-cell>
                </ng-container>

                   <!-- locations Column -->
                <ng-container matColumnDef="locations">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'USER_LIST.locations' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <p class="text-truncate">{{user.locationName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- PHONE-NUMBER Column -->
                <ng-container matColumnDef="phoneNumber">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'USER_LIST.phoneNumber' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <p class="text-truncate">{{user.phoneNumber}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ACTIONS Column -->
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'USER_LIST.actions' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <mat-icon *ngIf="locationId" matTooltip="{{'USER_LIST.services' | translate}}"
                            [routerLink]="'/tenant/' + tenantId + '/location/' + locationId + '/user/' + user.id + '/Services'"
                            class="logo-icon mx-1" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                            storage
                        </mat-icon>

                        <mat-icon class="logo-icon mx-1" 
                        (click)="edit(user.id)" 
                            [@animate]="{value:'*',params:{delay:'200ms',scale:'0.4'}}"
                            matTooltip="{{'USER_LIST.editUser' | translate}}">
                            edit
                        </mat-icon>

                        <mat-icon (click)="deleteUser(user.id)" class="logo-icon mx-1"
                            [@animate]="{value:'*',params:{delay:'275ms',scale:'0.6'}}"
                            matTooltip="{{'USER_LIST.deleteUser' | translate}}">
                            delete_forever
                        </mat-icon>

                        <mat-icon (click)="forgotpassword(user.id)" class="logo-icon mx-1"
                        [@animate]="{value:'*',params:{delay:'295ms',scale:'0.6'}}"
                        matTooltip="{{'LOGIN.forgetPassword' | translate}}">
                        lock_open
                        </mat-icon>

                        <mat-icon (click)="resetPassword(true ,user.id)" class="logo-icon mx-1"
                        [@animate]="{value:'*',params:{delay:'295ms',scale:'0.6'}}"
                        matTooltip="{{'USER_LIST.resetPassword' | translate}}">
                        lock_reset
                        </mat-icon>
                    </mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let user; columns: displayedColumns;" class="user" matRipple>
                </mat-row>

            </mat-table>

            <mat-paginator 
            [length]="length"
            [pageIndex]="pageIndex - 1"
            [pageSize]="pageSize"
            (page)='getUsers($event)'
            [pageSizeOptions]="pageSizeOptions">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>