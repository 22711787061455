import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { List } from 'lodash';
import { v4 as uuid } from 'uuid';


@Component({
    selector: 'slot-time-editor',
    templateUrl: './slot-time-editor.component.html',
})
export class SoltTimeEditor implements OnChanges {
    @ViewChild("manageEntityForm") manageEntityForm: FormGroup;
    @Input("data") data = "";
    deserializedData = [];
    deserializedDeleteData = [];
    dayOfWeek = [{"dayOfWeekId":0 ,dayOfWeek :"Sunday"}, {dayOfWeekId:1 ,dayOfWeek :"Monday"} ,{dayOfWeekId:2 ,dayOfWeek :"Tuesday"} , {dayOfWeekId:3 ,dayOfWeek :"Wednesday"}, {dayOfWeekId:4 ,dayOfWeek :"Thursday"}, {dayOfWeekId:5 ,dayOfWeek :"Friday"}, {dayOfWeekId:6,dayOfWeek :"Saturday"}];
    clickRow = true;
    constructor(
        private activeRouter: Router,
        private activeRoute: ActivatedRoute,
        private translate: TranslateService
    ) {

    }
    generateGuid() {
        return uuid();
    }

    removeRow(row) {

        this.deserializedDeleteData.push(this.deserializedData[this.deserializedData.indexOf(row)].id);
        this.deserializedData.splice(this.deserializedData.indexOf(row), 1);
        if (this.deserializedData.filter(c => c.dayOfWeekId == row.dayOfWeekId)?.length == 0){
            this.deserializedData.push({ startTime: this.data, endTime: this.data, guid: uuid(),dayOfWeekId : row.dayOfWeekId, dayOfWeek: row.dayOfWeek, added: 0 })
        }
        else if (row.added == 0){
            let items = this.deserializedData.find(c => c.dayOfWeekId == row.dayOfWeekId);
            items.added = 0;
        }
        this.deserializedData = this.deserializedData.slice().sort((a, b) => a.dayOfWeekId - b.dayOfWeekId);
        this.deserializedData = [...this.deserializedData];
    }
    ngOnChanges(changes: SimpleChanges): void {
        
        if (this.data == "") {
            return;
        }
        try {
            this.deserializedData = JSON.parse(this.data);
        }
        catch {
            this.dayOfWeek.forEach(day => {
                this.deserializedData.push({ startTime: this.data, endTime: this.data, guid: uuid(),dayOfWeekId : day.dayOfWeekId, dayOfWeek: day.dayOfWeek, added: 0 });
            });
            this.deserializedData = this.deserializedData.slice().sort((a, b) => a.dayOfWeekId - b.dayOfWeekId);
        }
    }

    setparameter(val) {

    }

    addRow(dayOfWeek) {
            var day = this.dayOfWeek.find(c => c.dayOfWeek == dayOfWeek);
        this.deserializedData.push({ startTime: "", endTime: "", dayOfWeek: day?.dayOfWeek ,dayOfWeekId :day?.dayOfWeekId, added: 1 });
        this.deserializedData = this.deserializedData.slice().sort((a, b) => a.dayOfWeekId - b.dayOfWeekId);
    }


    addlstRow(lst,chekboll) {
        if (lst != undefined) {
            if (lst.length > 0) {
                this.deserializedData = []
                var st
                var et 
                lst.forEach((ele, index) => {
                    if(chekboll){
                     st = this.convertTo24HourFormat(new Date(ele.startTime).toString());
                     et = this.convertTo24HourFormat(new Date(ele.endTime).toString())
                    }
                    else{
                     st = ele.startTime
                     et = ele.endTime
                    }
                    if (ele.dayOfWeek == 0) {
                        this.deserializedData.push({ startTime: st, endTime: et, id: ele.id, dayOfWeekId :ele.dayOfWeek , dayOfWeek: "Sunday", added: this.deserializedData.filter(c => c.dayOfWeek == "Sunday")?.length > 0 ? 1 : 0 })
                    }
                    else if (ele.dayOfWeek == 1) {
                        this.deserializedData.push({ startTime: st, endTime: et, id: ele.id,dayOfWeekId :ele.dayOfWeek , dayOfWeek: "Monday", added: this.deserializedData.filter(c => c.dayOfWeek == "Monday")?.length > 0 ? 1 : 0 })
                    }
                    else if (ele.dayOfWeek == 2) {
                        this.deserializedData.push({ startTime: st, endTime: et, id: ele.id, dayOfWeekId :ele.dayOfWeek , dayOfWeek: "Tuesday", added: this.deserializedData.filter(c => c.dayOfWeek == "Tuesday")?.length > 0 ? 1 : 0 })
                    }
                    else if (ele.dayOfWeek == 3) {
                        this.deserializedData.push({ startTime: st, endTime: et, id: ele.id, dayOfWeekId :ele.dayOfWeek , dayOfWeek: "Wednesday", added: this.deserializedData.filter(c => c.dayOfWeek == "Wednesday")?.length > 0 ? 1 : 0 })
                    }
                    else if (ele.dayOfWeek == 4) {
                        this.deserializedData.push({ startTime: st, endTime: et, id: ele.id, dayOfWeekId :ele.dayOfWeek , dayOfWeek: "Thursday", added: this.deserializedData.filter(c => c.dayOfWeek == "Thursday")?.length > 0 ? 1 : 0 })
                    }
                    else if (ele.dayOfWeek == 5) {
                        this.deserializedData.push({ startTime: st, endTime: et, id: ele.id, dayOfWeekId :ele.dayOfWeek , dayOfWeek: "Friday", added: this.deserializedData.filter(c => c.dayOfWeek == "Friday")?.length > 0 ? 1 : 0 })
                    }
                    else if (ele.dayOfWeek == 6) {
                        this.deserializedData.push({ startTime: st, endTime: et, id: ele.id, dayOfWeekId :ele.dayOfWeek , dayOfWeek: "Saturday", added: this.deserializedData.filter(c => c.dayOfWeek == "Saturday")?.length > 0 ? 1 : 0 })
                    }
                    else {
                        this.deserializedData.push({ startTime: st, endTime: et, id: ele.id, dayOfWeekId :ele.dayOfWeek , dayOfWeek: "" })
                    }
                });
                this.deserializedData = this.deserializedData.slice().sort((a, b) => a.dayOfWeekId - b.dayOfWeekId);
            this.dayOfWeek.forEach(day => {
                if (this.deserializedData.filter(c => c.dayOfWeekId ==day.dayOfWeekId)?.length == 0){
                    this.deserializedData.push({ startTime: "", endTime: "", dayOfWeek: day?.dayOfWeek ,dayOfWeekId :day?.dayOfWeekId, added:0 });
                }
            });
            }

        }
        //  else{
        //  this.deserializedData.push({startTime : "" , endTime:"" })
        // }

    }


    convertTo24HourFormat(dateTimeString: string): string {
        const dateObj = new Date(dateTimeString);
        const hours = dateObj.getHours().toString().padStart(2, '0');
        const minutes = dateObj.getMinutes().toString().padStart(2, '0');
        const seconds = dateObj.getSeconds().toString().padStart(2, '0');

        return `${hours}:${minutes}:${seconds}`;
    }

}