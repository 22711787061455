import { FuseUtils } from "@fuse/utils";

export class Banner {
    constructor(banner?)
    {
        banner = banner || {};
        this.id = banner.id || 0;//FuseUtils.generateGUID();
        this.name = banner.name || '';
        this.logo = banner.name || '';
    }
    id : string;    
    name: string;
    logo: string;
}