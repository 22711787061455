import { Component, OnInit } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { locale as arabic } from './i18n/ar';
import { locale as arNav } from '../../navigation/i18n/ar';
import { locale as enNav } from '../../navigation/i18n/en';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    selectedLanguage: any;
    languages: { id: string; title: string; flag: string; }[];
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private route: ActivatedRoute
    ) {
    }
    ngOnInit() {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic, arNav, enNav);

    }
}
