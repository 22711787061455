import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils'; 
import { UserService } from '../user.service';
import { User } from 'app/model/User';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { locale as english } from '../../../../main/home/i18n/en';
import { locale as turkish } from '../../../../main/home/i18n/tr';
import { locale as arabic } from '../../../../main/home/i18n/ar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from 'app/modules/role/role.service';


@Component({
    selector: 'app-user-entry',
    templateUrl: './user-entry.component.html',
    styleUrls: ['./user-entry.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class UserEntryComponent implements OnInit {

    user: User;
    pageType: string;
    userForm: FormGroup;
    // Private
    private _unsubscribeAll: Subject<any>;
    tenantId: string;
    userId: string;
    roles: { id: string; name: string; }[] = [] ;
    locationId: string;
    isLoading: boolean = false;
    /**
     * Constructor
     *
     * @param {UserService} _userService
     * @param {FormBuilder} _formBuilder
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        private userService: UserService,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private route: ActivatedRoute,
        private roleService: RoleService,
        private _location: Location,

        private router: Router,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translate :TranslateService
    ) {
        // Set the default
        this.user = new User();
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.roleService.getRoles().then(res => {
            res?.data?.forEach(element => {
            this.roles.push({ id: element.name, name: element.name });
                
            });
            if(this.user){
                this.user.role= this.user.applicationRoles.name;
            }
        })
        this.route.paramMap.subscribe(params => {
            if (params.get('tenantId'))
                this.tenantId = params.get('tenantId')
            if (params.get('locationId'))
                this.locationId = params.get('locationId')
            if (params.get('userId')) {
                this.userId = params.get('userId');
                this.pageType = 'edit';
                this.userService.getUserById(this.userId).then((res: User) => {
                    this.user = res;
                    if(this.user){
                        this.user.role= this.user.applicationRoles.name;
                        }
                    this.userForm = this.createUserForm();
                  //  this.tenantId = res.tenantId
                });
            } else {
                this.userId;
                this.pageType = 'new';
            }

        });
        this.userForm = this.createUserForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create user form
     *
     * @returns {FormGroup}
     */

    createUserForm(): FormGroup {
        return this._formBuilder.group({
            userName: [this.user.userName, [Validators.required, Validators.pattern("^[a-zA-Z0-9_]*$")]],
            arName: [this.user.arName, [Validators.required]],
            phoneNumber: [this.user.phone, [Validators.required, Validators.pattern("^[0-9]*$")]],
            email: [this.user.email, [Validators.required, Validators.email]],
            role: [this.user.role, [Validators.required]],
            password: [this.user.password, [
                Validators.required,
                Validators.minLength(8)]],
            passwordConfirm: [this.user.passwordConfirm, [Validators.required]]
        },
            {
                validators: [
                    this.passwordPattern('password'),
                    this.MustMatch('password', 'passwordConfirm')
                ]
            }
        );
    }
    // password must match confirm password
    MustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];
            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                return;
            }
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            } else {
                matchingControl.setErrors(null);
            }
        }
    }

    // password pattern 
    // at least one lower letter
    // at least one capital letter
    // at least one number
    // at least one special character
    passwordPattern(controlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            if (control.errors && !control.errors.mustMatch) {
                return;
            }
            if (!/\d/.test(control.value))
                control.setErrors({ number: true });
            else if (!/[a-z]/.test(control.value))
                control.setErrors({ lower: true });
            else if (!/[A-Z]/.test(control.value))
                control.setErrors({ upper: true });
            else if (!/[!@#$%^&*?_-]/.test(control.value))
                control.setErrors({ special: true });
        }
    }
    /**
     * update exist user
     */
    saveUser(): void {
        this.isLoading = true;
        const data = this.userForm.getRawValue();
        data.id = this.user.id;
        data.userId = this.user.id;
        if (this.user.tenantId)
            data.tenantId = this.user.tenantId;
        if (this.user.locationId)
            data.locationId = this.user.locationId;
        this.userService.saveUser(data)
            .then(() => {
                this.isLoading = false;
                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.userSaved'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });

                // if (this.locationId)
                //     this.router.navigate(['/tenant/' + this.tenantId + '/location/' + this.locationId + '/user'])
                // else if (this.tenantId)
                //     this.router.navigate(['/tenant/' + this.tenantId + '/user'])
            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }

    /**
     * Add New user
     */
    addUser(): void {
        this.isLoading = true;
        const data = this.userForm.getRawValue();
        //   data.handle = FuseUtils.handleize(data.name);
        if (this.tenantId)
            data.tenantId = this.tenantId;
        if (this.locationId)
            data.locationId = this.locationId;
        this.userService.addUser(data)
            .then(() => {

                // Trigger the subscription with new data
                this.userService.onUserChanged.next(data);
                this.isLoading = false;
                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.userAdded'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });


                // if (this.locationId)
                //     this.router.navigate(['/tenant/' + this.tenantId + '/location/' + this.locationId + '/user'])
                // else if (this.tenantId)
                //     this.router.navigate(['/tenant/' + this.tenantId + '/user'])
                // Change the location with new one

            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.message,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }

    checkValidForm() {
        if (this.userForm?.controls) {
            const data = this.userForm.getRawValue();
            if (!data.userName || !data.arName || !data.email || !data.role || !data.phoneNumber)
                return true;
            else return false;
        }
    }

    goBack(){
        this._location.back();

    }
}
