import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';
import { PublicHolidayService } from '../public-holiday.service';
import { PublicHoliday } from 'app/model/public-holiday';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { locale as english } from '../../../main/home/i18n/en';
import { locale as turkish } from '../../../main/home/i18n/tr';
import { locale as arabic } from '../../../main/home/i18n/ar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-public-holiday-entry',
    templateUrl: './public-holiday-entry.component.html',
    styleUrls: ['./public-holiday-entry.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class PublicHolidayEntryComponent implements OnInit {

    publicHoliday: PublicHoliday;
    pageType: string;
    publicHolidayForm: FormGroup;
    isLoading: boolean = false;
    allPublicHoliday : any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {PublicHolidayService} PublicHolidayService
     * @param {FormBuilder} _formBuilder
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        private publicHolidayService: PublicHolidayService,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private route: ActivatedRoute,
        private router: Router,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translate:TranslateService
    ) {
        // Set the default
        this.publicHoliday = new PublicHoliday();

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);        // Set the private defaults

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            if (params.get('id')) {
                this.publicHoliday.id = params.get('id')
                this.pageType = 'edit';
                this.publicHolidayService.getPublicHolidayById(this.publicHoliday.id).then((res: PublicHoliday) => {
                    this.publicHoliday = res;
                    this.allPublicHoliday = res;
                    this.publicHoliday.startDate = this.allPublicHoliday.startTime.split('T')[0];
                    this.publicHoliday.endDate = this.allPublicHoliday.endTime.split('T')[0];
                    this.publicHolidayForm = this.createPublicHolidayForm();

                });
            }
            else {
                this.pageType = 'new';
            }
        });
        this.publicHolidayForm = this.createPublicHolidayForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create publicHoliday form
     *
     * @returns {FormGroup}
     */
    createPublicHolidayForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.publicHoliday.id],
            name: [this.publicHoliday.name],
            startDate: [this.publicHoliday.startDate],
            endDate: [this.publicHoliday.endDate]
           
        });
    }

    /**
     * Save publicHoliday
     */
    savePublicHoliday(): void {
        this.isLoading = true;
        const data = this.publicHolidayForm.getRawValue();
        data.handle = FuseUtils.handleize(data.name);

        this.publicHolidayService.savePublicHoliday(data)
            .then(() => {
                this.isLoading = false;
                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.PublicHolidaySaved'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }

    /**
     * Add publicHoliday
     */
    addPublicHoliday(): void {
        this.isLoading = true;
        const data = this.publicHolidayForm.getRawValue();
        //   data.handle = FuseUtils.handleize(data.name);
        data.id = undefined;
        this.publicHolidayService.addPublicHoliday(data)
            .then(() => {

                // Trigger the subscription with new data
                this.publicHolidayService.onPublicHolidayChanged.next(data);
                this.isLoading = false;
                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.PublicHolidayAdd'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });

                this.router.navigate(['/public-holiday'])

                // Change the location with new one

            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }
}
