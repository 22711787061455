import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManager} from '../../shared/utilities/storage-manager';
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  Username: string;
  path : string ;

  constructor( private router: Router, private route : ActivatedRoute) { 
    this.path = window.location.pathname;
  }
  GetUserClaims() {
    if (this.getClaim("name")){            
        this.Username = this.getClaim("name");
        return true;
    }  
    else{
        return false
    }

}

logout(){
    StorageManager.remove("currentToken")
    if(this.path.includes('feedback/')){
      window.location.href
    }
    else if(this.path.includes('questionnaire/'))
    {
      window.location.href
    }
    else if(this.path.includes('forget-password')) {
      this.router.navigate(['forget-password'])
    }
    else if(this.path.includes('reset-password') ){
      this.router.navigate(['reset-password'])
    }
    else
    {
      this.router.navigate(['Login']) 
    }
}

CheckUserLogin(){
  if ( this.GetUserClaims()) {
     return true
  }
  else if(this.path.includes('feedback/')){
    window.location.href
  }
  else if(this.path.includes('questionnaire/'))
  {
    window.location.href
  } 
else if(this.path.includes('forget-password')) {
  this.router.navigate(['forget-password'])
}
else if(this.path.includes('reset-password') ){
  this.router.navigate(['reset-password'])
}

  
  else{
    this.router.navigate(['Login'])
  }
}


  getClaim(claim: string, token?: string, site?: string): any {
    
    var data;
    try {
        var currentToken = StorageManager.get("currentToken");
        if (currentToken) {
            const decoded = jwt_decode(currentToken);
            if (decoded) {
                data = decoded[claim];
            }
        }
    }
    catch (ex) {
        // invalid token
    }

      return data;
    
     
  
}

getToken(site?: string) {  
  var token = StorageManager.get("currentToken");;
  return token;
}
isAuthunticated(): boolean {
  var isAuthunticated = false;

  var token = this.getToken();
  if (token) {
      isAuthunticated = true;
  }
  return isAuthunticated;
}
}
