<div id="about" class="p-24" fxLayout="row wrap">

  <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="100" >

      <div class="profile-box info-box general" fxLayout="column" >

          <header class="accent">
              <div class="title"> {{'ABOUT.generalInformation' | translate}}</div>
              <div style="text-align:right ;">
                  <mat-icon (click)="saveUser()" class="matIcon logo-icon s-20 mr-16" style="text-align:right ;" 
                   [@animate]="{value:'*',params:{delay:'275ms',scale:'0.6'}}" style="cursor: pointer;"
                   matTooltip="{{'ABOUT.saveUser' | translate}}">
                   save
                  </mat-icon>
                 
              </div>
          </header>
          <div class="content">

            <form [formGroup]="aboutform"  novalidate>
              
              <div class="title">{{'ABOUT.userName' | translate}}</div>
              <div class="form-group" [ngClass]="{'editing': editing.userName}" >
                <input 
                  
                  type="text"
                  class="form-control"
                  formControlName="userName"
                  id="userName"
                  [(ngModel)]="username"
                  required
                />
                <div class="value">{{username}}</div>
                &nbsp;
                &nbsp;
                <mat-icon class="logo-icon s-20 mr-16" style="text-align:right ;" (click)="toggleEdit('userName')"
                [@animate]="{value:'*',params:{delay:'200ms',scale:'0.4'}}" style="cursor: pointer;"
                matTooltip="{{'ABOUT.editUserName' | translate}}">
                edit
              </mat-icon>
              </div>
              <br>

              <div class="title">{{'ABOUT.arabicName' | translate }}</div>
              <div class="form-group" [ngClass]="{'editing': editing.arname}" >
                <input 
                  
                  type="text"
                  class="form-control"
                  formControlName="arname"
                  id="arname"
                  [(ngModel)]="arabicName"
                  required
                />
                <div class="value">{{arabicName}}</div>
                &nbsp;
                &nbsp;
                <mat-icon class="logo-icon s-20 mr-16" style="text-align:right ;" (click)="toggleEdit('arname')"
                [@animate]="{value:'*',params:{delay:'200ms',scale:'0.4'}}" style="cursor: pointer;"
                matTooltip="{{'ABOUT.editArabicName' | translate}}">
                edit
              </mat-icon>
              </div>
              <br>


              <div class="title">{{'ABOUT.phone' | translate}}</div>
              <div class="form-group" [ngClass]="{'editing': editing.phone}" >
                <input 
                  
                  type="text"
                  class="form-control"
                  formControlName="phone"
                  id="phone"
                  [(ngModel)]="phoneNo"
                  required
                />
                <div class="value">{{phoneNo}}</div>
                &nbsp;
                &nbsp;
                <mat-icon class="logo-icon s-20 mr-16" style="text-align:right ;" (click)="toggleEdit('phone')"
                [@animate]="{value:'*',params:{delay:'200ms',scale:'0.4'}}" style="cursor: pointer;"
                matTooltip="{{'ABOUT.editPhone' | translate}}">
                edit
              </mat-icon>
              </div>
              <br>


              <div class="title">{{'ABOUT.email' | translate}}</div>
              <div class="form-group" [ngClass]="{'editing': editing.email}" >
                <input 
                  
                  type="text"
                  class="form-control"
                  formControlName="email"
                  id="email"
                  [(ngModel)]="email"
                  required
                />
                <div class="value">{{email}}</div>
                &nbsp;
                &nbsp;
                <mat-icon class="logo-icon s-20 mr-16" style="text-align:right ;" (click)="toggleEdit('email')"
                [@animate]="{value:'*',params:{delay:'200ms',scale:'0.4'}}" style="cursor: pointer;"
                matTooltip="{{'ABOUT.editEmail' | translate}}">
                edit
              </mat-icon>
              </div>

            
            </form>
          </div>

      </div>

      <div class="profile-box info-box work" fxLayout="column" *ngIf="userDetails.location || userDetails.tenant">

          <header class="accent">
              <div class="title">{{'ABOUT.work' | translate}}</div>
          </header>

          <div class="content">
              <div class="info-line" *ngIf="userDetails.location">
                  <div class="title">{{'ABOUT.location' | translate}}</div>
                  <div class="info">{{userDetails.location.name}}</div>
              </div>

              <div class="info-line" *ngIf="userDetails.tenant">
                  <div class="title">{{'ABOUT.tenant' | translate}}</div>
                  <div class="info">{{userDetails.tenant.name}}</div>
              </div>
          </div>
      </div>

  </div>

</div>
