import { FuseUtils } from "@fuse/utils";

export class FeedBack {
    constructor(feedback?)
    {
        feedback = feedback || {};
        //this.id = feedback.id || 0;//FuseUtils.generateGUID();
        this.rating = feedback.rating || 0;
        this.feedBackType = feedback.feedBackType || 0;
        this.ticketId = feedback.ticketId || '' ;
        this.userId = feedback.userId || '' ;
        this.comment = feedback.comment || '' ;
    }
    //id : string;    
    rating: number;
    ticketId: string;    
    userId :string;
    comment:string;
    feedBackType : number
}