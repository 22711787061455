import { Component , OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl , FormGroup ,FormBuilder ,Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { FeedBack } from 'app/model/FeedBack';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomerFeedbackService } from './customer-feedback.service';
declare const atob: any;



@Component({
    selector: 'app-customer-feedback',
    templateUrl: './customer-feedback.component.html',
    styleUrls: ['./customer-feedback.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CustomerFeedBackComponent implements OnInit {

  tiketId:any;
  tenantName : any;
  NameAR : any;
  tenantId:any;
  logo:any;

  question: any;
  answers: any[] = []; 
  selectedAnswer: string;
  isSend:boolean = false;


    constructor(
        private route: ActivatedRoute,
        private _customerFeedbackService : CustomerFeedbackService,
        private _fuseConfigService : FuseConfigService,
        private fb: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private translate: TranslateService
    ) {


      this._fuseConfigService.config = {
        layout: {
            navbar   : {
                hidden: true
            },
            toolbar  : {
                hidden: true
            },
            footer   : {
                hidden: true
            },
            sidepanel: {
                hidden: true
            }
        }
    };
    }

    /**
     * On init
     */
     ngOnInit() {      
    this.translate.use('ar');
      this.route.paramMap.subscribe(parms => {
        if(parms.get('tiketId'))
        {
          this.tiketId = parms.get('tiketId').split(':')[0];
        }
      });

      if(this.tiketId)
      {
        this._customerFeedbackService.getTiketDetails(this.tiketId,true).then((response) =>{
          if(response){
            this.tenantId = response.tenantId;
            this.tenantName = response.tenantName;
            
              this._customerFeedbackService.getTenantDetails(this.tenantId,true).then((response) =>{
                if(response){
                  this.logo = response.logo;
                  this.NameAR=response.name;
                }
              })
              this._customerFeedbackService.getQuestionnaire(this.tenantId,true).then((response) =>{
                if(response){
                  this.question = response.question;
                  this.answers = response.answers;
                }
              })
            
          }
        })
      }
    }
    
     sendCustomerFeedback() {
      if(this.selectedAnswer)
      {
        const data ={
          questionId:this.question.id,
          answerId:this.selectedAnswer,
          ticketId:this.tiketId
        }
        this._customerFeedbackService.sendCustomerFeedback(data)
        .then((response) => {
          this.isSend = true;
        });
      }
        else{
          this._matSnackBar.open(this.translate.instant('KEYPAD.pleaseSelectAnswer'), this.translate.instant('ok'), {
            verticalPosition: 'top',
            duration: 2000
          });
        }
  }

}
