<div mat-dialog-content>
    <form class=" p-24 mr-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form" [formGroup]="form">


        <div class="h2 mb-24"> {{'SERVICE_DETAILS_POPUP.serviceDetails' | translate}} </div>
        <div fxLayout="row" fxLayoutAlign="start" fxFlex="1 0 auto">
       
            <mat-form-field appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="43">
                <mat-label> {{'SERVICE_DETAILS_POPUP.priority' | translate}} </mat-label>
                <mat-select formControlName="priority" style="width: 220px !important;">
                    <mat-option [value]="1">
                        1
                    </mat-option>
                    <mat-option [value]="2">
                        2
                    </mat-option>
                    <mat-option [value]="3">
                        3
                    </mat-option>
                    <mat-option [value]="4">
                        4
                    </mat-option>
                    <mat-option [value]="5">
                        5
                    </mat-option>
                </mat-select>
                <mat-icon matSuffix class="secondary-text">sort</mat-icon>
                <!-- <mat-error> {{'SERVICE_DETAILS_POPUP.priorityRequired' | translate}} </mat-error> -->
            </mat-form-field>
        </div>

    </form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancelClick()"> {{'SERVICE_DETAILS_POPUP.cancel' | translate}} </button>
    <button mat-button cdkFocusInitial (click)="save()"> {{'SERVICE_DETAILS_POPUP.ok' | translate}} </button>
</div> 