import { Time } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { validateBasis } from '@angular/flex-layout';
import { FormBuilder, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocationService } from 'app/modules/branches-management/location/location.service';
import { StorageManager } from 'app/shared/utilities/storage-manager';

@Component({
  selector: 'app-counter-number-configration-popup',
  templateUrl: './counter-number-configration-popup.component.html',
  styleUrls: ['./counter-number-configration-popup.component.scss']
})
export class CounterNumberCnfigrationPopupComponent implements OnInit {

  form: FormGroup;
  ngOnInit(): void {

    this.form = this._formBuilder.group({
      CounterNumber : [{value:  this.data.CounterNumber}],
   
  });
  }

  
date :Date;
timepicker :any;
basicDatepicker :any;
  
  constructor(
    private _formBuilder: FormBuilder,
    private _matSnackBar: MatSnackBar,
    private locationService : LocationService,
    public dialogRef: MatDialogRef<CounterNumberCnfigrationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  cancelClick(): void {
    this.dialogRef.close("canceled");
  }


  save(){
    StorageManager.set("CounterNumberConfigration" , this.data.CounterNumber ); 
    this.dialogRef.close("saved");

  }
}

export interface DialogData {
  CounterNumber : number;
 
}
