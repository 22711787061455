import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { HomeModule } from 'app/main/home/home.module';
import { LoginModule } from './modules/account/account.module';
import { APP_INITIALIZER } from '@angular/core';
import { AuthModule, LogLevel, OidcConfigService } from 'angular-auth-oidc-client';
import { environment } from 'environments/environment';
import { BranchesManagementModule } from './modules/branches-management/branches-management.module';
import { ServiceDetailsPopupComponent } from './modal/service-details-popup/service-details-popup/service-details-popup.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { TimePickerModule, WavesModule } from 'ng-uikit-pro-standard'
import { ProjectDashboardModule } from './modules/dashboards/project/project.module';
import { AnalyticsDashboardModule } from './modules/dashboards/analytics/analytics.module';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from './fake-db/fake-db.service';
import { CalendarModule } from './modules/calendar/calendar.module';
import { MailModule } from './modules/mail/mail.module';
import { HttpRequestInterceptor } from './core/interceptors/http-interceptor';
import { CategoryModule } from './modules/category/category.module';
import { MatPaginatorIntlCro } from './MatPaginatorIntl';
import { KeypadModule } from './modules/keypad/keypad.module';
import { CounterNumberCnfigrationPopupComponent } from './modal/counter-number-configration-popup/counter-number-configration-popup.component';
import { TransferServiceToCounterPopupComponent } from './modal/transfer-service-to-counter-popup/transfer-service-to-counter-popup.component';
import { TimerComponent } from './component/timer/timer.component';
import { BannerModule } from './modules/banner/banner.module';
import { RoleModule } from './modules/role/role.module';
import { PermissionModule } from './modules/permission/permission.module';
import { UserService } from './modules/branches-management/user/user.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FeedBackModule } from './modules/feedback/feedback.module';
import { ProfileModule } from './modules/profile/profile.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LoadingInterceptor } from './core/interceptors/loading-interceptor';
import { LoaderService } from './shared/services/loader-service';
import { DynamicDashboardModule } from './modules/dynamic-dashboard/dynamic-dashboard/dynamic-dashboard.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { CheckBoxModule, ButtonAllModule } from '@syncfusion/ej2-angular-buttons';
import { ChartAllModule, AccumulationChartAllModule, RangeNavigatorAllModule } from '@syncfusion/ej2-angular-charts';
import { AutoCompleteModule } from '@syncfusion/ej2-angular-dropdowns';
import { NumericTextBoxModule, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import { MapsAllModule } from '@syncfusion/ej2-angular-maps';
import { SidebarModule } from '@syncfusion/ej2-angular-navigations';
import { ChatModule } from './modules/chat/chat.module';
import { CallApiModule } from './modules/dynamic-dashboard/call-api/call-api.module';
import { PricingModule } from './modules/pricing/pricing.module';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { ReportModule } from './modules/report/report.module';
import {MatStepperModule} from '@angular/material/stepper';
import {MatRadioModule} from '@angular/material/radio';
import { PopupUsersServiceComponent } from './modal/popup-users-service/popup-users-service.component';
import { ResetPasswordPopupComponent } from './modal/reset-password-popup/reset-password-popup.component';
import { SoltTimeEditorModule } from './component/slot-time-editor/slot-time-editor.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { ImportExportModule } from './modules/import-export/import-export.module';
import { WorkFlowModule } from './modules/workflow/workflow.module';
import { PublicHolidayModule } from './modules/public-holiday/public-holiday.module';
import { CompleteKeypadPopupComponent } from './modal/complete-keypad-popup/complete-keypad-popup.component';
import { CustomerFeedbackModule } from './modules/customer-feedback/customer-feedback.module';


const appRoutes: Routes = [
    {
        path: '**',
        redirectTo: 'Login'
    },

    {
        path: 'user',
        loadChildren: () => import('./modules/branches-management/user/user.module').then(m => m.UserModule)
    },
   
];

export function  configureAuth(oidcConfigService: OidcConfigService) {
    return () =>
        oidcConfigService.withConfig({
            stsServer: environment.IdentityUrl,
            redirectUrl: window.location.origin,
            postLogoutRedirectUri: window.location.origin,
            clientId: 'angular',
            scope: 'openid profile email',
            responseType: 'code',
            logLevel: LogLevel.Debug,
        });
}


@NgModule({
    declarations: [
        AppComponent,
        ServiceDetailsPopupComponent  ,
        CounterNumberCnfigrationPopupComponent,
        TransferServiceToCounterPopupComponent ,
        TimerComponent,
        PopupUsersServiceComponent,
        ResetPasswordPopupComponent,
        CompleteKeypadPopupComponent
           ],
    imports: [
        AutoCompleteModule,
        MapsAllModule,
        NumericTextBoxModule,
        MapsAllModule,
        AutoCompleteModule,
        DashboardLayoutModule, 
        CheckBoxModule,
        SidebarModule, 
        ButtonAllModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        NgxMaterialTimepickerModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        ChatModule,
        PricingModule,
        MatChipsModule,
        MatButtonModule,
        MatIconModule,
        WavesModule,
        TextBoxModule,
        ChartAllModule, 
        AccumulationChartAllModule,
        RangeNavigatorAllModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatDialogModule,
        SoltTimeEditorModule,


        BrowserModule,
        ReportModule,
        FeedBackModule,
        CustomerFeedbackModule,
        ProfileModule,
        ImportExportModule,
        HttpClientModule,
        AuthModule.forRoot(),
        MatCheckboxModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
        TranslateModule.forRoot(),
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        // App modules
        LayoutModule,
        HomeModule,
        BranchesManagementModule,
        LoginModule,
        AnalyticsDashboardModule,
        ProjectDashboardModule,
        CalendarModule,
        CategoryModule,
        BannerModule,
        PermissionModule,
        RoleModule,
        KeypadModule,
        MailModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        TimePickerModule,
        DynamicDashboardModule,
        CallApiModule,
        PublicHolidayModule,

        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),
        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),
        NgxSkeletonLoaderModule,
        MatStepperModule,
        MatRadioModule,
        WorkFlowModule
        
        

    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,            
            multi: true,
        } ,
        LoaderService,
        OidcConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: configureAuth,
            deps: [OidcConfigService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
     UserService,

    ],
    bootstrap: [
        AppComponent
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {
}
