import { Time } from "@angular/common";
import { FuseUtils } from "@fuse/utils";
import { Tenant } from "./Tenant";

export class Advertisement {   
    constructor(Advertisement?)
    {
        Advertisement = Advertisement || {};
        this.id = Advertisement.id || 0;//FuseUtils.generateGUID();
        this.name = Advertisement.name || '';
        this.createdTime= Advertisement.createdTime || '' ;
        this.tenantId = Advertisement.tenantId || 0;
        this.mediaGroupType = Advertisement.mediaGroupType || 0;
        this.type = Advertisement.type || '' ;
        this.isActivated= Advertisement.type || true ;
    }
    id : string;    
    name: string;
    discription: string;  
    tenant : Tenant;  
    tenantId: string;
    type: string;
    createdTime:Time;
    isActivated:boolean
    mediaGroupType:number
}