import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Banner } from 'app/model/Banner';
import { apiUrls } from 'app/shared/constants/apiUrls';
import { StorageManager } from 'app/shared/utilities/storage-manager';
import { environment } from 'environments/environment';
import { Http2ServerRequest } from 'http2';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BannerService implements Resolve<any>{
   
 
  banners : Banner[] = new Array<Banner>();
  banner : Banner  =   new Banner() ;
  onBannerChanged: BehaviorSubject<any>;
  currentPage: number = 1;
  pageSize : number = 10;

   /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
      private _httpClient: HttpClient
  )
  {
      // Set the defaults
      this.onBannerChanged = new BehaviorSubject({});
  }

   /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getBannersWithoutPagination()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    uploadImage(file: any , id :string) {
        const form = new FormData();
            form.append('file', file, file.name);
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.serviceUrl3 + apiUrls.banner + "/UploadFile?id=" + id  , form)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

  
  getAllBanners() :Observable<any>{
   return this._httpClient.get(environment.serviceUrl3 + apiUrls.banner )
  }

  getBannersWithoutPagination(): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.banner)
              .subscribe((response: any) => {
                  this.banners = response;
                  this.onBannerChanged.next(this.banners);
                  resolve(response);
              }, reject);
      });
  }
  
  getBanners(pageIndex ,pageSize): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.banner + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize)
              .subscribe((response: any) => {
                  this.banners = response;
                  this.onBannerChanged.next(this.banners);
                  resolve(response);
              }, reject);
      });
  }

  getBannersByFilter(pageIndex ,pageSize , search): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.banner + '?pageNumber='+ pageIndex + '&pageSize=' + pageSize + '&search=' + search)
              .subscribe((response: any) => {
                  this.banners = response;
                  this.onBannerChanged.next(this.banners);
                  resolve(response);
              }, reject);
      });
  }

  getBannerById(id): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(environment.serviceUrl3 + apiUrls.banner + '/' + id )
              .subscribe((response: any) => {
                  this.banner = response;
                  this.onBannerChanged.next(this.banner);
                  resolve(response);
              }, reject);
      });
  }

  
 // Delete banner
 deleteBanner(id): Promise<any>
 {
     return new Promise((resolve, reject) => {
         this._httpClient.delete(environment.serviceUrl3 + apiUrls.banner +"/"+ id )
             .subscribe((response: any) => {
                 resolve(response);
             }, reject);
     });
 }

  saveBanner(banner): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put(environment.serviceUrl3 + apiUrls.banner , banner)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  addBanner(banner): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.serviceUrl3 + apiUrls.banner , banner)
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }

}
