import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { fuseAnimations } from '@fuse/animations';
import { AdvertisementService } from '../Advertisement.service';
import { Service } from 'app/model/Service';
import { ActivatedRoute, Router } from '@angular/router';
import { locale as english } from '../../../../main/home/i18n/en';
import { locale as turkish } from '../../../../main/home/i18n/tr';
import { locale as arabic } from '../../../../main/home/i18n/ar';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { NameLanguageEditer } from 'app/component/name-language-editer/name-language-editer.component';
import { Advertisement } from 'app/model/Advertisement';

@Component({
    selector: 'app-Advertisement-entry',
    templateUrl: './Advertisement-entry.component.html',
    styleUrls: ['./Advertisement-entry.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AdvertisementEntryComponent implements OnInit {

    
    pageType: string;
    advertisement:Advertisement
    advertisementForm: FormGroup;
    @ViewChild("nameLanguageEditor") nameLanguageEditor :NameLanguageEditer;


    // Private
    private _unsubscribeAll: Subject<any>;
    extras: any;

    /**
     * Constructor
     *
     * @param {ServiceService} _serviceService
     * @param {FormBuilder} _formBuilder
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        private advertisementService: AdvertisementService,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private route: ActivatedRoute,
        private router: Router,
        private _location: Location,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translate:TranslateService
    ) {
        // Set the default
        this.advertisement = new Advertisement();
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, arabic);        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            if (params.get('id')) {
                this.advertisement.id = params.get('id')
                this.pageType = 'edit';
                this.advertisementService.getServiceById(this.advertisement.id, true).then((res: Advertisement) => {
                    this.advertisement = res;
                    this.advertisementForm = this.createServiceForm();
                });

            }
            else {
                if (params.get('tenantId')) {
                    this.advertisement.tenantId = params.get('tenantId');
                }
                this.pageType = 'new';
            }
            this.advertisementForm = this.createServiceForm();
        });

    }
    goBack() {
        this._location.back();
    };


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create service form
     *
     * @returns {FormGroup}
     */
    createServiceForm(): FormGroup {
        return this._formBuilder.group({
            name: [this.advertisement.name],
            //type: [this.advertisement.type],
            tenantId: [this.advertisement.tenantId],
            createdTime: [this.advertisement.createdTime],
            isActivated: [this.advertisement.isActivated],
            mediaGroupType: [this.advertisement.mediaGroupType],

        });
    }

    isValidForm(){
        return this.advertisementForm?.valid 
     }

    /**
     * Save service
     */
    saveService(): void {
        this.advertisement.name = JSON.stringify(this.nameLanguageEditor.deserializedData);
        this.advertisementForm.controls["name"].setValue(this.advertisement.name);
        const data = this.advertisementForm.getRawValue();
        // data.handle = FuseUtils.handleize(data.name);

        this.advertisementService.saveService(data)
            .then(() => {
                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.serviceAdded'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.message, this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }

    /**
     * Add service
     */
    addAvertisement(): void {
        const data = this.advertisementForm.getRawValue();
        data.id = undefined;
        data.createdTime=new Date().toISOString();
        data.mediaGroupType=Number(data.mediaGroupType) ;
        //   data.handle = FuseUtils.handleize(data.name);

        this.advertisementService.addAdvertisement(data)
            .then(() => {

                // Trigger the subscription with new data
                this.advertisementService.onServiceChanged.next(data);

                // Show the success message
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.serviceAdded'),  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
               this.goBack()
                // Change the location with new one

            }).catch(err => {
                this._matSnackBar.open(this.translate.instant('CONFIRM_MSG.error') + err.error.title,  this.translate.instant('ok'), {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }
}
