import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment'; // Update this path based on your environment file's actual location

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  
  constructor(private httpClient: HttpClient) { }

  getLocations(tenantId: string): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.serviceUrl2}/locations/${tenantId}`);
  }

  generateReport(reportData: any): Observable<Blob> {
    // Construct the URL with all query parameters
    const queryParams = `locationId=${reportData.locationId}&tenantId=${reportData.tenantId}&start=${reportData.start}&end=${reportData.end}&renderFormat=${reportData.renderFormat}&extension=pdf&mimeType=application/pdf&reportType=${reportData.reportType}&lng=${reportData.language}`;
    const url = `${environment.serviceUrl2}Report/Reports/Reports?${queryParams}`;

    // Define HTTP headers
    const headers = new HttpHeaders({
      'Authorization': 'Basic YWRtaW46UGEkJFdvUmQ='
    });

    // Make a GET request
    return this.httpClient.get<Blob>(url, {
      headers: headers,
      responseType: 'blob' as 'json'  // Ensure that Angular treats the response as a Blob
    });
  }
}
